import { withIcon } from "@icons/_withIcon";

export const RailFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M11.1404 7.8167C11.4352 5.40933 13.4795 3.6001 15.9048 3.6001H32.0944C34.5197 3.6001 36.564 5.40933 36.8588 7.8167L38.3996 20.4001V34.2001C38.3996 35.5256 37.3251 36.6001 35.9996 36.6001H23.9996H11.9996C10.6741 36.6001 9.59961 35.5256 9.59961 34.2001V20.4001L11.1404 7.8167Z"
        stroke={primary}
        fill="transparent"
        strokeWidth="2.4"
        strokeLinejoin="round"
      />
      <path
        d="M38.3996 20.3999L23.9996 23.3999L9.59961 20.3999"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 9.6001H12"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.1998 42H10.7998"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5996 37.2002L39.5996 44.4002"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4004 37.2002L8.40039 44.4002"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 28.7999C12 27.4744 13.0745 26.3999 14.4 26.3999H18C19.3255 26.3999 20.4 27.4744 20.4 28.7999C20.4 30.1254 19.3255 31.1999 18 31.1999H14.4C13.0745 31.1999 12 30.1254 12 28.7999Z"
        fill={accent}
      />
      <path
        d="M36 28.8002C36 30.1257 34.9255 31.2002 33.6 31.2002L30 31.2002C28.6745 31.2002 27.6 30.1257 27.6 28.8002C27.6 27.4747 28.6745 26.4002 30 26.4002L33.6 26.4002C34.9255 26.4002 36 27.4747 36 28.8002Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 48 48",
  }
);

export const RailFill = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16L6 14V24H26V14L16 16ZM10 18C9.17157 18 8.5 18.6716 8.5 19.5C8.5 20.3284 9.17157 21 10 21H12C12.8284 21 13.5 20.3284 13.5 19.5C13.5 18.6716 12.8284 18 12 18H10ZM20 18C19.1716 18 18.5 18.6716 18.5 19.5C18.5 20.3284 19.1716 21 20 21H22C22.8284 21 23.5 20.3284 23.5 19.5C23.5 18.6716 22.8284 18 22 18H20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49527 5.30411C5.84555 2.55784 8.18255 0.5 10.9511 0.5H21.0495C23.818 0.5 26.155 2.55784 26.5053 5.30411L27.4882 13.0102C27.4963 13.0732 27.5003 13.1365 27.5003 13.2V22C27.5003 23.933 25.9333 25.5 24.0003 25.5H8.00029C6.06729 25.5 4.50029 23.933 4.50029 22V13.2C4.50029 13.1365 4.50432 13.0732 4.51234 13.0102L5.49527 5.30411ZM10.9511 3.5C9.69265 3.5 8.63038 4.43538 8.47116 5.68369L7.50029 13.2953V22C7.50029 22.2761 7.72415 22.5 8.00029 22.5H24.0003C24.2764 22.5 24.5003 22.2761 24.5003 22V13.2953L23.5294 5.68368C23.3702 4.43538 22.3079 3.5 21.0495 3.5H10.9511Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52942 13.7058C4.69189 12.8935 5.48212 12.3667 6.29446 12.5291L16.0003 14.4703L25.7061 12.5291C26.5185 12.3667 27.3087 12.8935 27.4712 13.7058C27.6336 14.5182 27.1068 15.3084 26.2945 15.4709L16.2945 17.4709C16.1003 17.5097 15.9003 17.5097 15.7061 17.4709L5.70612 15.4709C4.89378 15.3084 4.36695 14.5182 4.52942 13.7058Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50029 6C6.50029 5.17157 7.17186 4.5 8.00029 4.5H24.0003C24.8287 4.5 25.5003 5.17157 25.5003 6C25.5003 6.82843 24.8287 7.5 24.0003 7.5H8.00029C7.17186 7.5 6.50029 6.82843 6.50029 6Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00029 29C6.00029 28.1716 6.67186 27.5 7.50029 27.5H24.5003C25.3287 27.5 26.0003 28.1716 26.0003 29C26.0003 29.8284 25.3287 30.5 24.5003 30.5H7.50029C6.67186 30.5 6.00029 29.8284 6.00029 29Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1682 22.7519C21.8575 22.2924 22.7888 22.4787 23.2484 23.1679L27.2484 29.1679C27.7079 29.8572 27.5216 30.7885 26.8323 31.2481C26.143 31.7076 25.2117 31.5213 24.7522 30.8321L20.7522 24.8321C20.2927 24.1428 20.4789 23.2115 21.1682 22.7519Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8323 22.7519C10.143 22.2924 9.21174 22.4787 8.75221 23.1679L4.75221 29.1679C4.29269 29.8572 4.47895 30.7885 5.16824 31.2481C5.85753 31.7076 6.78884 31.5213 7.24836 30.8321L11.2484 24.8321C11.7079 24.1428 11.5216 23.2115 10.8323 22.7519Z"
      />
      <rect x="8" y="2" width="16" height="4" />
    </>
  ),
  {
    viewBox: "0 0 32 32",
  }
);
