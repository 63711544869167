import { withIcon } from "@icons/_withIcon";

// Changelog: name was Profile
export const ProfileFill = withIcon(
  () => (
    <path d="M9.63025 0C4.75135 0 0.630249 4.1211 0.630249 9C0.630249 13.8789 4.75135 18 9.63025 18C14.5091 18 18.6302 13.8789 18.6302 9C18.6302 4.1211 14.5091 0 9.63025 0ZM9.63025 4.5C11.1845 4.5 12.3302 5.6448 12.3302 7.2C12.3302 8.7552 11.1845 9.9 9.63025 9.9C8.07685 9.9 6.93025 8.7552 6.93025 7.2C6.93025 5.6448 8.07685 4.5 9.63025 4.5ZM5.03485 13.2948C5.84215 12.1068 7.18855 11.3148 8.73025 11.3148H10.5302C12.0728 11.3148 13.4183 12.1068 14.2256 13.2948C13.0754 14.526 11.4437 15.3 9.63025 15.3C7.81675 15.3 6.18505 14.526 5.03485 13.2948Z" />
  ),
  {
    viewBox: "0 0 19 18",
  }
);
