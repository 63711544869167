import { withIcon } from "@icons/_withIcon";

// Changelog: name was Plus
export const PlusFill = withIcon(
  () => (
    <path d="M19.8184 8.87499H12.0934V1.14999C12.0934 0.851625 11.9749 0.565477 11.7639 0.354499C11.553 0.14352 11.2668 0.0249939 10.9684 0.0249939C10.6701 0.0249939 10.3839 0.14352 10.1729 0.354499C9.96197 0.565477 9.84344 0.851625 9.84344 1.14999V8.87499H2.11694C1.81858 8.87499 1.53243 8.99352 1.32145 9.2045C1.11047 9.41548 0.991943 9.70163 0.991943 9.99999C0.991943 10.2984 1.11047 10.5845 1.32145 10.7955C1.53243 11.0065 1.81858 11.125 2.11694 11.125H9.84344V18.85C9.84344 19.1484 9.96197 19.4345 10.1729 19.6455C10.3839 19.8565 10.6701 19.975 10.9684 19.975C11.2668 19.975 11.553 19.8565 11.7639 19.6455C11.9749 19.4345 12.0934 19.1484 12.0934 18.85V11.125H19.8184C20.1168 11.125 20.403 11.0065 20.6139 10.7955C20.8249 10.5845 20.9434 10.2984 20.9434 9.99999C20.9434 9.70163 20.8249 9.41548 20.6139 9.2045C20.403 8.99352 20.1168 8.87499 19.8184 8.87499Z" />
  ),
  {
    viewBox: "0 0 21 20",
  }
);
