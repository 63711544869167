import { withIcon } from "@icons/_withIcon";

export const LeafFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M62.5869 1.42051C61.5505 0.193164 59.4504 0.193164 58.4139 1.42051C56.9411 3.16606 22.3301 44.5411 22.3301 67.997C22.3301 91.4529 38.2037 101.081 53.0682 103.508C55.7956 103.945 57.7866 106.236 57.7866 108.908V117.773C57.7866 119.273 59.014 120.5 60.5141 120.5C62.0141 120.5 63.2415 119.273 63.2415 117.773V108.908C63.2415 103.535 59.3413 99.0079 53.941 98.1351C47.8042 97.1259 27.7849 91.9711 27.7849 67.997C27.7849 49.6414 53.0136 16.8577 60.5141 7.4754C68.0418 16.8305 93.2432 49.6142 93.2432 67.997C93.2432 88.3164 81.0516 95.2986 70.8237 97.5896C69.3509 97.9169 68.4236 99.3897 68.7509 100.835C69.0782 102.308 70.551 103.235 71.9965 102.908C89.2066 99.0624 98.6708 86.6526 98.6708 67.997C98.698 44.5411 64.087 3.16606 62.5869 1.42051Z"
        fill={primary}
      />
      <path
        d="M60.5121 95.953C62.0122 95.953 63.2395 94.7257 63.2395 93.2256V86.3798L86.7226 68.106C87.9227 67.1787 88.1136 65.4604 87.2136 64.2876C86.2863 63.0875 84.568 62.8966 83.3952 63.7967L63.2395 79.4794V64.6422L81.186 51.8233C82.4133 50.9505 82.686 49.2322 81.8133 48.0049C80.9405 46.7775 79.2222 46.5048 77.9949 47.3776L63.2122 57.9327V45.4956L73.031 38.1316C74.231 37.2315 74.4765 35.5132 73.5764 34.3132C72.6764 33.1131 70.9581 32.8676 69.758 33.7677L63.2122 38.6771V27.7673C63.2122 26.2673 61.9849 25.0399 60.4848 25.0399C58.9847 25.0399 57.7574 26.2673 57.7574 27.7673V38.6771L51.2115 33.7677C50.0115 32.8676 48.2932 33.1131 47.3931 34.3132C46.4931 35.5132 46.7386 37.2315 47.9386 38.1316L57.7574 45.4956V57.9327L42.9747 47.3776C41.7474 46.5048 40.0564 46.7775 39.1563 48.0049C38.2835 49.2322 38.5563 50.9232 39.7836 51.8233L57.7301 64.6422V79.4794L37.5744 63.7967C36.3743 62.8693 34.6833 63.0875 33.756 64.2876C32.8287 65.4877 33.0469 67.1787 34.2469 68.106L57.7301 86.3798V93.2256C57.7301 94.7257 58.9574 95.953 60.4575 95.953H60.5121Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 121 121",
    fill: "none",
  }
);
