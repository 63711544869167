import { withIcon } from "@icons/_withIcon";

// Changelog: name was MapPinOutline
export const MapPin = withIcon(
  () => (
    <>
      <path d="M12.2081 2.18802C11.1261 1.1181 9.67538 0.503287 8.15408 0.469965C6.63279 0.436643 5.15652 0.987341 4.02872 2.00887C2.90092 3.03039 2.20728 4.44513 2.09036 5.96229C1.97344 7.47945 2.44214 8.98376 3.4001 10.166L7.6171 15.317C7.664 15.3743 7.72305 15.4205 7.78997 15.4523C7.8569 15.484 7.93004 15.5004 8.0041 15.5004C8.07817 15.5004 8.15131 15.484 8.21823 15.4523C8.28516 15.4205 8.34421 15.3743 8.3911 15.317L12.6001 10.166C13.5404 9.02638 14.0213 7.57679 13.9488 6.10112C13.8763 4.62545 13.2556 3.23 12.2081 2.18802ZM8.0001 14.211L4.1691 9.53202C3.57534 8.80672 3.19968 7.92777 3.08579 6.99737C2.9719 6.06698 3.12446 5.12336 3.52574 4.27626C3.92703 3.42915 4.56054 2.71337 5.35261 2.21213C6.14468 1.7109 7.06276 1.44481 8.0001 1.44481C8.93745 1.44481 9.85553 1.7109 10.6476 2.21213C11.4397 2.71337 12.0732 3.42915 12.4745 4.27626C12.8757 5.12336 13.0283 6.06698 12.9144 6.99737C12.8005 7.92777 12.4249 8.80672 11.8311 9.53202L8.0001 14.211Z" />
      <path d="M7.99989 3.53903C7.45282 3.53903 6.91805 3.70126 6.46318 4.00519C6.00831 4.30912 5.65379 4.74111 5.44444 5.24653C5.23509 5.75195 5.18031 6.3081 5.28704 6.84465C5.39376 7.3812 5.6572 7.87406 6.04403 8.26089C6.43086 8.64772 6.92372 8.91116 7.46027 9.01788C7.99682 9.12461 8.55297 9.06983 9.05839 8.86048C9.56381 8.65113 9.9958 8.29661 10.2997 7.84174C10.6037 7.38687 10.7659 6.85209 10.7659 6.30503C10.7651 5.57169 10.4734 4.86861 9.95487 4.35005C9.43631 3.8315 8.73323 3.53983 7.99989 3.53903ZM7.99989 8.07003C7.65056 8.07003 7.30909 7.96643 7.01865 7.77234C6.72821 7.57824 6.50186 7.30237 6.36822 6.97962C6.23459 6.65687 6.19967 6.30173 6.26789 5.95913C6.33612 5.61654 6.50441 5.30187 6.75149 5.05493C6.99857 4.80799 7.31334 4.63987 7.65597 4.57184C7.99861 4.50381 8.35372 4.53893 8.6764 4.67275C8.99908 4.80657 9.27482 5.03307 9.46875 5.32362C9.66268 5.61417 9.76609 5.95571 9.76589 6.30503C9.76536 6.77315 9.5791 7.22193 9.24799 7.55284C8.91689 7.88376 8.46801 8.06977 7.99989 8.07003Z" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);

// Changelog: name was MapPin
export const MapPinFill = withIcon(
  () => (
    <path d="M16 0c-7.1 0-12.8 5.7-12.8 12.8 0 9.6 12.8 19.2 12.8 19.2s12.8-9.6 12.8-19.2c0-7.1-5.7-12.8-12.8-12.8zM16 19.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.9 6.4-6.4 6.4z" />
  ),
  {
    viewBox: "0 0 32 32",
  }
);
// Change log: previous name: `LocationPin`
export const MapPinFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M25.351 21.1168C27.9781 18.4898 27.978 14.2305 25.351 11.6034C22.7239 8.97637 18.4646 8.97637 15.8376 11.6034C13.2105 14.2305 13.2105 18.4898 15.8376 21.1168C18.4646 23.7439 22.7239 23.7439 25.351 21.1168Z"
        fill={accent}
      />
      <path
        d="M19.8205 39.2666C19.9143 39.3813 20.0324 39.4736 20.1662 39.5371C20.3001 39.6005 20.4464 39.6334 20.5945 39.6334C20.7426 39.6334 20.8889 39.6005 21.0227 39.5371C21.1566 39.4736 21.2747 39.3813 21.3685 39.2666L32.1685 26.0726C34.5967 23.1017 35.7906 19.3114 35.5035 15.4852C35.2164 11.6589 33.4702 8.08928 30.6258 5.51402C27.7814 2.93876 24.0563 1.55477 20.2204 1.64813C16.3846 1.74148 12.7312 3.30503 10.0155 6.01561C7.38125 8.63397 5.81879 12.141 5.63384 15.8506C5.44889 19.5601 6.65475 23.2052 9.01548 26.0726L19.8205 39.2666ZM11.4295 7.43361C13.7832 5.09093 16.9463 3.74098 20.2662 3.66227C23.5862 3.58356 26.8097 4.78208 29.2718 7.01059C31.7339 9.23909 33.2467 12.3276 33.4982 15.6389C33.7497 18.9502 32.7207 22.2318 30.6235 24.8066L20.5945 37.0546L10.5655 24.8066C8.52098 22.3229 7.47649 19.1659 7.63628 15.953C7.79607 12.74 9.14864 9.70218 11.4295 7.43361Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);

// Change log: previous name: `Destinations`
export const MapPinCircleFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M19.226 30.9056C19.3198 31.0203 19.4379 31.1126 19.5717 31.1761C19.7056 31.2395 19.8519 31.2724 20 31.2724C20.1481 31.2724 20.2944 31.2395 20.4283 31.1761C20.5621 31.1126 20.6802 31.0203 20.774 30.9056L26.504 23.9056C27.5122 22.6742 28.1502 21.1819 28.3437 19.6022C28.5371 18.0225 28.2782 16.4203 27.5969 14.982C26.9156 13.5436 25.84 12.3283 24.4952 11.4772C23.1503 10.6261 21.5915 10.1743 20 10.1743C18.4085 10.1743 16.8497 10.6261 15.5048 11.4772C14.16 12.3283 13.0844 13.5436 12.4031 14.982C11.7218 16.4203 11.4629 18.0225 11.6563 19.6022C11.8498 21.1819 12.4878 22.6742 13.496 23.9056L19.226 30.9056ZM20 14.8516C20.7516 14.8516 21.4863 15.0745 22.1112 15.492C22.7361 15.9096 23.2231 16.5031 23.5107 17.1974C23.7984 17.8918 23.8736 18.6558 23.727 19.393C23.5804 20.1301 23.2184 20.8072 22.687 21.3386C22.1556 21.8701 21.4785 22.232 20.7413 22.3786C20.0042 22.5252 19.2402 22.45 18.5458 22.1624C17.8514 21.8747 17.258 21.3877 16.8404 20.7628C16.4229 20.1379 16.2 19.4032 16.2 18.6516C16.2 17.6438 16.6004 16.6772 17.313 15.9646C18.0256 15.252 18.9922 14.8516 20 14.8516Z"
        fill={accent}
      />
      <path
        d="M20 1.63361C16.2421 1.63361 12.5687 2.74794 9.44416 4.83568C6.31962 6.92343 3.88435 9.89082 2.44628 13.3626C1.00822 16.8344 0.631954 20.6547 1.36507 24.3403C2.09819 28.026 3.90777 31.4114 6.56496 34.0686C9.22216 36.7258 12.6076 38.5354 16.2933 39.2685C19.9789 40.0016 23.7992 39.6254 27.271 38.1873C30.7428 36.7493 33.7102 34.314 35.7979 31.1894C37.8857 28.0649 39 24.3915 39 20.6336C38.9944 15.5962 36.9909 10.7667 33.4289 7.20472C29.8669 3.64273 25.0374 1.63916 20 1.63361ZM20 37.6336C16.6377 37.6336 13.3509 36.6366 10.5553 34.7686C7.75966 32.9006 5.58073 30.2456 4.29404 27.1392C3.00735 24.0329 2.67069 20.6147 3.32664 17.3171C3.98259 14.0194 5.60168 10.9903 7.97918 8.61279C10.3567 6.2353 13.3858 4.6162 16.6835 3.96026C19.9811 3.30431 23.3993 3.64096 26.5056 4.92765C29.612 6.21434 32.267 8.39328 34.135 11.1889C36.003 13.9845 37 17.2713 37 20.6336C36.995 25.1407 35.2023 29.4618 32.0153 32.6489C28.8282 35.8359 24.5071 37.6286 20 37.6336Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);

export const MapPinCircleFill = withIcon(
  () => (
    <>
      <path d="M8 15.5C9.48336 15.5 10.9334 15.0601 12.1668 14.236C13.4001 13.4119 14.3614 12.2406 14.9291 10.8701C15.4968 9.49968 15.6453 7.99168 15.3559 6.53683C15.0665 5.08197 14.3522 3.7456 13.3033 2.6967C12.2544 1.64781 10.918 0.933503 9.46318 0.644114C8.00832 0.354725 6.50032 0.50325 5.12987 1.07091C3.75943 1.63856 2.58809 2.59986 1.76398 3.83323C0.939867 5.0666 0.5 6.51664 0.5 8C0.502118 9.98848 1.29297 11.8949 2.69904 13.301C4.1051 14.707 6.01153 15.4979 8 15.5ZM5.486 4.715C6.13074 4.06826 6.99964 3.69443 7.91256 3.67103C8.82549 3.64763 9.7124 3.97644 10.3894 4.58931C11.0665 5.20217 11.4817 6.05207 11.549 6.96281C11.6163 7.87355 11.3306 8.77526 10.751 9.481L8.387 12.369C8.3401 12.4263 8.28106 12.4725 8.21413 12.5042C8.1472 12.536 8.07406 12.5524 8 12.5524C7.92594 12.5524 7.8528 12.536 7.78587 12.5042C7.71894 12.4725 7.6599 12.4263 7.613 12.369L5.249 9.481C4.68776 8.79981 4.40099 7.93365 4.44482 7.05212C4.48866 6.17059 4.85995 5.33716 5.486 4.715Z" />
      <path d="M8 8.73206C8.82843 8.73206 9.5 8.06048 9.5 7.23206C9.5 6.40363 8.82843 5.73206 8 5.73206C7.17157 5.73206 6.5 6.40363 6.5 7.23206C6.5 8.06048 7.17157 8.73206 8 8.73206Z" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);
