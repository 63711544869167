import { withIcon } from "@icons/_withIcon";

export const SignalFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6825 6.73407C16.4775 4.20213 20.0228 2.69312 23.8764 2.69312C27.848 2.69312 31.4925 4.29606 34.3254 6.96995C34.9189 7.53006 34.9186 8.584 34.3233 9.22672C33.7671 9.8272 32.8708 9.82356 32.2179 9.24341C29.9302 7.21067 27.0322 5.99115 23.876 5.99115C20.8184 5.99115 18.0028 7.1357 15.7499 9.05556C15.0883 9.61939 14.1918 9.6043 13.6452 8.98957C13.0605 8.33199 13.081 7.27892 13.6825 6.73407ZM17.6568 11.242C19.4124 9.82165 21.5596 8.98979 23.876 8.98979C26.2695 8.98979 28.4827 9.87804 30.2696 11.3862C30.8706 11.8935 30.8846 12.9391 30.284 13.5876C29.731 14.1846 28.822 14.1834 28.0892 13.6602C26.8748 12.7931 25.4288 12.2878 23.8755 12.2878C22.3798 12.2878 20.9834 12.7564 19.7974 13.5656C19.0567 14.0709 18.1474 14.0527 17.6041 13.4417C17.0141 12.7782 17.0489 11.7339 17.6568 11.242ZM21.8161 15.7786C22.4465 15.4618 23.143 15.2865 23.8755 15.2865C24.6442 15.2865 25.3734 15.4796 26.0281 15.8267C26.3834 16.0151 26.574 16.3762 26.5884 16.8155C26.6029 17.2598 26.4313 17.747 26.1006 18.104L25.0133 19.2779C24.3793 19.9623 23.3932 19.9521 22.7701 19.2514L21.7044 18.0529C21.3798 17.6878 21.217 17.1964 21.2396 16.7525C21.2618 16.3137 21.4587 15.9582 21.8161 15.7786Z"
        fill={accent}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.7842C8.68629 23.7842 6 26.4705 6 29.7842V44.3117C6 44.864 6.44772 45.3117 7 45.3117H8C8.55228 45.3117 9 44.864 9 44.3117V29.7842C9 28.1273 10.3431 26.7842 12 26.7842H36C37.6569 26.7842 39 28.1273 39 29.7842V44.3117C39 44.864 39.4477 45.3117 40 45.3117H41C41.5523 45.3117 42 44.864 42 44.3117V29.7842C42 26.4705 39.3137 23.7842 36 23.7842H12ZM16.6641 30.0413C16.6641 29.4025 17.1818 28.8848 17.8206 28.8848H26.4119C27.0506 28.8848 27.5684 29.4025 27.5684 30.0413C27.5684 30.68 27.0506 31.1978 26.4119 31.1978H17.8206C17.1818 31.1978 16.6641 30.68 16.6641 30.0413ZM30.1956 28.8848C29.5568 28.8848 29.0391 29.4025 29.0391 30.0413C29.0391 30.68 29.5568 31.1978 30.1956 31.1978C30.8343 31.1978 31.3521 30.68 31.3521 30.0413C31.3521 29.4025 30.8343 28.8848 30.1956 28.8848Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 48 48",
    fill: "none",
  }
);
