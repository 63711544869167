/* eslint-disable no-console */
import constants from "./constants";

// for the events that require specifically-cased strings add the keys here:
const nocaps = [
  "userId",
  "hashedEmail",
  "fieldName",
  "fieldValue",
  "outboundUrl",
  "captureType",
  "capturePlacement",
  "subscriptionPreference",
  "signUpType",
];

export const sanitize = payload => {
  /**
   * This function is only concerned with protective formating (uppcasing)
   * of the values in the object. It will not add/remove fields to match
   * the schema. Errant payloads will not validate and throw.
   */
  const sanitized = Object.entries(payload).reduce((acc, [k, v]) => {
    if (typeof payload[k] === "string") {
      acc[k] = nocaps.includes(k) ? v : v.toUpperCase();
      return acc;
    }

    if (typeof payload[k] === "boolean" || typeof payload[k] === "number") {
      acc[k] = payload[k];
      return acc;
    }

    if (Array.isArray(payload[k])) {
      acc[k] = payload[k].reduce((arr, obj) => {
        const f = sanitize(obj);
        arr.push(f);
        return arr;
      }, []);

      return acc;
    }

    if (typeof payload[k] === "object") {
      acc[k] = sanitize(payload[k]);
      return acc;
    }

    if (typeof payload[k] === "boolean") {
      acc[k] = v;
      return acc;
    }

    return acc;
  }, {});

  return sanitized;
};

/**
 * I would love to store these in something like the `CohesionContext` state
 * but because this isn't a React component, that can't be done.
 *
 * Refact: Possible.
 */
const queue = [];
let interval;
const enqueue = event => {
  queue.push(event);

  if (!interval) {
    interval = setInterval(() => {
      if (typeof window !== "undefined" && "tagular" in window) {
        clearInterval(interval);
        // eslint-disable-next-line no-use-before-define
        queue.forEach(beam);
      }
    }, 300);
  }
};

export const beam = (event, payload) => {
  const body = {
    "@type": event,
    ...payload,
  };

  if (typeof window !== "undefined" && "tagular" in window) {
    /**
     * Inexplicably, `Identify` events do not follow the even convention
     * and need to be handled specifically. The rest have their appropriate
     * name as the last key in the object chain.
     */
    const eventArr = event.split(".");
    const label = event.includes("core")
      ? eventArr[eventArr.length - 2]
      : eventArr.pop();

    const supressWebContext = ["CartStarted"].includes(label);

    try {
      /**
       * Note: This mutates the `body` object (😬) so the logged values for `body` will
       * be different above and below this point, though there is no indication in this
       * code.
       */
      window.tagular("beam", label, body, false, !supressWebContext);
    } catch (e) {
      console.error("Tagular:", e);
    }

    /**
     * Logging the intended output is quite helpful.
     */
    if (process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV !== "production") {
      console.groupCollapsed(
        `%cTagular: ${event}`,
        "background: #2980b9; color: #fff; padding: 3px 6px;"
      );
      Object.entries(body).forEach(([key, value]) => {
        console.log(`%c${key}:`, "color: #2980b9", value);
      });
      console.groupEnd();
    }
  } else {
    /**
     * If the code is here, something with Cohesion or Tagular has gone awry and
     * the events will need to be placed in a queue for processing later.
     */
    console.warn("Tagular: Not found in window");
    enqueue({ event, payload });
  }
};

export const tagular = (event, payload) => {
  /**
   * adBlockCaptured is the only event that has no payload
   */
  if (event === "adBlockCaptured") {
    beam(constants.events[event], {});
    return;
  }

  const sanitized = sanitize(payload);

  beam(constants.events[event], sanitized);
};
