import { withIcon } from "@icons/_withIcon";

// Changelog: name was Info
export const InfoFill = withIcon(
  () => (
    <path d="M8 15.5C9.48336 15.5 10.9334 15.0601 12.1668 14.236C13.4001 13.4119 14.3614 12.2406 14.9291 10.8701C15.4968 9.49968 15.6453 7.99168 15.3559 6.53683C15.0665 5.08197 14.3522 3.7456 13.3033 2.6967C12.2544 1.64781 10.918 0.933503 9.46318 0.644114C8.00832 0.354725 6.50032 0.50325 5.12987 1.07091C3.75943 1.63856 2.58809 2.59986 1.76398 3.83323C0.939867 5.0666 0.5 6.51664 0.5 8C0.502118 9.98848 1.29297 11.8949 2.69904 13.301C4.1051 14.707 6.01153 15.4979 8 15.5ZM8 3.565C8.19778 3.565 8.39112 3.62365 8.55557 3.73353C8.72002 3.84342 8.84819 3.99959 8.92388 4.18232C8.99957 4.36505 9.01937 4.56611 8.98079 4.76009C8.9422 4.95407 8.84696 5.13226 8.70711 5.27211C8.56725 5.41196 8.38907 5.5072 8.19509 5.54579C8.00111 5.58437 7.80004 5.56457 7.61732 5.48888C7.43459 5.4132 7.27841 5.28502 7.16853 5.12057C7.05865 4.95612 7 4.76278 7 4.565C7 4.29979 7.10536 4.04543 7.29289 3.8579C7.48043 3.67036 7.73478 3.565 8 3.565ZM8 12.75C7.80109 12.75 7.61032 12.671 7.46967 12.5303C7.32902 12.3897 7.25 12.1989 7.25 12V7.565C7.25 7.36609 7.32902 7.17533 7.46967 7.03467C7.61032 6.89402 7.80109 6.815 8 6.815C8.19891 6.815 8.38968 6.89402 8.53033 7.03467C8.67098 7.17533 8.75 7.36609 8.75 7.565V12C8.75 12.1989 8.67098 12.3897 8.53033 12.5303C8.38968 12.671 8.19891 12.75 8 12.75Z" />
  ),
  {
    viewBox: "0 0 16 16",
  }
);
