import { withIcon } from "@icons/_withIcon";

export const CloseX = withIcon(
  () => (
    <path
      d="M15.6587 15.66L1.00067 1M15.6587 1.00133L1 15.66L15.6587 1.00133Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeMiterlimit="10"
    />
  ),
  {
    viewBox: "0 0 17 17",
  }
);
