import { withIcon } from "@icons/_withIcon";

export const Share = withIcon(
  () => (
    <path d="M14.0767 0.230831C13.9693 0.120113 13.83 0.0446195 13.6777 0.0145471C13.5254 -0.0155254 13.3674 0.0012718 13.225 0.06267C13.0877 0.123918 12.971 0.222673 12.8887 0.347277C12.8063 0.471881 12.7618 0.617133 12.7604 0.765887V5.48968C9.31333 5.68835 6.07309 7.17864 3.70152 9.65617C1.32995 12.1337 0.00596882 15.4115 0 18.8202V21.2815C6.77043e-05 21.4386 0.0491621 21.5918 0.140602 21.7204C0.232043 21.849 0.361388 21.9466 0.511037 22H0.774299C0.888847 21.9983 1.00176 21.9729 1.10578 21.9255C1.2098 21.8781 1.30261 21.8098 1.37825 21.7248L2.71004 20.1961C3.94902 18.7401 5.47753 17.5506 7.20094 16.7013C8.92436 15.8519 10.806 15.3608 12.7295 15.2583V19.9362C12.7279 20.0876 12.771 20.2361 12.8535 20.3637C12.936 20.4913 13.0543 20.5924 13.194 20.6547C13.3377 20.7081 13.4937 20.7207 13.6443 20.691C13.7949 20.6612 13.934 20.5904 14.0458 20.4866L23.771 10.8861C23.8435 10.815 23.9011 10.7305 23.9405 10.6374C23.9798 10.5442 24 10.4443 24 10.3434C24 10.2425 23.9798 10.1426 23.9405 10.0494C23.9011 9.9563 23.8435 9.87176 23.771 9.8007L14.0767 0.230831ZM14.309 18.0712V14.4481C14.309 14.2453 14.2275 14.0509 14.0823 13.9076C13.937 13.7642 13.7401 13.6837 13.5347 13.6837C11.251 13.6816 8.99447 14.1732 6.92375 15.1239C4.85303 16.0747 3.01834 17.4616 1.5486 19.1871V18.8202C1.55063 17.2664 1.86267 15.7281 2.46691 14.2933C3.07115 12.8585 3.95575 11.5553 5.0702 10.4579C6.18466 9.36062 7.50714 8.49074 8.96215 7.89796C10.4172 7.30518 11.9762 7.00111 13.5502 7.00312C13.7556 7.00312 13.9525 6.92259 14.0977 6.77924C14.2429 6.6359 14.3245 6.44148 14.3245 6.23875V2.61566L22.0675 10.3358L14.309 18.0712Z" />
  ),
  {
    viewBox: "0 0 24 22",
  }
);
