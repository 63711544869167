module.exports = {
  content: [
    "./pages/*.{js,ts,jsx,tsx,mdx}",
    "./pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./containers/**/*.{js,ts,jsx,tsx,mdx}",
    "./utils/**/*.{js,ts,jsx,tsx,mdx}",
    "./components/**/*.{js,ts,jsx,tsx,mdx}",
    "./.storybook/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    colors: {
      current: "currentColor",
      transparent: {
        DEFAULT: "transparent",
        20: "rgba(18,18,18,.2)",
        40: "rgba(18,18,18,.4)",
        60: "rgba(18,18,18,.6)",
        80: "rgba(18,18,18,.8)",
      },
      white: "#ffffff",
      black: {
        DEFAULT: "#121212",
        400: "#4b4b4b",
        300: "#848484",
        200: "#c2c2c2",
        100: "#f6f6f6",
      },
      blue: {
        DEFAULT: "#0057d9",
        400: "#246fde",
        300: "#689ce9",
        200: "#acc9f4",
        100: "#f0f6ff",
      },
      purple: {
        DEFAULT: "#7d3371",
        300: "#b78eb0",
        100: "#f1e8ef",
      },
      green: {
        DEFAULT: "#4c7953",
        300: "#9db7a1",
        100: "#eef4ef",
      },
      gold: {
        DEFAULT: "#926e08",
        300: "#c8b47b",
        100: "#fefaed",
      },
      red: {
        DEFAULT: "#da241e",
        300: "#ec8b88",
        100: "#fdf2f1",
      },
      sand: {
        DEFAULT: "#FBF7EC",
        100: "#FDFBF6",
        200: "#FAF7F3",
        300: "#EFE9DB",
      },
      link: {
        DEFAULT: "#0057d9",
      },
      slate: {
        DEFAULT: "#374559",
      },
      bit: {
        green: "#607D6D",
        red: "#BA5632",
        pink: "#E3716C",
        purple: "#563753",
      },
      guide: {
        purple: "#3A1FE6",
      },
      itinerary: {
        blue: {
          500: "#0b2e62",
          200: "#CCDDF7",
          50: "#F0F7FC",
        },
        green: {
          500: "#1C6C39",
          100: "#E7FCBF",
        },
        red: {
          500: "#840C13",
        },
      },
    },
    container: {
      center: true,
      padding: {
        DEFAULT: "1rem",
        sm: "1.4rem",
        md: "2rem",
      },
    },
    fontFamily: {
      display: ["Pangea Display", "Helvetica", "Arial", "sans-serif"],
      sans: ["Pangea Text", "Helvetica", "Arial", "sans-serif"],
      mono: ["Apercu Mono", "Courier New", "Courier", "monospace"],
      mencken: ["Mencken Std Narrow", "Georgia"],
      tusker: ["Tusker Grotesk", "Helvetica", "Impact"],
      rama: ["Rama Gothic", "Helvetica", "Impact"],
      instrument: ["Instrument Serif", "Helvetica", "Impact"],
      neue: ["Neue Haas Grotesk Text", "Helvetica", "Arial"],
    },
    fontSize: {
      tiny: "8px",
      xs: ["12px", 1.3],
      sm: ["14px", 1.4],
      base: ["16px", 1.5],
      md: ["18px", 1.45],
      lg: ["20px", 1.5],
      xl: ["24px", 1.3],
      "2xl": ["32px", 1.3],
      "3xl": ["40px", 1.1],
      "4xl": ["48px", 1.2],
      "5xl": ["64px", 1],
      "6xl": ["88px", 1.1],
    },
    fontWeight: {
      light: 300,
      normal: 400,
      semibold: 500,
      bold: 600,
      extrabold: 700,
      black: 900,
    },
    lineHeight: {
      none: 1,
      tight: "1.1",
      normal: "1.2",
      relaxed: "1.3",
      loose: "1.5",
    },
    borderRadius: {
      none: "0px",
      sm: "4px",
      md: "8px",
      DEFAULT: "16px",
      lg: "26px",
      pill: "9999px",
      full: "90%",
      window: "100000px",
      marker: "50% 50% 50% 0",
    },
    shadow: {
      /**
       * TODO:
       *
       * These have been placeholder/afterthought for three years.
       * inspo: https://manuarora.in/boxshadows
       */
      DEFAULT: "0px 2px 6px 1px rgba(70, 70, 70, 0.13)",
      md: "0px 4px 25px 2px rgba(70, 70, 70, 0.2)",
      lg: "0px 5px 35px 3px rgba(70, 70, 70, 0.25)",
      "lg-inverted": "0px 5px 35px -3px rgba(70, 70, 70, 0.25)",
      blur: "12px 0 10px -4px #121212 inset, -12px 0 15px -4px #121212 inset",
    },
    extend: {
      keyframes: {
        fadeIn: {
          "0%": { opacity: "0" },
          "100%": { opacity: "1" },
        },
        zeroBorder: {
          to: { borderRadius: 0 },
        },
        halfSpin: {
          from: {
            transform: "rotate(0deg)",
          },
          to: {
            transform: "rotate(180deg)",
          },
        },
      },
      // all spacing should be calculated from a 4px base
      // example: 28rem * 4 = 112
      spacing: {
        112: "28rem",
        128: "32rem",
        144: "36rem",
      },
      padding: {
        "4/3": "75%",
        "16/9": "56.25%",
      },
      width: {
        55: "55%",
        88: "22rem",
        90: "90%",
        screen: "100vw",
        content: "max-content",
      },
      maxWidth: {
        xxs: "15.625rem",
      },
      minHeight: {
        0: 0,
        "screen-1/2": "50vh",
        "screen-3/5": "60vh",
        "screen-2/3": "66vh",
        "screen-3/4": "75vh",
        "screen-4/5": "80vh",
        144: "36rem",
        184: "48rem",
      },
      height: {
        five: "5px",
        88: "22rem",
        90: "90%",
        144: "36rem",
        184: "48rem",
        204: "54rem",
        "screen-1/3": "33vh",
        "screen-2/5": "40vh",
        "screen-1/2": "50vh",
        "screen-2/3": "66vh",
        "screen-3/4": "75vh",
        "screen-4/5": "80vh",
        "screen-90": "90vh",
        "screen-dvh": "100dvh",
      },
      maxHeight: {
        "screen-1/3": "33vh",
        "screen-2/5": "40vh",
        "screen-1/2": "50vh",
        "screen-3/5": "50vh",
        "screen-2/3": "66vh",
        "screen-3/4": "75vh",
        "screen-4/5": "80vh",
        "screen-90": "90vh",
      },
      transitionProperty: {
        height: "height",
        top: "top",
      },
      flex: {
        2: "2 2 0%",
      },
      backgroundImage: {
        "hero-v-gradient":
          "linear-gradient(0deg, rgba(18,18,18,.6), rgba(18,18,18,.6) 51%, rgba(18,18,18,0))",
        "textured-background": "url('/images/texture.svg')",
      },
      backgroundSize: {
        "size-200v": "200% 200%",
      },
      backgroundPosition: {
        "transition-v-half": "0 var(--tw-translate-x, 100%)",
      },
      animation: {
        "half-spin": "halfSpin 4.99s linear",
      },
      listStyleType: {
        circle: "circle",
      },
    },
  },
  plugins: [
    // eslint-disable-next-line global-require
    require("@tailwindcss/forms"),
    // eslint-disable-next-line global-require, import/no-extraneous-dependencies
    require("@tailwindcss/container-queries"),
  ],
};
