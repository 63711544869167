import { withIcon } from "@icons/_withIcon";

// Changelog: name was Hamburger
export const HamburgerFill = withIcon(
  () => (
    <>
      <path d="M1 11.25H13C13.1989 11.25 13.3897 11.171 13.5303 11.0303C13.671 10.8897 13.75 10.6989 13.75 10.5C13.75 10.3011 13.671 10.1103 13.5303 9.96967C13.3897 9.82902 13.1989 9.75 13 9.75H1C0.801088 9.75 0.610323 9.82902 0.46967 9.96967C0.329018 10.1103 0.25 10.3011 0.25 10.5C0.25 10.6989 0.329018 10.8897 0.46967 11.0303C0.610323 11.171 0.801088 11.25 1 11.25Z" />
      <path d="M1 6.75H11C11.1989 6.75 11.3897 6.67098 11.5303 6.53033C11.671 6.38968 11.75 6.19891 11.75 6C11.75 5.80109 11.671 5.61032 11.5303 5.46967C11.3897 5.32902 11.1989 5.25 11 5.25H1C0.801088 5.25 0.610323 5.32902 0.46967 5.46967C0.329018 5.61032 0.25 5.80109 0.25 6C0.25 6.19891 0.329018 6.38968 0.46967 6.53033C0.610323 6.67098 0.801088 6.75 1 6.75Z" />
      <path d="M13 0.75H1C0.801088 0.75 0.610323 0.829018 0.46967 0.96967C0.329018 1.11032 0.25 1.30109 0.25 1.5C0.25 1.69891 0.329018 1.88968 0.46967 2.03033C0.610323 2.17098 0.801088 2.25 1 2.25H13C13.1989 2.25 13.3897 2.17098 13.5303 2.03033C13.671 1.88968 13.75 1.69891 13.75 1.5C13.75 1.30109 13.671 1.11032 13.5303 0.96967C13.3897 0.829018 13.1989 0.75 13 0.75Z" />
    </>
  ),
  {
    viewBox: "0 0 14 12",
  }
);

export const HamburgerDrag = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C3.71402e-08 4.69317 0.344396 4.44444 0.76923 4.44444H19.2308C19.6556 4.44444 20 4.69317 20 5C20 5.30682 19.6556 5.55556 19.2308 5.55556L0.76923 5.55555C0.344396 5.55555 -3.71402e-08 5.30682 0 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.555555C3.71402e-08 0.24873 0.344396 -2.68235e-08 0.76923 0L19.2308 0C19.6556 2.68235e-08 20 0.248731 20 0.555556C20 0.862381 19.6556 1.11111 19.2308 1.11111L0.76923 1.11111C0.344396 1.11111 -3.71402e-08 0.86238 0 0.555555Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.44444C3.71402e-08 9.13762 0.344396 8.88889 0.76923 8.88889L19.2308 8.88889C19.6556 8.88889 20 9.13762 20 9.44444C20 9.75127 19.6556 10 19.2308 10L0.76923 10C0.344396 10 -3.71402e-08 9.75127 0 9.44444Z"
      />
    </>
  ),
  {
    viewBox: "0 0 20 10",
  }
);
