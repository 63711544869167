import { withIcon } from "@icons/_withIcon";

// Changelog: name was ErrorX
export const ErrorXFill = withIcon(
  () => (
    <path d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.4979 6.01153 14.707 4.1051 13.301 2.69904C11.8949 1.29297 9.98848 0.502118 8 0.5ZM10.53 9.47C10.6037 9.53866 10.6628 9.62146 10.7038 9.71346C10.7448 9.80546 10.7668 9.90477 10.7686 10.0055C10.7704 10.1062 10.7518 10.2062 10.7141 10.2996C10.6764 10.393 10.6203 10.4778 10.549 10.549C10.4778 10.6203 10.393 10.6764 10.2996 10.7141C10.2062 10.7518 10.1062 10.7704 10.0055 10.7686C9.90478 10.7668 9.80546 10.7448 9.71347 10.7038C9.62147 10.6628 9.53867 10.6037 9.47 10.53L8 9.061L6.53 10.53C6.38783 10.6625 6.19978 10.7346 6.00548 10.7312C5.81118 10.7277 5.62579 10.649 5.48838 10.5116C5.35097 10.3742 5.27226 10.1888 5.26883 9.99452C5.2654 9.80022 5.33752 9.61217 5.47 9.47L6.939 8L5.47 6.53C5.33752 6.38783 5.2654 6.19978 5.26883 6.00548C5.27226 5.81118 5.35097 5.62579 5.48838 5.48838C5.62579 5.35097 5.81118 5.27225 6.00548 5.26883C6.19978 5.2654 6.38783 5.33752 6.53 5.47L8 6.939L9.47 5.47C9.61218 5.33752 9.80023 5.2654 9.99453 5.26883C10.1888 5.27225 10.3742 5.35097 10.5116 5.48838C10.649 5.62579 10.7278 5.81118 10.7312 6.00548C10.7346 6.19978 10.6625 6.38783 10.53 6.53L9.061 8L10.53 9.47Z" />
  ),
  {
    viewBox: "0 0 16 16",
  }
);
