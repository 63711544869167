import { withIcon } from "@icons/_withIcon";

// Change log: previous name: `Checkmark: PlanningToolkit`
export const ThingsToKnowFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M31.2265 12.2068C29.6059 12.2068 28.0216 12.6873 26.6741 13.5877C25.3266 14.4881 24.2764 15.7678 23.6562 17.265C23.036 18.7623 22.8738 20.4098 23.1899 21.9993C23.5061 23.5888 24.2865 25.0488 25.4324 26.1948C26.5784 27.3407 28.0384 28.1211 29.6279 28.4373C31.2174 28.7535 32.8649 28.5912 34.3622 27.971C35.8594 27.3508 37.1392 26.3006 38.0395 24.9531C38.9399 23.6056 39.4205 22.0214 39.4205 20.4008C39.4205 18.2276 38.5572 16.1434 37.0205 14.6067C35.4838 13.0701 33.3997 12.2068 31.2265 12.2068ZM35.4585 18.7008L30.6335 23.5248C30.446 23.7122 30.1916 23.8175 29.9265 23.8175C29.6613 23.8175 29.407 23.7122 29.2195 23.5248L26.9945 21.3008C26.8068 21.1132 26.7014 20.8589 26.7013 20.5936C26.7012 20.3283 26.8065 20.0739 26.994 19.8863C27.1815 19.6986 27.4359 19.5931 27.7011 19.5931C27.9664 19.593 28.2208 19.6982 28.4085 19.8858L29.9265 21.4008L34.0445 17.2838C34.2324 17.0962 34.4871 16.9911 34.7525 16.9913C35.018 16.9916 35.2725 17.0974 35.46 17.2853C35.6475 17.4732 35.7527 17.7279 35.7524 17.9933C35.7521 18.2588 35.6464 18.5132 35.4585 18.7008Z"
        fill={accent}
      />
      <path
        d="M1.78748 7.36376V34.2638C1.78746 34.4273 1.82756 34.5884 1.90427 34.7329C1.98099 34.8774 2.09198 35.0008 2.2275 35.0924C2.36303 35.184 2.51896 35.241 2.68162 35.2583C2.84428 35.2756 3.00871 35.2528 3.16048 35.1918C8.20738 33.1513 13.8496 33.1513 18.8965 35.1918H18.9085C19.0125 35.2309 19.1224 35.2522 19.2335 35.2548C19.2455 35.2548 19.2575 35.2618 19.2695 35.2618C19.2815 35.2618 19.3065 35.2548 19.3255 35.2538C19.3835 35.2506 19.4411 35.2419 19.4975 35.2278C19.5313 35.2214 19.5647 35.2131 19.5975 35.2028C19.6135 35.1968 19.6295 35.1958 19.6455 35.1898C24.6926 33.1502 30.3344 33.1502 35.3815 35.1898C35.5333 35.2508 35.6977 35.2736 35.8603 35.2563C36.023 35.239 36.1789 35.182 36.3145 35.0904C36.45 34.9988 36.561 34.8754 36.6377 34.7309C36.7144 34.5864 36.7545 34.4253 36.7545 34.2618V30.3498C36.7545 30.0845 36.6491 29.8302 36.4616 29.6427C36.2741 29.4551 36.0197 29.3498 35.7545 29.3498C35.4893 29.3498 35.2349 29.4551 35.0474 29.6427C34.8598 29.8302 34.7545 30.0845 34.7545 30.3498V32.8368C30.0537 31.2738 24.9733 31.2738 20.2725 32.8368V8.05076C24.9468 6.33177 30.0802 6.33177 34.7545 8.05076V11.0938C34.7545 11.359 34.8598 11.6133 35.0474 11.8009C35.2349 11.9884 35.4893 12.0938 35.7545 12.0938C36.0197 12.0938 36.2741 11.9884 36.4616 11.8009C36.6491 11.6133 36.7545 11.359 36.7545 11.0938V7.36376C36.7545 7.16417 36.6948 6.96914 36.5831 6.80375C36.4713 6.63837 36.3127 6.51021 36.1275 6.43576C30.7284 4.25903 24.7045 4.21257 19.2725 6.30576C13.8405 4.21257 7.81653 4.25903 2.41748 6.43576C2.23173 6.50974 2.07243 6.6377 1.96013 6.80312C1.84782 6.96854 1.78769 7.16383 1.78748 7.36376ZM3.78748 8.05076C8.46188 6.33229 13.5951 6.33229 18.2695 8.05076V32.8368C13.5686 31.2741 8.48836 31.2741 3.78748 32.8368V8.05076Z"
        fill={primary}
      />
      <path
        d="M6.36348 14.9267C6.43457 14.926 6.50534 14.9173 6.57448 14.9007C9.46408 14.2725 12.4539 14.263 15.3475 14.8727C15.6071 14.9268 15.8776 14.8756 16.0995 14.7303C16.3213 14.5849 16.4764 14.3574 16.5305 14.0977C16.5846 13.8381 16.5333 13.5676 16.388 13.3457C16.2427 13.1239 16.0151 12.9689 15.7555 12.9147C12.5891 12.2477 9.31747 12.2583 6.15548 12.9457C5.91374 12.9973 5.69966 13.1366 5.55455 13.3367C5.40944 13.5368 5.34358 13.7835 5.36967 14.0293C5.39577 14.2751 5.51197 14.5025 5.69587 14.6677C5.87976 14.8329 6.11831 14.9241 6.36548 14.9237L6.36348 14.9267Z"
        fill={primary}
      />
      <path
        d="M6.36348 21.2668C6.43439 21.2668 6.50511 21.2594 6.57448 21.2448C9.46407 20.6167 12.4538 20.6068 15.3475 21.2158C15.6071 21.2699 15.8776 21.2186 16.0995 21.0733C16.3213 20.9279 16.4764 20.7004 16.5305 20.4408C16.5846 20.1811 16.5333 19.9106 16.388 19.6888C16.2427 19.4669 16.0151 19.3119 15.7555 19.2578C12.5891 18.5911 9.3174 18.602 6.15548 19.2898C5.91478 19.3423 5.70193 19.4818 5.55777 19.6816C5.4136 19.8814 5.34827 20.1274 5.37429 20.3724C5.40032 20.6174 5.51587 20.8442 5.69879 21.0092C5.8817 21.1743 6.11911 21.266 6.36548 21.2668H6.36348Z"
        fill={primary}
      />
      <path
        d="M13.1625 27.2177C13.2932 27.2309 13.4251 27.2181 13.5509 27.1803C13.6766 27.1424 13.7937 27.0801 13.8953 26.997C13.997 26.9139 14.0813 26.8116 14.1435 26.6959C14.2056 26.5802 14.2444 26.4534 14.2575 26.3227C14.2706 26.1921 14.2579 26.0601 14.22 25.9344C14.1821 25.8086 14.1199 25.6916 14.0368 25.5899C13.9537 25.4882 13.8513 25.4039 13.7356 25.3418C13.62 25.2796 13.4932 25.2409 13.3625 25.2277C10.9527 24.9822 8.51931 25.1172 6.15149 25.6277C5.90975 25.6793 5.69567 25.8186 5.55055 26.0187C5.40544 26.2188 5.33958 26.4655 5.36568 26.7113C5.39177 26.9571 5.50798 27.1845 5.69187 27.3497C5.87576 27.5149 6.11431 27.6061 6.36149 27.6057C6.43243 27.6055 6.50315 27.5978 6.57249 27.5827C8.73646 27.1166 10.9603 26.9934 13.1625 27.2177Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
