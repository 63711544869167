import { withIcon } from "@icons/_withIcon";

// Changelog: name was Heart
export const HeartFill = withIcon(
  () => (
    <path d="M28.7311 0.251636C26.018 0.212379 23.189 0.998811 20.9966 3.20687C18.7871 1.00311 15.931 0.251474 13.2914 0.251474C6.7324 0.251474 0.166016 5.68574 0.166016 13.3764C0.166016 20.7916 4.90882 26.965 9.59972 31.1619C11.9732 33.2855 14.4076 34.9713 16.3845 36.1324C17.3724 36.7125 18.2628 37.1715 18.9893 37.4912C19.3513 37.6504 19.691 37.7833 19.9936 37.8792C20.2568 37.9627 20.6279 38.0638 21 38.0638C21.3721 38.0638 21.7431 37.9627 22.0063 37.8792C22.3089 37.7833 22.6486 37.6504 23.0106 37.4912C23.7371 37.1715 24.6275 36.7125 25.6154 36.1324C27.5924 34.9713 30.0267 33.2855 32.4002 31.1619C37.0911 26.965 41.8339 20.7916 41.8339 13.3764C41.8339 5.66752 35.2522 0.345991 28.7311 0.251636Z" />
  ),
  {
    viewBox: "0 0 42 39",
  }
);
