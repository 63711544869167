import { withIcon } from "@icons/_withIcon";

// Changelog: name was More
export const MoreFill = withIcon(
  () => (
    <>
      <circle cx="3" cy="3" r="3" />
      <circle cx="13" cy="3" r="3" />
      <circle cx="23" cy="3" r="3" />
    </>
  ),
  {
    viewBox: "0 0 26 6",
  }
);
