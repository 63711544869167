import { withIcon } from "@icons/_withIcon";

export const RomanceFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M22.2495 28.0108L28.6945 34.4538C28.7531 34.5125 28.8227 34.5591 28.8993 34.5908C28.9759 34.6226 29.058 34.6389 29.141 34.6389C29.2239 34.6389 29.306 34.6226 29.3827 34.5908C29.4593 34.5591 29.5289 34.5125 29.5875 34.4538L31.7945 32.2478L36.0315 28.0108L37.1945 26.8488C37.6729 26.3704 38.0464 25.7976 38.2913 25.1669C38.5362 24.5363 38.6471 23.8615 38.6169 23.1856C38.5867 22.5097 38.4161 21.8475 38.1159 21.2412C37.8158 20.6348 37.3926 20.0976 36.8735 19.6638C35.8899 18.893 34.6553 18.5148 33.4088 18.6024C32.1622 18.6899 30.9926 19.2371 30.1265 20.1378L29.1435 21.1208L27.9945 19.9728C27.5422 19.5203 27.0052 19.1614 26.4142 18.9165C25.8232 18.6716 25.1897 18.5455 24.55 18.5455C23.9102 18.5455 23.2767 18.6716 22.6857 18.9165C22.0947 19.1614 21.5577 19.5203 21.1055 19.9728C20.653 20.4251 20.294 20.962 20.0491 21.5531C19.8043 22.1441 19.6782 22.7776 19.6782 23.4173C19.6782 24.0571 19.8043 24.6905 20.0491 25.2816C20.294 25.8726 20.653 26.4096 21.1055 26.8618L22.2495 28.0108Z"
        fill={accent}
      />
      <path
        d="M18.4595 36.1228C18.7381 36.4014 19.0688 36.6224 19.4328 36.7732C19.7968 36.924 20.187 37.0017 20.581 37.0017C20.975 37.0017 21.3651 36.924 21.7291 36.7732C22.0932 36.6224 22.4239 36.4014 22.7025 36.1228L24.7025 34.1228C24.798 34.0305 24.8742 33.9202 24.9266 33.7982C24.979 33.6762 25.0066 33.545 25.0077 33.4122C25.0089 33.2794 24.9836 33.1477 24.9333 33.0248C24.883 32.9019 24.8088 32.7903 24.7149 32.6964C24.621 32.6025 24.5093 32.5283 24.3864 32.478C24.2635 32.4277 24.1319 32.4024 23.9991 32.4035C23.8663 32.4047 23.7351 32.4323 23.6131 32.4847C23.4911 32.5371 23.3807 32.6133 23.2885 32.7088L21.2885 34.7088C21.0982 34.8911 20.8449 34.9928 20.5815 34.9928C20.318 34.9928 20.0647 34.8911 19.8745 34.7088L5.99448 20.8278C4.44297 19.2763 3.57133 17.172 3.57133 14.9778C3.57133 12.7836 4.44297 10.6793 5.99448 9.1278C7.546 7.57628 9.65031 6.70465 11.8445 6.70465C14.0387 6.70465 16.143 7.57628 17.6945 9.1278L19.8745 11.3168C20.062 11.5043 20.3163 11.6096 20.5815 11.6096C20.8466 11.6096 21.101 11.5043 21.2885 11.3168L23.2605 9.3458C24.7731 7.8023 26.8145 6.88949 28.9734 6.79133C31.1322 6.69316 33.248 7.41694 34.8945 8.8168C36.0748 9.88744 36.922 11.2753 37.3349 12.8145C37.7477 14.3537 37.7087 15.9792 37.2225 17.4968C37.1588 17.7436 37.1918 18.0054 37.3147 18.2287C37.4376 18.452 37.6412 18.62 37.8837 18.6982C38.1263 18.7765 38.3896 18.7592 38.6199 18.6498C38.8501 18.5404 39.0299 18.3473 39.1225 18.1098C39.726 16.226 39.7746 14.2084 39.2625 12.2977C38.7504 10.3871 37.6992 8.66419 36.2345 7.3348C34.2182 5.56765 31.5992 4.64421 28.9204 4.75591C26.2417 4.86762 23.7086 6.00591 21.8465 7.9348L20.5815 9.1958L19.1035 7.7168C17.1773 5.79079 14.565 4.70883 11.8411 4.70892C9.11726 4.70902 6.50498 5.79116 4.57898 7.7173C2.65298 9.64343 1.57102 12.2558 1.57111 14.9796C1.5712 17.7035 2.65335 20.3158 4.57948 22.2418L18.4595 36.1228Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
