import { withIcon } from "@icons/_withIcon";

export const Trash = withIcon(
  () => (
    <>
      <rect width="24" height="24" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_2097_60171" transform="scale(0.015625)" />
        </pattern>
        <image
          id="image0_2097_60171"
          width="64"
          height="64"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALuSURBVHic7ZqxahVBFIa/o2hhTKImohYRJEjAUkTUXkUEC1/AFxC1sbHxBWJh7zPYiDa+gCJpDQQMGEEQNYlG7ZLfIjcQ5s69m5mdnVm9+3V3sjPnn5OzZ+fsHujo6OjoGF0shxFJM8AdYGaPU1aAZ2b2pTlVmZA0KemzwlmWNNa0vn1NGwCuAKci5p0BLifW0kcOB3wCFDFvC/iYWEsZJD2StB4Q/muS7ubQliUJAkgy4MgeL183s5io6QilMgJ6/7lZYLJ5OUn5AXyoiqShDpC0H3gB3EgoLCcvgVtmtjXogqqnwHX+3c0D3ASuDbugygGH0mkpxtA9VN0CY8ACMJdSUUYWgQtm9mfQBXtJgoeBq8BxYB4Y3/Xn98DTmiLrch84t+v3T+Ah8BV4bWa/klmStOQcWN4kWzxe01tH02LI/NCj8Dfn93Tg/CZwNXwPmVzXAVOB85vA1eBqHEpdB0xKOhC4RjJ6tiec4UYd4IaXAccC10jJFP2JvNFbwLd4yTzgs501BwwSkQuf7aw5YJCIXGR3gC+8Sj4JfLZHPgKy54CRioBVtl9W7qZNEbAJrIUsEOQAM9sE1itE5MS1vTbs5YePmNfibToO1zoGQ5wD3CTTpgjI4oA2VYStcMCEpIMR69SiVwiNO8NBj0BIcwuUKoim6S+EiuSAHTG5qX0IgjS3AJR5EtQ+BEE6B4x8BLTFASOfA4rdAiOVA3wFURsc4KtTKgl2QIsKItfmamghBPE9Qm04Dtc+BkO8A9pQENX6IrRDqghoQw7IGgG+L0TZCqKeLbcQKuoAyFsQ+b4IFc0BACcj14rhhGdsNWahWAeseMYuRa4Vg6+FNqqrNKpRUtJZYMkj4DYRh5FAjgLPgdPO+KyZLYcuFt0pKmkBOB87PzHvzOxizMQ6zdKPa8xNTRktkp4ENEA3xXyRzfccYJIeSNoosPENSffq7iFJt7ikCbY7SudovrnyN9sJ+JWZbTRsq6Ojo6Pjv+Yv5DQ/c0VPYa8AAAAASUVORK5CYII="
        />
      </defs>
    </>
  ),
  {
    viewBox: "0 0 24 24",
  }
);
