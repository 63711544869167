import { withIcon } from "@icons/_withIcon";

export const Missing = withIcon(
  () => (
    <>
      <path d="M32 1.616L30.384 0L0 30.384L1.616 32L3.90171 29.7143H27.4286C28.0345 29.7134 28.6154 29.4723 29.0438 29.0438C29.4723 28.6154 29.7134 28.0345 29.7143 27.4286V3.90171L32 1.616ZM27.4286 27.4286H6.18743L15.0937 18.5223L17.8126 21.2411C18.2412 21.6696 18.8225 21.9104 19.4286 21.9104C20.0347 21.9104 20.6159 21.6696 21.0446 21.2411L22.8571 19.4286L27.4286 23.9966V27.4286ZM27.4286 20.7634L24.4731 17.808C24.0445 17.3795 23.4632 17.1388 22.8571 17.1388C22.2511 17.1388 21.6698 17.3795 21.2411 17.808L19.4286 19.6206L16.712 16.904L27.4286 6.18743V20.7634Z" />
      <path d="M4.78621 23.3846V19.8974L10.5982 14.089L12.1941 15.6861L13.8401 14.0401L12.2418 12.4419C11.8058 12.006 11.2146 11.7612 10.5982 11.7612C9.98173 11.7612 9.39052 12.006 8.95455 12.4419L4.78621 16.6102V4.78633H23.3845V2.46155H4.78621C4.16983 2.46216 3.57887 2.70729 3.14302 3.14314C2.70717 3.57899 2.46204 4.16995 2.46143 4.78633V23.3846H4.78621Z" />
    </>
  ),
  {
    viewBox: "0 0 32 32",
  }
);
