import PropTypes from "prop-types";
import tailwind from "@/tailwind.config";

/**
 * This HOC provides a uniform interface and baseline for svg (icon) usages across the site.
 * The consumed icons are wrapped with the `<svg>` tag to allow this, and should be provided
 * as the inner `<path>` of an svg.
 */
export function withIcon(Icon, intrinsicProps = {}) {
  function wrappedSVGPath(props) {
    /**
     * Some icons will have attributes that vary from the standardized settings here. In that case,
     * the icon (path) file should include a second arg (object) defining those attributes, e.g.
     * 
        export const example = withIcon(
          () => (
            <path d="M424.4 214.7L72.4 ..." />
          ),
          {
            viewBox: "0 0 448 512",
          }
        );
     *
     * Here, we'll compose those intrinsic and traditional props for use on the svg.
     */
    const composedProps = {
      className: null,
      mono: false,
      primary: "currentColor",
      accent: tailwind.theme.colors.blue.DEFAULT,
      fill: "currentColor",
      height: "1em",
      width: "1em",
      label: "",
      viewBox: "0 0 32 32",
      ...props,
      ...intrinsicProps,
    };

    const {
      className,
      mono,
      primary,
      accent,
      height,
      width,
      label,
      viewBox,
      white,
      fill,
    } = composedProps;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className={className}
        viewBox={viewBox}
        fill={fill}
        role={label ? "img" : null}
        {...(label ? { "aria-label": label } : { "aria-hidden": true })}
      >
        <Icon
          primary={primary}
          accent={mono ? "currentColor" : accent}
          white={white}
        />
      </svg>
    );
  }

  wrappedSVGPath.displayName = Icon.name;
  wrappedSVGPath.propTypes = {
    className: PropTypes.string,
    mono: PropTypes.bool,
    primary: PropTypes.string,
    accent: PropTypes.string,
    fill: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    label: PropTypes.string,
    viewbox: PropTypes.string,
  };

  return wrappedSVGPath;
}

withIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  intrinsicProps: PropTypes.shape({}),
};
