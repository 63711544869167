import { createContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import { tagular } from "@cohesion/tagular";

import isCohesionReachable from "@utils/isCohesionReachable";

export const AdContext = createContext();

export function AdContextProvider({ children, GPTReady, adTargeting = null }) {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    /**
     * This checks for adblockers in a slightly atypical way:
     * - Check to see if the non-proxied Cohesion endpoint resolves
     * - If it doesn't, it's implied to be blocked (possible network error; unlikely)
     * - If unreachable, event and behave as if blocked
     */
    const checkAdblock = async () => {
      const verdict = await isCohesionReachable();

      if (verdict === false) {
        setAdBlockDetected(true);
        /**
         * No need to wait on the cohesion-ready event or hooks, as tagular will build
         * a queue and fire when available
         */
        tagular("adBlockCaptured");
      }
    };

    checkAdblock();
  }, []);

  const ctx = useMemo(
    () => ({
      GPTReady,
      targeting: adTargeting,
      adBlockDetected,
    }),
    [GPTReady, adBlockDetected, adTargeting]
  );

  return <AdContext.Provider value={ctx}>{children}</AdContext.Provider>;
}

AdContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  GPTReady: PropTypes.bool.isRequired,
  adTargeting: PropTypes.shape({}),
};
