import { withIcon } from "@icons/_withIcon";

export const X = withIcon(
  ({ primary, white }) => (
    <path
      d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
      fill={white ? "#fff" : primary}
    />
  ),
  {
    viewBox: "0 0 300 300",
  }
);

export const XCircle = withIcon(
  ({ primary, accent, white }) => (
    <>
      <path
        d="M150 300C232.85 300 300 232.85 300 150C300 67.15 232.85 0 150 0C67.15 0 0 67.15 0 150C0 232.85 67.15 300 150 300Z"
        fill={primary}
      />
      <path
        d="M57.74 63.04L129.65 158.71L57.36 236.71H73.57L136.72 168.24L187.61 236.71H243.22L167.34 135.54L234.34 63.04H218.13L159.98 125.75L113.03 63.04H57.74ZM81.73 75.03H106.93L218.86 224.68H193.66L81.73 75.03Z"
        fill={white ? "#fff" : accent}
      />
    </>
  ),
  {
    viewBox: "0 0 300 300",
    primary: "#000",
    accent: "white",
  }
);
