import { createContext, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { swrFetch } from "@queries/swrFetch";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
  /**
   * Reminder: This is client-side.
   */
  const { data, isValidating, error, mutate } = useSWR(
    `/api/auth/profile`,
    swrFetch,
    {
      revalidateOnFocus: false,
    }
  );

  const ctx = useMemo(
    () => ({ user: data, isLoading: isValidating, error, mutate }),
    [data, isValidating, error, mutate]
  );

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  const ctx = useContext(UserContext);

  if (!ctx) {
    throw new Error(
      "useUserContext must be called from within a UserContextProvider"
    );
  }

  return ctx;
}

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
