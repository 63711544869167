import { withIcon } from "@icons/_withIcon";

// Change log: previous name: `WTTN: History` (requires prop: mono)
export const AttractionsFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M20.716 7.34598C20.5338 7.18675 20.3 7.099 20.058 7.099C19.816 7.099 19.5822 7.18675 19.4 7.34598L16.367 9.99499C16.2596 10.0888 16.1735 10.2046 16.1145 10.3345C16.0555 10.4644 16.025 10.6053 16.025 10.748C16.025 10.8906 16.0555 11.0316 16.1145 11.1615C16.1735 11.2914 16.2596 11.4071 16.367 11.501L19.4 14.151C19.5822 14.3102 19.816 14.398 20.058 14.398C20.3 14.398 20.5338 14.3102 20.716 14.151L23.749 11.501C23.8564 11.4071 23.9425 11.2914 24.0015 11.1615C24.0605 11.0316 24.091 10.8906 24.091 10.748C24.091 10.6053 24.0605 10.4644 24.0015 10.3345C23.9425 10.2046 23.8564 10.0888 23.749 9.99499L20.716 7.34598Z"
        fill={accent}
      />
      <path
        d="M35.071 18.338L37.5 13.759C37.5807 13.6066 37.6206 13.4359 37.6159 13.2635C37.6111 13.0911 37.5619 12.9229 37.4729 12.7751C37.384 12.6274 37.2583 12.5052 37.1082 12.4203C36.958 12.3355 36.7885 12.2909 36.616 12.291H34.023L20.693 1.31798C20.514 1.17064 20.2893 1.09008 20.0575 1.09008C19.8257 1.09008 19.601 1.17064 19.422 1.31798L6.093 12.291H3.477C3.30454 12.2909 3.135 12.3355 2.98485 12.4203C2.83469 12.5052 2.70903 12.6274 2.62006 12.7751C2.5311 12.9229 2.48185 13.0911 2.47711 13.2635C2.47237 13.4359 2.51229 13.6066 2.593 13.759L5.018 18.338C5.09427 18.4783 5.20338 18.598 5.336 18.687V30.459H3.478C3.21279 30.459 2.95843 30.5643 2.7709 30.7519C2.58336 30.9394 2.478 31.1938 2.478 31.459V36.722C2.478 36.9872 2.58336 37.2416 2.7709 37.4291C2.95843 37.6166 3.21279 37.722 3.478 37.722H36.612C36.8772 37.722 37.1316 37.6166 37.3191 37.4291C37.5066 37.2416 37.612 36.9872 37.612 36.722V31.459C37.612 31.1938 37.5066 30.9394 37.3191 30.7519C37.1316 30.5643 36.8772 30.459 36.612 30.459H34.664V18.744C34.8365 18.6514 34.978 18.5102 35.071 18.338ZM5.138 14.291H12.3C12.5652 14.291 12.8196 14.1856 13.0071 13.9981C13.1946 13.8106 13.3 13.5562 13.3 13.291C13.3 13.0258 13.1946 12.7714 13.0071 12.5839C12.8196 12.3963 12.5652 12.291 12.3 12.291H9.24L20.058 3.38398L30.875 12.289H27.723C27.4578 12.289 27.2034 12.3943 27.0159 12.5819C26.8284 12.7694 26.723 13.0238 26.723 13.289C26.723 13.5542 26.8284 13.8086 27.0159 13.9961C27.2034 14.1836 27.4578 14.289 27.723 14.289H34.951L33.585 16.868H6.5L5.138 14.291ZM28.4 30.459H23.132V18.87H28.4V30.459ZM11.6 18.87H16.867V30.459H11.6V18.87ZM18.867 18.87H21.131V30.459H18.868L18.867 18.87ZM7.335 18.87H9.6V30.459H7.336L7.335 18.87ZM35.612 32.459V35.722H4.478V32.459H35.612ZM30.399 30.459V18.87H32.664V30.459H30.399Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);

export const AttractionsFill = withIcon(
  () => (
    <path d="M14 6.49991C14.1326 6.49991 14.2598 6.44723 14.3536 6.35347C14.4473 6.2597 14.5 6.13252 14.5 5.99991V4.56391C14.4998 4.47731 14.4771 4.39224 14.4342 4.31704C14.3912 4.24184 14.3295 4.17908 14.255 4.13491L8.255 0.569913C8.17781 0.524149 8.08973 0.5 8 0.5C7.91027 0.5 7.82219 0.524149 7.745 0.569913L1.745 4.13491C1.67051 4.17908 1.60876 4.24184 1.56581 4.31704C1.52287 4.39224 1.50019 4.47731 1.5 4.56391V5.99991C1.5 6.13252 1.55268 6.2597 1.64645 6.35347C1.74021 6.44723 1.86739 6.49991 2 6.49991H2.25V12.4999H2C1.86739 12.4999 1.74021 12.5526 1.64645 12.6464C1.55268 12.7401 1.5 12.8673 1.5 12.9999V14.9999C1.5 15.1325 1.55268 15.2597 1.64645 15.3535C1.74021 15.4472 1.86739 15.4999 2 15.4999H14C14.1326 15.4999 14.2598 15.4472 14.3536 15.3535C14.4473 15.2597 14.5 15.1325 14.5 14.9999V12.9999C14.5 12.8673 14.4473 12.7401 14.3536 12.6464C14.2598 12.5526 14.1326 12.4999 14 12.4999H13.75V6.49991H14ZM8 2.94591C8.17172 2.94571 8.33963 2.99645 8.48251 3.09171C8.62538 3.18697 8.73679 3.32246 8.80264 3.48105C8.86849 3.63964 8.88582 3.8142 8.85244 3.98264C8.81906 4.15108 8.73647 4.30584 8.61512 4.42733C8.49377 4.54882 8.33911 4.63159 8.17071 4.66516C8.0023 4.69873 7.82773 4.6816 7.66906 4.61594C7.5104 4.55027 7.37478 4.43901 7.27935 4.29625C7.18393 4.15349 7.133 3.98563 7.133 3.81391C7.133 3.58388 7.22431 3.36325 7.38688 3.2005C7.54944 3.03774 7.76997 2.94618 8 2.94591ZM5.583 12.4999H3.75V6.49991H5.583V12.4999ZM8.917 12.4999H7.083V6.49991H8.917V12.4999ZM12.25 12.4999H10.417V6.49991H12.25V12.4999Z" />
  ),
  {
    viewBox: "0 0 16 16",
  }
);
