import { withIcon } from "@icons/_withIcon";

export const GuidebooksFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M13 21H8V29H13C13.2652 29 13.5196 28.8946 13.7071 28.7071C13.8946 28.5196 14 28.2652 14 28V22C14 21.7348 13.8946 21.4804 13.7071 21.2929C13.5196 21.1054 13.2652 21 13 21Z"
        fill={accent}
      />
      <path
        d="M9.5 39H31C31.7957 39 32.5587 38.6839 33.1213 38.1213C33.6839 37.5587 34 36.7957 34 36V4C34 3.20435 33.6839 2.44129 33.1213 1.87868C32.5587 1.31607 31.7957 1 31 1H9.5C8.57174 1 7.68151 1.36875 7.02513 2.02513C6.36875 2.6815 6 3.57174 6 4.5V35C5.99992 35.0583 6.00494 35.1165 6.015 35.174C6.005 35.281 6 35.39 6 35.5C6 36.4283 6.36875 37.3185 7.02513 37.9749C7.68151 38.6312 8.57174 39 9.5 39ZM32 36C32 36.2652 31.8946 36.5196 31.7071 36.7071C31.5196 36.8946 31.2652 37 31 37H9.5C9.10218 37 8.72065 36.842 8.43934 36.5607C8.15804 36.2794 8 35.8978 8 35.5C8 35.1022 8.15804 34.7206 8.43934 34.4393C8.72065 34.158 9.10218 34 9.5 34H31C31.3406 34.0002 31.6788 33.9424 32 33.829V36ZM32 31C32 31.2652 31.8946 31.5196 31.7071 31.7071C31.5196 31.8946 31.2652 32 31 32H9.5C8.98104 31.9993 8.4685 32.1148 8 32.338V17.414L12.293 13.121C12.4805 12.9335 12.7348 12.8282 13 12.8282C13.2652 12.8282 13.5195 12.9335 13.707 13.121L20.293 19.707L26.793 26.207C26.9816 26.3892 27.2342 26.49 27.4964 26.4877C27.7586 26.4854 28.0094 26.3802 28.1948 26.1948C28.3802 26.0094 28.4854 25.7586 28.4877 25.4964C28.49 25.2342 28.3892 24.9816 28.207 24.793L22.414 19L24.793 16.621C24.9805 16.4335 25.2348 16.3282 25.5 16.3282C25.7652 16.3282 26.0195 16.4335 26.207 16.621L32 22.414V31ZM8 4.5C8 4.10218 8.15804 3.72064 8.43934 3.43934C8.72065 3.15804 9.10218 3 9.5 3H31C31.2652 3 31.5196 3.10536 31.7071 3.29289C31.8946 3.48043 32 3.73478 32 4V19.586L27.621 15.207C27.0584 14.6446 26.2955 14.3286 25.5 14.3286C24.7045 14.3286 23.9416 14.6446 23.379 15.207L21 17.586L15.121 11.707C14.5584 11.1446 13.7955 10.8286 13 10.8286C12.2045 10.8286 11.4416 11.1446 10.879 11.707L8 14.586V4.5Z"
        fill={primary}
      />
      <path
        d="M18 8H18.277C18.4505 8.30388 18.7012 8.55646 19.0038 8.73215C19.3064 8.90784 19.6501 9.00037 20 9.00037C20.3499 9.00037 20.6936 8.90784 20.9962 8.73215C21.2988 8.55646 21.5495 8.30388 21.723 8H22C22.2652 8 22.5196 7.89465 22.7071 7.70711C22.8946 7.51957 23 7.26522 23 7C23 6.73479 22.8946 6.48043 22.7071 6.2929C22.5196 6.10536 22.2652 6 22 6H21.723C21.5495 5.69613 21.2988 5.44354 20.9962 5.26786C20.6936 5.09217 20.3499 4.99963 20 4.99963C19.6501 4.99963 19.3064 5.09217 19.0038 5.26786C18.7012 5.44354 18.4505 5.69613 18.277 6H18C17.7348 6 17.4804 6.10536 17.2929 6.2929C17.1054 6.48043 17 6.73479 17 7C17 7.26522 17.1054 7.51957 17.2929 7.70711C17.4804 7.89465 17.7348 8 18 8Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
