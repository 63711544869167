import PropTypes from "prop-types";
import cn from "classnames";

import { SOCIAL_ICONS, SOCIAL_ICONS_BRANDED } from "@constants/socialIcons";
import { tagular } from "@cohesion/tagular";

export function SocialRow({
  className = "",
  negative = false,
  socials = [],
  white = false,
}) {
  const ICON_SET = negative ? SOCIAL_ICONS_BRANDED : SOCIAL_ICONS;
  return (
    <nav className={className}>
      <ul className="flex space-x-4 lg:space-x-2">
        {socials.map(({ label, href }) => {
          const Icon = ICON_SET[label.toLowerCase()];

          if (!Icon) return null;

          return (
            <li key={label} data-testid={label}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
                href={href}
                onClick={() => {
                  tagular("click", {
                    actionOutcome: "EXTERNALLINK",
                    outboundUrl: href,
                    webElement: {
                      location: "SECTION",
                      elementType: `LOGO-${label.toUpperCase()}`,
                      position: "Follow Us",
                      text: "",
                    },
                  });
                }}
              >
                <Icon
                  className={cn(
                    negative ? "text-xl" : "text-2xl",
                    white && "text-white"
                  )}
                  white={white}
                  label={`Lonely Planet on ${label}`}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

SocialRow.propTypes = {
  className: PropTypes.string,
  negative: PropTypes.bool,
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
      primary: PropTypes.string,
      accent: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    })
  ),
  white: PropTypes.bool,
};
