import { withIcon } from "@icons/_withIcon";

export const Calendar = withIcon(
  () => (
    <>
      <path d="M14 1.5H12.5V1C12.5 0.867392 12.4473 0.740215 12.3536 0.646447C12.2598 0.552678 12.1326 0.5 12 0.5C11.8674 0.5 11.7402 0.552678 11.6464 0.646447C11.5527 0.740215 11.5 0.867392 11.5 1V1.5H4.5V1C4.5 0.867392 4.44732 0.740215 4.35355 0.646447C4.25979 0.552678 4.13261 0.5 4 0.5C3.86739 0.5 3.74021 0.552678 3.64645 0.646447C3.55268 0.740215 3.5 0.867392 3.5 1V1.5H2C1.60218 1.5 1.22064 1.65804 0.93934 1.93934C0.658035 2.22064 0.5 2.60218 0.5 3V14C0.5 14.3978 0.658035 14.7794 0.93934 15.0607C1.22064 15.342 1.60218 15.5 2 15.5H14C14.3978 15.5 14.7794 15.342 15.0607 15.0607C15.342 14.7794 15.5 14.3978 15.5 14V3C15.5 2.60218 15.342 2.22064 15.0607 1.93934C14.7794 1.65804 14.3978 1.5 14 1.5ZM2 2.5H3.5V3C3.5 3.13261 3.55268 3.25979 3.64645 3.35355C3.74021 3.44732 3.86739 3.5 4 3.5C4.13261 3.5 4.25979 3.44732 4.35355 3.35355C4.44732 3.25979 4.5 3.13261 4.5 3V2.5H11.5V3C11.5 3.13261 11.5527 3.25979 11.6464 3.35355C11.7402 3.44732 11.8674 3.5 12 3.5C12.1326 3.5 12.2598 3.44732 12.3536 3.35355C12.4473 3.25979 12.5 3.13261 12.5 3V2.5H14C14.1326 2.5 14.2598 2.55268 14.3536 2.64645C14.4473 2.74021 14.5 2.86739 14.5 3V4.5H1.5V3C1.5 2.86739 1.55268 2.74021 1.64645 2.64645C1.74021 2.55268 1.86739 2.5 2 2.5ZM14 14.5H2C1.86739 14.5 1.74021 14.4473 1.64645 14.3536C1.55268 14.2598 1.5 14.1326 1.5 14V5.5H14.5V14C14.5 14.1326 14.4473 14.2598 14.3536 14.3536C14.2598 14.4473 14.1326 14.5 14 14.5Z" />
      <path d="M5 7H4C3.86739 7 3.74021 7.05268 3.64645 7.14645C3.55268 7.24021 3.5 7.36739 3.5 7.5C3.5 7.63261 3.55268 7.75979 3.64645 7.85355C3.74021 7.94732 3.86739 8 4 8H5C5.13261 8 5.25979 7.94732 5.35355 7.85355C5.44732 7.75979 5.5 7.63261 5.5 7.5C5.5 7.36739 5.44732 7.24021 5.35355 7.14645C5.25979 7.05268 5.13261 7 5 7Z" />
      <path d="M8.5 7H7.5C7.36739 7 7.24021 7.05268 7.14645 7.14645C7.05268 7.24021 7 7.36739 7 7.5C7 7.63261 7.05268 7.75979 7.14645 7.85355C7.24021 7.94732 7.36739 8 7.5 8H8.5C8.63261 8 8.75979 7.94732 8.85355 7.85355C8.94732 7.75979 9 7.63261 9 7.5C9 7.36739 8.94732 7.24021 8.85355 7.14645C8.75979 7.05268 8.63261 7 8.5 7Z" />
      <path d="M12 7H11C10.8674 7 10.7402 7.05268 10.6464 7.14645C10.5527 7.24021 10.5 7.36739 10.5 7.5C10.5 7.63261 10.5527 7.75979 10.6464 7.85355C10.7402 7.94732 10.8674 8 11 8H12C12.1326 8 12.2598 7.94732 12.3536 7.85355C12.4473 7.75979 12.5 7.63261 12.5 7.5C12.5 7.36739 12.4473 7.24021 12.3536 7.14645C12.2598 7.05268 12.1326 7 12 7Z" />
      <path d="M5 9.5H4C3.86739 9.5 3.74021 9.55268 3.64645 9.64645C3.55268 9.74021 3.5 9.86739 3.5 10C3.5 10.1326 3.55268 10.2598 3.64645 10.3536C3.74021 10.4473 3.86739 10.5 4 10.5H5C5.13261 10.5 5.25979 10.4473 5.35355 10.3536C5.44732 10.2598 5.5 10.1326 5.5 10C5.5 9.86739 5.44732 9.74021 5.35355 9.64645C5.25979 9.55268 5.13261 9.5 5 9.5Z" />
      <path d="M8.5 9.5H7.5C7.36739 9.5 7.24021 9.55268 7.14645 9.64645C7.05268 9.74021 7 9.86739 7 10C7 10.1326 7.05268 10.2598 7.14645 10.3536C7.24021 10.4473 7.36739 10.5 7.5 10.5H8.5C8.63261 10.5 8.75979 10.4473 8.85355 10.3536C8.94732 10.2598 9 10.1326 9 10C9 9.86739 8.94732 9.74021 8.85355 9.64645C8.75979 9.55268 8.63261 9.5 8.5 9.5Z" />
      <path d="M12 9.5H11C10.8674 9.5 10.7402 9.55268 10.6464 9.64645C10.5527 9.74021 10.5 9.86739 10.5 10C10.5 10.1326 10.5527 10.2598 10.6464 10.3536C10.7402 10.4473 10.8674 10.5 11 10.5H12C12.1326 10.5 12.2598 10.4473 12.3536 10.3536C12.4473 10.2598 12.5 10.1326 12.5 10C12.5 9.86739 12.4473 9.74021 12.3536 9.64645C12.2598 9.55268 12.1326 9.5 12 9.5Z" />
      <path d="M12 12H11C10.8674 12 10.7402 12.0527 10.6464 12.1464C10.5527 12.2402 10.5 12.3674 10.5 12.5C10.5 12.6326 10.5527 12.7598 10.6464 12.8536C10.7402 12.9473 10.8674 13 11 13H12C12.1326 13 12.2598 12.9473 12.3536 12.8536C12.4473 12.7598 12.5 12.6326 12.5 12.5C12.5 12.3674 12.4473 12.2402 12.3536 12.1464C12.2598 12.0527 12.1326 12 12 12Z" />
      <path d="M5 12H4C3.86739 12 3.74021 12.0527 3.64645 12.1464C3.55268 12.2402 3.5 12.3674 3.5 12.5C3.5 12.6326 3.55268 12.7598 3.64645 12.8536C3.74021 12.9473 3.86739 13 4 13H5C5.13261 13 5.25979 12.9473 5.35355 12.8536C5.44732 12.7598 5.5 12.6326 5.5 12.5C5.5 12.3674 5.44732 12.2402 5.35355 12.1464C5.25979 12.0527 5.13261 12 5 12Z" />
      <path d="M8.5 12H7.5C7.36739 12 7.24021 12.0527 7.14645 12.1464C7.05268 12.2402 7 12.3674 7 12.5C7 12.6326 7.05268 12.7598 7.14645 12.8536C7.24021 12.9473 7.36739 13 7.5 13H8.5C8.63261 13 8.75979 12.9473 8.85355 12.8536C8.94732 12.7598 9 12.6326 9 12.5C9 12.3674 8.94732 12.2402 8.85355 12.1464C8.75979 12.0527 8.63261 12 8.5 12Z" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);

// Changelog: name was Calendar
export const CalendarFill = withIcon(
  () => (
    <path d="M9.25 18C9.625 18 10 17.6875 10 17.25V14.75C10 14.375 9.625 14 9.25 14H6.75C6.3125 14 6 14.375 6 14.75V17.25C6 17.6875 6.3125 18 6.75 18H9.25ZM16 17.25V14.75C16 14.375 15.625 14 15.25 14H12.75C12.3125 14 12 14.375 12 14.75V17.25C12 17.6875 12.3125 18 12.75 18H15.25C15.625 18 16 17.6875 16 17.25ZM22 17.25V14.75C22 14.375 21.625 14 21.25 14H18.75C18.3125 14 18 14.375 18 14.75V17.25C18 17.6875 18.3125 18 18.75 18H21.25C21.625 18 22 17.6875 22 17.25ZM16 23.25V20.75C16 20.375 15.625 20 15.25 20H12.75C12.3125 20 12 20.375 12 20.75V23.25C12 23.6875 12.3125 24 12.75 24H15.25C15.625 24 16 23.6875 16 23.25ZM10 23.25V20.75C10 20.375 9.625 20 9.25 20H6.75C6.3125 20 6 20.375 6 20.75V23.25C6 23.6875 6.3125 24 6.75 24H9.25C9.625 24 10 23.6875 10 23.25ZM22 23.25V20.75C22 20.375 21.625 20 21.25 20H18.75C18.3125 20 18 20.375 18 20.75V23.25C18 23.6875 18.3125 24 18.75 24H21.25C21.625 24 22 23.6875 22 23.25ZM28 7C28 5.375 26.625 4 25 4H22V0.75C22 0.375 21.625 0 21.25 0H18.75C18.3125 0 18 0.375 18 0.75V4H10V0.75C10 0.375 9.625 0 9.25 0H6.75C6.3125 0 6 0.375 6 0.75V4H3C1.3125 4 0 5.375 0 7V29C0 30.6875 1.3125 32 3 32H25C26.625 32 28 30.6875 28 29V7ZM25 28.625C25 28.875 24.8125 29 24.625 29H3.375C3.125 29 3 28.875 3 28.625V10H25V28.625Z" />
  ),
  {
    viewBox: "0 0 28 32",
  }
);

export const CalendarSolidFill = withIcon(
  () => (
    <>
      <path d="M28 3.5H25.5V2.5C25.5 1.67188 24.8281 1 24 1C23.1719 1 22.5 1.67188 22.5 2.5V3.5H9.5V2.5C9.5 1.67188 8.82812 1 8 1C7.17188 1 6.5 1.67188 6.5 2.5V3.5H4C2.3457 3.5 1 4.8457 1 6.5V9H31V6.5C31 4.8457 29.6543 3.5 28 3.5Z" />
      <path d="M1 27.5C1 29.1543 2.3457 30.5 4 30.5H28C29.6543 30.5 31 29.1543 31 27.5V12H1V27.5ZM23 16H25C25.8281 16 26.5 16.6719 26.5 17.5C26.5 18.3281 25.8281 19 25 19H23C22.1719 19 21.5 18.3281 21.5 17.5C21.5 16.6719 22.1719 16 23 16ZM23 23.0566H25C25.8281 23.0566 26.5 23.7285 26.5 24.5566C26.5 25.3848 25.8281 26.0566 25 26.0566H23C22.1719 26.0566 21.5 25.3848 21.5 24.5566C21.5 23.7285 22.1719 23.0566 23 23.0566ZM15 16H17C17.8281 16 18.5 16.6719 18.5 17.5C18.5 18.3281 17.8281 19 17 19H15C14.1719 19 13.5 18.3281 13.5 17.5C13.5 16.6719 14.1719 16 15 16ZM15 23.0566H17C17.8281 23.0566 18.5 23.7285 18.5 24.5566C18.5 25.3848 17.8281 26.0566 17 26.0566H15C14.1719 26.0566 13.5 25.3848 13.5 24.5566C13.5 23.7285 14.1719 23.0566 15 23.0566ZM7 16H9C9.82812 16 10.5 16.6719 10.5 17.5C10.5 18.3281 9.82812 19 9 19H7C6.17188 19 5.5 18.3281 5.5 17.5C5.5 16.6719 6.17188 16 7 16ZM7 23.0566H9C9.82812 23.0566 10.5 23.7285 10.5 24.5566C10.5 25.3848 9.82812 26.0566 9 26.0566H7C6.17188 26.0566 5.5 25.3848 5.5 24.5566C5.5 23.7285 6.17188 23.0566 7 23.0566Z" />
    </>
  ),
  {
    viewBox: "0 0 32 32",
  }
);
export const CalendarFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M29.9365 10.566H10.9365C9.83191 10.566 8.93648 11.4614 8.93648 12.566V14.566C8.93648 15.6706 9.83191 16.566 10.9365 16.566H29.9365C31.0411 16.566 31.9365 15.6706 31.9365 14.566V12.566C31.9365 11.4614 31.0411 10.566 29.9365 10.566Z"
        fill={accent}
      />
      <path
        d="M8.87048 38.434H32.3185C33.6441 38.4324 34.9149 37.9051 35.8523 36.9678C36.7896 36.0304 37.3169 34.7596 37.3185 33.434V8.81201C37.3169 7.48641 36.7896 6.21557 35.8523 5.27823C34.9149 4.34089 33.6441 3.8136 32.3185 3.81201H28.5795V2.56601C28.5795 2.30079 28.4741 2.04644 28.2866 1.8589C28.0991 1.67137 27.8447 1.56601 27.5795 1.56601C27.3143 1.56601 27.0599 1.67137 26.8724 1.8589C26.6848 2.04644 26.5795 2.30079 26.5795 2.56601V3.81201H14.6095V2.56601C14.6095 2.30079 14.5041 2.04644 14.3166 1.8589C14.1291 1.67137 13.8747 1.56601 13.6095 1.56601C13.3443 1.56601 13.0899 1.67137 12.9024 1.8589C12.7148 2.04644 12.6095 2.30079 12.6095 2.56601V3.81201H8.87048C7.54489 3.8136 6.27404 4.34089 5.3367 5.27823C4.39936 6.21557 3.87207 7.48641 3.87048 8.81201V33.434C3.87207 34.7596 4.39936 36.0304 5.3367 36.9678C6.27404 37.9051 7.54489 38.4324 8.87048 38.434ZM5.87048 8.81201C5.87048 8.01636 6.18655 7.2533 6.74916 6.69069C7.31177 6.12808 8.07483 5.81201 8.87048 5.81201H12.6095V7.05901C12.6095 7.32423 12.7148 7.57858 12.9024 7.76612C13.0899 7.95365 13.3443 8.05901 13.6095 8.05901C13.8747 8.05901 14.1291 7.95365 14.3166 7.76612C14.5041 7.57858 14.6095 7.32423 14.6095 7.05901V5.81201H26.5795V7.05901C26.5795 7.32423 26.6848 7.57858 26.8724 7.76612C27.0599 7.95365 27.3143 8.05901 27.5795 8.05901C27.8447 8.05901 28.0991 7.95365 28.2866 7.76612C28.4741 7.57858 28.5795 7.32423 28.5795 7.05901V5.81201H32.3185C33.1141 5.81201 33.8772 6.12808 34.4398 6.69069C35.0024 7.2533 35.3185 8.01636 35.3185 8.81201V33.434C35.3185 34.2297 35.0024 34.9927 34.4398 35.5553C33.8772 36.1179 33.1141 36.434 32.3185 36.434H8.87048C8.07483 36.434 7.31177 36.1179 6.74916 35.5553C6.18655 34.9927 5.87048 34.2297 5.87048 33.434V8.81201Z"
        fill={primary}
      />
      <path
        d="M11.6095 22.166H13.8555C14.1207 22.166 14.3751 22.0607 14.5626 21.8731C14.7501 21.6856 14.8555 21.4312 14.8555 21.166C14.8555 20.9008 14.7501 20.6464 14.5626 20.4589C14.3751 20.2714 14.1207 20.166 13.8555 20.166H11.6095C11.3443 20.166 11.0899 20.2714 10.9024 20.4589C10.7148 20.6464 10.6095 20.9008 10.6095 21.166C10.6095 21.4312 10.7148 21.6856 10.9024 21.8731C11.0899 22.0607 11.3443 22.166 11.6095 22.166Z"
        fill={primary}
      />
      <path
        d="M19.4715 22.166H21.7175C21.9827 22.166 22.2371 22.0607 22.4246 21.8731C22.6121 21.6856 22.7175 21.4312 22.7175 21.166C22.7175 20.9008 22.6121 20.6464 22.4246 20.4589C22.2371 20.2714 21.9827 20.166 21.7175 20.166H19.4715C19.2063 20.166 18.9519 20.2714 18.7644 20.4589C18.5768 20.6464 18.4715 20.9008 18.4715 21.166C18.4715 21.4312 18.5768 21.6856 18.7644 21.8731C18.9519 22.0607 19.2063 22.166 19.4715 22.166Z"
        fill={primary}
      />
      <path
        d="M27.3335 22.166H29.5795C29.8447 22.166 30.0991 22.0607 30.2866 21.8731C30.4741 21.6856 30.5795 21.4312 30.5795 21.166C30.5795 20.9008 30.4741 20.6464 30.2866 20.4589C30.0991 20.2714 29.8447 20.166 29.5795 20.166H27.3335C27.0683 20.166 26.8139 20.2714 26.6264 20.4589C26.4388 20.6464 26.3335 20.9008 26.3335 21.166C26.3335 21.4312 26.4388 21.6856 26.6264 21.8731C26.8139 22.0607 27.0683 22.166 27.3335 22.166Z"
        fill={primary}
      />
      <path
        d="M11.6095 27.566H13.8555C14.1207 27.566 14.3751 27.4607 14.5626 27.2731C14.7501 27.0856 14.8555 26.8312 14.8555 26.566C14.8555 26.3008 14.7501 26.0464 14.5626 25.8589C14.3751 25.6714 14.1207 25.566 13.8555 25.566H11.6095C11.3443 25.566 11.0899 25.6714 10.9024 25.8589C10.7148 26.0464 10.6095 26.3008 10.6095 26.566C10.6095 26.8312 10.7148 27.0856 10.9024 27.2731C11.0899 27.4607 11.3443 27.566 11.6095 27.566Z"
        fill={primary}
      />
      <path
        d="M19.4715 27.566H21.7175C21.9827 27.566 22.2371 27.4607 22.4246 27.2731C22.6121 27.0856 22.7175 26.8312 22.7175 26.566C22.7175 26.3008 22.6121 26.0464 22.4246 25.8589C22.2371 25.6714 21.9827 25.566 21.7175 25.566H19.4715C19.2063 25.566 18.9519 25.6714 18.7644 25.8589C18.5768 26.0464 18.4715 26.3008 18.4715 26.566C18.4715 26.8312 18.5768 27.0856 18.7644 27.2731C18.9519 27.4607 19.2063 27.566 19.4715 27.566Z"
        fill={primary}
      />
      <path
        d="M27.3335 27.566H29.5795C29.8447 27.566 30.0991 27.4607 30.2866 27.2731C30.4741 27.0856 30.5795 26.8312 30.5795 26.566C30.5795 26.3008 30.4741 26.0464 30.2866 25.8589C30.0991 25.6714 29.8447 25.566 29.5795 25.566H27.3335C27.0683 25.566 26.8139 25.6714 26.6264 25.8589C26.4388 26.0464 26.3335 26.3008 26.3335 26.566C26.3335 26.8312 26.4388 27.0856 26.6264 27.2731C26.8139 27.4607 27.0683 27.566 27.3335 27.566Z"
        fill={primary}
      />
      <path
        d="M27.3335 32.706H29.5795C29.8447 32.706 30.0991 32.6006 30.2866 32.4131C30.4741 32.2256 30.5795 31.9712 30.5795 31.706C30.5795 31.4408 30.4741 31.1864 30.2866 30.9989C30.0991 30.8114 29.8447 30.706 29.5795 30.706H27.3335C27.0683 30.706 26.8139 30.8114 26.6264 30.9989C26.4388 31.1864 26.3335 31.4408 26.3335 31.706C26.3335 31.9712 26.4388 32.2256 26.6264 32.4131C26.8139 32.6006 27.0683 32.706 27.3335 32.706Z"
        fill={primary}
      />
      <path
        d="M11.6095 32.706H13.8555C14.1207 32.706 14.3751 32.6006 14.5626 32.4131C14.7501 32.2256 14.8555 31.9712 14.8555 31.706C14.8555 31.4408 14.7501 31.1864 14.5626 30.9989C14.3751 30.8114 14.1207 30.706 13.8555 30.706H11.6095C11.3443 30.706 11.0899 30.8114 10.9024 30.9989C10.7148 31.1864 10.6095 31.4408 10.6095 31.706C10.6095 31.9712 10.7148 32.2256 10.9024 32.4131C11.0899 32.6006 11.3443 32.706 11.6095 32.706Z"
        fill={primary}
      />
      <path
        d="M19.4715 32.706H21.7175C21.9827 32.706 22.2371 32.6006 22.4246 32.4131C22.6121 32.2256 22.7175 31.9712 22.7175 31.706C22.7175 31.4408 22.6121 31.1864 22.4246 30.9989C22.2371 30.8114 21.9827 30.706 21.7175 30.706H19.4715C19.2063 30.706 18.9519 30.8114 18.7644 30.9989C18.5768 31.1864 18.4715 31.4408 18.4715 31.706C18.4715 31.9712 18.5768 32.2256 18.7644 32.4131C18.9519 32.6006 19.2063 32.706 19.4715 32.706Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
