import { withIcon } from "@icons/_withIcon";

export const CaretLeft = withIcon(
  () => (
    <>
      <path d="M20 1.25C16.2916 1.25 12.6665 2.34967 9.58307 4.40994C6.49964 6.47022 4.09641 9.39857 2.67727 12.8247C1.25812 16.2508 0.886812 20.0208 1.61028 23.6579C2.33376 27.2951 4.11952 30.636 6.74176 33.2583C9.36399 35.8805 12.7049 37.6663 16.3421 38.3897C19.9792 39.1132 23.7492 38.7419 27.1753 37.3227C30.6014 35.9036 33.5298 33.5004 35.5901 30.4169C37.6503 27.3335 38.75 23.7084 38.75 20C38.75 15.0272 36.7746 10.2581 33.2583 6.74175C29.742 3.22544 24.9728 1.25 20 1.25ZM20 36.25C16.7861 36.25 13.6443 35.297 10.972 33.5114C8.29969 31.7258 6.21689 29.1879 4.98697 26.2186C3.75704 23.2493 3.43524 19.982 4.06225 16.8298C4.68926 13.6776 6.23692 10.7821 8.50952 8.50951C10.7821 6.23691 13.6776 4.68925 16.8298 4.06224C19.982 3.43523 23.2493 3.75703 26.2186 4.98696C29.1879 6.21688 31.7258 8.29969 33.5114 10.972C35.297 13.6443 36.25 16.7861 36.25 20C36.2434 24.3077 34.5292 28.4371 31.4832 31.4832C28.4372 34.5292 24.3077 36.2434 20 36.25Z" />
      <path d="M23.3994 12.7501C23.2832 12.6329 23.145 12.5399 22.9926 12.4764C22.8403 12.413 22.6769 12.3803 22.5119 12.3803C22.3469 12.3803 22.1835 12.413 22.0312 12.4764C21.8789 12.5399 21.7406 12.6329 21.6244 12.7501L14.8494 19.1001C14.6113 19.339 14.4775 19.6627 14.4775 20.0001C14.4775 20.3374 14.6113 20.6611 14.8494 20.9001L21.6244 27.3001C21.7353 27.4125 21.8677 27.5015 22.0138 27.5616C22.1598 27.6218 22.3165 27.6518 22.4744 27.6501C22.6481 27.6528 22.8205 27.6185 22.98 27.5496C23.1394 27.4806 23.2824 27.3785 23.3994 27.2501C23.5158 27.136 23.6083 26.9999 23.6714 26.8496C23.7346 26.6994 23.7671 26.538 23.7671 26.3751C23.7671 26.2121 23.7346 26.0508 23.6714 25.9005C23.6083 25.7503 23.5158 25.6141 23.3994 25.5001L17.4994 20.0001L23.3494 14.5001C23.4691 14.3894 23.5656 14.2559 23.633 14.1074C23.7005 13.959 23.7377 13.7986 23.7423 13.6356C23.747 13.4726 23.719 13.3103 23.6602 13.1582C23.6013 13.0062 23.5126 12.8674 23.3994 12.7501Z" />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
