import { withIcon } from "@icons/_withIcon";

export const ArrowRight = withIcon(
  () => (
    <path d="M14.4998 7.80903C14.4742 7.74825 14.4372 7.69292 14.3908 7.64603L8.68084 1.93803C8.58696 1.84427 8.45967 1.79165 8.32699 1.79175C8.19431 1.79184 8.0671 1.84464 7.97334 1.93853C7.87959 2.03241 7.82697 2.1597 7.82707 2.29238C7.82716 2.42506 7.87996 2.55227 7.97384 2.64603L12.8278 7.50003H1.96484C1.83224 7.50003 1.70506 7.5527 1.61129 7.64647C1.51752 7.74024 1.46484 7.86742 1.46484 8.00003C1.46484 8.13263 1.51752 8.25981 1.61129 8.35358C1.70506 8.44735 1.83224 8.50003 1.96484 8.50003H12.8278L7.97384 13.354C7.87996 13.4478 7.82716 13.575 7.82707 13.7077C7.82697 13.8404 7.87959 13.9676 7.97334 14.0615C8.0671 14.1554 8.19431 14.2082 8.32699 14.2083C8.45967 14.2084 8.58696 14.1558 8.68084 14.062L14.3878 8.35403C14.4342 8.30713 14.4712 8.2518 14.4968 8.19103C14.5478 8.069 14.5489 7.93184 14.4998 7.80903Z" />
  ),
  {
    viewBox: "0 0 16 16",
  }
);
export const ArrowRightFill = withIcon(
  () => (
    <path d="M14.6942 7.70001C14.6564 7.60851 14.6006 7.52554 14.5302 7.45601L8.82315 1.75101C8.68031 1.6148 8.49052 1.53882 8.29315 1.53882C8.09578 1.53882 7.90599 1.6148 7.76315 1.75101C7.69346 1.82066 7.63817 1.90336 7.60045 1.99439C7.56273 2.08541 7.54331 2.18298 7.54331 2.28151C7.54331 2.38004 7.56273 2.47761 7.60045 2.56863C7.63817 2.65966 7.69346 2.74236 7.76315 2.81201L12.1902 7.23901H1.93115C1.73224 7.23901 1.54147 7.31803 1.40082 7.45868C1.26017 7.59933 1.18115 7.7901 1.18115 7.98901C1.18115 8.18792 1.26017 8.37869 1.40082 8.51934C1.54147 8.65999 1.73224 8.73901 1.93115 8.73901H12.1902L7.76315 13.167C7.62647 13.3084 7.55078 13.4978 7.55239 13.6945C7.55401 13.8911 7.6328 14.0793 7.77179 14.2184C7.91078 14.3575 8.09885 14.4365 8.2955 14.4383C8.49215 14.4401 8.68164 14.3646 8.82315 14.228L14.5302 8.52101C14.6349 8.41609 14.7063 8.2826 14.7355 8.13726C14.7646 7.99193 14.7503 7.84121 14.6942 7.70401V7.70001Z" />
  ),
  {
    viewBox: "0 0 16 16",
  }
);
