import { withIcon } from "@icons/_withIcon";

export const PaperMapFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M9.11448 30.2075C9.21195 30.208 9.30917 30.1979 9.40448 30.1775C9.50054 30.1615 9.59447 30.1347 9.68448 30.0975C9.77426 30.0569 9.86113 30.0101 9.94448 29.9575C10.0255 29.8997 10.1024 29.8362 10.1745 29.7675C10.3497 29.5944 10.479 29.3802 10.5506 29.1445C10.6222 28.9088 10.6338 28.6589 10.5845 28.4175C10.5648 28.3211 10.5342 28.2271 10.4935 28.1375C10.4606 28.0462 10.4174 27.9589 10.3645 27.8775C10.3064 27.7967 10.2429 27.7199 10.1745 27.6475C9.88632 27.38 9.50767 27.2314 9.11448 27.2314C8.72129 27.2314 8.34264 27.38 8.05448 27.6475C7.9858 27.7196 7.92231 27.7965 7.86448 27.8775C7.81191 27.9609 7.76513 28.0478 7.72448 28.1375C7.69144 28.2289 7.66471 28.3225 7.64448 28.4175C7.60167 28.6348 7.60749 28.8588 7.66152 29.0735C7.71555 29.2883 7.81645 29.4884 7.95698 29.6595C8.09751 29.8306 8.27418 29.9685 8.4743 30.0633C8.67443 30.158 8.89306 30.2073 9.11448 30.2075Z"
        fill={accent}
      />
      <path
        d="M19.6635 16.7375C19.9542 16.7356 20.2385 16.6525 20.4845 16.4975C20.7903 16.301 21.0151 16.0008 21.1177 15.652C21.2202 15.3032 21.1936 14.9291 21.0428 14.5983C20.892 14.2675 20.627 14.0021 20.2964 13.8507C19.9658 13.6994 19.5918 13.6723 19.2428 13.7743C18.8939 13.8763 18.5933 14.1006 18.3963 14.4062C18.1993 14.7118 18.119 15.0781 18.1701 15.438C18.2212 15.798 18.4003 16.1275 18.6745 16.3662C18.9488 16.6048 19.2999 16.7367 19.6635 16.7375Z"
        fill={accent}
      />
      <path
        d="M20.5945 21.5675C21.4229 21.5675 22.0945 20.896 22.0945 20.0675C22.0945 19.2391 21.4229 18.5675 20.5945 18.5675C19.7661 18.5675 19.0945 19.2391 19.0945 20.0675C19.0945 20.896 19.7661 21.5675 20.5945 21.5675Z"
        fill={accent}
      />
      <path
        d="M21.4945 26.4075C21.8272 26.4075 22.1504 26.2969 22.4134 26.093C22.6763 25.8892 22.864 25.6037 22.947 25.2815C23.0299 24.9593 23.0034 24.6187 22.8716 24.3133C22.7398 24.0078 22.5102 23.7548 22.2188 23.5941C21.9275 23.4334 21.5911 23.3741 21.2624 23.4256C20.9337 23.477 20.6314 23.6363 20.4031 23.8783C20.1749 24.1203 20.0335 24.4314 20.0014 24.7625C19.9692 25.0937 20.048 25.4261 20.2255 25.7075C20.3601 25.9224 20.5472 26.0995 20.7693 26.222C20.9913 26.3444 21.2409 26.4083 21.4945 26.4075Z"
        fill={accent}
      />
      <path
        d="M9.11448 20.1875C9.94291 20.1875 10.6145 19.516 10.6145 18.6875C10.6145 17.8591 9.94291 17.1875 9.11448 17.1875C8.28605 17.1875 7.61448 17.8591 7.61448 18.6875C7.61448 19.516 8.28605 20.1875 9.11448 20.1875Z"
        fill={accent}
      />
      <path
        d="M10.9245 15.6875C11.1698 15.6892 11.4118 15.6307 11.6293 15.5172C11.8468 15.4036 12.033 15.2384 12.1718 15.0361C12.3106 14.8338 12.3977 14.6006 12.4254 14.3568C12.453 14.113 12.4205 13.8662 12.3306 13.6379C12.2407 13.4096 12.0962 13.2069 11.9097 13.0475C11.7232 12.888 11.5005 12.7768 11.261 12.7234C11.0216 12.6701 10.7727 12.6763 10.5362 12.7415C10.2997 12.8068 10.0828 12.929 9.90448 13.0975C9.68693 13.3032 9.53589 13.5692 9.47081 13.8614C9.40572 14.1537 9.42956 14.4587 9.53926 14.7372C9.64896 15.0158 9.8395 15.2551 10.0864 15.4245C10.3332 15.5938 10.6251 15.6854 10.9245 15.6875Z"
        fill={accent}
      />
      <path
        d="M32.0735 23.0775C32.9019 23.0775 33.5735 22.406 33.5735 21.5775C33.5735 20.7491 32.9019 20.0775 32.0735 20.0775C31.2451 20.0775 30.5735 20.7491 30.5735 21.5775C30.5735 22.406 31.2451 23.0775 32.0735 23.0775Z"
        fill={accent}
      />
      <path
        d="M9.11448 25.1975C9.94291 25.1975 10.6145 24.526 10.6145 23.6975C10.6145 22.8691 9.94291 22.1975 9.11448 22.1975C8.28605 22.1975 7.61448 22.8691 7.61448 23.6975C7.61448 24.526 8.28605 25.1975 9.11448 25.1975Z"
        fill={accent}
      />
      <path
        d="M30.2145 27.5575C30.5585 27.5573 30.8919 27.4389 31.159 27.222C31.426 27.0052 31.6104 26.7032 31.6813 26.3666C31.7522 26.03 31.7052 25.6792 31.5483 25.3731C31.3913 25.0671 31.1339 24.8242 30.8193 24.6853C30.5046 24.5464 30.1517 24.5198 29.8198 24.6101C29.4879 24.7004 29.197 24.902 28.9961 25.1812C28.7951 25.4604 28.6962 25.8002 28.716 26.1436C28.7357 26.487 28.8729 26.8132 29.1045 27.0675C29.2456 27.2215 29.4171 27.3445 29.6081 27.4288C29.7992 27.5132 30.0057 27.557 30.2145 27.5575Z"
        fill={accent}
      />
      <path
        d="M24.0945 27.3175C24.0434 27.7113 24.1505 28.1092 24.3923 28.4241C24.6341 28.7391 24.9909 28.9452 25.3845 28.9975C25.4508 29.0056 25.5177 29.009 25.5845 29.0075C25.873 29.0071 26.1552 28.9236 26.3974 28.7668C26.6396 28.6101 26.8314 28.3868 26.9499 28.1238C27.0685 27.8608 27.1086 27.5692 27.0656 27.284C27.0227 26.9987 26.8983 26.7319 26.7075 26.5155C26.5168 26.2992 26.2676 26.1424 25.99 26.064C25.7124 25.9856 25.4181 25.9889 25.1423 26.0736C24.8665 26.1582 24.621 26.3206 24.4351 26.5412C24.2493 26.7618 24.131 27.0314 24.0945 27.3175Z"
        fill={accent}
      />
      <path
        d="M32.0735 18.0675C32.9019 18.0675 33.5735 17.396 33.5735 16.5675C33.5735 15.7391 32.9019 15.0675 32.0735 15.0675C31.2451 15.0675 30.5735 15.7391 30.5735 16.5675C30.5735 17.396 31.2451 18.0675 32.0735 18.0675Z"
        fill={accent}
      />
      <path
        d="M15.3645 14.1675C15.4241 14.1758 15.4843 14.1792 15.5445 14.1775C15.9345 14.1757 16.3085 14.0221 16.5871 13.7492C16.8657 13.4763 17.0271 13.1056 17.0371 12.7157C17.047 12.3258 16.9046 11.9474 16.6402 11.6607C16.3758 11.3739 16.0102 11.2015 15.6207 11.1798C15.2313 11.1582 14.8488 11.2891 14.5543 11.5448C14.2597 11.8004 14.0764 12.1608 14.043 12.5494C14.0097 12.9379 14.129 13.3242 14.3757 13.6263C14.6224 13.9284 14.9771 14.1225 15.3645 14.1675Z"
        fill={accent}
      />
      <path
        d="M33.1345 10.4275C33.0643 10.3567 32.9873 10.2931 32.9045 10.2375C32.824 10.1828 32.7366 10.1391 32.6445 10.1075C32.5548 10.0671 32.4609 10.0369 32.3645 10.0175C32.1729 9.97754 31.9751 9.97754 31.7835 10.0175C31.6874 10.0371 31.5938 10.0672 31.5045 10.1075C31.4127 10.1405 31.3251 10.1841 31.2435 10.2375C31.1628 10.2954 31.0862 10.3589 31.0145 10.4275C30.7343 10.7094 30.5759 11.0901 30.5735 11.4875C30.5738 11.6845 30.6129 11.8795 30.6886 12.0614C30.7642 12.2433 30.875 12.4085 31.0145 12.5475C31.2988 12.8125 31.6749 12.9567 32.0635 12.9499C32.4521 12.943 32.8229 12.7856 33.0977 12.5108C33.3726 12.2359 33.53 11.8652 33.5368 11.4766C33.5437 11.088 33.3994 10.7119 33.1345 10.4275Z"
        fill={accent}
      />
      <path
        d="M38.8175 32.1545V4.59853C38.8175 4.44267 38.7811 4.28895 38.7111 4.14967C38.6412 4.01038 38.5396 3.88937 38.4146 3.7963C38.2896 3.70323 38.1445 3.64068 37.991 3.61363C37.8375 3.58659 37.6798 3.5958 37.5305 3.64053L26.3355 6.99953L15.1405 3.64053C15.0485 3.61399 14.9532 3.60019 14.8575 3.59953H14.8485C14.753 3.6002 14.6582 3.61399 14.5665 3.64053L3.08448 7.08553C2.8784 7.14728 2.69772 7.27384 2.56928 7.44642C2.44083 7.619 2.37147 7.8284 2.37148 8.04353V35.5985C2.37148 35.8638 2.47684 36.1181 2.66438 36.3056C2.85191 36.4932 3.10627 36.5985 3.37148 36.5985C3.46872 36.5991 3.56549 36.5849 3.65848 36.5565L14.8585 33.1985L26.0585 36.5565C26.1516 36.5843 26.2483 36.5985 26.3455 36.5985C26.4427 36.5986 26.5394 36.5844 26.6325 36.5565L38.1045 33.1175C38.3114 33.0555 38.4927 32.9282 38.6212 32.7546C38.7497 32.5811 38.8186 32.3705 38.8175 32.1545ZM36.8175 31.4105L27.3355 34.2545V32.0065C27.3355 31.7413 27.2301 31.487 27.0426 31.2994C26.8551 31.1119 26.6007 31.0065 26.3355 31.0065C26.0703 31.0065 25.8159 31.1119 25.6284 31.2994C25.4408 31.487 25.3355 31.7413 25.3355 32.0065V34.2545L15.8535 31.4105V17.2285C15.8535 16.9633 15.7481 16.709 15.5606 16.5214C15.3731 16.3339 15.1187 16.2285 14.8535 16.2285C14.5883 16.2285 14.3339 16.3339 14.1464 16.5214C13.9588 16.709 13.8535 16.9633 13.8535 17.2285V31.4105L4.37148 34.2545V8.78753L13.8535 5.94253V8.19153C13.8535 8.45675 13.9588 8.7111 14.1464 8.89864C14.3339 9.08618 14.5883 9.19153 14.8535 9.19153C15.1187 9.19153 15.3731 9.08618 15.5606 8.89864C15.7481 8.7111 15.8535 8.45675 15.8535 8.19153V5.94253L25.3355 8.78753V22.9695C25.3355 23.2348 25.4408 23.4891 25.6284 23.6766C25.8159 23.8642 26.0703 23.9695 26.3355 23.9695C26.6007 23.9695 26.8551 23.8642 27.0426 23.6766C27.2301 23.4891 27.3355 23.2348 27.3355 22.9695V8.78753L36.8175 5.94353V31.4105Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
