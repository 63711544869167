import { withIcon } from "@icons/_withIcon";

export const MarketFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M6.18945 100.99H114.986"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1953 35.9893V100.99"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.633 34.3809V100.99"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1953 68.619H106.631"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="14.3711"
        y="69.4025"
        width="92.2598"
        height="31.5873"
        fill={primary}
      />
      <path
        d="M1.86328 18.9257C1.86328 27.5222 8.37075 34.4919 16.3942 34.4919C24.4176 34.4919 30.9251 27.5222 30.9251 18.9257"
        fill={accent}
      />
      <path
        d="M1.86328 18.9257C1.86328 27.5222 8.37075 34.4919 16.3942 34.4919C24.4176 34.4919 30.9251 27.5222 30.9251 18.9257"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9453 18.9257C30.9453 27.5222 37.4528 34.4919 45.4762 34.4919C53.4996 34.4919 60.0071 27.5222 60.0071 18.9257"
        fill={accent}
      />
      <path
        d="M30.9453 18.9257C30.9453 27.5222 37.4528 34.4919 45.4762 34.4919C53.4996 34.4919 60.0071 27.5222 60.0071 18.9257"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.0059 18.9257C60.0059 27.5222 66.5133 34.4919 74.5367 34.4919C82.5602 34.4919 89.0676 27.5222 89.0676 18.9257"
        fill={accent}
      />
      <path
        d="M60.0059 18.9257C60.0059 27.5222 66.5133 34.4919 74.5367 34.4919C82.5602 34.4919 89.0676 27.5222 89.0676 18.9257"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.0859 18.9257C89.0859 27.5222 95.5934 34.4919 103.617 34.4919C111.64 34.4919 118.148 27.5222 118.148 18.9257"
        fill={accent}
      />
      <path
        d="M89.0859 18.9257C89.0859 27.5222 95.5934 34.4919 103.617 34.4919C111.64 34.4919 118.148 27.5222 118.148 18.9257"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.6787 2.00995H20.3504L1.86328 18.9256H118.166L99.6787 2.00995Z"
        stroke={primary}
        strokeWidth="3.69742"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  {
    viewBox: "0 0 120 103",
    fill: "none",
  }
);
