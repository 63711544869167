import { withIcon } from "@icons/_withIcon";

export const PinterestCircle = withIcon(
  ({ primary, accent, white }) => (
    <>
      <path
        d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
        fill={white ? "#fff" : primary}
      />
      <path
        d="M16 8C11.6 8 8 11.6 8 16C8 19.3 10 22.1 12.8 23.3C12.8 22.7 12.8 22.1 12.9 21.5C13.1 20.8 13.9 17.1 13.9 17.1C13.9 17.1 13.6 16.6 13.6 15.8C13.6 14.6 14.3 13.7 15.1 13.7C15.8 13.7 16.2 14.2 16.2 14.9C16.2 15.6 15.7 16.7 15.5 17.7C15.3 18.5 15.9 19.2 16.8 19.2C18.3 19.2 19.3 17.3 19.3 14.9C19.3 13.1 18.1 11.8 16 11.8C13.6 11.8 12.1 13.6 12.1 15.6C12.1 16.3 12.3 16.8 12.6 17.2C12.7 17.4 12.8 17.4 12.7 17.6C12.7 17.7 12.6 18.1 12.5 18.2C12.4 18.4 12.3 18.5 12.1 18.4C11 17.9 10.5 16.7 10.5 15.3C10.5 13 12.4 10.3 16.2 10.3C19.3 10.3 21.3 12.5 21.3 14.9C21.3 18 19.6 20.4 17 20.4C16.1 20.4 15.3 19.9 15 19.4C15 19.4 14.5 21.2 14.4 21.6C14.2 22.2 13.9 22.8 13.6 23.3C14.3 23.5 15.1 23.6 15.9 23.6C20.3 23.6 23.9 20 23.9 15.6C24 11.6 20.4 8 16 8Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 32 32",
    primary: "#E60023",
    accent: "white",
  }
);

export const PinterestFlat = withIcon(
  () => (
    <path d="M10.0142 0C4.49667 0 0.0241699 4.4725 0.0241699 9.98917C0.0241699 14.2217 2.65584 17.8367 6.3725 19.2908C6.285 18.5 6.20667 17.2883 6.40667 16.425C6.58917 15.6442 7.57833 11.4608 7.57833 11.4608C7.57833 11.4608 7.27917 10.8608 7.27917 9.97667C7.27917 8.59083 8.085 7.55083 9.08583 7.55083C9.93917 7.55083 10.3508 8.19167 10.3508 8.9575C10.3508 9.815 9.80667 11.0967 9.52417 12.2842C9.28667 13.2783 10.0242 14.0883 11.0033 14.0883C12.7767 14.0883 14.1433 12.2175 14.1433 9.51583C14.1433 7.13167 12.4242 5.45833 9.97 5.45833C7.12833 5.45833 5.4625 7.59333 5.4625 9.79083C5.4625 10.6517 5.79084 11.5767 6.20334 12.075C6.28584 12.175 6.29667 12.2625 6.27417 12.3625C6.19917 12.675 6.03 13.3617 5.99584 13.4983C5.95167 13.6858 5.8525 13.7242 5.66167 13.6358C4.41584 13.0608 3.63417 11.2375 3.63417 9.76417C3.63417 6.6175 5.92417 3.72083 10.2342 3.72083C13.6992 3.72083 16.3942 6.19333 16.3942 9.49C16.3942 12.9358 14.2217 15.7083 11.2 15.7083C10.1883 15.7083 9.23833 15.1842 8.90167 14.5592L8.2775 16.9325C8.05333 17.8033 7.44084 18.8925 7.02917 19.5542C7.965 19.8417 8.95083 20 9.9875 20C15.4933 20 19.975 15.5292 19.975 10.0108C19.975 4.49167 15.4933 0.0216667 9.9875 0.0216667L10.0142 0Z" />
  ),
  {
    viewBox: "0 0 20 20",
  }
);
