/**
 * NOTE:
 * In DFE we called on `getInitialProps` to handle gathering a few bits of info:
 * - cookies
 * - app ctx server response
 *
 * This immediately opted us out of some static optimatization and we want to
 * avoid that if possible in this iteration of Next repo. The Next example
 * pages have various custom _app files which we may be able to lift to
 * get the data we need to flow without forfeiting the intrinsic benefits
 * of Next.
 */
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import { GoogleTagManager } from "@next/third-parties/google";
import { nanoid } from "nanoid";
import Head from "next/head";

import { CohesionContext, withCohesion } from "@cohesion";
import { Layout } from "@components/layout";
import { ErrorBoundary } from "@components/errorBoundary";
import { browserInstrumentation } from "../instrumentation.browser";

import "@components/index.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

function LPWeb({ Component, pageProps: pagePropsFromProps = {}, router }) {
  /**
   * Until we encapsulate things in a cookie provider, we are requestions cookies
   * on the page's `getServerSideProps` method and drilling it back down into the
   * layout component here.
   */
  const { events } = useRouter();
  const pageProps =
    typeof pagePropsFromProps === "object" ? pagePropsFromProps : {};
  const {
    adTargeting = null,
    disableAlert = false,
    disableNav = false,
    navbarClassName = "",
  } = pageProps;

  function getReactPageId() {
    if (!router.route) return null;
    return router.route;
  }

  useEffect(() => {
    events.on("routeChangeComplete", () => {
      // eslint-disable-next-line no-unused-expressions
      window.tagular && window.tagular("pageView");
    });

    if (typeof window !== "undefined")
      browserInstrumentation({ getReactPageId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setCohesionLoaded } = useContext(CohesionContext);

  const [GPTReady, setGPTReady] = useState(false);

  return (
    <>
      <GoogleTagManager gtmId="GTM-P9V444" />
      <ErrorBoundary>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <Layout
          adTargeting={adTargeting}
          disableAlert={disableAlert}
          disableNav={disableNav}
          GPTReady={GPTReady}
          navbarClassName={navbarClassName}
        >
          <Script id="elsewhere-utm" strategy="afterInteractive">
            {`window.chsn_ad_id = "${nanoid(32)}"`}
          </Script>

          <Script
            id="gpt"
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            strategy="afterInteractive"
            onLoad={() => {
              setGPTReady(true);
            }}
            onError={e => {
              // eslint-disable-next-line no-console
              console.error("GPT Failed to load", {
                errorInfo: JSON.stringify(e),
                event: e,
                srcElement: e.srcElement,
                currentTarget: e.currentTarget,
              });
            }}
          />
          <Script
            id="cohesion"
            src="https://cohesion.lonelyplanet.com/cohesion/cohesion-latest.min.js"
            strategy="afterInteractive"
            onLoad={() => {
              setCohesionLoaded(true);
            }}
            onError={e => {
              // eslint-disable-next-line no-console
              console.error("Cohesion script failed to load", {
                errorInfo: JSON.stringify(e),
              });
            }}
          />
          <Component {...pageProps} />
        </Layout>
      </ErrorBoundary>
    </>
  );
}

LPWeb.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    adTargeting: PropTypes.shape({}),
    disableAlert: PropTypes.bool,
    disableNav: PropTypes.bool,
    navbarClassName: PropTypes.string,
  }),
  router: PropTypes.shape({}).isRequired,
};

export default withCohesion({
  App: LPWeb,
});
