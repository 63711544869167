module.exports = {
  events: {
    click: "redventures.usertracking.v3.ElementClicked",
    view: "redventures.usertracking.v3.ElementViewed",
    swipe: "redventures.usertracking.v3.ElementSwiped",
    formstart: "redventures.usertracking.v3.FormStarted",
    formview: "redventures.usertracking.v3.FormViewed",
    input: "redventures.usertracking.v3.FieldInputted",
    submit: "redventures.usertracking.v3.FormSubmitted",
    identify: "core.Identify.v1",
    product_click: "redventures.ecommerce.v1.ProductClicked",
    product_view: "redventures.ecommerce.v1.ProductViewed",
    cart_started: "redventures.cart.v1.CartStarted",
    search: "redventures.usertracking.v1.SearchQuerySubmitted",
    adBlockCaptured: "lonelyplanet.AdBlockerCaptured.v2",
    signUpCaptured: "core.SignUpCaptured.v2",
    feedback: "lonelyplanet.Feedback.v1",
  },
};
