import { withIcon } from "@icons/_withIcon";

// Changelog: name was GlowingStar
export const GlowingStarFill = withIcon(
  () => (
    <>
      <path d="M14.1005 5.76265C14.7043 3.93078 17.2957 3.93078 17.8995 5.76265L19.7771 11.459H25.7725C27.7169 11.459 28.5179 13.9528 26.9371 15.085L22.1115 18.541L23.97 24.1795C24.5757 26.017 22.479 27.5581 20.906 26.4316L16 22.918L11.094 26.4316C9.52099 27.5581 7.42431 26.017 8.03001 24.1795L9.88855 18.541L5.06294 15.085C3.48214 13.9528 4.28306 11.459 6.22746 11.459H12.2229L14.1005 5.76265Z" />
      <path
        d="M23 8L25 6"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8L7 6"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 28V30"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 20.5L29 21.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 20.5L3 21.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  {
    viewBox: "0 0 32 32",
  }
);
