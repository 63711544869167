import { withIcon } from "@icons/_withIcon";

// Changelog: name was List
export const ListFill = withIcon(
  () => (
    <>
      <circle cx="2.1875" cy="2" r="1.5" />
      <circle cx="2.1875" cy="8" r="1.5" />
      <circle cx="2.1875" cy="14" r="1.5" />
      <rect x="6.8125" y="1" width="13" height="2" rx="1" />
      <rect x="6.8125" y="7" width="13" height="2" rx="1" />
      <rect x="6.8125" y="13" width="13" height="2" rx="1" />
    </>
  ),
  {
    viewBox: "0 0 20 16",
  }
);
