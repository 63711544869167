export async function swrFetch(endpoint) {
  try {
    const response = await fetch(endpoint);

    if (!response.ok) {
      throw new Error(`HTTP status: ${response.status}`);
    } else {
      const results = await response.json();
      return results;
    }
  } catch (error) {
    throw new Error(error);
  }
}
