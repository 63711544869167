import { withIcon } from "@icons/_withIcon";

// Changelog: previous name: `BestNeighborhoods` requires prop 'mono'
export const NeighborhoodsFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M18.569 19.1475H16.009C15.7329 19.1475 15.509 19.3714 15.509 19.6475V24.5735C15.509 24.8497 15.7329 25.0735 16.009 25.0735H18.569C18.8451 25.0735 19.069 24.8497 19.069 24.5735V19.6475C19.069 19.3714 18.8451 19.1475 18.569 19.1475Z"
        fill={accent}
      />
      <path
        d="M31.388 26.7165H28.828C28.5519 26.7165 28.328 26.9404 28.328 27.2165V32.1425C28.328 32.4187 28.5519 32.6425 28.828 32.6425H31.388C31.6641 32.6425 31.888 32.4187 31.888 32.1425V27.2165C31.888 26.9404 31.6641 26.7165 31.388 26.7165Z"
        fill={accent}
      />
      <path
        d="M11.76 19.1475H9.20001C8.92387 19.1475 8.70001 19.3714 8.70001 19.6475V24.5735C8.70001 24.8497 8.92387 25.0735 9.20001 25.0735H11.76C12.0362 25.0735 12.26 24.8497 12.26 24.5735V19.6475C12.26 19.3714 12.0362 19.1475 11.76 19.1475Z"
        fill={accent}
      />
      <path
        d="M18.569 9.75153H16.009C15.7329 9.75153 15.509 9.97538 15.509 10.2515V15.1775C15.509 15.4537 15.7329 15.6775 16.009 15.6775H18.569C18.8451 15.6775 19.069 15.4537 19.069 15.1775V10.2515C19.069 9.97538 18.8451 9.75153 18.569 9.75153Z"
        fill={accent}
      />
      <path
        d="M11.76 9.75153H9.20001C8.92387 9.75153 8.70001 9.97538 8.70001 10.2515V15.1775C8.70001 15.4537 8.92387 15.6775 9.20001 15.6775H11.76C12.0362 15.6775 12.26 15.4537 12.26 15.1775V10.2515C12.26 9.97538 12.0362 9.75153 11.76 9.75153Z"
        fill={accent}
      />
      <path
        d="M24.554 2.78553C24.4451 2.69181 24.3173 2.62266 24.1792 2.5828C24.0411 2.54294 23.8961 2.53329 23.754 2.55453L3.66301 5.56453C3.42626 5.6002 3.21016 5.71964 3.054 5.90112C2.89784 6.08261 2.81197 6.3141 2.81201 6.55353V36.8915C2.81201 37.1567 2.91737 37.4111 3.1049 37.5986C3.29244 37.7862 3.5468 37.8915 3.81201 37.8915H36.312C36.5772 37.8915 36.8316 37.7862 37.0191 37.5986C37.2067 37.4111 37.312 37.1567 37.312 36.8915V19.1475C37.3121 18.9529 37.2554 18.7624 37.1488 18.5995C37.0422 18.4366 36.8904 18.3084 36.712 18.2305L34.774 17.3915V16.0255C34.774 15.7603 34.6687 15.506 34.4811 15.3184C34.2936 15.1309 34.0392 15.0255 33.774 15.0255C33.5088 15.0255 33.2544 15.1309 33.0669 15.3184C32.8794 15.506 32.774 15.7603 32.774 16.0255V16.5255L30.506 15.5435C30.3801 15.4877 30.2438 15.4588 30.106 15.4588C29.9682 15.4588 29.832 15.4877 29.706 15.5435L24.9 17.6255V3.54353C24.9002 3.39973 24.8694 3.25758 24.8097 3.12677C24.75 2.99596 24.6628 2.87957 24.554 2.78553ZM12.131 35.8915V30.6795H16.047V35.8915H12.131ZM17.047 28.6795H11.131C10.8658 28.6795 10.6114 28.7849 10.4239 28.9724C10.2364 29.16 10.131 29.4143 10.131 29.6795V35.8915H4.81201V7.41753L22.9 4.70453V35.8915H18.047V29.6795C18.047 29.4143 17.9417 29.16 17.7541 28.9724C17.5666 28.7849 17.3122 28.6795 17.047 28.6795ZM30.108 17.5505L35.314 19.8045V35.8915H24.9V19.8045L30.108 17.5505Z"
        fill={primary}
      />
      <path
        d="M30.108 23.6105C30.9364 23.6105 31.608 22.939 31.608 22.1105C31.608 21.2821 30.9364 20.6105 30.108 20.6105C29.2796 20.6105 28.608 21.2821 28.608 22.1105C28.608 22.939 29.2796 23.6105 30.108 23.6105Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
