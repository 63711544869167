import { withIcon } from "@icons/_withIcon";

export const Solo = withIcon(
  () => (
    <path
      d="M1.5 20.5C1.5 16.3579 4.85786 13 9 13C13.1421 13 16.5 16.3579 16.5 20.5M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  ),
  {
    viewBox: "0 0 18 21",
    fill: "none",
  }
);
