// TODO: refactor candidate - finding another way to check for adlbockers without
// the need for an extra network request that we're expecting to fail
export default async function isCohesionReachable() {
  const req = new Request(
    "https://cdn.cohesionapps.com/cohesion/cohesion-latest.min.js",
    {
      method: "HEAD",
      mode: "no-cors",
    }
  );

  try {
    await fetch(req);
  } catch (error) {
    /**
     * Something, likely an adblocker, has gotten in the way of the req to
     * the cohesion cdn
     */
    return false;
  }

  return true;
}
