import { createContext, useMemo } from "react";
import PropTypes from "prop-types";

const CookieContext = createContext({});
export default CookieContext;

export function CookieContextProvider({ cookies = null, children }) {
  const cookiesContext = useMemo(() => cookies || {}, [cookies]);
  return (
    <CookieContext.Provider value={cookiesContext}>
      {children}
    </CookieContext.Provider>
  );
}

CookieContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  cookies: PropTypes.shape({}),
};
