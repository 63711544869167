import { withIcon } from "@icons/_withIcon";

export const StoriesFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M34.185 20.708C34.2426 20.661 34.2889 20.6019 34.3208 20.5347C34.3526 20.4676 34.3691 20.3943 34.369 20.32V13.469C34.3688 13.3539 34.3289 13.2424 34.256 13.1533C34.1831 13.0642 34.0818 13.003 33.969 12.98C30.626 12.2737 27.1712 12.2853 23.833 13.014C23.7213 13.038 23.6212 13.0995 23.5493 13.1883C23.4775 13.277 23.4382 13.3878 23.438 13.502V20.354C23.438 20.4866 23.4907 20.6138 23.5844 20.7075C23.6782 20.8013 23.8054 20.854 23.938 20.854C23.9737 20.854 24.0092 20.85 24.044 20.842C27.2455 20.1459 30.558 20.135 33.764 20.81C33.8373 20.8256 33.9132 20.8246 33.9861 20.8069C34.059 20.7893 34.127 20.7555 34.185 20.708Z"
        fill={accent}
      />
      <path
        d="M2.563 36.409C8.03574 34.1959 14.1543 34.1959 19.627 36.409H19.642C19.7203 36.4316 19.7994 36.4516 19.879 36.469C19.9596 36.4716 20.0404 36.4716 20.121 36.469C20.1572 36.463 20.1929 36.4547 20.228 36.444C20.2721 36.4356 20.3156 36.4239 20.358 36.409H20.373C25.8457 34.1959 31.9643 34.1959 37.437 36.409C37.5887 36.4697 37.753 36.4924 37.9154 36.4749C38.0779 36.4575 38.2336 36.4005 38.369 36.309C38.5048 36.2175 38.616 36.094 38.6929 35.9495C38.7697 35.8049 38.81 35.6437 38.81 35.48V6.40998C38.81 6.21039 38.7503 6.01536 38.6386 5.84997C38.5269 5.68459 38.3682 5.55643 38.183 5.48198C32.3581 3.13253 25.8577 3.08641 20 5.35298C14.1421 3.08705 7.64165 3.13388 1.817 5.48398C1.63181 5.55843 1.47314 5.68659 1.3614 5.85197C1.24966 6.01736 1.18997 6.21239 1.19 6.41198V35.484C1.19 35.7492 1.29536 36.0036 1.4829 36.1911C1.67043 36.3786 1.92479 36.484 2.19 36.484C2.31803 36.4834 2.44472 36.458 2.563 36.409ZM36.81 7.09698V34.047C31.6821 32.316 26.1279 32.316 21 34.047V7.09698C26.1004 5.20594 31.7096 5.20594 36.81 7.09698ZM3.19 7.09698C8.29036 5.20594 13.8996 5.20594 19 7.09698V34.047C13.8721 32.3163 8.31791 32.3163 3.19 34.047V7.09698Z"
        fill={primary}
      />
      <path
        d="M6.05301 14.502C6.12425 14.502 6.1953 14.4947 6.26501 14.48C9.39784 13.7981 12.6395 13.7869 15.777 14.447C15.9065 14.4766 16.0405 14.48 16.1714 14.4572C16.3022 14.4344 16.4272 14.3858 16.539 14.3142C16.6508 14.2426 16.7473 14.1494 16.8227 14.0401C16.8981 13.9308 16.951 13.8076 16.9783 13.6776C17.0056 13.5477 17.0067 13.4136 16.9817 13.2832C16.9566 13.1528 16.9058 13.0286 16.8322 12.9181C16.7586 12.8075 16.6638 12.7127 16.5532 12.6392C16.4426 12.5657 16.3184 12.515 16.188 12.49C12.7758 11.7722 9.25041 11.7838 5.84301 12.524C5.60127 12.5755 5.38719 12.7148 5.24207 12.9149C5.09696 13.115 5.0311 13.3618 5.0572 13.6076C5.08329 13.8534 5.1995 14.0808 5.38339 14.246C5.56728 14.4111 5.80583 14.5023 6.05301 14.502Z"
        fill={primary}
      />
      <path
        d="M6.05301 21.354C6.12431 21.3542 6.19541 21.3465 6.26501 21.331C9.39791 20.6494 12.6396 20.6385 15.777 21.299C16.0345 21.3484 16.3011 21.2946 16.5193 21.1494C16.7376 21.0042 16.8901 20.779 16.944 20.5224C16.9979 20.2658 16.9488 19.9984 16.8074 19.7776C16.6661 19.5568 16.4436 19.4004 16.188 19.342C12.7759 18.6237 9.25036 18.6353 5.84301 19.376C5.60127 19.4275 5.38719 19.5668 5.24207 19.7669C5.09696 19.967 5.0311 20.2138 5.0572 20.4596C5.08329 20.7054 5.1995 20.9328 5.38339 21.098C5.56728 21.2631 5.80583 21.3543 6.05301 21.354Z"
        fill={primary}
      />
      <path
        d="M13.409 27.784C13.6729 27.8105 13.9365 27.7311 14.1419 27.5633C14.3472 27.3954 14.4775 27.1529 14.504 26.889C14.5305 26.6251 14.4511 26.3615 14.2833 26.1561C14.1154 25.9508 13.8729 25.8205 13.609 25.794C11.0132 25.5308 8.39227 25.6773 5.84199 26.228C5.60128 26.2805 5.38844 26.4201 5.24428 26.6199C5.10011 26.8196 5.03478 27.0656 5.0608 27.3106C5.08683 27.5556 5.20238 27.7824 5.38529 27.9474C5.56821 28.1125 5.80562 28.2042 6.05199 28.205C6.12323 28.205 6.19428 28.1976 6.26399 28.183C8.60978 27.6745 11.0212 27.5399 13.409 27.784Z"
        fill={primary}
      />
      <path
        d="M23.94 27.936C24.0113 27.9363 24.0824 27.9286 24.152 27.913C27.285 27.2324 30.5265 27.2215 33.664 27.881C33.7937 27.9112 33.9282 27.9153 34.0596 27.8928C34.1909 27.8704 34.3164 27.822 34.4287 27.7503C34.5411 27.6787 34.638 27.5854 34.7137 27.4758C34.7895 27.3662 34.8426 27.2426 34.8699 27.1122C34.8972 26.9818 34.8982 26.8472 34.8728 26.7165C34.8474 26.5857 34.7961 26.4613 34.722 26.3506C34.6478 26.2399 34.5523 26.1451 34.4411 26.0719C34.3298 25.9986 34.205 25.9483 34.074 25.924C30.6621 25.2062 27.1371 25.2178 23.73 25.958C23.4883 26.0095 23.2742 26.1488 23.1291 26.3489C22.984 26.549 22.9181 26.7958 22.9442 27.0416C22.9703 27.2874 23.0865 27.5148 23.2704 27.6799C23.4543 27.8451 23.6928 27.9363 23.94 27.936Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
