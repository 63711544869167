import { withIcon } from "@icons/_withIcon";

export const Train = withIcon(
  () => (
    <>
      <path
        d="M3.49146 2.74695C3.61884 1.74831 4.46865 1 5.47539 1H10.5246C11.5313 1 12.3812 1.74831 12.5085 2.74695L13 6.6V11C13 11.5523 12.5523 12 12 12H8H4C3.44772 12 3 11.5523 3 11V6.6L3.49146 2.74695Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M13 7L8 8L3 7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3H4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9.75H5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 9.75H10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14H4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12L13 15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12L3 15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  {
    viewBox: "0 0 16 16",
    fill: "none",
  }
);

export const TrainFill = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8L3 7V12H13V7L8 8ZM5 9C4.58579 9 4.25 9.33579 4.25 9.75C4.25 10.1642 4.58579 10.5 5 10.5H6C6.41421 10.5 6.75 10.1642 6.75 9.75C6.75 9.33579 6.41421 9 6 9H5ZM10 9C9.58579 9 9.25 9.33579 9.25 9.75C9.25 10.1642 9.58579 10.5 10 10.5H11C11.4142 10.5 11.75 10.1642 11.75 9.75C11.75 9.33579 11.4142 9 11 9H10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74763 2.65205C2.92278 1.27892 4.09127 0.25 5.47553 0.25H10.5248C11.909 0.25 13.0775 1.27892 13.2527 2.65205L13.7441 6.50511C13.7481 6.53658 13.7501 6.56827 13.7501 6.6V11C13.7501 11.9665 12.9666 12.75 12.0001 12.75H4.00014C3.03365 12.75 2.25014 11.9665 2.25014 11V6.6C2.25014 6.56827 2.25216 6.53658 2.25617 6.50511L2.74763 2.65205ZM5.47553 1.75C4.84632 1.75 4.31519 2.21769 4.23558 2.84184L3.75014 6.64764V11C3.75014 11.1381 3.86207 11.25 4.00014 11.25H12.0001C12.1382 11.25 12.2501 11.1381 12.2501 11V6.64764L11.7647 2.84184C11.6851 2.21769 11.154 1.75 10.5248 1.75H5.47553Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26471 6.85291C2.34594 6.44674 2.74106 6.18333 3.14723 6.26456L8.00014 7.23515L12.8531 6.26456C13.2592 6.18333 13.6543 6.44674 13.7356 6.85291C13.8168 7.25908 13.5534 7.6542 13.1472 7.73544L8.14723 8.73544C8.05013 8.75486 7.95015 8.75486 7.85306 8.73544L2.85306 7.73544C2.44689 7.6542 2.18348 7.25908 2.26471 6.85291Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25014 3C3.25014 2.58579 3.58593 2.25 4.00014 2.25H12.0001C12.4144 2.25 12.7501 2.58579 12.7501 3C12.7501 3.41421 12.4144 3.75 12.0001 3.75H4.00014C3.58593 3.75 3.25014 3.41421 3.25014 3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00014 14.5C3.00014 14.0858 3.33593 13.75 3.75014 13.75H12.2501C12.6644 13.75 13.0001 14.0858 13.0001 14.5C13.0001 14.9142 12.6644 15.25 12.2501 15.25H3.75014C3.33593 15.25 3.00014 14.9142 3.00014 14.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5841 11.376C10.9288 11.1462 11.3944 11.2393 11.6242 11.584L13.6242 14.584C13.8539 14.9286 13.7608 15.3943 13.4162 15.624C13.0715 15.8538 12.6059 15.7607 12.3761 15.416L10.3761 12.416C10.1463 12.0714 10.2395 11.6057 10.5841 11.376Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41617 11.376C5.07152 11.1462 4.60587 11.2393 4.37611 11.584L2.37611 14.584C2.14634 14.9286 2.23947 15.3943 2.58412 15.624C2.92877 15.8538 3.39442 15.7607 3.62418 15.416L5.62418 12.416C5.85395 12.0714 5.76082 11.6057 5.41617 11.376Z"
        fill="currentColor"
      />
      <rect x="4" y="1" width="8" height="2" fill="currentColor" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);
