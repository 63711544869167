import { withIcon } from "@icons/_withIcon";

export const ViewFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M20 23.7236C21.6569 23.7236 23 22.3805 23 20.7236C23 19.0667 21.6569 17.7236 20 17.7236C18.3431 17.7236 17 19.0667 17 20.7236C17 22.3805 18.3431 23.7236 20 23.7236Z"
        fill={accent}
      />
      <path
        d="M20 29.7236C21.78 29.7236 23.5201 29.1958 25.0001 28.2068C26.4802 27.2179 27.6337 25.8123 28.3149 24.1678C28.9961 22.5232 29.1743 20.7136 28.8271 18.9678C28.4798 17.222 27.6226 15.6183 26.364 14.3596C25.1053 13.101 23.5016 12.2438 21.7558 11.8965C20.01 11.5493 18.2004 11.7275 16.5558 12.4087C14.9113 13.0899 13.5057 14.2434 12.5168 15.7235C11.5278 17.2035 11 18.9436 11 20.7236C11 23.1106 11.9482 25.3997 13.636 27.0876C15.3239 28.7754 17.6131 29.7236 20 29.7236ZM20 15.7236C20.9889 15.7236 21.9556 16.0169 22.7779 16.5663C23.6001 17.1157 24.241 17.8966 24.6194 18.8102C24.9978 19.7238 25.0969 20.7292 24.9039 21.6991C24.711 22.669 24.2348 23.5599 23.5355 24.2591C22.8363 24.9584 21.9454 25.4346 20.9755 25.6275C20.0055 25.8205 19.0002 25.7214 18.0866 25.343C17.173 24.9646 16.3921 24.3237 15.8427 23.5015C15.2932 22.6792 15 21.7125 15 20.7236C15.0016 19.398 15.5289 18.1272 16.4662 17.1898C17.4036 16.2525 18.6744 15.7252 20 15.7236Z"
        fill={accent}
      />
      <path
        d="M8.58099 30.3336C10.0413 31.9014 11.8088 33.1517 13.7733 34.0066C15.7379 34.8615 17.8575 35.3027 20 35.3027C22.1425 35.3027 24.2621 34.8615 26.2267 34.0066C28.1912 33.1517 29.9587 31.9014 31.419 30.3336L38.462 22.7696C38.9769 22.2133 39.2629 21.4831 39.2629 20.7251C39.2629 19.9671 38.9769 19.2369 38.462 18.6806L31.419 11.1166C29.9587 9.54881 28.1912 8.29851 26.2267 7.44361C24.2621 6.5887 22.1425 6.14751 20 6.14751C17.8575 6.14751 15.7379 6.5887 13.7733 7.44361C11.8088 8.29851 10.0413 9.54881 8.58099 11.1166L1.53799 18.6786C1.02311 19.2349 0.737091 19.9651 0.737091 20.7231C0.737091 21.4811 1.02311 22.2113 1.53799 22.7676L8.58099 30.3336ZM2.99999 20.0416L10.044 12.4786C11.3167 11.1112 12.8575 10.0207 14.5702 9.27506C16.283 8.52939 18.131 8.14456 19.999 8.14456C21.867 8.14456 23.715 8.52939 25.4278 9.27506C27.1405 10.0207 28.6813 11.1112 29.954 12.4786L37 20.0416C37.1723 20.2267 37.2682 20.4702 37.2682 20.7231C37.2682 20.976 37.1723 21.2195 37 21.4046L29.956 28.9676C28.6833 30.335 27.1425 31.4255 25.4298 32.1712C23.717 32.9168 21.869 33.3016 20.001 33.3016C18.133 33.3016 16.285 32.9168 14.5722 32.1712C12.8595 31.4255 11.3187 30.335 10.046 28.9676L2.99999 21.4046C2.82764 21.2195 2.73181 20.976 2.73181 20.7231C2.73181 20.4702 2.82764 20.2267 2.99999 20.0416Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
