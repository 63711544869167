import { withIcon } from "@icons/_withIcon";

// Changelog: name was See
export const SeeFill = withIcon(
  () => (
    <>
      <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
      <path
        d="M12.5 10.1937C11.3219 10.1937 10.3469 11.1687 10.3469 12.3469C10.3469 13.525 11.3219 14.5 12.5 14.5C13.6781 14.5 14.6531 13.525 14.6531 12.3469C14.6531 11.1687 13.6781 10.1937 12.5 10.1937ZM12.5 13.4437C11.8906 13.4437 11.4031 12.9562 11.4031 12.3469C11.4031 11.7375 11.8906 11.25 12.5 11.25C13.1094 11.25 13.5969 11.7375 13.5969 12.3469C13.5969 12.9562 13.1094 13.4437 12.5 13.4437Z"
        fill="white"
      />
      <path
        d="M12.5 8C9.20937 8 6 12.3469 6 12.3469C6 12.3469 9.20937 16.6938 12.5 16.6938C15.7906 16.6938 19 12.3469 19 12.3469C19 12.3469 15.7906 8 12.5 8ZM12.5813 15.5969C10.7531 15.5969 9.25 14.1344 9.25 12.3469C9.25 10.5594 10.7531 9.09687 12.5813 9.09687C14.4094 9.09687 15.9125 10.5594 15.9125 12.3469C15.9125 14.1344 14.45 15.5969 12.5813 15.5969Z"
        fill="white"
      />
    </>
  ),
  {
    viewBox: "0 0 24 24",
  }
);
