import { withIcon } from "@icons/_withIcon";

export const DaytripFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M41.9998 26.9999C41.9998 37.2724 33.6723 45.5999 23.3998 45.5999C13.1273 45.5999 4.7998 37.2724 4.7998 26.9999C4.7998 16.7274 13.1273 8.3999 23.3998 8.3999H26.3998"
        stroke={primary}
        fill="transparent"
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M21.5996 2.3999L27.5996 8.3999L21.5996 14.3999"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5979 27.661C18.2458 26.9999 19.0259 26.1536 19.0259 24.9635C19.0259 23.4165 17.9549 22.2 15.826 22.2C13.6839 22.2 12.8376 23.6677 12.5996 24.9106L14.345 25.4792C14.4905 24.8181 14.8343 24.0247 15.826 24.0247C16.4871 24.0247 16.9235 24.4743 16.9235 25.0693C16.9235 25.6115 16.6194 26.0081 16.1301 26.5238L12.798 30.0676V31.6279H19.0392V29.856H15.4822L17.5979 27.661Z"
        fill={accent}
      />
      <path
        d="M25.8863 27.9122V22.3718H24.0219L19.8038 27.7668V29.6841H23.8897V31.6279H25.8863V29.6841H27.0367V27.9122H25.8863ZM23.8897 25.3206V27.9122H21.8534L23.8897 25.3206Z"
        fill={accent}
      />
      <path
        d="M31.8195 24.5801C31.0658 24.5801 30.4179 24.871 30.0212 25.585V22.3718H28.0774V31.6279H30.0212V27.6081C30.0212 26.7354 30.5369 26.2726 31.2377 26.2726C31.8063 26.2726 32.2558 26.6957 32.2558 27.4891V31.6279H34.1996V27.1453C34.1996 25.5982 33.1814 24.5801 31.8195 24.5801Z"
        fill={accent}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8635 10.9393C33.2365 10.3915 33.983 10.2498 34.5308 10.6228C34.8739 10.8564 35.2093 11.1005 35.5365 11.3547C36.0599 11.7613 36.1546 12.5151 35.748 13.0385C35.3415 13.5619 34.5876 13.6566 34.0642 13.25C33.7765 13.0266 33.4816 12.8119 33.18 12.6066C32.6322 12.2336 32.4905 11.4871 32.8635 10.9393ZM37.3614 14.6519C37.8848 14.2453 38.6386 14.34 39.0452 14.8634C39.2994 15.1906 39.5435 15.526 39.7771 15.8691C40.1501 16.4169 40.0084 17.1634 39.4606 17.5364C38.9128 17.9094 38.1663 17.7677 37.7933 17.2199C37.588 16.9183 37.3733 16.6234 37.1499 16.3357C36.7433 15.8123 36.838 15.0584 37.3614 14.6519ZM40.4984 19.5686C41.1219 19.3441 41.8095 19.6675 42.034 20.2911C42.174 20.68 42.3023 21.0746 42.4182 21.4744C42.6029 22.1109 42.2366 22.7766 41.6001 22.9612C40.9635 23.1459 40.2979 22.7795 40.1133 22.143C40.0114 21.792 39.8989 21.4456 39.7759 21.1042C39.5514 20.4806 39.8749 19.7931 40.4984 19.5686Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 48 48",
  }
);
