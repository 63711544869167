module.exports = {
  INTERNATIONAL_SITES: [
    {
      label: "English",
      url: "www.lonelyplanet.com",
    },
    {
      label: "Deutsch",
      url: "www.lonelyplanet.de",
    },
    {
      label: "Français",
      url: "www.lonelyplanet.fr",
    },
    {
      label: "Español",
      url: "www.lonelyplanet.es",
    },
    {
      label: "Italiano",
      url: "www.lonelyplanetitalia.it",
    },
    {
      label: "Česky",
      url: "www.lonelyplanet.cz",
    },
  ],
};
