import { withIcon } from "@icons/_withIcon";

export const TopChoiceFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M23.563 27.9401H21.2L20.472 25.6971C20.431 25.6051 20.3643 25.5269 20.2798 25.472C20.1953 25.4171 20.0967 25.3879 19.996 25.3879C19.8953 25.3879 19.7967 25.4171 19.7122 25.472C19.6277 25.5269 19.561 25.6051 19.52 25.6971L18.8 27.9401H16.437C16.3326 27.9414 16.2311 27.9753 16.147 28.0371C16.0628 28.0989 16.0001 28.1856 15.9677 28.2848C15.9352 28.3841 15.9347 28.491 15.9661 28.5906C15.9976 28.6902 16.0594 28.7775 16.143 28.8401L18.051 30.2271L17.322 32.4701C17.2892 32.5706 17.2891 32.6789 17.3218 32.7795C17.3544 32.8801 17.4181 32.9677 17.5036 33.0298C17.5892 33.0919 17.6922 33.1253 17.7979 33.1252C17.9036 33.125 18.0066 33.0914 18.092 33.0291L20 31.6511L21.908 33.0371C21.9934 33.0994 22.0964 33.133 22.2021 33.1332C22.3078 33.1333 22.4108 33.0999 22.4964 33.0378C22.5819 32.9757 22.6456 32.8881 22.6782 32.7875C22.7109 32.6869 22.7108 32.5786 22.678 32.4781L21.949 30.2351L23.857 28.8481C23.9406 28.7855 24.0024 28.6982 24.0338 28.5986C24.0653 28.499 24.0648 28.3921 24.0323 28.2928C23.9999 28.1936 23.9372 28.1069 23.853 28.0451C23.7688 27.9833 23.6674 27.9494 23.563 27.9481V27.9401Z"
        fill={accent}
      />
      <path
        d="M37.851 16.8161L23.535 2.50013C22.5974 1.56277 21.3258 1.03619 20 1.03619C18.6742 1.03619 17.4026 1.56277 16.465 2.50013L2.14901 16.8161C1.21165 17.7538 0.685074 19.0253 0.685074 20.3511C0.685074 21.6769 1.21165 22.9485 2.14901 23.8861L16.465 38.2021C17.4026 39.1395 18.6742 39.6661 20 39.6661C21.3258 39.6661 22.5974 39.1395 23.535 38.2021L37.851 23.8861C38.7884 22.9485 39.3149 21.6769 39.3149 20.3511C39.3149 19.0253 38.7884 17.7538 37.851 16.8161ZM36.437 22.4721L22.121 36.7881C21.5493 37.3331 20.7898 37.6371 20 37.6371C19.2102 37.6371 18.4507 37.3331 17.879 36.7881L3.56301 22.4721C3.00059 21.9095 2.68465 21.1466 2.68465 20.3511C2.68465 19.5556 3.00059 18.7927 3.56301 18.2301L17.879 3.91413C18.4416 3.35171 19.2045 3.03577 20 3.03577C20.7955 3.03577 21.5584 3.35171 22.121 3.91413L36.437 18.2301C36.9994 18.7927 37.3154 19.5556 37.3154 20.3511C37.3154 21.1466 36.9994 21.9095 36.437 22.4721Z"
        fill={primary}
      />
      <path
        d="M15.675 15.0511H16.761V11.1391H18.223V10.1781H14.213V11.1391H15.675V15.0511Z"
        fill={primary}
      />
      <path
        d="M19.554 15.1421C19.8058 15.1516 20.0568 15.1089 20.2912 15.0166C20.5257 14.9244 20.7386 14.7847 20.9164 14.6063C21.0943 14.4278 21.2334 14.2145 21.3248 13.9798C21.4163 13.745 21.4582 13.4939 21.448 13.2421C21.448 12.7409 21.2489 12.2602 20.8944 11.9057C20.54 11.5513 20.0593 11.3521 19.558 11.3521C19.0567 11.3521 18.576 11.5513 18.2216 11.9057C17.8671 12.2602 17.668 12.7409 17.668 13.2421C17.6574 13.4933 17.6988 13.7439 17.7896 13.9783C17.8804 14.2127 18.0187 14.4258 18.1958 14.6042C18.3729 14.7826 18.585 14.9225 18.8187 15.0151C19.0524 15.1077 19.3028 15.1509 19.554 15.1421ZM19.554 12.2531C20.035 12.2531 20.396 12.6011 20.396 13.2411C20.396 13.8811 20.035 14.2361 19.554 14.2361C19.073 14.2361 18.726 13.8821 18.726 13.2411C18.726 12.6001 19.081 12.2511 19.554 12.2511V12.2531Z"
        fill={primary}
      />
      <path
        d="M22.988 14.5841C23.1013 14.7621 23.2592 14.9073 23.4461 15.0052C23.6329 15.1031 23.8422 15.1503 24.053 15.1421C24.2841 15.1259 24.5096 15.0634 24.7161 14.9584C24.9226 14.8534 25.1059 14.708 25.2551 14.5308C25.4043 14.3535 25.5164 14.1482 25.5848 13.9268C25.6531 13.7055 25.6763 13.4726 25.653 13.2421C25.6765 13.0116 25.6534 12.7788 25.5851 12.5573C25.5168 12.3359 25.4047 12.1305 25.2554 11.9533C25.1062 11.776 24.9228 11.6306 24.7163 11.5256C24.5097 11.4206 24.2841 11.3582 24.053 11.3421C23.8431 11.3339 23.6346 11.3803 23.448 11.4767C23.2613 11.5731 23.1028 11.7162 22.988 11.8921V11.4391H21.965V16.5281H22.988V14.5841ZM23.788 12.2531C24.268 12.2531 24.603 12.6081 24.603 13.2531C24.603 13.8981 24.268 14.2411 23.788 14.2411C23.308 14.2411 22.974 13.8931 22.974 13.2531C22.974 12.6131 23.308 12.2511 23.788 12.2511V12.2531Z"
        fill={primary}
      />
      <path
        d="M11.6 18.8511C11.8439 18.8518 12.082 18.9258 12.2832 19.0637C12.4844 19.2016 12.6394 19.3969 12.728 19.6241L13.646 19.0601C13.4592 18.6823 13.166 18.3674 12.8025 18.1542C12.4389 17.941 12.0209 17.8387 11.6 17.8601C11.2679 17.8491 10.9371 17.9075 10.6289 18.0317C10.3207 18.156 10.0418 18.3432 9.81018 18.5815C9.57857 18.8198 9.39931 19.1039 9.2839 19.4155C9.16849 19.7272 9.11948 20.0595 9.14001 20.3911C9.12006 20.7225 9.1695 21.0544 9.28517 21.3655C9.40084 21.6767 9.58019 21.9603 9.81174 22.1981C10.0433 22.436 10.322 22.6229 10.6299 22.7469C10.9378 22.8708 11.2682 22.9292 11.6 22.9181C12.0209 22.9395 12.4389 22.8373 12.8025 22.6241C13.166 22.4109 13.4592 22.096 13.646 21.7181L12.728 21.1541C12.6394 21.3814 12.4844 21.5767 12.2832 21.7146C12.082 21.8525 11.8439 21.9265 11.6 21.9271C11.4082 21.9246 11.2192 21.8812 11.0455 21.8C10.8717 21.7189 10.7172 21.6017 10.5922 21.4562C10.4672 21.3108 10.3746 21.1404 10.3204 20.9565C10.2662 20.7725 10.2518 20.5791 10.278 20.3891C10.2518 20.1992 10.2662 20.0058 10.3204 19.8218C10.3746 19.6379 10.4672 19.4675 10.5922 19.3221C10.7172 19.1766 10.8717 19.0594 11.0455 18.9783C11.2192 18.8971 11.4082 18.8537 11.6 18.8511Z"
        fill={primary}
      />
      <path
        d="M16.012 19.1171C15.8204 19.1066 15.6297 19.1507 15.4622 19.2443C15.2947 19.338 15.1573 19.4773 15.066 19.6461V17.6071H14.043V22.8271H15.066V20.7111C15.0551 20.6217 15.0637 20.531 15.0912 20.4452C15.1187 20.3594 15.1644 20.2806 15.2252 20.2141C15.286 20.1476 15.3604 20.0951 15.4434 20.06C15.5264 20.025 15.616 20.0083 15.706 20.0111C16.006 20.0111 16.242 20.2341 16.242 20.6521V22.8301H17.266V20.4681C17.28 20.2954 17.2579 20.1216 17.2012 19.9578C17.1444 19.794 17.0542 19.6438 16.9362 19.5167C16.8183 19.3897 16.6753 19.2886 16.5161 19.2198C16.357 19.151 16.1853 19.116 16.012 19.1171Z"
        fill={primary}
      />
      <path
        d="M19.634 19.1171C19.258 19.1159 18.89 19.2264 18.5768 19.4344C18.2635 19.6425 18.0191 19.9388 17.8744 20.2859C17.7296 20.6329 17.6911 21.0152 17.7638 21.3841C17.8364 21.7531 18.0169 22.0922 18.2824 22.3585C18.5479 22.6248 18.8864 22.8064 19.2551 22.8802C19.6238 22.954 20.0062 22.9167 20.3537 22.7731C20.7012 22.6294 20.9983 22.3859 21.2074 22.0733C21.4164 21.7608 21.528 21.3932 21.528 21.0171C21.5387 20.7653 21.4971 20.5139 21.4058 20.279C21.3144 20.044 21.1754 19.8305 20.9974 19.652C20.8195 19.4735 20.6064 19.3338 20.3718 19.2417C20.1371 19.1496 19.8859 19.1072 19.634 19.1171ZM19.634 22.0171C19.161 22.0171 18.806 21.6621 18.806 21.0221C18.806 20.3821 19.161 20.0331 19.634 20.0331C20.107 20.0331 20.476 20.3811 20.476 21.0221C20.476 21.6631 20.115 22.0131 19.634 22.0131V22.0171Z"
        fill={primary}
      />
      <path
        d="M22.566 17.5931C22.4834 17.5866 22.4003 17.5973 22.3219 17.6246C22.2436 17.6518 22.1718 17.695 22.111 17.7514C22.0502 17.8077 22.0018 17.8761 21.9687 17.9521C21.9357 18.0282 21.9188 18.1102 21.919 18.1931C21.9345 18.3532 22.009 18.5018 22.1281 18.6099C22.2471 18.718 22.4022 18.7779 22.563 18.7779C22.7238 18.7779 22.8789 18.718 22.9979 18.6099C23.117 18.5018 23.1915 18.3532 23.207 18.1931C23.2083 18.1105 23.1922 18.0285 23.1599 17.9524C23.1276 17.8763 23.0797 17.8078 23.0193 17.7513C22.959 17.6948 22.8874 17.6515 22.8094 17.6243C22.7313 17.597 22.6484 17.5864 22.566 17.5931Z"
        fill={primary}
      />
      <path d="M23.067 19.2151H22.044V22.8281H23.067V19.2151Z" fill={primary} />
      <path
        d="M25.47 20.0221C25.6393 20.0112 25.8075 20.0554 25.9494 20.1483C26.0914 20.2412 26.1993 20.3776 26.257 20.5371L27.113 20.0431C26.9636 19.7418 26.727 19.4924 26.434 19.3273C26.141 19.1622 25.8051 19.0889 25.47 19.1171C24.9661 19.1171 24.4828 19.3173 24.1265 19.6736C23.7702 20.03 23.57 20.5132 23.57 21.0171C23.57 21.5211 23.7702 22.0043 24.1265 22.3606C24.4828 22.717 24.9661 22.9171 25.47 22.9171C25.8051 22.9452 26.1409 22.8719 26.4339 22.7068C26.7269 22.5417 26.9635 22.2924 27.113 21.9911L26.257 21.4971C26.1994 21.6567 26.0915 21.7933 25.9496 21.8862C25.8076 21.9791 25.6393 22.0233 25.47 22.0121C25.052 22.0121 24.642 21.6981 24.642 21.0171C24.642 20.3361 25.052 20.0221 25.47 20.0221Z"
        fill={primary}
      />
      <path
        d="M29.134 19.1171C28.8889 19.1138 28.6458 19.1615 28.4202 19.2574C28.1946 19.3533 27.9914 19.4951 27.8237 19.6739C27.656 19.8527 27.5275 20.0645 27.4462 20.2958C27.365 20.5271 27.3329 20.7728 27.352 21.0171C27.3367 21.2694 27.3749 21.5221 27.4641 21.7586C27.5533 21.9951 27.6914 22.2101 27.8695 22.3895C28.0475 22.5689 28.2615 22.7087 28.4974 22.7996C28.7332 22.8905 28.9856 22.9306 29.238 22.9171C29.5304 22.9304 29.8218 22.8738 30.088 22.752C30.3543 22.6303 30.5877 22.447 30.769 22.2171L30.06 21.6391C29.9718 21.7744 29.8492 21.8837 29.7047 21.9558C29.5602 22.0278 29.3991 22.0601 29.238 22.0491C29.1312 22.0574 29.0239 22.0436 28.9226 22.0086C28.8214 21.9736 28.7284 21.9182 28.6495 21.8458C28.5705 21.7735 28.5073 21.6856 28.4637 21.5878C28.4201 21.49 28.397 21.3842 28.396 21.2771H30.853C30.86 21.2001 30.86 21.0961 30.86 21.0201C30.8839 20.7792 30.857 20.536 30.7811 20.3061C30.7052 20.0763 30.582 19.8649 30.4194 19.6855C30.2567 19.5062 30.0583 19.363 29.8369 19.2651C29.6155 19.1672 29.3761 19.1168 29.134 19.1171ZM28.417 20.5931C28.4202 20.5025 28.4414 20.4134 28.4792 20.331C28.5171 20.2486 28.5709 20.1745 28.6376 20.113C28.7043 20.0516 28.7825 20.0039 28.8677 19.9729C28.9529 19.9418 29.0434 19.928 29.134 19.9321C29.489 19.9321 29.739 20.1321 29.834 20.5931H28.417Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
// Changelog: previous name: `TopChoiceSolid`
export const TopChoiceBorderlessFeature = withIcon(
  ({ accent }) => (
    <>
      <rect
        x="0.57959"
        y="19.5"
        width="28"
        height="28"
        rx="4"
        transform="rotate(-45 0.57959 19.5)"
        fill="white"
      />
      <path
        d="M23.5629 27.5891H21.1999L20.4719 25.3461C20.431 25.254 20.3642 25.1758 20.2797 25.1209C20.1952 25.0661 20.0967 25.0369 19.9959 25.0369C19.8952 25.0369 19.7966 25.0661 19.7121 25.1209C19.6276 25.1758 19.5609 25.254 19.5199 25.3461L18.7999 27.5891H16.4369C16.3325 27.5903 16.2311 27.6242 16.1469 27.686C16.0627 27.7479 16 27.8345 15.9676 27.9338C15.9352 28.033 15.9346 28.14 15.9661 28.2396C15.9975 28.3392 16.0594 28.4264 16.1429 28.4891L18.0509 29.8761L17.3219 32.1191C17.2891 32.2196 17.2891 32.3279 17.3217 32.4284C17.3543 32.529 17.418 32.6166 17.5035 32.6787C17.5891 32.7408 17.6921 32.7742 17.7978 32.7741C17.9035 32.774 18.0065 32.7404 18.0919 32.6781L19.9999 31.3001L21.9079 32.6861C21.9933 32.7484 22.0963 32.782 22.202 32.7821C22.3077 32.7822 22.4108 32.7488 22.4963 32.6867C22.5819 32.6246 22.6455 32.537 22.6781 32.4364C22.7108 32.3359 22.7107 32.2276 22.6779 32.1271L21.9489 29.8841L23.8569 28.4971C23.9405 28.4344 24.0023 28.3472 24.0338 28.2476C24.0652 28.148 24.0647 28.041 24.0322 27.9418C23.9998 27.8425 23.9371 27.7559 23.8529 27.694C23.7688 27.6322 23.6673 27.5983 23.5629 27.5971V27.5891Z"
        fill={accent}
      />
      <path
        d="M37.851 16.465L23.535 2.14899C22.5974 1.21164 21.3258 0.685059 20 0.685059C18.6742 0.685059 17.4026 1.21164 16.465 2.14899L2.14899 16.465C1.21164 17.4026 0.685059 18.6742 0.685059 20C0.685059 21.3258 1.21164 22.5974 2.14899 23.535L16.465 37.851C17.4026 38.7883 18.6742 39.3149 20 39.3149C21.3258 39.3149 22.5974 38.7883 23.535 37.851L37.851 23.535C38.7883 22.5974 39.3149 21.3258 39.3149 20C39.3149 18.6742 38.7883 17.4026 37.851 16.465ZM36.437 22.121L22.121 36.437C21.5493 36.9819 20.7898 37.2859 20 37.2859C19.2102 37.2859 18.4507 36.9819 17.879 36.437L3.56299 22.121C3.00058 21.5584 2.68463 20.7955 2.68463 20C2.68463 19.2045 3.00058 18.4416 3.56299 17.879L17.879 3.56299C18.4416 3.00058 19.2045 2.68463 20 2.68463C20.7955 2.68463 21.5584 3.00058 22.121 3.56299L36.437 17.879C36.9994 18.4416 37.3154 19.2045 37.3154 20C37.3154 20.7955 36.9994 21.5584 36.437 22.121Z"
        fill="white"
      />
      <path d="M15.6749 14.7H16.7609V10.788H18.2229V9.82703H14.2129V10.788H15.6749V14.7Z" />
      <path d="M19.5541 14.791C19.8059 14.8004 20.0569 14.7577 20.2914 14.6655C20.5258 14.5733 20.7387 14.4335 20.9166 14.2551C21.0944 14.0767 21.2335 13.8634 21.325 13.6286C21.4164 13.3939 21.4584 13.1427 21.4481 12.891C21.4481 12.3897 21.249 11.909 20.8946 11.5545C20.5401 11.2001 20.0594 11.001 19.5581 11.001C19.0569 11.001 18.5761 11.2001 18.2217 11.5545C17.8673 11.909 17.6681 12.3897 17.6681 12.891C17.6575 13.1421 17.6989 13.3928 17.7897 13.6272C17.8806 13.8616 18.0189 14.0747 18.196 14.2531C18.3731 14.4315 18.5851 14.5714 18.8189 14.6639C19.0526 14.7565 19.3029 14.7997 19.5541 14.791ZM19.5541 11.902C20.0351 11.902 20.3961 12.25 20.3961 12.89C20.3961 13.53 20.0351 13.885 19.5541 13.885C19.0731 13.885 18.7261 13.531 18.7261 12.89C18.7261 12.249 19.0811 11.9 19.5541 11.9V11.902Z" />
      <path d="M22.9878 14.233C23.1011 14.411 23.2591 14.5562 23.4459 14.6541C23.6328 14.752 23.842 14.7992 24.0528 14.791C24.2839 14.7748 24.5094 14.7123 24.7159 14.6073C24.9224 14.5023 25.1057 14.3569 25.2549 14.1796C25.4042 14.0024 25.5163 13.7971 25.5846 13.5757C25.653 13.3544 25.6762 13.1215 25.6528 12.891C25.6763 12.6605 25.6532 12.4276 25.5849 12.2062C25.5166 11.9848 25.4045 11.7794 25.2553 11.6022C25.106 11.4249 24.9227 11.2795 24.7161 11.1745C24.5095 11.0695 24.284 11.0071 24.0528 10.991C23.8429 10.9828 23.6345 11.0292 23.4478 11.1256C23.2611 11.222 23.1027 11.3651 22.9878 11.541V11.088H21.9648V16.177H22.9878V14.233ZM23.7878 11.902C24.2678 11.902 24.6028 12.257 24.6028 12.902C24.6028 13.547 24.2678 13.89 23.7878 13.89C23.3078 13.89 22.9738 13.542 22.9738 12.902C22.9738 12.262 23.3078 11.9 23.7878 11.9V11.902Z" />
      <path d="M11.5998 18.5C11.8437 18.5006 12.0818 18.5747 12.283 18.7126C12.4842 18.8505 12.6392 19.0458 12.7278 19.273L13.6458 18.709C13.459 18.3312 13.1658 18.0163 12.8023 17.803C12.4387 17.5898 12.0207 17.4876 11.5998 17.509C11.2677 17.4979 10.9369 17.5564 10.6287 17.6806C10.3205 17.8048 10.0416 17.9921 9.80999 18.2304C9.57837 18.4687 9.39911 18.7528 9.2837 19.0644C9.16829 19.376 9.11928 19.7083 9.13981 20.04C9.11986 20.3714 9.1693 20.7032 9.28497 21.0144C9.40064 21.3255 9.57999 21.6091 9.81155 21.847C10.0431 22.0848 10.3218 22.2717 10.6297 22.3957C10.9376 22.5197 11.268 22.5781 11.5998 22.567C12.0207 22.5884 12.4387 22.4862 12.8023 22.273C13.1658 22.0598 13.459 21.7448 13.6458 21.367L12.7278 20.803C12.6392 21.0303 12.4842 21.2256 12.283 21.3634C12.0818 21.5013 11.8437 21.5754 11.5998 21.576C11.408 21.5734 11.219 21.5301 11.0453 21.4489C10.8715 21.3677 10.717 21.2505 10.592 21.1051C10.467 20.9597 10.3744 20.7893 10.3202 20.6053C10.266 20.4214 10.2516 20.228 10.2778 20.038C10.2516 19.848 10.266 19.6547 10.3202 19.4707C10.3744 19.2867 10.467 19.1164 10.592 18.9709C10.717 18.8255 10.8715 18.7083 11.0453 18.6271C11.219 18.5459 11.408 18.5026 11.5998 18.5Z" />
      <path d="M16.012 18.766C15.8204 18.7554 15.6297 18.7995 15.4622 18.8932C15.2947 18.9868 15.1573 19.1262 15.066 19.295V17.256H14.043V22.476H15.066V20.36C15.0551 20.2706 15.0637 20.1798 15.0912 20.0941C15.1187 20.0083 15.1644 19.9294 15.2251 19.8629C15.2859 19.7965 15.3604 19.7439 15.4434 19.7089C15.5263 19.6738 15.6159 19.6572 15.706 19.66C16.006 19.66 16.242 19.883 16.242 20.301V22.479H17.266V20.117C17.28 19.9442 17.2579 19.7704 17.2011 19.6066C17.1444 19.4428 17.0541 19.2926 16.9362 19.1656C16.8183 19.0385 16.6752 18.9374 16.5161 18.8686C16.357 18.7998 16.1853 18.7649 16.012 18.766Z" />
      <path d="M19.634 18.7661C19.258 18.7649 18.8901 18.8753 18.5768 19.0833C18.2636 19.2914 18.0191 19.5877 17.8744 19.9348C17.7297 20.2819 17.6912 20.6641 17.7638 21.033C17.8364 21.402 18.0169 21.7411 18.2824 22.0074C18.5479 22.2737 18.8864 22.4553 19.2551 22.5291C19.6239 22.6029 20.0062 22.5656 20.3537 22.422C20.7013 22.2784 20.9984 22.0348 21.2074 21.7223C21.4164 21.4097 21.528 21.0421 21.528 20.6661C21.5387 20.4142 21.4971 20.1629 21.4058 19.9279C21.3145 19.6929 21.1754 19.4795 20.9975 19.3009C20.8195 19.1224 20.6065 18.9827 20.3718 18.8906C20.1371 18.7986 19.8859 18.7562 19.634 18.7661ZM19.634 21.6661C19.161 21.6661 18.806 21.3111 18.806 20.6711C18.806 20.0311 19.161 19.6821 19.634 19.6821C20.107 19.6821 20.476 20.0301 20.476 20.6711C20.476 21.3121 20.115 21.6621 19.634 21.6621V21.6661Z" />
      <path d="M22.5659 17.2419C22.4833 17.2354 22.4002 17.2462 22.3219 17.2734C22.2436 17.3006 22.1718 17.3438 22.111 17.4002C22.0502 17.4565 22.0017 17.5249 21.9687 17.6009C21.9356 17.677 21.9187 17.759 21.9189 17.8419C21.9344 18.002 22.0089 18.1506 22.128 18.2587C22.2471 18.3668 22.4021 18.4267 22.5629 18.4267C22.7238 18.4267 22.8788 18.3668 22.9979 18.2587C23.1169 18.1506 23.1915 18.002 23.2069 17.8419C23.2082 17.7593 23.1921 17.6773 23.1598 17.6012C23.1275 17.5251 23.0796 17.4566 23.0193 17.4001C22.9589 17.3436 22.8874 17.3003 22.8093 17.2731C22.7313 17.2458 22.6484 17.2352 22.5659 17.2419Z" />
      <path d="M23.0669 18.864H22.0439V22.477H23.0669V18.864Z" />
      <path d="M25.4698 19.671C25.6391 19.6601 25.8073 19.7043 25.9493 19.7972C26.0912 19.8901 26.1991 20.0265 26.2568 20.186L27.1128 19.692C26.9634 19.3907 26.7268 19.1413 26.4338 18.9762C26.1408 18.8111 25.805 18.7378 25.4698 18.766C24.9659 18.766 24.4826 18.9662 24.1263 19.3225C23.77 19.6789 23.5698 20.1621 23.5698 20.666C23.5698 21.17 23.77 21.6532 24.1263 22.0095C24.4826 22.3659 24.9659 22.566 25.4698 22.566C25.8049 22.5941 26.1408 22.5208 26.4337 22.3557C26.7267 22.1906 26.9633 21.9413 27.1128 21.64L26.2568 21.146C26.1993 21.3056 26.0914 21.4422 25.9494 21.5351C25.8074 21.628 25.6391 21.6722 25.4698 21.661C25.0518 21.661 24.6418 21.347 24.6418 20.666C24.6418 19.985 25.0518 19.671 25.4698 19.671Z" />
      <path d="M29.134 18.766C28.8889 18.7627 28.6458 18.8104 28.4202 18.9063C28.1946 19.0022 27.9915 19.144 27.8238 19.3228C27.6561 19.5016 27.5275 19.7134 27.4463 19.9447C27.3651 20.176 27.333 20.4217 27.352 20.666C27.3367 20.9183 27.3749 21.171 27.4641 21.4075C27.5533 21.644 27.6914 21.859 27.8695 22.0384C28.0476 22.2178 28.2615 22.3575 28.4974 22.4485C28.7332 22.5394 28.9856 22.5795 29.238 22.566C29.5305 22.5793 29.8218 22.5227 30.0881 22.4009C30.3543 22.2792 30.5877 22.0959 30.769 21.866L30.06 21.288C29.9718 21.4233 29.8492 21.5326 29.7047 21.6046C29.5602 21.6767 29.3991 21.709 29.238 21.698C29.1312 21.7063 29.0239 21.6925 28.9227 21.6575C28.8214 21.6225 28.7285 21.5671 28.6495 21.4947C28.5706 21.4224 28.5073 21.3345 28.4637 21.2367C28.4201 21.1389 28.3971 21.0331 28.396 20.926H30.853C30.86 20.849 30.86 20.745 30.86 20.669C30.8839 20.4281 30.8571 20.1849 30.7812 19.955C30.7053 19.7252 30.582 19.5138 30.4194 19.3344C30.2568 19.1551 30.0584 19.0119 29.837 18.914C29.6156 18.8161 29.3761 18.7657 29.134 18.766ZM28.417 20.242C28.4202 20.1514 28.4414 20.0623 28.4792 19.9799C28.5171 19.8975 28.5709 19.8234 28.6376 19.7619C28.7043 19.7005 28.7825 19.6528 28.8677 19.6218C28.9529 19.5907 29.0434 19.5769 29.134 19.581C29.489 19.581 29.739 19.781 29.834 20.242H28.417Z" />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
