import { withIcon } from "@icons/_withIcon";

export const QuickTripsFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M30.961 38.8191C30.7886 38.8193 30.6192 38.7749 30.469 38.6903C30.3188 38.6056 30.1931 38.4836 30.104 38.3361L28.504 35.6791C28.2648 35.2857 27.9286 34.9603 27.5277 34.7341C27.1267 34.5079 26.6744 34.3884 26.214 34.3871H13.422C12.6343 34.3871 11.8789 34.0742 11.3219 33.5172C10.7649 32.9602 10.452 32.2048 10.452 31.4171C10.452 30.6294 10.7649 29.874 11.3219 29.317C11.8789 28.76 12.6343 28.4471 13.422 28.4471H20C20.2652 28.4471 20.5196 28.5524 20.7071 28.74C20.8946 28.9275 21 29.1819 21 29.4471C21 29.7123 20.8946 29.9667 20.7071 30.1542C20.5196 30.3417 20.2652 30.4471 20 30.4471H13.422C13.2244 30.4476 13.0315 30.5083 12.8692 30.621C12.7068 30.7337 12.5826 30.8931 12.513 31.0781C12.458 31.2248 12.4394 31.3827 12.4589 31.5382C12.4783 31.6936 12.5351 31.8421 12.6245 31.9708C12.7138 32.0995 12.8331 32.2046 12.972 32.2771C13.1109 32.3496 13.2653 32.3873 13.422 32.3871H26.21C27.0142 32.3898 27.8043 32.5988 28.5046 32.9942C29.205 33.3895 29.7921 33.9579 30.21 34.6451L31.81 37.3021C31.9016 37.4537 31.9512 37.6269 31.9539 37.804C31.9566 37.9811 31.9121 38.1558 31.8252 38.3101C31.7382 38.4644 31.6118 38.5928 31.4589 38.6823C31.306 38.7717 31.1321 38.8189 30.955 38.8191H30.961Z"
        fill={accent}
      />
      <path
        d="M35.332 38.8191H4.668C4.10907 38.8184 3.56033 38.6694 3.07785 38.3872C2.59538 38.1051 2.19647 37.6999 1.92188 37.213C1.64729 36.7262 1.50688 36.1752 1.51498 35.6163C1.52308 35.0575 1.67941 34.5107 1.968 34.0321L6.81 26.0031C7.09178 25.5389 7.48802 25.1548 7.96078 24.8876C8.43353 24.6204 8.96696 24.4791 9.51 24.4771H12.374C12.6392 24.4771 12.8936 24.5824 13.0811 24.77C13.2686 24.9575 13.374 25.2119 13.374 25.4771C13.374 25.7423 13.2686 25.9967 13.0811 26.1842C12.8936 26.3717 12.6392 26.4771 12.374 26.4771H9.51C9.31091 26.4776 9.11532 26.5294 8.94196 26.6272C8.7686 26.7251 8.62331 26.8659 8.52 27.0361L3.67799 35.0651C3.57231 35.2405 3.51509 35.4409 3.51219 35.6457C3.50929 35.8505 3.56081 36.0524 3.66149 36.2308C3.76217 36.4092 3.9084 36.5576 4.08523 36.661C4.26207 36.7643 4.46317 36.8189 4.668 36.8191H35.332C35.5368 36.8189 35.7379 36.7643 35.9148 36.661C36.0916 36.5576 36.2378 36.4092 36.3385 36.2308C36.4392 36.0524 36.4907 35.8505 36.4878 35.6457C36.4849 35.4409 36.4277 35.2405 36.322 35.0651L31.48 27.0361C31.3767 26.8659 31.2314 26.7251 31.058 26.6272C30.8847 26.5294 30.6891 26.4776 30.49 26.4771H27.626C27.3608 26.4771 27.1064 26.3717 26.9189 26.1842C26.7314 25.9967 26.626 25.7423 26.626 25.4771C26.626 25.2119 26.7314 24.9575 26.9189 24.77C27.1064 24.5824 27.3608 24.4771 27.626 24.4771H30.49C31.033 24.4791 31.5665 24.6204 32.0392 24.8876C32.512 25.1548 32.9082 25.5389 33.19 26.0031L38.032 34.0321C38.3206 34.5107 38.4769 35.0575 38.485 35.6163C38.4931 36.1752 38.3527 36.7262 38.0781 37.213C37.8035 37.6999 37.4046 38.1051 36.9221 38.3872C36.4397 38.6694 35.8909 38.8184 35.332 38.8191Z"
        fill={primary}
      />
      <path
        d="M20 30.4511C19.8518 30.4511 19.7056 30.4181 19.5717 30.3547C19.4378 30.2912 19.3198 30.1988 19.226 30.0841L11.253 20.3471C9.89884 18.6914 9.04241 16.6854 8.78324 14.5622C8.52408 12.4391 8.87283 10.2859 9.78895 8.35315C10.7051 6.42035 12.151 4.78726 13.9585 3.64369C15.7661 2.50013 17.8611 1.89307 20 1.89307C22.1389 1.89307 24.2339 2.50013 26.0415 3.64369C27.849 4.78726 29.2949 6.42035 30.211 8.35315C31.1271 10.2859 31.4759 12.4391 31.2167 14.5622C30.9576 16.6854 30.1011 18.6914 28.747 20.3471L20.774 30.0841C20.6802 30.1988 20.5621 30.2912 20.4283 30.3547C20.2944 30.4181 20.1481 30.4511 20 30.4511ZM20 3.88209C18.2384 3.88091 16.5128 4.38006 15.0238 5.32145C13.5349 6.26283 12.344 7.60772 11.5896 9.1996C10.8353 10.7915 10.5486 12.5648 10.7628 14.3133C10.9771 16.0618 11.6836 17.7135 12.8 19.0761L20 27.8681L27.2 19.0761C28.3164 17.7135 29.0229 16.0618 29.2371 14.3133C29.4514 12.5648 29.1647 10.7915 28.4103 9.1996C27.656 7.60772 26.4651 6.26283 24.9761 5.32145C23.4872 4.38006 21.7615 3.88091 20 3.88209Z"
        fill={primary}
      />
      <path
        d="M20 7.33008C18.8226 7.32988 17.6715 7.67885 16.6924 8.33285C15.7133 8.98686 14.9501 9.91652 14.4994 11.0043C14.0487 12.092 13.9307 13.289 14.1603 14.4438C14.3898 15.5987 14.9567 16.6595 15.7892 17.4921C16.6217 18.3248 17.6825 18.8918 18.8373 19.1216C19.992 19.3514 21.189 19.2336 22.2769 18.783C23.3647 18.3325 24.2945 17.5695 24.9487 16.5905C25.6028 15.6115 25.952 14.4605 25.952 13.2831C25.9521 12.5014 25.7983 11.7273 25.4992 11.005C25.2002 10.2828 24.7618 9.62653 24.209 9.07373C23.6563 8.52093 23.0002 8.08242 22.278 7.78324C21.5558 7.48406 20.7817 7.33008 20 7.33008ZM22.394 15.6771C22.2065 15.8645 21.9522 15.9699 21.687 15.9699C21.4218 15.9699 21.1675 15.8645 20.98 15.6771L19.293 13.9901C19.1054 13.8026 19 13.5483 19 13.2831V10.5831C19 10.3179 19.1053 10.0635 19.2929 9.87597C19.4804 9.68843 19.7348 9.58308 20 9.58308C20.2652 9.58308 20.5196 9.68843 20.7071 9.87597C20.8946 10.0635 21 10.3179 21 10.5831V12.8731L22.394 14.2671C22.5802 14.4544 22.6848 14.7079 22.6848 14.9721C22.6848 15.2363 22.5802 15.4897 22.394 15.6771Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
