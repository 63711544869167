import { withIcon } from "@icons/_withIcon";

export const SignOut = withIcon(
  ({ fill }) => (
    <>
      <path
        d="M5.46094 3L5.46094 3.021C5.46106 3.08616 5.47415 3.15064 5.49944 3.21069C5.52474 3.27074 5.56173 3.32515 5.60827 3.37076C5.65481 3.41637 5.70996 3.45226 5.77051 3.47633C5.83106 3.50041 5.89579 3.51219 5.96094 3.511C6.09463 3.50838 6.22195 3.45335 6.31547 3.35778C6.40899 3.2622 6.46123 3.13372 6.46094 3C6.46094 2.60218 6.61897 2.22064 6.90028 1.93934C7.18158 1.65804 7.56311 1.5 7.96094 1.5L12.9609 1.5C13.3588 1.5 13.7403 1.65803 14.0216 1.93934C14.3029 2.22064 14.4609 2.60218 14.4609 3L14.4609 13C14.4609 13.3978 14.3029 13.7794 14.0216 14.0607C13.7403 14.342 13.3588 14.5 12.9609 14.5L7.96094 14.5C7.76294 14.5 7.5669 14.4608 7.38413 14.3847C7.20135 14.3086 7.03546 14.197 6.89601 14.0564C6.75657 13.9159 6.64633 13.7491 6.57166 13.5657C6.49698 13.3823 6.45935 13.186 6.46094 12.988C6.45936 12.8564 6.40598 12.7308 6.31239 12.6383C6.21879 12.5458 6.09251 12.494 5.96094 12.494C5.8277 12.4953 5.70038 12.5492 5.60673 12.644C5.51307 12.7388 5.46067 12.8668 5.46094 13C5.46094 13.3283 5.5256 13.6534 5.65124 13.9567C5.77688 14.26 5.96103 14.5356 6.19317 14.7678C6.66201 15.2366 7.2979 15.5 7.96094 15.5L12.9609 15.5C13.624 15.5 14.2599 15.2366 14.7287 14.7678C14.9609 14.5356 15.145 14.26 15.2706 13.9567C15.3963 13.6534 15.4609 13.3283 15.4609 13L15.4609 3C15.4609 2.33696 15.1975 1.70107 14.7287 1.23223C14.2599 0.763392 13.624 0.5 12.9609 0.5L7.96094 0.5C7.63263 0.5 7.30754 0.564664 7.00423 0.690301C6.70092 0.815938 6.42532 1.00009 6.19317 1.23223C5.96102 1.46438 5.77688 1.73998 5.65124 2.04329C5.5256 2.34661 5.46094 2.6717 5.46094 3Z"
        fill={fill || "currentColor"}
      />
      <path
        d="M4.51665 10.2004L2.72165 8.50044L11.6927 8.50044C11.8253 8.50044 11.9524 8.44776 12.0462 8.35399C12.14 8.26022 12.1927 8.13305 12.1927 8.00044C12.1927 7.86783 12.14 7.74065 12.0462 7.64688C11.9524 7.55312 11.8253 7.50044 11.6927 7.50044L2.72165 7.50044L4.52165 5.80044C4.57107 5.75359 4.6104 5.69713 4.63723 5.63454C4.66405 5.57195 4.67781 5.50454 4.67765 5.43644C4.67805 5.30869 4.62895 5.18576 4.54065 5.09344C4.49555 5.04569 4.44149 5.00729 4.38154 4.98044C4.3216 4.95359 4.25695 4.93882 4.1913 4.93696C4.12564 4.9351 4.06026 4.9462 3.9989 4.96962C3.93753 4.99303 3.88138 5.02831 3.83365 5.07344L1.12165 7.64244L1.12165 7.64744C1.07388 7.69402 1.03591 7.74969 1.00998 7.81118C0.984059 7.87266 0.970703 7.93871 0.970703 8.00544C0.970703 8.07216 0.984059 8.13821 1.00998 8.1997C1.03591 8.26118 1.07388 8.31686 1.12165 8.36344L3.82865 10.9294C3.92506 11.0207 4.05376 11.0699 4.18644 11.0662C4.31912 11.0626 4.44492 11.0063 4.53615 10.9099C4.62739 10.8135 4.67659 10.6848 4.67293 10.5521C4.66927 10.4195 4.61306 10.2937 4.51665 10.2024L4.51665 10.2004Z"
        fill={fill || "currentColor"}
      />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);
