import { withIcon } from "@icons/_withIcon";

export const Kids = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 10.8613 3.91854 13.0848 6.24993 13.9088C6.64047 14.0468 6.84517 14.4753 6.70713 14.8658C6.5691 15.2564 6.1406 15.4611 5.75007 15.323C2.8381 14.2938 0.75 11.5167 0.75 8.25C0.75 4.10786 4.10786 0.75 8.25 0.75C10.6064 0.75 12.7093 1.83755 14.083 3.53522C14.3436 3.85721 14.2938 4.32947 13.9718 4.59003C13.6498 4.85058 13.1775 4.80078 12.917 4.47878C11.8158 3.11795 10.1345 2.25 8.25 2.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46978 10.3447C5.76268 10.0518 6.23755 10.0518 6.53044 10.3447C6.85927 10.6735 7.2568 10.8875 7.67619 10.9888C8.07882 11.086 8.3264 11.4913 8.22915 11.8939C8.13191 12.2965 7.72668 12.5441 7.32404 12.4469C6.64407 12.2826 5.99921 11.9348 5.46978 11.4053C5.17689 11.1124 5.17689 10.6376 5.46978 10.3447Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9698 18.2197C13.2627 17.9268 13.7376 17.9268 14.0304 18.2197C14.9802 19.1695 16.5202 19.1695 17.47 18.2197C17.7629 17.9268 18.2378 17.9268 18.5306 18.2197C18.8235 18.5126 18.8235 18.9874 18.5306 19.2803C16.9951 20.8159 14.5054 20.8159 12.9698 19.2803C12.6769 18.9874 12.6769 18.5126 12.9698 18.2197Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28117 1.5C7.28117 1.08579 7.61695 0.75 8.03117 0.75C9.29548 0.75 10.5 1.66469 10.5 3C10.5 4.33531 9.29548 5.25 8.03117 5.25C7.33411 5.25 6.68659 4.98567 6.22641 4.53699C5.92984 4.24782 5.92384 3.77298 6.21301 3.47641C6.50218 3.17984 6.97702 3.17384 7.27359 3.46301C7.44288 3.62808 7.7101 3.75 8.03117 3.75C8.66542 3.75 9 3.32155 9 3C9 2.67845 8.66542 2.25 8.03117 2.25C7.61695 2.25 7.28117 1.91421 7.28117 1.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 9.75C12.4363 9.75 9.75 12.4363 9.75 15.75C9.75 19.0637 12.4363 21.75 15.75 21.75C19.0637 21.75 21.75 19.0637 21.75 15.75C21.75 12.4363 19.0637 9.75 15.75 9.75ZM8.25 15.75C8.25 11.6079 11.6079 8.25 15.75 8.25C19.8921 8.25 23.25 11.6079 23.25 15.75C23.25 19.8921 19.8921 23.25 15.75 23.25C11.6079 23.25 8.25 19.8921 8.25 15.75Z"
      />
      <path d="M19.5 15.75C19.5 16.1642 19.1642 16.5 18.75 16.5C18.3358 16.5 18 16.1642 18 15.75C18 15.3358 18.3358 15 18.75 15C19.1642 15 19.5 15.3358 19.5 15.75Z" />
      <path d="M12 7.5C12 7.91421 11.6642 8.25 11.25 8.25C10.8358 8.25 10.5 7.91421 10.5 7.5C10.5 7.08579 10.8358 6.75 11.25 6.75C11.6642 6.75 12 7.08579 12 7.5Z" />
      <path d="M13.5 15.75C13.5 16.1642 13.1642 16.5 12.75 16.5C12.3358 16.5 12 16.1642 12 15.75C12 15.3358 12.3358 15 12.75 15C13.1642 15 13.5 15.3358 13.5 15.75Z" />
      <path d="M6 7.5C6 7.91421 5.66421 8.25 5.25 8.25C4.83579 8.25 4.5 7.91421 4.5 7.5C4.5 7.08579 4.83579 6.75 5.25 6.75C5.66421 6.75 6 7.08579 6 7.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 15.375C18.5429 15.375 18.375 15.5429 18.375 15.75C18.375 15.9571 18.5429 16.125 18.75 16.125C18.9571 16.125 19.125 15.9571 19.125 15.75C19.125 15.5429 18.9571 15.375 18.75 15.375ZM17.625 15.75C17.625 15.1287 18.1287 14.625 18.75 14.625C19.3713 14.625 19.875 15.1287 19.875 15.75C19.875 16.3713 19.3713 16.875 18.75 16.875C18.1287 16.875 17.625 16.3713 17.625 15.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 7.125C11.0429 7.125 10.875 7.29289 10.875 7.5C10.875 7.70711 11.0429 7.875 11.25 7.875C11.4571 7.875 11.625 7.70711 11.625 7.5C11.625 7.29289 11.4571 7.125 11.25 7.125ZM10.125 7.5C10.125 6.87868 10.6287 6.375 11.25 6.375C11.8713 6.375 12.375 6.87868 12.375 7.5C12.375 8.12132 11.8713 8.625 11.25 8.625C10.6287 8.625 10.125 8.12132 10.125 7.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 15.375C12.5429 15.375 12.375 15.5429 12.375 15.75C12.375 15.9571 12.5429 16.125 12.75 16.125C12.9571 16.125 13.125 15.9571 13.125 15.75C13.125 15.5429 12.9571 15.375 12.75 15.375ZM11.625 15.75C11.625 15.1287 12.1287 14.625 12.75 14.625C13.3713 14.625 13.875 15.1287 13.875 15.75C13.875 16.3713 13.3713 16.875 12.75 16.875C12.1287 16.875 11.625 16.3713 11.625 15.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 7.125C5.04289 7.125 4.875 7.29289 4.875 7.5C4.875 7.70711 5.04289 7.875 5.25 7.875C5.45711 7.875 5.625 7.70711 5.625 7.5C5.625 7.29289 5.45711 7.125 5.25 7.125ZM4.125 7.5C4.125 6.87868 4.62868 6.375 5.25 6.375C5.87132 6.375 6.375 6.87868 6.375 7.5C6.375 8.12132 5.87132 8.625 5.25 8.625C4.62868 8.625 4.125 8.12132 4.125 7.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9.75V9H16.5V9.75C16.5 10.9926 17.5074 12 18.75 12H21.75V13.5H18.75C16.6789 13.5 15 11.8211 15 9.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 9.75V9H15V9.75C15 10.9926 13.9926 12 12.75 12H9.75V13.5H12.75C14.8211 13.5 16.5 11.8211 16.5 9.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 5.25H18C17.1716 5.25 16.5 5.92157 16.5 6.75V8.25H18C18.8284 8.25 19.5 7.57843 19.5 6.75V5.25ZM18 3.75C16.3431 3.75 15 5.09315 15 6.75V9.75H18C19.6569 9.75 21 8.40685 21 6.75V3.75H18Z"
      />
    </>
  ),
  {
    viewBox: "0 0 24 24",
  }
);
export const KidsFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M14.9998 3.6001C16.6567 3.6001 17.9998 4.94324 17.9998 6.6001C17.9998 8.25695 16.6567 9.6001 14.9998 9.6001C14.0183 9.6001 13.1469 9.12877 12.5996 8.4001"
        stroke={accent}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M27.6004 16.2001C27.6004 9.24131 21.9592 3.6001 15.0004 3.6001C8.0416 3.6001 2.40039 9.24131 2.40039 16.2001C2.40039 23.1589 8.0416 28.8001 15.0004 28.8001"
        stroke={primary}
        fill="transparent"
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M19.1996 21.6001C16.8801 23.9196 13.1194 23.9196 10.7998 21.6001"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M11.4004 15.6001C10.572 16.4285 9.22882 16.4285 8.40039 15.6001"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M21.5996 15.6001C20.7712 16.4285 19.428 16.4285 18.5996 15.6001"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M45.5858 32.3998C45.7479 35.8213 44.5225 39.2964 41.9095 41.9094C36.9889 46.83 29.0111 46.83 24.0905 41.9094C21.4775 39.2964 20.2521 35.8213 20.4142 32.3998"
        stroke={primary}
        fill="transparent"
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M37.1996 38.3999C34.8801 40.7194 31.1194 40.7194 28.7998 38.3999"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <circle
        cx="28.2"
        cy="32.4002"
        r="1.2"
        fill={primary}
        stroke={primary}
        strokeWidth="1.2"
      />
      <circle
        cx="37.7996"
        cy="32.4002"
        r="1.2"
        fill={primary}
        stroke={primary}
        strokeWidth="1.2"
      />
      <path
        d="M41.9095 24.0907C36.9889 19.1701 29.0111 19.1701 24.0905 24.0907C23.0331 25.1481 22.2029 26.3466 21.6 27.6256L22.4724 27.7679C26.373 28.4042 30.3097 26.859 32.7361 23.7394L33 23.4001L33.2484 23.7195C35.683 26.8497 39.6373 28.3939 43.5489 27.7419L44.4 27.6001C43.7971 26.3211 42.9669 25.1481 41.9095 24.0907Z"
        fill={accent}
        stroke={accent}
        strokeWidth="2.4"
      />
      <path
        d="M30.9058 15.918C29.8697 12.8098 32.1832 9.6001 35.4595 9.6001H43.1998V12.1464C43.1998 13.9838 42.1509 15.66 40.4983 16.4633L32.3998 20.4001L30.9058 15.918Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 48 48",
  }
);

export const KidsFill = withIcon(
  () => (
    <>
      <path
        d="M8 19.4879C4.50442 18.2524 2 14.9187 2 11C2 6.02944 6.02944 2 11 2C13.8273 2 16.35 3.30367 18 5.34267"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M8 14.5C8.40304 14.903 8.867 15.208 9.36224 15.415"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M24.0003 25C22.3433 26.6569 19.6569 26.6569 18 25"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M10.7079 2C11.9736 2 12.9996 2.89543 12.9996 4C12.9996 5.10457 11.9736 6 10.7079 6C10.335 6 9.98301 5.92231 9.67188 5.78447"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="21" cy="21" r="9" stroke="currentColor" strokeWidth="3" />
      <circle cx="25" cy="21" r="1" stroke="currentColor" />
      <circle cx="15" cy="10" r="1" stroke="currentColor" />
      <circle cx="17" cy="21" r="1" stroke="currentColor" />
      <circle cx="7" cy="10" r="1" stroke="currentColor" />
      <path
        d="M21 12V13C21 15.2091 22.7909 17 25 17H29"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M21 12V13C21 15.2091 19.2091 17 17 17H13"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 9C21.5 7.61929 22.6193 6.5 24 6.5H26.5V9C26.5 10.3807 25.3807 11.5 24 11.5H21.5V9Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path d="M17 12H20V16H14L17 12Z" />
      <path d="M25 12H22V16H28L25 12Z" />
    </>
  ),
  {
    viewBox: "0 0 32 32",
    fill: "none",
  }
);
