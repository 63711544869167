const buildURL = (url, params = {}) =>
  new URL(
    `${url.origin}${url.pathname}${
      Object.keys(params)[0] || url.searchParams[0]
        ? `?${new URLSearchParams([
            ...Array.from((url?.searchParams || []).entries()),
            ...Object.entries(params || []),
          ])}`
        : ""
    }`
  ).toString();

/**
 * These objects help map to `sources` on imgix.com. As new url paths are added or external
 * domains change we need to update these objects and/or the source configuration
 * in the imgix.com ui. Essentially, an image-serving domain needs to be passed through
 * imgix platform which gives us these *.imgix.com sources. Our data has the original path,
 * so we convert that path to the provided imgix proxy, which then processes the original
 * image from the source as mapped on their end.
 */
const sources = [
  {
    domain: "https://lonelyplanetimages.imgix.net",
    regex: /https?:\/\/media.lonelyplanet.com/,
  },
  {
    domain: "https://lonelyplanetstatic.imgix.net",
    regex: /https?:\/\/s3.amazonaws.com\/static-asset/,
  },
  // Convert production hosted media urls for nonprod
  // This occurs when production events are synced to nonprod
  {
    domain: "https://lp-cms-production.imgix.net",
    regex: /https?:\/\/lp-cms(-(qa|staging))?\.imgix\.net/,
  },
  {
    domain: process.env.NEXT_PUBLIC_IMGIX_URL,
    regex:
      /https?:\/\/cms(-(qa|staging))?.lonelyplanet.com\/sites\/default\/files/,
  },
  {
    domain: process.env.NEXT_PUBLIC_IMGIX_URL,
    regex:
      /https?:\/\/lp-cms(-(development|staging|production))?.s3.amazonaws.com\/public/,
  },
  // TODO: remove after fixing consumer api file references
  {
    domain: process.env.NEXT_PUBLIC_IMGIX_URL,
    regex:
      /https?:\/\/lp-cms(-(development|staging|production))?.s3.amazonaws.com/,
  },
  {
    domain: process.env.NEXT_PUBLIC_IMGIX_URL,
    regex:
      /https?:\/\/cms-ssl.(qa|staging|production).lonelyplanet.com\/sites\/default\/files/,
  },
  {
    domain: process.env.NEXT_PUBLIC_IMGIX_URL,
    regex: /https?:\/\/.*\/s3fs_to_s3\/files/,
  },
];

export function imgix(src, options = {}) {
  // Prevents the page from breaking if a falsy src is passed in
  if (!src) return src;

  /**
   * Converts an image path to the imgix url with params
   */
  const match = sources.find(({ regex }) => regex.test(src));

  try {
    const srcURL = new URL(
      !match ? src : src.replace(match.regex, match.domain)
    );
    return buildURL(srcURL, options);
  } catch (error) {
    return src;
  }
}
