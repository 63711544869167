import PropTypes from "prop-types";
import cn from "classnames";

export function Button({
  accessibleLabel = "",
  className = "",
  type = "button",
  href = "",
  inverted = false,
  disabled = null,
  onClick = null,
  target = null,
  rel = null,
  children,
}) {
  return (
    <>
      {href && (
        <a
          href={href}
          className={cn("btn", { "btn-inverted": inverted }, className)}
          onClick={onClick}
          data-testid="a"
          target={target}
          rel={rel}
        >
          {children}
        </a>
      )}
      {!href && (
        <button
          {...(accessibleLabel && { "aria-label": accessibleLabel })}
          className={cn("btn", { "btn-inverted": inverted }, className)}
          onClick={onClick}
          // eslint-disable-next-line react/button-has-type
          type={type}
          disabled={disabled}
          data-testid="btn"
          formTarget={target}
        >
          {children}
        </button>
      )}
    </>
  );
}

Button.propTypes = {
  accessibleLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  /** optional: for applying button styles to links */
  href: PropTypes.string,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  rel: PropTypes.string,
};
