import { withIcon } from "@icons/_withIcon";

export const WithKidsFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M13.3248 14.0467C16.1731 13.3744 17.937 10.5203 17.2646 7.67206C16.5923 4.82379 13.7382 3.05986 10.89 3.73222C8.04169 4.40458 6.27776 7.25861 6.95012 10.1069C7.62248 12.9552 10.4765 14.7191 13.3248 14.0467Z"
        fill={accent}
      />
      <path
        d="M22.0045 20.3602C22.7907 20.3603 23.5593 20.1274 24.2131 19.6907C24.867 19.2541 25.3766 18.6333 25.6776 17.907C25.9786 17.1807 26.0575 16.3814 25.9042 15.6103C25.751 14.8391 25.3725 14.1308 24.8166 13.5748C24.2607 13.0187 23.5524 12.6401 22.7813 12.4866C22.0102 12.3331 21.2109 12.4118 20.4845 12.7126C19.7582 13.0135 19.1373 13.5229 18.7005 14.1767C18.2636 14.8304 18.0305 15.5989 18.0305 16.3852C18.0305 17.4392 18.4491 18.4501 19.1944 19.1955C19.9396 19.941 20.9504 20.3599 22.0045 20.3602Z"
        fill={accent}
      />
      <path
        d="M33.0979 17.1465C35.6666 16.5401 37.2574 13.9661 36.651 11.3973C36.0445 8.82858 33.4706 7.2378 30.9018 7.84422C28.333 8.45065 26.7423 11.0246 27.3487 13.5934C27.9551 16.1622 30.5291 17.7529 33.0979 17.1465Z"
        fill={accent}
      />
      <path
        d="M22.0055 22.3781C19.984 22.3803 18.046 23.1841 16.6165 24.6134C15.187 26.0427 14.3829 27.9807 14.3805 30.0021V32.0311C14.3805 32.2964 14.4858 32.5507 14.6734 32.7382C14.8609 32.9258 15.1153 33.0311 15.3805 33.0311C15.6457 33.0311 15.9001 32.9258 16.0876 32.7382C16.2751 32.5507 16.3805 32.2964 16.3805 32.0311V30.0021C16.4002 28.5234 17.0015 27.1119 18.0541 26.0732C19.1068 25.0345 20.5261 24.4521 22.005 24.4521C23.4838 24.4521 24.9032 25.0345 25.9559 26.0732C27.0085 27.1119 27.6098 28.5234 27.6295 30.0021V32.0311C27.6295 32.2964 27.7348 32.5507 27.9224 32.7382C28.1099 32.9258 28.3643 33.0311 28.6295 33.0311C28.8947 33.0311 29.1491 32.9258 29.3366 32.7382C29.5241 32.5507 29.6295 32.2964 29.6295 32.0311V30.0021C29.6274 27.9808 28.8234 26.0428 27.3941 24.6135C25.9648 23.1842 24.0268 22.3803 22.0055 22.3781Z"
        fill={primary}
      />
      <path
        d="M16.3435 18.3091C14.7787 17.6019 13.0617 17.2979 11.3492 17.4251C9.63679 17.5523 7.98351 18.1066 6.54042 19.0373C5.09734 19.968 3.91047 21.2454 3.08823 22.7529C2.26599 24.2605 1.83461 25.95 1.83348 27.6671V31.1051C1.8356 32.9174 2.55653 34.6549 3.8381 35.9362C5.11967 37.2176 6.8572 37.9383 8.66948 37.9401H18.8335C19.0987 37.9401 19.3531 37.8348 19.5406 37.6473C19.7281 37.4597 19.8335 37.2054 19.8335 36.9401C19.8335 36.6749 19.7281 36.4206 19.5406 36.233C19.3531 36.0455 19.0987 35.9401 18.8335 35.9401H8.66948C7.38747 35.9388 6.15834 35.429 5.25173 34.5226C4.34512 33.6162 3.83507 32.3872 3.83348 31.1051V27.6671C3.83586 25.4735 4.70835 23.3703 6.25951 21.8192C7.81067 20.268 9.91381 19.3955 12.1075 19.3931C13.2836 19.3899 14.4466 19.6399 15.5175 20.1261C15.6375 20.1836 15.7678 20.2165 15.9007 20.2229C16.0335 20.2294 16.1664 20.2093 16.2914 20.1637C16.4164 20.1182 16.5311 20.0482 16.6287 19.9578C16.7263 19.8674 16.8049 19.7584 16.8599 19.6372C16.9148 19.516 16.945 19.3851 16.9488 19.2521C16.9525 19.1191 16.9296 18.9867 16.8816 18.8627C16.8335 18.7386 16.7611 18.6254 16.6687 18.5297C16.5763 18.4339 16.4657 18.3576 16.3435 18.3051V18.3091Z"
        fill={primary}
      />
      <path
        d="M39.3555 30.4381C39.3515 27.7148 38.2677 25.1042 36.3417 23.1788C34.4158 21.2534 31.8048 20.1703 29.0815 20.1671C28.8163 20.1671 28.5619 20.2725 28.3744 20.46C28.1868 20.6476 28.0815 20.9019 28.0815 21.1671C28.0815 21.4324 28.1868 21.6867 28.3744 21.8743C28.5619 22.0618 28.8163 22.1671 29.0815 22.1671C31.2751 22.1698 33.3781 23.0424 34.9292 24.5935C36.4803 26.1446 37.3528 28.2476 37.3555 30.4411V31.1121C37.3539 32.3942 36.8438 33.6232 35.9372 34.5296C35.0306 35.436 33.8015 35.9458 32.5195 35.9471H25.1075C24.8423 35.9471 24.5879 36.0525 24.4004 36.24C24.2128 36.4276 24.1075 36.6819 24.1075 36.9471C24.1075 37.2124 24.2128 37.4667 24.4004 37.6543C24.5879 37.8418 24.8423 37.9471 25.1075 37.9471H32.5195C34.3318 37.9453 36.0693 37.2246 37.3509 35.9432C38.6324 34.6619 39.3534 32.9244 39.3555 31.1121V30.4381Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
