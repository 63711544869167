import { withIcon } from "@icons/_withIcon";

export const MuseumFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M77.7162 41.4286H33.2885C32.3469 41.4286 31.5835 42.192 31.5835 43.1336V46.5574C31.5835 47.4991 32.3469 48.2624 33.2885 48.2624H77.7162C78.6578 48.2624 79.4212 47.4991 79.4212 46.5574V43.1336C79.4212 42.192 78.6578 41.4286 77.7162 41.4286Z"
        fill={primary}
      />
      <path
        d="M70.7473 24.3371C68.5709 17.0182 62.9291 10.226 67.1293 3.15658C67.462 2.60212 67.5729 1.95062 67.2956 1.36843C67.0045 0.786244 66.4085 0.425842 65.7708 0.425842H45.2689C44.6174 0.425842 44.0352 0.786244 43.7441 1.36843C43.453 1.95062 43.5778 2.60212 43.9105 3.15658C48.1106 10.226 42.4688 17.0182 40.2925 24.3371H70.775H70.7473Z"
        fill={accent}
      />
      <path
        d="M43.527 38.0044H67.4389C68.132 38.0044 68.631 37.5054 69.0192 36.9232C71.2094 33.735 71.75 30.6855 71.445 27.7468H39.507C39.202 30.6855 39.7288 33.735 41.9329 36.9232C42.3348 37.5054 42.82 38.0044 43.5131 38.0044H43.527Z"
        fill={accent}
      />
      <path
        d="M37.7188 52.6863H73.2986V70.8471C61.8316 72.7843 49.1986 72.7843 37.7188 70.8469V52.6863Z"
        fill="black"
        stroke={primary}
        strokeWidth="2"
      />
      <path
        d="M73.2766 86.8503V112.802C73.2766 113.192 72.9619 113.507 72.5716 113.507H38.4018C38.0114 113.507 37.6968 113.192 37.6968 112.802V86.8503C43.4777 87.7585 49.4738 88.2123 55.4798 88.213C55.4844 88.213 55.489 88.213 55.4936 88.213L73.2766 86.8503Z"
        fill="black"
        stroke={primary}
        strokeWidth="2"
      />
      <path
        d="M104.395 114.882V115.882H105.395H108.167C109.154 115.882 109.94 116.668 109.94 117.654C109.94 118.641 109.154 119.427 108.167 119.427H97.0778C96.0914 119.427 95.3054 118.641 95.3054 117.654C95.3054 116.668 96.0914 115.882 97.0778 115.882H99.8502H100.85V114.882V72.8812V71.1399L99.3462 72.0175C87.9313 78.6783 71.7657 82.0556 55.4919 82.0556C39.2181 82.0556 23.0525 78.6783 11.6375 72.0175L10.1335 71.1399V72.8812V114.882V115.882H11.1335H13.9059C14.8923 115.882 15.6783 116.668 15.6783 117.654C15.6783 118.641 14.8923 119.427 13.9059 119.427H2.81634C1.82992 119.427 1.04395 118.641 1.04395 117.654C1.04395 116.668 1.82992 115.882 2.81634 115.882H5.58874H6.58874V114.882V64.9801V63.9801H5.58874H2.81634C1.82992 63.9801 1.04395 63.1941 1.04395 62.2077C1.04395 61.2214 1.82992 60.4354 2.81634 60.4354H13.9059C14.8923 60.4354 15.6783 61.2214 15.6783 62.2077C15.6783 63.1941 14.8923 63.9801 13.9059 63.9801H11.1335H10.1335V64.9801V66.2553V66.7773L10.5618 67.0758C21.4979 74.6969 38.5595 78.4417 55.4919 78.4417C72.4242 78.4417 89.4858 74.6969 100.422 67.0758L100.85 66.7773V66.2553V64.9801V63.9801H99.8502H97.0778C96.0914 63.9801 95.3054 63.1941 95.3054 62.2077C95.3054 61.2214 96.0914 60.4354 97.0778 60.4354H108.167C109.154 60.4354 109.94 61.2214 109.94 62.2077C109.94 63.1941 109.154 63.9801 108.167 63.9801H105.395H104.395V64.9801V114.882Z"
        fill="black"
        stroke={primary}
        strokeWidth="2"
      />
    </>
  ),
  {
    viewBox: "0 0 111 121",
    fill: "none",
  }
);
