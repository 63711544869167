import { withIcon } from "@icons/_withIcon";

export const CaretRight = withIcon(
  () => (
    <>
      <path d="M20 38.75C23.7084 38.75 27.3335 37.6503 30.4169 35.5901C33.5004 33.5298 35.9036 30.6014 37.3227 27.1753C38.7419 23.7492 39.1132 19.9792 38.3897 16.3421C37.6663 12.7049 35.8805 9.36399 33.2583 6.74176C30.636 4.11952 27.2951 2.33376 23.6579 1.61028C20.0208 0.886812 16.2508 1.25812 12.8247 2.67727C9.39857 4.09641 6.47022 6.49964 4.40994 9.58307C2.34967 12.6665 1.25 16.2916 1.25 20C1.25 24.9728 3.22544 29.742 6.74175 33.2583C10.2581 36.7746 15.0272 38.75 20 38.75ZM20 3.75001C23.2139 3.75001 26.3557 4.70305 29.028 6.48863C31.7003 8.2742 33.7831 10.8121 35.013 13.7814C36.243 16.7507 36.5648 20.018 35.9378 23.1702C35.3107 26.3224 33.7631 29.2179 31.4905 31.4905C29.2179 33.7631 26.3224 35.3108 23.1702 35.9378C20.018 36.5648 16.7507 36.243 13.7814 35.013C10.8121 33.7831 8.27419 31.7003 6.48862 29.028C4.70305 26.3557 3.75 23.214 3.75 20C3.75661 15.6923 5.47078 11.5629 8.51682 8.51683C11.5629 5.47079 15.6923 3.75662 20 3.75001Z" />
      <path d="M16.6006 27.25C16.7176 27.3785 16.8606 27.4806 17.0201 27.5496C17.1795 27.6185 17.3519 27.6528 17.5256 27.65C17.6835 27.6518 17.8402 27.6217 17.9862 27.5616C18.1323 27.5015 18.2647 27.4125 18.3756 27.3L25.1506 20.9C25.3887 20.6611 25.5225 20.3374 25.5225 20C25.5225 19.6627 25.3887 19.339 25.1506 19.1L18.3756 12.7C18.2594 12.5829 18.1211 12.4899 17.9688 12.4264C17.8165 12.363 17.6531 12.3303 17.4881 12.3303C17.3231 12.3303 17.1597 12.363 17.0074 12.4264C16.855 12.4899 16.7168 12.5829 16.6006 12.7C16.4842 12.8141 16.3917 12.9502 16.3286 13.1005C16.2654 13.2507 16.2329 13.4121 16.2329 13.575C16.2329 13.738 16.2654 13.8993 16.3286 14.0496C16.3917 14.1998 16.4842 14.336 16.6006 14.45L22.5006 20L16.6506 25.5C16.5309 25.6107 16.4344 25.7442 16.367 25.8927C16.2995 26.0411 16.2624 26.2015 16.2577 26.3645C16.253 26.5275 16.281 26.6898 16.3399 26.8419C16.3987 26.9939 16.4874 27.1327 16.6006 27.25Z" />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
