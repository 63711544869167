import { withIcon } from "@icons/_withIcon";

export const SpendingDiariesFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d=" M7.57966 35.9465L4.18555 39.3406L10.9857 46.1407L14.3798 42.7466L7.57966 35.9465Z"
        fill={accent}
      />
      <path
        d="M44.0523 24.1476C43.2971 23.3945 42.2742 22.9716 41.2077 22.9716C40.1412 22.9716 39.1182 23.3945 38.3631 24.1476L32.7483 29.7492C32.5598 28.8174 32.0552 27.9793 31.3198 27.3768C30.5844 26.7744 29.6634 26.4445 28.7127 26.4432H16.9947C16.4847 26.4416 15.9794 26.5413 15.5083 26.7366C15.0372 26.9319 14.6096 27.2188 14.2503 27.5808L3.67947 38.1504C3.4545 38.3754 3.32813 38.6806 3.32812 38.9988C3.32812 39.317 3.4545 39.6222 3.67947 39.8472L10.4799 46.6476C10.7049 46.8726 11.0101 46.9989 11.3283 46.9989C11.6465 46.9989 11.9516 46.8726 12.1767 46.6476L16.5771 42.2472C17.285 41.5426 18.2423 41.1459 19.2411 41.1432H30.8919C31.4798 41.1452 32.0623 41.0304 32.6055 40.8054C33.1487 40.5805 33.6418 40.2499 34.0563 39.8328L44.0523 29.838C44.8054 29.0826 45.2284 28.0595 45.2284 26.9928C45.2284 25.9261 44.8054 24.903 44.0523 24.1476ZM42.3603 28.1412L32.3595 38.136C32.1671 38.3292 31.9384 38.4823 31.6864 38.5865C31.4345 38.6907 31.1645 38.744 30.8919 38.7432H19.2411C17.6062 38.7479 16.0392 39.3973 14.8803 40.5504L11.3283 44.1024L6.22587 39L15.9459 29.28C16.0829 29.142 16.2459 29.0327 16.4256 28.9583C16.6052 28.8839 16.7978 28.846 16.9923 28.8468H28.7103C29.158 28.8586 29.5834 29.0448 29.8959 29.3657C30.2084 29.6865 30.3833 30.1167 30.3833 30.5646C30.3833 31.0125 30.2084 31.4427 29.8959 31.7635C29.5834 32.0844 29.158 32.2706 28.7103 32.2824H20.0835C19.7652 32.2824 19.46 32.4088 19.2349 32.6339C19.0099 32.8589 18.8835 33.1641 18.8835 33.4824C18.8835 33.8007 19.0099 34.1059 19.2349 34.3309C19.46 34.556 19.7652 34.6824 20.0835 34.6824H29.4159C29.9841 34.6838 30.547 34.5727 31.0722 34.3556C31.5974 34.1386 32.0744 33.8198 32.4759 33.4176L40.0587 25.8468C40.3629 25.5416 40.776 25.3697 41.2069 25.3691C41.6379 25.3684 42.0515 25.5389 42.3567 25.8432C42.6619 26.1475 42.8337 26.5605 42.8344 26.9915C42.8351 27.4224 42.6645 27.836 42.3603 28.1412Z"
        fill={primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d=" M23.8875 22.8C29.5208 22.8 34.0875 18.2333 34.0875 12.6C34.0875 6.96672 29.5208 2.40002 23.8875 2.40002C18.2542 2.40002 13.6875 6.96672 13.6875 12.6C13.6875 18.2333 18.2542 22.8 23.8875 22.8ZM24.7913 19.0177C27.1369 18.7485 28.5609 17.3303 28.5609 15.2838C28.5609 13.4168 27.3882 12.2141 24.9756 11.5319L24.6908 11.4601V8.3365C25.562 8.58783 26.2154 9.25204 26.4332 10.365L28.4772 9.80854C28.1253 7.72615 26.7013 6.46953 24.7913 6.16435V4.80002H23.0154V6.12845C20.8374 6.37977 19.4133 7.78 19.4133 9.70083C19.4133 11.5858 20.5023 12.7706 22.7641 13.4527L23.1327 13.5604V16.8456C22.0102 16.5584 21.273 15.6787 20.9714 14.458L18.9609 15.1761C19.4971 17.5278 21.0384 18.7485 23.0154 19.0177V20.4H24.7913V19.0177ZM23.1327 10.9934C22.2782 10.6882 21.7421 10.3112 21.7421 9.57517C21.7421 8.91096 22.2447 8.42626 23.1327 8.2647V10.9934ZM24.6908 16.8815V14.0092C25.763 14.3862 26.2489 14.7812 26.2489 15.4813C26.2489 16.1993 25.6625 16.702 24.6908 16.8815Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 48 48",
  }
);

export const SpendingDiariesFill = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.5C13.7239 5.5 13.5 5.72386 13.5 6C13.5 6.27614 13.7239 6.5 14 6.5H18C19.933 6.5 21.5 8.067 21.5 10C21.5 11.933 19.933 13.5 18 13.5H13C12.1716 13.5 11.5 12.8284 11.5 12C11.5 11.1716 12.1716 10.5 13 10.5H18C18.2761 10.5 18.5 10.2761 18.5 10C18.5 9.72386 18.2761 9.5 18 9.5H14C12.067 9.5 10.5 7.933 10.5 6C10.5 4.067 12.067 2.5 14 2.5H19C19.8284 2.5 20.5 3.17157 20.5 4C20.5 4.82843 19.8284 5.5 19 5.5H14Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0.5C16.8284 0.5 17.5 1.17157 17.5 2V4C17.5 4.82843 16.8284 5.5 16 5.5C15.1716 5.5 14.5 4.82843 14.5 4V2C14.5 1.17157 15.1716 0.5 16 0.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10.5C16.8284 10.5 17.5 11.1716 17.5 12V14C17.5 14.8284 16.8284 15.5 16 15.5C15.1716 15.5 14.5 14.8284 14.5 14V12C14.5 11.1716 15.1716 10.5 16 10.5Z"
      />
      <path d="M29.7071 16.2071C28.7643 15.2643 27.2357 15.2643 26.2929 16.2071L21.1716 21.3284C20.7965 21.7035 20.2878 21.9142 19.7574 21.9142H13.8158C13.2167 21.9142 12.8594 21.2465 13.1917 20.7481V20.7481C13.3308 20.5394 13.565 20.4141 13.8158 20.4141H18.25C19.2165 20.4141 20 19.6306 20 18.6641V18.6641C20 17.6976 19.2165 16.9141 18.25 16.9141H11.8286C11.0329 16.9141 10.2699 17.2301 9.70726 17.7927L2.29289 25.2071C1.90237 25.5976 1.90237 26.2308 2.29289 26.6213L6.29289 30.6213C6.68342 31.0118 7.31658 31.0118 7.70711 30.6213L9.53553 28.7929C10.0981 28.2303 10.8612 27.9142 11.6569 27.9142H19.3431C20.6692 27.9142 21.941 27.3874 22.8787 26.4497L29.7071 19.6213C30.6499 18.6785 30.6499 17.1499 29.7071 16.2071Z" />
    </>
  ),
  {
    viewBox: "0 0 32 32",
  }
);
