import { withIcon } from "@icons/_withIcon";

export const CartFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M32.586 35.0528C33.7668 35.0528 34.724 34.0956 34.724 32.9148C34.724 31.734 33.7668 30.7768 32.586 30.7768C31.4052 30.7768 30.448 31.734 30.448 32.9148C30.448 34.0956 31.4052 35.0528 32.586 35.0528Z"
        fill={accent}
      />
      <path
        d="M15.586 35.0528C16.7668 35.0528 17.724 34.0956 17.724 32.9148C17.724 31.734 16.7668 30.7768 15.586 30.7768C14.4052 30.7768 13.448 31.734 13.448 32.9148C13.448 34.0956 14.4052 35.0528 15.586 35.0528Z"
        fill={accent}
      />
      <path
        d="M15.586 27.4168C14.4982 27.4168 13.4348 27.7394 12.5304 28.3437C11.6259 28.9481 10.9209 29.8071 10.5047 30.812C10.0884 31.817 9.97946 32.9229 10.1917 33.9898C10.4039 35.0567 10.9277 36.0367 11.6969 36.8059C12.4661 37.5751 13.4461 38.0989 14.513 38.3111C15.5799 38.5233 16.6858 38.4144 17.6908 37.9981C18.6958 37.5819 19.5547 36.8769 20.1591 35.9724C20.7634 35.068 21.086 34.0046 21.086 32.9168C21.0844 31.4586 20.5044 30.0606 19.4733 29.0295C18.4422 27.9984 17.0442 27.4184 15.586 27.4168ZM15.586 36.4168C14.8938 36.4168 14.2171 36.2115 13.6415 35.827C13.0659 35.4424 12.6173 34.8957 12.3524 34.2562C12.0875 33.6167 12.0182 32.9129 12.1533 32.234C12.2883 31.5551 12.6216 30.9314 13.1111 30.4419C13.6006 29.9525 14.2243 29.6191 14.9032 29.4841C15.5821 29.349 16.2859 29.4183 16.9254 29.6832C17.5649 29.9481 18.1116 30.3967 18.4961 30.9723C18.8807 31.5479 19.086 32.2246 19.086 32.9168C19.086 33.8451 18.7172 34.7353 18.0609 35.3917C17.4045 36.0481 16.5143 36.4168 15.586 36.4168Z"
        fill={primary}
      />
      <path
        d="M32.586 27.4168C31.4982 27.4168 30.4348 27.7394 29.5304 28.3437C28.6259 28.9481 27.9209 29.8071 27.5047 30.812C27.0884 31.817 26.9795 32.9229 27.1917 33.9898C27.4039 35.0567 27.9277 36.0367 28.6969 36.8059C29.4661 37.5751 30.4461 38.0989 31.513 38.3111C32.5799 38.5233 33.6858 38.4144 34.6908 37.9981C35.6958 37.5819 36.5547 36.8769 37.1591 35.9724C37.7634 35.068 38.086 34.0046 38.086 32.9168C38.0844 31.4586 37.5044 30.0606 36.4733 29.0295C35.4422 27.9984 34.0442 27.4184 32.586 27.4168ZM32.586 36.4168C31.8938 36.4168 31.2171 36.2115 30.6415 35.827C30.0659 35.4424 29.6173 34.8957 29.3524 34.2562C29.0875 33.6167 29.0182 32.9129 29.1533 32.234C29.2883 31.5551 29.6216 30.9314 30.1111 30.4419C30.6006 29.9525 31.2243 29.6191 31.9032 29.4841C32.5821 29.349 33.2859 29.4183 33.9254 29.6832C34.5649 29.9481 35.1116 30.3967 35.4961 30.9723C35.8807 31.5479 36.086 32.2246 36.086 32.9168C36.086 33.8451 35.7173 34.7353 35.0609 35.3917C34.4045 36.0481 33.5143 36.4168 32.586 36.4168Z"
        fill={primary}
      />
      <path
        d="M37.869 6.7738H8.53799C8.23657 5.88409 7.66391 5.11119 6.90052 4.56376C6.13713 4.01633 5.22138 3.72188 4.28199 3.7218H2.68199C2.41677 3.7218 2.16242 3.82716 1.97488 4.01469C1.78735 4.20223 1.68199 4.45659 1.68199 4.7218C1.68199 4.98702 1.78735 5.24137 1.97488 5.42891C2.16242 5.61645 2.41677 5.7218 2.68199 5.7218H4.28199C4.90213 5.71981 5.50098 5.94782 5.96275 6.36176C6.42452 6.77569 6.71642 7.34614 6.78199 7.9628L8.18599 21.0538C8.32971 22.4169 8.97351 23.6783 9.99302 24.5944C11.0125 25.5105 12.3354 26.0162 13.706 26.0138H36.683C36.9482 26.0138 37.2026 25.9084 37.3901 25.7209C37.5776 25.5334 37.683 25.279 37.683 25.0138C37.683 24.7486 37.5776 24.4942 37.3901 24.3067C37.2026 24.1192 36.9482 24.0138 36.683 24.0138H13.706C12.8584 24.0141 12.0389 23.7101 11.3966 23.1571C10.7542 22.6042 10.3318 21.839 10.206 21.0008H31.093C32.8383 21.0033 34.5228 20.3595 35.8216 19.1936C37.1205 18.0277 37.9417 16.4223 38.127 14.6868L38.863 7.8808C38.878 7.74128 38.8635 7.60016 38.8204 7.46662C38.7773 7.33308 38.7066 7.2101 38.6128 7.10569C38.519 7.00128 38.4044 6.91777 38.2762 6.86059C38.1481 6.80341 38.0093 6.77384 37.869 6.7738ZM36.139 14.4738C36.0056 15.7186 35.4164 16.87 34.4847 17.7062C33.553 18.5424 32.3449 19.0042 31.093 19.0028H9.97599L8.87599 8.7738H36.755L36.139 14.4738Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);

export const CartPlus = withIcon(
  () => (
    <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM252 160c0 11 9 20 20 20h44v44c0 11 9 20 20 20s20-9 20-20V180h44c11 0 20-9 20-20s-9-20-20-20H356V96c0-11-9-20-20-20s-20 9-20 20v44H272c-11 0-20 9-20 20z" />
  ),
  {
    viewBox: "0 0 576 512",
  }
);
export const CartMinus = withIcon(
  () => (
    <path d="M0 24C0 10.7 10.7 0 24 0H69.5C91.5 0 111 12.8 120.1 32H531.1C557.4 32 576.6 57 569.7 82.4L528.7 234.7C520.2 266.1 491.7 288 459.2 288H170.7L176.1 316.5C178.3 327.8 188.2 336 199.7 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H199.7C165.1 384 135.4 359.4 129 325.5L77.4 54.5C76.7 50.7 73.4 48 69.5 48H24C10.7 48 0 37.3 0 24ZM128 464C128 457.697 129.242 451.455 131.654 445.631C134.066 439.808 137.602 434.516 142.059 430.059C146.516 425.602 151.808 422.066 157.631 419.654C163.455 417.242 169.697 416 176 416C182.303 416 188.545 417.242 194.369 419.654C200.192 422.066 205.484 425.602 209.941 430.059C214.398 434.516 217.934 439.808 220.346 445.631C222.758 451.455 224 457.697 224 464C224 470.303 222.758 476.545 220.346 482.369C217.934 488.192 214.398 493.484 209.941 497.941C205.484 502.398 200.192 505.934 194.369 508.346C188.545 510.758 182.303 512 176 512C169.697 512 163.455 510.758 157.631 508.346C151.808 505.934 146.516 502.398 142.059 497.941C137.602 493.484 134.066 488.192 131.654 482.369C129.242 476.545 128 470.303 128 464ZM464 416C476.73 416 488.939 421.057 497.941 430.059C506.943 439.061 512 451.27 512 464C512 476.73 506.943 488.939 497.941 497.941C488.939 506.943 476.73 512 464 512C451.27 512 439.061 506.943 430.059 497.941C421.057 488.939 416 476.73 416 464C416 451.27 421.057 439.061 430.059 430.059C439.061 421.057 451.27 416 464 416ZM252 160C252 171 261 180 272 180H316H321C333 180 325 180 336 180C347 180 343.5 180 356 180H400C411 180 420 171 420 160C420 149 411 140 400 140H356H351C341 140 347 140 336 140C325 140 325 140 316 140H272C261 140 252 149 252 160Z" />
  ),
  {
    viewBox: "0 0 576 512",
  }
);

export const Cart = withIcon(
  () => (
    <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
  ),
  {
    viewBox: "0 0 576 512",
  }
);
