import { withIcon } from "@icons/_withIcon";

// Change log: previous name: `FiveShops`
export const ShopFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M25.553 15.6848L21.278 18.2498C21.2078 18.292 21.1496 18.3517 21.1093 18.423C21.069 18.4943 21.0479 18.5749 21.048 18.6568V27.6818C21.048 27.8078 21.098 27.9286 21.1871 28.0177C21.2762 28.1068 21.397 28.1568 21.523 28.1568H30.073C30.199 28.1568 30.3198 28.1068 30.4089 28.0177C30.498 27.9286 30.548 27.8078 30.548 27.6818V18.6568C30.5479 18.5748 30.5266 18.4942 30.4861 18.4229C30.4456 18.3516 30.3874 18.2919 30.317 18.2498L26.042 15.6848C25.9683 15.6402 25.8837 15.6165 25.7975 15.6165C25.7113 15.6165 25.6267 15.6402 25.553 15.6848Z"
        fill={accent}
      />
      <path
        d="M22.881 1.8168C21.8993 1.81974 20.9253 1.98879 20 2.3168C18.6815 1.85185 17.2707 1.71027 15.8861 1.90398C14.5016 2.09768 13.1838 2.621 12.0435 3.42994C10.9033 4.23887 9.97391 5.30977 9.33361 6.55257C8.69332 7.79538 8.36079 9.17376 8.364 10.5718V10.6058H4.548C4.28279 10.6058 4.02843 10.7112 3.8409 10.8987C3.65336 11.0862 3.548 11.3406 3.548 11.6058V34.8168C3.54959 36.1424 4.07689 37.4132 5.01422 38.3506C5.95156 39.2879 7.22241 39.8152 8.548 39.8168H31.568C32.8936 39.8152 34.1644 39.2879 35.1018 38.3506C36.0391 37.4132 36.5664 36.1424 36.568 34.8168V11.6058C36.568 11.3406 36.4627 11.0862 36.2751 10.8987C36.0876 10.7112 35.8332 10.6058 35.568 10.6058H31.636V10.5718C31.6334 8.25065 30.7101 6.02531 29.0688 4.38401C27.4275 2.7427 25.2022 1.81945 22.881 1.8168ZM23.874 10.5718V10.6058H16.125V10.5718C16.1271 9.29235 16.4924 8.03978 17.1784 6.95976C17.8643 5.87973 18.8428 5.01662 20 4.4708C21.157 5.01677 22.1353 5.87994 22.8211 6.95995C23.5068 8.03996 23.872 9.29246 23.874 10.5718ZM10.364 10.5718C10.3661 8.78092 11.0785 7.06398 12.3448 5.79764C13.6112 4.53129 15.3281 3.81892 17.119 3.8168C17.182 3.8168 17.243 3.8248 17.306 3.8258C16.3111 4.64606 15.5099 5.67625 14.96 6.84254C14.41 8.00884 14.1249 9.28235 14.125 10.5718V10.6058H10.364V10.5718ZM8.548 37.8168C7.75235 37.8168 6.98929 37.5007 6.42668 36.9381C5.86407 36.3755 5.548 35.6125 5.548 34.8168V12.6058H10.853V34.8168C10.8568 35.9021 11.217 36.9561 11.878 37.8168H8.548ZM34.568 12.6058V34.8168C34.568 35.6125 34.2519 36.3755 33.6893 36.9381C33.1267 37.5007 32.3637 37.8168 31.568 37.8168H15.853C15.0574 37.8168 14.2943 37.5007 13.7317 36.9381C13.1691 36.3755 12.853 35.6125 12.853 34.8168V12.6058H34.568ZM25.874 10.6058V10.5718C25.8743 9.28247 25.5892 8.00906 25.0392 6.84291C24.4892 5.67676 23.688 4.64676 22.693 3.8268C22.756 3.8268 22.818 3.8168 22.881 3.8168C24.6719 3.81892 26.3888 4.53129 27.6552 5.79764C28.9215 7.06398 29.6339 8.78092 29.636 10.5718V10.6058H25.874Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);

// Change log: previous name: `FiveShops`
export const ShopFill = withIcon(
  () => (
    <path d="M23.4 6.93201C23.1703 5.13164 22.2921 3.47676 20.9299 2.27735C19.5677 1.07793 17.815 0.41626 16 0.41626C14.185 0.41626 12.4323 1.07793 11.0701 2.27735C9.70793 3.47676 8.82973 5.13164 8.6 6.93201H4C3.73478 6.93201 3.48043 7.03737 3.29289 7.22491C3.10536 7.41244 3 7.6668 3 7.93201V25.978C3 27.3041 3.52678 28.5759 4.46447 29.5135C5.40215 30.4512 6.67392 30.978 8 30.978H24C25.3261 30.978 26.5979 30.4512 27.5355 29.5135C28.4732 28.5759 29 27.3041 29 25.978V7.93201C29 7.6668 28.8946 7.41244 28.7071 7.22491C28.5196 7.03737 28.2652 6.93201 28 6.93201H23.4ZM10 10.5C10.3978 10.5 10.7794 10.658 11.0607 10.9394C11.342 11.2207 11.5 11.6022 11.5 12C11.5 13.1935 11.9741 14.3381 12.818 15.182C13.6619 16.0259 14.8065 16.5 16 16.5C17.1935 16.5 18.3381 16.0259 19.182 15.182C20.0259 14.3381 20.5 13.1935 20.5 12C20.5 11.6022 20.658 11.2207 20.9393 10.9394C21.2206 10.658 21.6022 10.5 22 10.5C22.3978 10.5 22.7794 10.658 23.0607 10.9394C23.342 11.2207 23.5 11.6022 23.5 12C23.5 13.9891 22.7098 15.8968 21.3033 17.3033C19.8968 18.7098 17.9891 19.5 16 19.5C14.0109 19.5 12.1032 18.7098 10.6967 17.3033C9.29018 15.8968 8.5 13.9891 8.5 12C8.5 11.6022 8.65804 11.2207 8.93934 10.9394C9.22064 10.658 9.60218 10.5 10 10.5ZM16 3.43201C17.0182 3.43443 18.0055 3.78177 18.801 4.4174C19.5964 5.05303 20.153 5.93942 20.38 6.93201H11.62C11.847 5.93942 12.4036 5.05303 13.199 4.4174C13.9944 3.78177 14.9818 3.43443 16 3.43201Z" />
  ),
  {
    viewBox: "0 0 32 32",
  }
);
