import { withIcon } from "@icons/_withIcon";

// Changelog: name was Sleep
export const SleepFill = withIcon(
  () => (
    <>
      <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
      <path
        d="M14.925 14.6625C11.85 14.6625 9.3375 12.15 9.3375 9.075C9.3375 7.95 9.675 6.9 10.2375 6C7.8 6.7875 6 9.1125 6 11.85C6 15.2625 8.7375 18 12.15 18C14.8875 18 17.2125 16.2 18 13.725C17.1375 14.325 16.0875 14.6625 14.925 14.6625Z"
        fill="white"
      />
    </>
  ),
  {
    viewBox: "0 0 24 24",
  }
);
