import PropTypes from "prop-types";
import cn from "classnames";

export function Input({
  className = "",
  type = "text",
  placeholder = "",
  name = "",
  required = null,
  id = "",
  onChange,
  onFocus = () => {},
  value,
}) {
  return (
    <input
      id={id}
      required={required}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      className={cn(
        "rounded-pill border border-black-200 focus:border-black-300 px-8 py-3 font-light",
        className
      )}
    />
  );
}

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(["text", "email", "checkbox"]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
