import { withIcon } from "@icons/_withIcon";

export const CaretDown = withIcon(
  () => (
    <>
      <path d="M16 31C18.9667 31 21.8668 30.1203 24.3336 28.4721C26.8003 26.8238 28.7229 24.4812 29.8582 21.7403C30.9935 18.9994 31.2906 15.9834 30.7118 13.0737C30.133 10.1639 28.7044 7.49119 26.6066 5.3934C24.5088 3.29562 21.8361 1.86701 18.9264 1.28823C16.0166 0.709449 13.0006 1.0065 10.2597 2.14181C7.51886 3.27713 5.17618 5.19972 3.52796 7.66645C1.87973 10.1332 1 13.0333 1 16C1 19.9783 2.58035 23.7936 5.3934 26.6066C8.20644 29.4197 12.0218 31 16 31ZM16 3.00001C18.5712 3.00001 21.0846 3.76244 23.2224 5.1909C25.3603 6.61936 27.0265 8.64968 28.0104 11.0251C28.9944 13.4006 29.2518 16.0144 28.7502 18.5362C28.2486 21.0579 27.0105 23.3743 25.1924 25.1924C23.3743 27.0105 21.0579 28.2486 18.5362 28.7502C16.0144 29.2518 13.4006 28.9944 11.0251 28.0104C8.64968 27.0265 6.61935 25.3603 5.1909 23.2224C3.76244 21.0846 3 18.5712 3 16C3.00529 12.5538 4.37663 9.25029 6.81345 6.81346C9.25028 4.37663 12.5538 3.0053 16 3.00001Z" />
      <path d="M15.2804 20.1201C15.4716 20.3106 15.7305 20.4176 16.0004 20.4176C16.2703 20.4176 16.5292 20.3106 16.7204 20.1201L21.8404 14.7001C21.9341 14.6071 22.0085 14.4965 22.0593 14.3746C22.1101 14.2528 22.1362 14.1221 22.1362 13.9901C22.1362 13.858 22.1101 13.7273 22.0593 13.6055C22.0085 13.4836 21.9341 13.373 21.8404 13.2801C21.7492 13.1869 21.6403 13.1129 21.5201 13.0624C21.3999 13.0119 21.2708 12.9859 21.1404 12.9859C21.01 12.9859 20.881 13.0119 20.7608 13.0624C20.6406 13.1129 20.5317 13.1869 20.4404 13.2801L16.0004 18.0001L11.6004 13.3201C11.5129 13.2139 11.4043 13.127 11.2817 13.0648C11.159 13.0025 11.0248 12.9662 10.8875 12.9582C10.7501 12.9502 10.6126 12.9706 10.4835 13.0181C10.3544 13.0657 10.2365 13.1393 10.1372 13.2345C10.0379 13.3297 9.95926 13.4444 9.90626 13.5713C9.85327 13.6983 9.82705 13.8348 9.82923 13.9724C9.83141 14.1099 9.86194 14.2455 9.91893 14.3707C9.97592 14.496 10.0581 14.6081 10.1604 14.7001L15.2804 20.1201Z" />
    </>
  ),
  {
    viewBox: "0 0 32 32",
  }
);
