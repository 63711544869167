import { withIcon } from "@icons/_withIcon";

export const Map = withIcon(
  () => (
    <>
      <path d="M8.00001 10.7194C8.13261 10.7194 8.25979 10.6667 8.35356 10.573C8.44733 10.4792 8.50001 10.352 8.50001 10.2194V5.36941C9.10703 5.2455 9.64642 4.90062 10.0136 4.40162C10.3808 3.90262 10.5496 3.28505 10.4873 2.66865C10.4251 2.05225 10.1362 1.48092 9.67658 1.06544C9.21701 0.649962 8.61954 0.419922 8.00001 0.419922C7.38047 0.419922 6.783 0.649962 6.32343 1.06544C5.86386 1.48092 5.57493 2.05225 5.51267 2.66865C5.4504 3.28505 5.61922 3.90262 5.98641 4.40162C6.35359 4.90062 6.89298 5.2455 7.50001 5.36941V10.2194C7.50001 10.352 7.55268 10.4792 7.64645 10.573C7.74022 10.6667 7.8674 10.7194 8.00001 10.7194ZM6.50001 2.91941C6.50001 2.62274 6.58798 2.33273 6.7528 2.08606C6.91762 1.83938 7.15189 1.64712 7.42598 1.53359C7.70007 1.42006 8.00167 1.39036 8.29264 1.44823C8.58361 1.50611 8.85089 1.64897 9.06067 1.85875C9.27044 2.06853 9.41331 2.3358 9.47118 2.62678C9.52906 2.91775 9.49936 3.21935 9.38583 3.49344C9.27229 3.76753 9.08003 4.00179 8.83336 4.16662C8.58669 4.33144 8.29668 4.41941 8.00001 4.41941C7.60218 4.41941 7.22065 4.26138 6.93935 3.98007C6.65804 3.69877 6.50001 3.31724 6.50001 2.91941Z" />
      <path d="M15.23 7.78961L11.56 5.91961H11.47H11.38H11.2L9.92001 6.27961C9.79234 6.31691 9.6843 6.40261 9.61892 6.51843C9.55354 6.63425 9.53598 6.77103 9.57001 6.89961C9.60221 7.00427 9.66725 7.09578 9.75549 7.16061C9.84374 7.22545 9.95051 7.26016 10.06 7.25961H10.19L10.83 6.99961V12.7196L5.17001 14.3396V8.60961L6.17001 8.33961C6.23356 8.32341 6.29322 8.29463 6.34544 8.25495C6.39767 8.21528 6.44139 8.16552 6.47403 8.10864C6.50667 8.05175 6.52756 7.98889 6.53546 7.92378C6.54335 7.85868 6.5381 7.79265 6.52001 7.72961C6.4849 7.6027 6.40115 7.49477 6.28694 7.42924C6.17273 7.36371 6.03729 7.34587 5.91001 7.37961L4.72001 7.69961L1.23001 5.91961C1.1552 5.87756 1.07083 5.85547 0.985009 5.85547C0.899191 5.85547 0.814818 5.87756 0.740008 5.91961C0.666299 5.96448 0.605457 6.02766 0.563401 6.10301C0.521345 6.17836 0.499506 6.26332 0.500008 6.34961V13.1096C0.501069 13.2018 0.526632 13.2921 0.574078 13.3712C0.621523 13.4502 0.689144 13.5153 0.770008 13.5596L4.44001 15.4296C4.51254 15.4616 4.59076 15.4786 4.67001 15.4796H4.80001L11.28 13.6696L14.77 15.4496C14.8425 15.4816 14.9208 15.4986 15 15.4996C15.0916 15.5016 15.1818 15.4773 15.26 15.4296C15.3324 15.3833 15.3923 15.3199 15.4341 15.2448C15.476 15.1698 15.4987 15.0855 15.5 14.9996V8.22961C15.5004 8.13841 15.4754 8.0489 15.4277 7.97117C15.38 7.89343 15.3115 7.83055 15.23 7.78961ZM1.50001 7.17961L4.17001 8.53961V14.1796L1.50001 12.8296V7.17961ZM14.5 14.1796L11.83 12.8296V7.17961L14.5 8.53961V14.1796Z" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);
