import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

export const CohesionContext = createContext();

export const withCohesion = ({ App }) => {
  function CohesionProviderHOC(props) {
    const [cohesionLoaded, setCohesionLoadedState] = useState(false);
    const [monarchReady, setMonarchReady] = useState(false);
    const [monarchResponses, setMonarchResponses] = useState([]);

    const setCohesionLoaded = useCallback(value => {
      setCohesionLoadedState(value);
    }, []);

    const handleMonarchResponse = useCallback(
      res => {
        const prev = monarchResponses;
        const next = [...prev, res];

        setMonarchResponses(next);
      },
      [monarchResponses]
    );

    const ctx = useMemo(
      () => ({
        monarchReady,
        monarchResponses,
        handleMonarchResponse,
        setCohesionLoaded,
      }),
      [setCohesionLoaded, monarchReady, monarchResponses, handleMonarchResponse]
    );

    useEffect(() => {
      // window.cohesion("tagular:ready", () => {
      //   console.log("Tagular ready", window.tagular);
      // });

      window.cohesion("monarch:ready", () => {
        /**
         * There is no return payload for this event. Any `res` will be undefined.
         */
        setMonarchReady(true);
      });

      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [cohesionLoaded]);

    return (
      <CohesionContext.Provider value={ctx}>
        <App {...props} />
      </CohesionContext.Provider>
    );
  }

  return CohesionProviderHOC;
};
