import PropTypes from "prop-types";
import cn from "classnames";
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { SHA256, enc } from "crypto-js";
import { Input } from "@components/fields/input";
import { Button } from "@components/button";
import { tagular } from "@cohesion/tagular";

export function NewsletterSignup({
  background = "light",
  btnText = "Sign Up",
  callback = () => {},
  className = "",
  errorText = "There was an error processing your request, please try again later.",
  formName = "NEWSLETTER-FORM",
  formType = "NEWSLETTER",
  fullWidth = true,
  inlineForm = false,
  layoutForm = "default",
  successText = "Thanks for signing up!",
  title = "",
  source,
}) {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [hasFocused, setHasFocused] = useState(false);

  const tagularPayload = {
    formContext: {
      formId: source,
      formName,
      formType,
    },
  };

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      tagular("formview", {
        captureContext: {
          capturePlacement: "Inline",
          captureType: "Custom Form",
          identityRequested: false,
        },
        ...tagularPayload,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleFocus = () => {
    if (!hasFocused) {
      tagular("formstart", tagularPayload);
      setHasFocused(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const hashedEmail =
      email !== "" ? SHA256(email.toLowerCase()).toString(enc.Hex) : null;

    const data = {
      source,
      email,
      formName,
      formType,
    };

    await fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(res => {
        setError(res.status !== 200);
        setSuccess(res.status === 200);
        if (res.status === 200) {
          document.cookie = "newsletterSubscribed=true; max-age=31536000";
          setTimeout(() => {
            tagular("submit", {
              field: [
                {
                  fieldName: "userId",
                  fieldValue: hashedEmail,
                },
              ],
              ...tagularPayload,
            });
          }, 3000);
          callback();
        }
      })
      .catch(err => {
        setError(err.message);
      });
    return true;
  };

  const handleChange = e => {
    setEmail(e.target.value);
  };

  return (
    <div className={className}>
      {title && !success && (
        <h3
          className={cn("text-lg font-semibold", {
            "text-white": background === "dark",
            "text-blue": background === "light",
            "text-base": layoutForm === "hero",
          })}
        >
          {title}
        </h3>
      )}

      {error && (
        <>
          {layoutForm === "default" && (
            <p
              className={cn({
                "text-white": background === "dark",
                "text-red": background === "light",
              })}
            >
              {errorText}
            </p>
          )}
          {layoutForm === "hero" && (
            <div
              className={cn({
                "text-white inline-block w-auto bg-red text-xs mt-2 p-1":
                  background === "dark",
                "text-red": background === "light",
              })}
            >
              <p
                className={cn({
                  "text-white": background === "dark",
                  "text-red": background === "light",
                })}
              >
                {errorText}
              </p>
            </div>
          )}
        </>
      )}

      {success && (
        <>
          <h3
            className={cn("text-lg font-bold mt-4", {
              "text-white": background === "dark",
              "text-blue": background === "light",
            })}
          >
            {successText}
          </h3>
          <p
            className={cn({
              "text-white": background === "dark",
              "text-black-400": background === "light",
            })}
          >
            We just sent a confirmation email to <span>{email}</span>
          </p>
        </>
      )}

      {!success && (
        <form ref={ref} onSubmit={handleSubmit}>
          <Input
            required
            type="email"
            value={email}
            placeholder="Email address"
            onChange={handleChange}
            onFocus={handleFocus}
            className={cn("my-4 placeholder-black max-w-full h-10", {
              "w-full": fullWidth,
              "mb-0 w-full leading-none md:w-3/5": inlineForm,
            })}
          />
          {inlineForm && (
            <Button
              type="submit"
              className="btn btn-primary leading-none h-10 mt-4 mb-4 w-full ml-0 md:mt-0 md:ml-6 md:w-auto"
            >
              {btnText}
            </Button>
          )}
          {!inlineForm && (
            <Button
              type="submit"
              className={cn("btn btn-primary mb-4", {
                "w-full": fullWidth,
              })}
            >
              {btnText}
            </Button>
          )}
          <p
            className={cn("text-xs", {
              "text-white": background === "dark",
              "text-black-400": background === "light",
            })}
          >
            Subscribe to Lonely Planet newsletters and promotions.{" "}
            <span className="whitespace-nowrap">
              Read our{" "}
              <a
                href="/legal"
                className={cn("underline", {
                  "text-white": background === "dark",
                  "text-blue": background === "light",
                })}
              >
                Privacy Policy.
              </a>
            </span>
          </p>
        </form>
      )}
    </div>
  );
}

NewsletterSignup.propTypes = {
  layoutForm: PropTypes.oneOf(["default", "hero"]),
  background: PropTypes.oneOf(["light", "dark"]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  inlineForm: PropTypes.bool,
  formName: PropTypes.string,
  formType: PropTypes.string,
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
  btnText: PropTypes.string,
  successText: PropTypes.string,
  errorText: PropTypes.string,
  callback: PropTypes.func,
};
