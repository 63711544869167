import { withIcon } from "@icons/_withIcon";

export const Friends = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 1.75C4.25736 1.75 3.25 2.75736 3.25 4C3.25 5.24264 4.25736 6.25 5.5 6.25C6.74264 6.25 7.75 5.24264 7.75 4C7.75 2.75736 6.74264 1.75 5.5 1.75ZM1.75 4C1.75 1.92893 3.42893 0.25 5.5 0.25C7.57107 0.25 9.25 1.92893 9.25 4C9.25 6.07107 7.57107 7.75 5.5 7.75C3.42893 7.75 1.75 6.07107 1.75 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 10.75C3.42893 10.75 1.75 12.4289 1.75 14.5H0.25C0.25 11.6005 2.6005 9.25 5.5 9.25C8.3995 9.25 10.75 11.6005 10.75 14.5H9.25C9.25 12.4289 7.57107 10.75 5.5 10.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 6.25C13.2574 6.25 12.25 7.25736 12.25 8.5C12.25 9.74264 13.2574 10.75 14.5 10.75C15.7426 10.75 16.75 9.74264 16.75 8.5C16.75 7.25736 15.7426 6.25 14.5 6.25ZM10.75 8.5C10.75 6.42893 12.4289 4.75 14.5 4.75C16.5711 4.75 18.25 6.42893 18.25 8.5C18.25 10.5711 16.5711 12.25 14.5 12.25C12.4289 12.25 10.75 10.5711 10.75 8.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 15.25C12.4289 15.25 10.75 16.9289 10.75 19H9.25C9.25 16.1005 11.6005 13.75 14.5 13.75C17.3995 13.75 19.75 16.1005 19.75 19H18.25C18.25 16.9289 16.5711 15.25 14.5 15.25Z"
        fill="currentColor"
      />
    </>
  ),
  {
    viewBox: "0 0 20 19",
    fill: "none",
  }
);
