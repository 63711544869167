import { withIcon } from "@icons/_withIcon";

// Changelog: name was CheckMarkFlat
export const Checkmark = withIcon(
  () => (
    <>
      <path d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.4978 6.01154 14.707 4.10515 13.3009 2.6991C11.8949 1.29304 9.98846 0.50217 8 0.5ZM8 14.5C6.71442 14.5 5.45772 14.1188 4.3888 13.4046C3.31988 12.6903 2.48676 11.6752 1.99479 10.4874C1.50282 9.29972 1.3741 7.99279 1.6249 6.73191C1.8757 5.47104 2.49477 4.31285 3.40381 3.40381C4.31285 2.49476 5.47104 1.8757 6.73192 1.6249C7.99279 1.37409 9.29973 1.50281 10.4874 1.99478C11.6752 2.48675 12.6903 3.31987 13.4046 4.38879C14.1188 5.45771 14.5 6.71442 14.5 8C14.498 9.7233 13.8126 11.3754 12.594 12.594C11.3754 13.8125 9.7233 14.498 8 14.5Z" />
      <path d="M10.7559 5.64654L7.10947 9.29306L5.52841 7.71197C5.48216 7.66478 5.42701 7.62722 5.36615 7.60147C5.3053 7.57572 5.23994 7.56229 5.17386 7.56196C5.10778 7.56162 5.04229 7.57439 4.98118 7.59953C4.92007 7.62466 4.86454 7.66166 4.81782 7.70838C4.77109 7.75511 4.73409 7.81063 4.70896 7.87174C4.68383 7.93286 4.67106 7.99835 4.67139 8.06443C4.67173 8.1305 4.68516 8.19586 4.71091 8.25672C4.73665 8.31757 4.77421 8.37272 4.82141 8.41897L6.75598 10.3535C6.80239 10.4 6.8575 10.4368 6.91816 10.4619C6.97881 10.4871 7.04382 10.5 7.10948 10.5C7.17513 10.5 7.24014 10.4871 7.3008 10.4619C7.36145 10.4368 7.41656 10.4 7.46298 10.3535L11.463 6.35354C11.5552 6.25946 11.6065 6.13281 11.6058 6.00109C11.6052 5.86937 11.5526 5.74323 11.4594 5.65009C11.3663 5.55695 11.2401 5.50433 11.1084 5.50367C10.9767 5.503 10.8501 5.55435 10.756 5.64654H10.7559Z" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);

// Change log: previous name: `CheckMark, TripPlanning: FeedbackCheckmark`
export const CheckmarkFill = withIcon(
  ({ accent }) => (
    <>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        rx="32"
        fill="currentColor"
      />
      <path
        d="M46.1671 22.5L26.6115 42.0556L17.7227 33.1667"
        stroke={accent}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  {
    viewBox: "0 0 65 65",
    fill: "none",
  }
);
CheckmarkFill.defaultProps = {
  ...CheckmarkFill.defaultProps,
  accent: "#fff",
};
export const CheckmarkFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M17.7818 3.23389C16.1237 2.13801 14.1363 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 11.4907 22.4637 10.9899 22.3937 10.5"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="transparent"
      />
      <path
        d="M7.5 10.5L12 15L22.5 4.5"
        fill="transparent"
        stroke={accent}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  {
    viewBox: "0 0 24 24",
  }
);
