import { withIcon } from "@icons/_withIcon";

export const TrophyFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M32.3996 44.4H15.5996C15.5996 41.0863 18.2859 38.4 21.5996 38.4H26.3996C29.7133 38.4 32.3996 41.0863 32.3996 44.4Z"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 38.4L24 32.4"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M12 3.59998H36V20.4C36 27.0274 30.6274 32.4 24 32.4V32.4C17.3726 32.4 12 27.0274 12 20.4V3.59998Z"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinejoin="round"
      />
      <path
        d="M36 7.19995H43.2V15.6C43.2 19.5764 39.9764 22.8 36 22.8V22.8"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.19995H4.8V15.6C4.8 19.5764 8.02355 22.8 12 22.8V22.8"
        stroke={primary}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 4.80005H18V21.6L24 20.4L30 21.6V4.80005ZM21.6 10.2206V8.40005H25.2V16.8H23.1133V10.2206H21.6Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 48 48",
    fill: "none",
  }
);

export const TrophyFill = withIcon(
  () => (
    <>
      <path d="M4.875 0.5C4.39175 0.5 4 0.891751 4 1.375V7C4 7.08396 4.00259 7.16732 4.00769 7.25C4.00793 7.25391 4.00817 7.25782 4.00843 7.26173C4.04272 7.79271 4.18062 8.29547 4.40212 8.75C4.94605 9.8662 5.99411 10.6916 7.25 10.9298V13.5H6C5.17157 13.5 4.5 14.1716 4.5 15C4.5 15.2761 4.72386 15.5 5 15.5H11C11.2761 15.5 11.5 15.2761 11.5 15C11.5 14.1716 10.8284 13.5 10 13.5H8.75V10.9298C10.0223 10.6885 11.0813 9.84451 11.619 8.70606C11.8297 8.25978 11.9604 7.76824 11.9923 7.25C11.9974 7.16732 12 7.08396 12 7V1.375C12 0.891751 11.6082 0.5 11.125 0.5H4.875Z" />
      <path d="M3 1.25H1C0.585786 1.25 0.25 1.58579 0.25 2V5C0.25 6.82843 1.55858 8.35122 3.29029 8.68293C3.10407 8.16184 3.00187 7.6008 3.00003 7.01613C2.25915 6.64796 1.75 5.88344 1.75 5V2.75H3V1.25Z" />
      <path d="M12.7097 8.68293C14.4414 8.35122 15.75 6.82843 15.75 5V2C15.75 1.58579 15.4142 1.25 15 1.25H13V2.75H14.25V5C14.25 5.88344 13.7408 6.64796 13 7.01613C12.9981 7.6008 12.8959 8.16184 12.7097 8.68293Z" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);
