import { withIcon } from "@icons/_withIcon";

export const Swap = withIcon(
  () => (
    <>
      <path d="M5.40742 7.95057L2.70742 5.40057L16.1699 5.40057C16.3688 5.40057 16.5596 5.32155 16.7003 5.1809C16.8409 5.04024 16.9199 4.84948 16.9199 4.65057C16.9199 4.45165 16.8409 4.26089 16.7003 4.12024C16.5596 3.97958 16.3688 3.90057 16.1699 3.90057L2.71492 3.90057L5.40742 1.35057C5.55183 1.21371 5.63596 1.0251 5.64131 0.826218C5.64665 0.627336 5.57277 0.434476 5.43592 0.290066C5.29907 0.145655 5.11046 0.0615235 4.91157 0.0561787C4.71269 0.0508339 4.51983 0.124714 4.37542 0.261565L0.314922 4.10157L0.314922 4.11057C0.253694 4.17012 0.202978 4.2396 0.164922 4.31607C0.164922 4.32957 0.146922 4.33856 0.140922 4.35206C0.101104 4.44523 0.0805757 4.5455 0.0805757 4.64682C0.0805757 4.74813 0.101104 4.8484 0.140922 4.94157C0.140922 4.95507 0.158922 4.96407 0.164922 4.97757C0.202978 5.05403 0.253694 5.12351 0.314922 5.18307L0.314922 5.19206L4.37542 9.03207C4.44693 9.09983 4.53108 9.15284 4.62307 9.18808C4.71506 9.22332 4.8131 9.2401 4.91157 9.23745C5.01005 9.23481 5.10704 9.21279 5.19701 9.17266C5.28698 9.13253 5.36816 9.07507 5.43592 9.00357C5.50368 8.93206 5.5567 8.84791 5.59194 8.75592C5.62718 8.66392 5.64395 8.56589 5.64131 8.46741C5.63866 8.36894 5.61665 8.27195 5.57651 8.18198C5.53638 8.09201 5.47893 8.01083 5.40742 7.94307L5.40742 7.95057Z" />
      <path d="M19.6855 14.891L19.6855 14.882C19.7569 14.8122 19.8137 14.7288 19.8524 14.6367C19.8912 14.5446 19.9111 14.4457 19.9111 14.3458C19.9111 14.2459 19.8912 14.147 19.8524 14.0549C19.8137 13.9628 19.7569 13.8794 19.6855 13.8095L19.6855 13.8005L15.625 9.96054C15.4806 9.82369 15.2877 9.74981 15.0888 9.75515C14.8899 9.7605 14.7013 9.84463 14.5645 9.98904C14.4276 10.1335 14.3537 10.3263 14.3591 10.5252C14.3644 10.7241 14.4486 10.9127 14.593 11.0495L17.2855 13.5995L3.83048 13.5995C3.63156 13.5995 3.4408 13.6786 3.30015 13.8192C3.15949 13.9599 3.08048 14.1506 3.08048 14.3495C3.08048 14.5485 3.15949 14.7392 3.30015 14.8799C3.4408 15.0205 3.63156 15.0995 3.83048 15.0995L17.2855 15.0995L14.5855 17.6495C14.4411 17.7864 14.3569 17.975 14.3516 18.1739C14.3462 18.3728 14.4201 18.5656 14.557 18.71C14.6938 18.8545 14.8824 18.9386 15.0813 18.9439C15.2802 18.9493 15.4731 18.8754 15.6175 18.7385L19.6855 14.891Z" />
    </>
  ),
  {
    viewBox: "0 0 20 19",
  }
);
