import { withIcon } from "@icons/_withIcon";

// Change log: previous name: `TripPlanning: Pagination`
export const Indicator = withIcon(
  ({ primary }) => (
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="3"
      rx="1.5"
      fill="currentColor"
      stroke={primary}
    />
  ),
  {
    viewBox: "0 0 18 4",
  }
);
