import { withIcon } from "@icons/_withIcon";

export const Edit = withIcon(
  ({ primary }) => (
    <>
      <path
        d="M13.5 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V10.5"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M11.25 15C11.4489 15 11.6397 14.921 11.7803 14.7803L20.7803 5.78033C21.4874 5.07322 21.4874 3.92678 20.7803 3.21967C20.0732 2.51256 18.9268 2.51256 18.2197 3.21967L9.21967 12.2197C9.07902 12.3603 9 12.5511 9 12.75V14.25C9 14.6642 9.33579 15 9.75 15H11.25Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  ),
  {
    viewBox: "0 0 24 24",
  }
);
