import { withIcon } from "@icons/_withIcon";

export const Loading = withIcon(
  () => (
    <g transform="translate(0, 380) scale(0.1, -0.1)">
      <path d="M1975 3488 c-48 -27 -44 -45 36 -188 32 -59 59 -110 59 -113 0 -4 -43 -7 -95 -7 l-94 0 -47 50 c-37 40 -53 50 -73 48 -38 -4 -38 -39 -2 -98 17 -28 31 -55 31 -61 0 -6 -13 -29 -29 -52 -36 -53 -40 -68 -29 -89 16 -32 51 -21 103 32 l49 50 93 0 c51 0 93 -3 93 -7 0 -3 -27 -54 -59 -113 -81 -145 -84 -162 -33 -189 34 -18 53 -2 193 164 l123 145 68 0 c60 0 72 3 93 25 31 30 31 40 0 70 -21 22 -33 25 -93 25 l-69 0 -117 140 c-108 129 -159 180 -175 180 -3 0 -15 -6 -26 -12z" />
      <path d="M1612 3105 c-217 -74 -422 -212 -560 -377 -193 -231 -282 -431 -319 -721 -14 -109 -15 -146 -5 -250 28 -296 136 -551 322 -763 l74 -84 28 27 28 26 -48 51 c-108 115 -221 304 -271 455 -65 194 -79 493 -32 675 85 321 262 573 532 752 83 55 259 144 287 144 9 0 52 66 52 80 0 14 -5 13 -88 -15z" />
      <path d="M1281 830 l-21 -30 41 -29 c22 -17 85 -53 140 -81 l99 -51 14 33 14 33 -88 46 c-48 25 -107 60 -132 77 l-45 31 -22 -29z" />
      <path d="M2700 808 c-25 -17 -82 -48 -127 -70 -46 -21 -83 -40 -83 -42 0 -2 6 -18 13 -35 l13 -31 84 41 c46 22 110 56 142 76 l58 36 -21 28 c-11 16 -23 29 -27 28 -4 0 -27 -14 -52 -31z" />
      <path d="M1875 612 c-3 -8 -5 -24 -5 -36 0 -22 5 -23 90 -29 50 -3 122 -3 161 0 l70 6 -7 31 c-3 17 -7 32 -8 33 0 1 -67 3 -148 5 -116 2 -149 0 -153 -10z" />
    </g>
  ),
  {
    viewBox: "0 0 424 380",
  }
);
