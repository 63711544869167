import { withIcon } from "@icons/_withIcon";

export const WebsiteFill = withIcon(
  () => {
    return (
      <path d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.4979 6.01153 14.707 4.1051 13.301 2.69904C11.8949 1.29297 9.98848 0.502118 8 0.5V0.5ZM14.475 7.5H11.588C11.6084 5.51364 11.0871 3.55927 10.08 1.847C11.2849 2.25654 12.3434 3.01026 13.1245 4.01497C13.9057 5.01967 14.3752 6.23129 14.475 7.5V7.5ZM8 14.5C6.825 14.5 5.525 12.059 5.411 8.5H10.589C10.475 12.059 9.175 14.5 8 14.5ZM5.411 7.5C5.525 3.941 6.825 1.5 8 1.5C9.175 1.5 10.475 3.941 10.589 7.5H5.411ZM5.92 1.847C4.91293 3.55927 4.39158 5.51364 4.412 7.5H1.525C1.62484 6.23129 2.09435 5.01967 2.87547 4.01497C3.6566 3.01026 4.71507 2.25654 5.92 1.847V1.847ZM1.525 8.5H4.412C4.39158 10.4864 4.91293 12.4407 5.92 14.153C4.71507 13.7435 3.6566 12.9897 2.87547 11.985C2.09435 10.9803 1.62484 9.76871 1.525 8.5V8.5ZM10.08 14.153C11.0871 12.4407 11.6084 10.4864 11.588 8.5H14.475C14.3752 9.76871 13.9057 10.9803 13.1245 11.985C12.3434 12.9897 11.2849 13.7435 10.08 14.153V14.153Z" />
    );
  },
  {
    viewBox: "0 0 16 16",
  }
);
