import { withIcon } from "@icons/_withIcon";

export const ToursFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M26.5049 51.5819C28.2322 51.562 29.8996 51.562 31.4972 51.5819"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M38.5979 52.0063C47.359 52.8721 52.2095 55.117 51.9638 59.4603C52.3087 67.9218 6.51552 62.8693 6.68799 72.7052C6.83458 81.1373 30.9966 83.5731 49.0707 83.6758"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M54.8682 83.7859C56.6205 83.7659 58.2929 83.7259 59.8605 83.676"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.405 25.0829C21.3984 25.0829 22.3669 24.7884 23.1957 24.2342C24.0194 23.6801 24.6634 22.8963 25.0428 21.9827C25.4222 21.0641 25.5221 20.0557 25.3274 19.0822C25.1327 18.1087 24.6534 17.2151 23.9545 16.5111C23.2506 15.8072 22.357 15.333 21.3835 15.1383C20.41 14.9436 19.4015 15.0434 18.4829 15.4278C17.5643 15.8072 16.7805 16.4512 16.2314 17.28C15.6822 18.1037 15.3877 19.0772 15.3877 20.0706C15.3877 21.4036 15.9169 22.6766 16.8604 23.6152C17.804 24.5537 19.077 25.0829 20.41 25.0879L20.405 25.0829Z"
        fill={accent}
      />
      <path
        d="M19.1267 49.9095C19.2815 50.0992 19.4762 50.254 19.7008 50.3588C19.9205 50.4637 20.1651 50.5186 20.4097 50.5186C20.6543 50.5186 20.899 50.4637 21.1186 50.3588C21.3383 50.254 21.538 50.0992 21.6927 49.9095L35.6462 32.8509C38.0126 29.9653 39.5053 26.4657 39.9596 22.7615C40.4139 19.0572 39.8048 15.303 38.2073 11.9282C36.6097 8.55343 34.0886 5.70783 30.9335 3.71092C27.7784 1.714 24.124 0.65564 20.3947 0.65564C16.6655 0.65564 13.0061 1.714 9.85598 3.71092C6.70085 5.70783 4.17974 8.55842 2.5822 11.9282C0.984661 15.303 0.3756 19.0572 0.8299 22.7615C1.2842 26.4657 2.78189 29.9653 5.14325 32.8509L19.1217 49.9095H19.1267ZM20.4097 10.0761C22.3867 10.0761 24.3187 10.6602 25.9612 11.7585C27.6036 12.8568 28.8817 14.4144 29.6405 16.2415C30.3943 18.0687 30.594 20.0756 30.2096 22.0126C29.8252 23.9496 28.8717 25.7319 27.4738 27.1247C26.076 28.5226 24.2987 29.4711 22.3617 29.8605C20.4247 30.2449 18.4178 30.0502 16.5906 29.2914C14.7634 28.5375 13.2058 27.2545 12.1075 25.6121C11.0092 23.9696 10.4251 22.0376 10.4251 20.0606C10.4251 17.4147 11.4785 14.8737 13.3556 13.0016C15.2327 11.1295 17.7688 10.0761 20.4147 10.0711L20.4097 10.0761Z"
        fill={primary}
      />
      <path
        d="M96.218 30.4696C89.9577 26.341 81.306 23.7999 71.7558 23.7999V19.3518H67.3076V77.1575C68.0415 76.9228 68.8053 76.803 69.5791 76.803C70.0684 76.803 70.5626 76.8529 71.0419 76.9478C71.2815 76.9977 71.5211 77.0576 71.7558 77.1275V59.3849C78.5054 51.9614 87.3567 48.2521 96.218 48.2671C105.079 48.2771 113.931 44.5678 120.68 37.1443C111.13 37.1443 102.473 34.5982 96.218 30.4746V30.4696ZM96.253 43.814C96.223 43.814 96.2031 43.814 96.1831 43.814C87.172 43.814 78.6551 47.1239 71.7558 53.1845V28.2481C80.1428 28.2481 87.9658 30.3548 93.7718 34.1839C98.2399 37.1293 103.587 39.261 109.403 40.4592C105.354 42.6558 100.881 43.814 96.248 43.814H96.253Z"
        fill="black"
      />
      <path
        d="M69.7643 88.6945C70.7578 88.6945 71.7263 88.4 72.555 87.8458C73.3788 87.2917 74.0228 86.5079 74.4022 85.5943C74.7816 84.6757 74.8814 83.6673 74.6867 82.6938C74.492 81.7203 74.0128 80.8267 73.3139 80.1228C72.6099 79.4189 71.7163 78.9446 70.7428 78.7499C69.7693 78.5552 68.7609 78.6551 67.8423 79.0395C66.9237 79.4189 66.1399 80.0629 65.5908 80.8916C65.0416 81.7153 64.7471 82.6888 64.7471 83.6823C64.7471 85.0152 65.2763 86.2883 66.2198 87.2268C67.1633 88.1654 68.4364 88.6945 69.7693 88.6995L69.7643 88.6945Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 121 89",
    fill: "none",
  }
);
