import { withIcon } from "@icons/_withIcon";

export const PlanningTipsFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M30.8104 6.90135L29.3962 8.31561L32.5741 11.4932L33.9882 10.079L30.8104 6.90135Z"
        fill={accent}
      />
      <path
        d="M31.632 19.5008C31.3668 19.5008 31.1124 19.6061 30.9249 19.7936C30.7374 19.9812 30.632 20.2355 30.632 20.5008C30.632 25.2008 25.863 29.0168 20 29.0168C14.137 29.0168 9.368 25.2008 9.368 20.5008C9.368 15.8008 14.137 11.9848 20 11.9848C20.2652 11.9848 20.5196 11.8794 20.7071 11.6919C20.8946 11.5043 21 11.25 21 10.9848C21 10.7195 20.8946 10.4652 20.7071 10.2776C20.5196 10.0901 20.2652 9.98476 20 9.98476C13.035 9.98476 7.368 14.7008 7.368 20.5008C7.368 26.3008 13.035 31.0168 20 31.0168C26.965 31.0168 32.632 26.3008 32.632 20.5008C32.632 20.2355 32.5266 19.9812 32.3391 19.7936C32.1516 19.6061 31.8972 19.5008 31.632 19.5008Z"
        fill={accent}
      />
      <path
        d="M37 14.9008C36.7348 14.9008 36.4804 15.0061 36.2929 15.1937C36.1054 15.3812 36 15.6355 36 15.9008V31.9008C36 32.6964 35.6839 33.4595 35.1213 34.0221C34.5587 34.5847 33.7956 34.9008 33 34.9008H7C6.20435 34.9008 5.44129 34.5847 4.87868 34.0221C4.31607 33.4595 4 32.6964 4 31.9008V9.90076C4 9.10511 4.31607 8.34205 4.87868 7.77944C5.44129 7.21683 6.20435 6.90076 7 6.90076H24C24.2652 6.90076 24.5196 6.7954 24.7071 6.60786C24.8946 6.42033 25 6.16597 25 5.90076C25 5.63554 24.8946 5.38119 24.7071 5.19365C24.5196 5.00611 24.2652 4.90076 24 4.90076H7C5.6744 4.90234 4.40356 5.42964 3.46622 6.36698C2.52888 7.30431 2.00159 8.57516 2 9.90076V31.9008C2.00159 33.2264 2.52888 34.4972 3.46622 35.4345C4.40356 36.3719 5.6744 36.8992 7 36.9008H33C34.3256 36.8992 35.5964 36.3719 36.5338 35.4345C37.4711 34.4972 37.9984 33.2264 38 31.9008V15.9008C38 15.6355 37.8946 15.3812 37.7071 15.1937C37.5196 15.0061 37.2652 14.9008 37 14.9008Z"
        fill={primary}
      />
      <path
        d="M34.077 2.56475C33.5195 2.56347 32.9674 2.67276 32.4524 2.88629C31.9375 3.09982 31.47 3.41336 31.077 3.80875L28.689 6.19375L19.228 15.6548C19.1333 15.7494 19.0586 15.862 19.0083 15.986C18.958 16.1101 18.933 16.2429 18.935 16.3768L19 20.9158C19.0039 21.1763 19.1093 21.425 19.2939 21.609C19.4784 21.793 19.7274 21.8977 19.988 21.9008L24.516 21.9538H24.527C24.7922 21.9537 25.0465 21.8483 25.234 21.6608L34.7 12.2008L37.08 9.81475C37.6737 9.22098 38.0781 8.46453 38.2421 7.64099C38.406 6.81746 38.3222 5.9638 38.0011 5.18791C37.6801 4.41201 37.1362 3.7487 36.4383 3.2818C35.7404 2.81491 34.9197 2.56538 34.08 2.56475H34.077ZM35.666 8.40075L33.988 10.0788L32.574 11.4928L24.118 19.9488L20.986 19.9128L20.941 16.7698L29.4 8.31475L30.81 6.90075L32.488 5.22275C32.9159 4.81381 33.4851 4.58561 34.077 4.58561C34.6689 4.58561 35.2381 4.81381 35.666 5.22275C36.0872 5.64429 36.3238 6.21583 36.3238 6.81175C36.3238 7.40768 36.0872 7.97922 35.666 8.40075Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
