import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { SanitizeHTML } from "@components/sanitizeHtml";
import { CloseX } from "@icons";

import styles from "./styles.module.css";

export function AlertBanner({
  alert = null,
  closable = true,
  onClose = () => {},
}) {
  const [visible, setVisible] = useState(alert);

  const closeBanner = () => {
    setVisible(false);
    onClose();
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setVisible(alert);
  }, [alert]);

  return !alert ? null : (
    <div
      data-testid="alert-banner"
      className={cn("bg-black", { hidden: !visible })}
    >
      <div className="container flex items-start py-2 lg:justify-between lg:py-4">
        <SanitizeHTML
          elem="p"
          html={alert}
          className={cn(
            styles["alert-text"],
            "flex-1 pr-4 lg:pr-0 text-sm text-black-200 tracking-wide lg:text-center"
          )}
        />
        {closable && (
          <button
            type="button"
            title="Close alert"
            tabIndex="0"
            onClick={closeBanner}
            data-testid="close-btn"
            aria-label="Dismiss banner"
          >
            <CloseX className="text-lg text-white" />
          </button>
        )}
      </div>
    </div>
  );
}

AlertBanner.propTypes = {
  alert: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};
