import { withIcon } from "@icons/_withIcon";

export const DashedLine = withIcon(
  () => (
    <line
      x1="0"
      y1="10"
      x2="100"
      y2="10"
      stroke="currentColor"
      strokeWidth="2"
      strokeDasharray="10 4"
      vectorEffect="non-scaling-stroke"
    />
  ),
  {
    viewBox: "0 0 100 20",
  }
);
