import { withIcon } from "@icons/_withIcon";

export const DrinkMenu = withIcon(
  () => (
    <>
      <path d="M62.2384 17.4272H54.3668V1.75887C54.3668 1.186 54.095 0.653342 53.632 0.331734C53.1689 7.53449e-05 52.575 -0.0803267 52.0415 0.100578L1.18779 17.5177C1.18779 17.5177 1.14753 17.5378 1.1274 17.5478C1.07707 17.5679 1.0368 17.588 0.99654 17.6081C0.936143 17.6383 0.885813 17.6684 0.835483 17.6986C0.795219 17.7187 0.76502 17.7488 0.724756 17.779C0.674426 17.8192 0.624096 17.8594 0.573765 17.8996C0.543567 17.9297 0.513369 17.9599 0.483171 17.99C0.442907 18.0403 0.402642 18.0905 0.362378 18.1408C0.33218 18.1709 0.312048 18.2111 0.28185 18.2513C0.251651 18.3016 0.221453 18.3619 0.191255 18.4222C0.171123 18.4624 0.150991 18.5026 0.130859 18.5428C0.100661 18.6131 0.0805285 18.6935 0.0603964 18.7639C0.0603964 18.794 0.0402642 18.8242 0.0301982 18.8543C0.0100661 18.9649 0 19.0755 0 19.186C0 19.186 0 19.186 0 19.1961V92.2513C0 93.2262 0.785153 94.0101 1.76156 94.0101H62.2384C63.2149 94.0101 64 93.2262 64 92.2513V19.186C64 18.2111 63.2149 17.4272 62.2384 17.4272ZM50.8437 4.22118V17.4272H12.3209L50.8437 4.22118ZM60.4769 90.4825H3.52312V20.9448H60.4769V90.4825Z" />
      <path d="M30.2385 50.3015V63.7789H25.9201C24.9437 63.7789 24.1586 64.5628 24.1586 65.5377C24.1586 66.5125 24.9437 67.2965 25.9201 67.2965H38.0799C39.0564 67.2965 39.8415 66.5125 39.8415 65.5377C39.8415 64.5628 39.0564 63.7789 38.0799 63.7789H33.7616V50.3015L45.8308 37.6784C46.314 37.1658 46.4549 36.4221 46.1731 35.7688C45.8912 35.1256 45.257 34.7035 44.5524 34.7035H19.4275C18.7229 34.7035 18.0888 35.1256 17.8069 35.7688C17.5251 36.412 17.666 37.1658 18.1491 37.6784L30.2184 50.3015H30.2385ZM40.4455 38.2211L32 47.0553L23.5546 38.2211H40.4455Z" />
      <path d="M47.9648 72.4121H16.0352C15.0588 72.4121 14.2737 73.196 14.2737 74.1709V82.0101C14.2737 82.985 15.0588 83.7689 16.0352 83.7689H47.9547C48.9311 83.7689 49.7163 82.985 49.7163 82.0101V74.1709C49.7163 73.196 48.9311 72.4121 47.9547 72.4121H47.9648ZM46.2032 80.2513H17.8069V75.9297H46.2032V80.2513Z" />
    </>
  ),
  {
    viewBox: "0 0 64 94",
  }
);
export const DrinkMenuFill = withIcon(
  () => (
    <>
      <path d="M17.2128 0L-0.000732422 4.59364H17.2128V0Z" />
      <path d="M14.2452 24.9364H4.76978V26.4381H14.2452V24.9364Z" />
      <path d="M12.3215 11.8229H6.68726L9.50437 14.8978L12.3215 11.8229Z" />
      <path d="M-0.000671387 30H18.9993V5.81616H-0.000671387V30ZM4.76323 10.9717C4.85812 10.7469 5.06752 10.6005 5.3031 10.6005H13.6857C13.9213 10.6005 14.134 10.7469 14.2256 10.9717C14.3205 11.1998 14.2714 11.4586 14.1111 11.6357L10.0834 16.025V20.7106H11.523C11.8502 20.7106 12.112 20.983 12.112 21.3235C12.112 21.6641 11.8502 21.9365 11.523 21.9365H7.46583C7.13864 21.9365 6.87689 21.6641 6.87689 21.3235C6.87689 20.983 7.13864 20.7106 7.46583 20.7106H8.90548V16.025H8.89893L4.87121 11.6357C4.71088 11.4586 4.66181 11.1964 4.75669 10.9717H4.76323ZM3.58535 24.3269C3.58535 23.9864 3.8471 23.714 4.17429 23.714H14.8244C15.1516 23.714 15.4133 23.9864 15.4133 24.3269V27.0545C15.4133 27.395 15.1516 27.6675 14.8244 27.6675H4.17429C3.8471 27.6675 3.58535 27.395 3.58535 27.0545V24.3269Z" />
    </>
  ),
  {
    viewBox: "0 0 19 30",
  }
);
