import { withIcon } from "@icons/_withIcon";

export const SustainabilityFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M7.02414 30.1666L5.18858 32.0024L9.44135 36.2547L11.2769 34.4189L7.02414 30.1666Z"
        fill={accent}
      />
      <path
        d="M21.414 13.1932H24.077C25.7537 13.1905 27.3609 12.5231 28.5462 11.3372C29.7315 10.1513 30.3982 8.54382 30.4 6.86715V4.20415L21.412 13.1922L21.414 13.1932Z"
        fill={accent}
      />
      <path
        d="M16.751 13.1921L19.414 13.1921L10.425 4.20415L10.425 6.86615C10.4269 8.54334 11.0939 10.1513 12.2799 11.3373C13.4658 12.5232 15.0738 13.1903 16.751 13.1921Z"
        fill={accent}
      />
      <path
        d="M31.969 19.6222L27.29 24.2942C27.133 23.5176 26.7124 22.8192 26.0996 22.3172C25.4867 21.8151 24.7192 21.5403 23.927 21.5392H14.162C13.3046 21.5417 12.4828 21.8824 11.875 22.4872L3.06599 31.2952C2.87852 31.4827 2.77321 31.737 2.77321 32.0022C2.77321 32.2673 2.87852 32.5216 3.06599 32.7092L8.733 38.3752C8.92052 38.5626 9.17483 38.6679 9.43999 38.6679C9.70516 38.6679 9.95947 38.5626 10.147 38.3752L13.814 34.7082C14.1049 34.4159 14.4509 34.1841 14.832 34.0264C15.213 33.8686 15.6216 33.788 16.034 33.7892H25.743C26.7315 33.7864 27.679 33.3941 28.38 32.6972L36.709 24.3672C37.3273 23.7363 37.6717 22.887 37.6676 22.0037C37.6634 21.1204 37.311 20.2744 36.6867 19.6494C36.0625 19.0244 35.217 18.6708 34.3337 18.6656C33.4504 18.6603 32.6006 19.0037 31.969 19.6212V19.6222ZM35.3 22.9542L26.971 31.2832C26.6458 31.6062 26.2064 31.7881 25.748 31.7892H16.034C15.3588 31.787 14.6899 31.9189 14.0661 32.1772C13.4423 32.4356 12.8759 32.8152 12.4 33.2942L11.276 34.4182L9.43999 36.2542L5.188 32.0022L7.02299 30.1672L13.289 23.9012C13.521 23.6703 13.8347 23.5402 14.162 23.5392H23.927C24.3001 23.549 24.6546 23.7042 24.915 23.9715C25.1754 24.2389 25.3212 24.5974 25.3212 24.9707C25.3212 25.3439 25.1754 25.7024 24.915 25.9698C24.6546 26.2371 24.3001 26.3923 23.927 26.4022H16.736C16.4708 26.4022 16.2164 26.5075 16.0289 26.695C15.8414 26.8826 15.736 27.1369 15.736 27.4022C15.736 27.6674 15.8414 27.9217 16.0289 28.1093C16.2164 28.2968 16.4708 28.4022 16.736 28.4022H24.513C24.9866 28.4037 25.4558 28.3113 25.8935 28.1304C26.3312 27.9495 26.7287 27.6836 27.063 27.3482L33.382 21.0382C33.5078 20.9122 33.6572 20.8123 33.8216 20.7441C33.986 20.6759 34.1623 20.6407 34.3403 20.6406C34.5183 20.6405 34.6946 20.6755 34.8591 20.7435C35.0236 20.8116 35.1731 20.9113 35.299 21.0372C35.4249 21.163 35.5249 21.3123 35.5931 21.4768C35.6613 21.6412 35.6964 21.8174 35.6965 21.9954C35.6966 22.1735 35.6616 22.3497 35.5936 22.5142C35.5256 22.6787 35.4258 22.8282 35.3 22.9542Z"
        fill={primary}
      />
      <path
        d="M20.414 19.6222C20.6792 19.6222 20.9336 19.5168 21.1211 19.3293C21.3086 19.1417 21.414 18.8874 21.414 18.6222V15.1932H24.077C26.2839 15.1897 28.3993 14.3113 29.9595 12.7505C31.5197 11.1898 32.3974 9.07402 32.4 6.86716V3.20415C32.4 2.93894 32.2946 2.68458 32.1071 2.49705C31.9196 2.30951 31.6652 2.20415 31.4 2.20415H27.739C26.2373 2.20519 24.7637 2.61213 23.4743 3.38192C22.1849 4.15171 21.1275 5.25572 20.414 6.57716C19.7002 5.25578 18.6426 4.15185 17.353 3.38209C16.0635 2.61232 14.5898 2.20533 13.088 2.20415H9.425C9.15979 2.20415 8.90543 2.30951 8.7179 2.49705C8.53036 2.68458 8.425 2.93894 8.425 3.20415V6.86716C8.42765 9.07436 9.30556 11.1904 10.8662 12.7513C12.4268 14.3121 14.5428 15.1902 16.75 15.1932H19.414V18.6222C19.414 18.8874 19.5194 19.1417 19.7069 19.3293C19.8944 19.5168 20.1488 19.6222 20.414 19.6222ZM27.739 4.20415H30.4V6.86716C30.3982 8.54417 29.7312 10.152 28.5455 11.3379C27.3597 12.5238 25.752 13.191 24.075 13.1932H21.414V10.5302C21.4156 8.85306 22.0825 7.24509 23.2682 6.0591C24.454 4.87312 26.0619 4.20601 27.739 4.20415ZM16.75 13.1932C15.073 13.191 13.4653 12.5238 12.2795 11.3379C11.0938 10.152 10.4269 8.54417 10.425 6.86716V4.20415H13.088C14.7652 4.20601 16.3732 4.87309 17.5591 6.05904C18.7451 7.245 19.4122 8.85296 19.414 10.5302V13.1922H16.75V13.1932Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
