import { withIcon } from "@icons/_withIcon";

export const Bookmark = withIcon(
  () => (
    <path d="M12 0.625H2C1.50272 0.625 1.02581 0.822544 0.674175 1.17417C0.322544 1.52581 0.125 2.00272 0.125 2.5V18.75C0.125941 18.8607 0.15514 18.9693 0.20983 19.0656C0.264519 19.1618 0.342886 19.2425 0.4375 19.3C0.535537 19.3525 0.645032 19.38 0.75625 19.38C0.867468 19.38 0.976963 19.3525 1.075 19.3L7 15.725L12.925 19.2875C13.0228 19.3471 13.1355 19.3774 13.25 19.375C13.3585 19.3741 13.4654 19.3485 13.5625 19.3C13.6571 19.2425 13.7355 19.1618 13.7902 19.0656C13.8449 18.9693 13.8741 18.8607 13.875 18.75V2.5C13.875 2.00272 13.6775 1.52581 13.3258 1.17417C12.9742 0.822544 12.4973 0.625 12 0.625ZM12.625 17.65L7.325 14.4625C7.22619 14.4054 7.1141 14.3754 7 14.3754C6.8859 14.3754 6.77381 14.4054 6.675 14.4625L1.375 17.65V2.5C1.375 2.33424 1.44085 2.17527 1.55806 2.05806C1.67527 1.94085 1.83424 1.875 2 1.875H12C12.1658 1.875 12.3247 1.94085 12.4419 2.05806C12.5592 2.17527 12.625 2.33424 12.625 2.5V17.65Z" />
  ),
  {
    viewBox: "0 0 14 20",
  }
);

export const BookmarkFill = withIcon(
  () => (
    <path d="M10 0.5H2C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V15C0.500753 15.0886 0.524112 15.1755 0.567864 15.2525C0.611615 15.3295 0.674308 15.394 0.75 15.44C0.828429 15.482 0.916025 15.504 1.005 15.504C1.09397 15.504 1.18157 15.482 1.26 15.44L6 12.58L10.74 15.43C10.8182 15.4777 10.9084 15.502 11 15.5C11.0868 15.4993 11.1723 15.4788 11.25 15.44C11.3257 15.394 11.3884 15.3295 11.4321 15.2525C11.4759 15.1755 11.4992 15.0886 11.5 15V2C11.5 1.60218 11.342 1.22064 11.0607 0.93934C10.7794 0.658035 10.3978 0.5 10 0.5Z" />
  ),
  {
    viewBox: "0 0 12 16",
  }
);
