import { withIcon } from "@icons/_withIcon";

export const MapItinerary = withIcon(
  () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.48339 0.563511C6.62647 0.48357 6.79964 0.478824 6.94688 0.550808L13.2572 3.63589L18.7561 0.563511C18.911 0.476994 19.1001 0.478991 19.253 0.568758C19.406 0.658525 19.5 0.822624 19.5 1V13.8C19.5 13.9812 19.402 14.1481 19.2439 14.2365L13.5166 17.4365C13.3735 17.5164 13.2003 17.5212 13.0531 17.4492L6.74273 14.3641L1.24388 17.4365C1.08903 17.523 0.899942 17.521 0.746958 17.4312C0.593974 17.3415 0.5 17.1774 0.5 17V4.2C0.5 4.01885 0.59798 3.85187 0.75612 3.76351L6.48339 0.563511ZM7.22727 13.4879L12.7727 16.199V4.51211L7.22727 1.801V13.4879ZM6.22727 1.85212V13.5066L1.5 16.1479V4.49339L6.22727 1.85212ZM13.7727 4.49339V16.1479L18.5 13.5066V1.85212L13.7727 4.49339Z"
    />
  ),
  {
    viewBox: "0 0 20 18",
  }
);
