import { ArrowRight, ArrowRightFill } from "./arrowRight";

export function ArrowLeft({ className, ...props }) {
  return (
    <ArrowRight className={`${className} transform rotate-180`} {...props} />
  );
}
export function ArrowLeftFill({ className, ...props }) {
  return (
    <ArrowRightFill
      className={`${className} transform rotate-180`}
      {...props}
    />
  );
}
