import { withIcon } from "@icons/_withIcon";

export const ArcheologyFeature = withIcon(
  ({ primary, accent }) => (
    <g clipPath="url(#clip0_1226_291)">
      <path
        d="M51.5226 61.8055L4.74473 76.5467C3.00135 77.0991 2.01746 78.9633 2.56982 80.7067C3.12218 82.4501 4.98639 83.434 6.72977 82.8816L51.5054 68.7791V61.8228L51.5226 61.8055Z"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.6067 25.9019C79.6067 12.9904 69.1464 2.53003 56.235 2.53003C43.3236 2.53003 32.8633 12.9904 32.8633 25.9019C32.8633 37.2426 40.9415 46.6845 51.6435 48.8249C51.9887 48.894 52.3339 48.963 52.6792 49.0148C52.7827 49.0148 52.9036 49.0493 53.0071 49.0493C53.6803 49.1356 54.3535 49.2047 55.0267 49.2392C55.4064 49.2565 55.8034 49.2737 56.1832 49.2737C56.1832 49.2737 56.2177 49.2737 56.235 49.2737C56.632 49.2737 57.0463 49.2737 57.4433 49.2392C58.1164 49.2047 58.7896 49.1356 59.4456 49.0493C59.5664 49.0493 59.6872 49.0148 59.7908 48.9975C60.136 48.9458 60.4812 48.8767 60.8265 48.8077C71.5457 46.6673 79.6067 37.2253 79.6067 25.8846V25.9019Z"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.0068 49.0493C53.68 49.1356 54.3532 49.2047 55.0264 49.2392C54.336 49.2047 53.6628 49.1356 53.0068 49.0493Z"
        fill="white"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6445 48.825C51.9898 48.894 52.335 48.963 52.6802 49.0148"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.4434 49.2393C58.1165 49.2048 58.7897 49.1357 59.4457 49.0494"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2364 49.2737C56.2364 49.2737 56.2018 49.2737 56.1846 49.2737C56.1846 49.2737 56.2191 49.2737 56.2364 49.2737Z"
        fill="white"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8267 48.825C60.4815 48.8941 60.1362 48.9631 59.791 49.0149"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.9481 78.6698V85.5744C60.9481 87.5767 59.3082 89.2165 57.3059 89.2165H55.1656C53.1633 89.2165 51.5234 87.5767 51.5234 85.5744V48.8077C51.558 48.825 51.6097 48.825 51.6443 48.825C51.9895 48.894 52.3347 48.9631 52.6799 49.0149C52.7835 49.0321 52.9043 49.0494 53.0079 49.0494C53.6638 49.1357 54.337 49.2047 55.0275 49.2393C55.4072 49.2738 55.8042 49.2738 56.184 49.2738C56.2012 49.2738 56.2357 49.2738 56.2357 49.2738C56.65 49.2738 57.047 49.2565 57.444 49.2393C58.1172 49.2047 58.7904 49.1357 59.4463 49.0494V49.0667C59.5499 49.0494 59.6707 49.0321 59.7916 49.0149C60.1368 48.9631 60.482 48.894 60.8272 48.825C60.8618 48.8077 60.9481 48.8077 60.9481 48.8077V78.6698ZM60.9481 78.6698C60.3957 79.274 58.9803 80.4995 56.4256 80.4995M56.4256 80.4995C53.0424 80.4995 51.7478 78.3419 51.7478 78.3419C51.7478 78.3419 53.0252 80.4995 56.4256 80.4995Z"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.2 45.6835L60.9473 58.8366V65.7929L102.944 52.0874C102.391 49.8952 101.805 47.7548 101.2 45.6662V45.6835Z"
        stroke={primary}
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.239 43.9573C118.686 42.2139 116.822 41.23 115.079 41.7824L113.301 42.8181C113.991 44.8894 114.578 47.0299 115.061 49.222L117.064 48.1173C118.807 47.565 119.791 45.7007 119.239 43.9573Z"
        stroke={primary}
        strokeWidth="2.72728"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.0239 7.44953C111.145 24.0031 122.261 53.9688 110.644 94.0496C108.279 54.245 97.1804 19.3081 81.0239 7.44953ZM81.0239 4.11809C79.9192 4.11809 78.8317 4.67045 78.2103 5.65435C77.2609 7.13882 77.6407 9.10662 79.0561 10.1423C93.849 20.9824 104.948 54.0033 107.33 94.2395C107.434 95.8621 108.676 97.1739 110.299 97.3638C110.42 97.3638 110.541 97.3811 110.679 97.3811C112.146 97.3811 113.475 96.4144 113.889 94.9645C125.696 54.176 114.614 22.0526 82.6637 4.5151C82.1631 4.23892 81.6108 4.10083 81.0584 4.10083L81.0239 4.11809Z"
        fill={primary}
      />
      <path
        d="M74.1355 25.643C74.1355 15.4934 65.9019 7.2597 55.7523 7.2597C45.6027 7.2597 37.3691 15.4934 37.3691 25.643C37.3691 35.7927 45.6027 44.0263 55.7523 44.0263C65.9019 44.0263 74.1355 35.7927 74.1355 25.643ZM67.2656 28.4739C66.3507 28.4739 65.5912 27.7316 65.5912 26.7995C65.5912 19.2391 60.551 13.094 54.3714 13.094C53.4566 13.094 52.6971 12.3518 52.6971 11.4197C52.6971 10.4876 53.4393 9.74534 54.3714 9.74534C62.3979 9.74534 68.9399 17.3921 68.9399 26.7823C68.9399 27.6971 68.1977 28.4566 67.2656 28.4566V28.4739Z"
        fill={accent}
      />
    </g>
  ),
  {
    viewBox: "0 0 121 98",
    fill: "none",
  }
);
export const ArcheologyFlat = withIcon(
  () => (
    <g clipPath="url(#clip0_1226_273)">
      <path
        d="M51.5226 61.8055L4.74473 76.5467C3.00135 77.0991 2.01746 78.9633 2.56982 80.7067C3.12218 82.4501 4.98639 83.434 6.72977 82.8816L51.5054 68.7791V61.8228L51.5226 61.8055Z"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.6067 25.9019C79.6067 12.9904 69.1464 2.53003 56.235 2.53003C43.3236 2.53003 32.8633 12.9904 32.8633 25.9019C32.8633 37.2426 40.9415 46.6845 51.6435 48.8249C51.9887 48.894 52.3339 48.963 52.6792 49.0148C52.7827 49.0148 52.9036 49.0493 53.0071 49.0493C53.6803 49.1356 54.3535 49.2047 55.0267 49.2392C55.4064 49.2565 55.8034 49.2737 56.1832 49.2737C56.1832 49.2737 56.2177 49.2737 56.235 49.2737C56.632 49.2737 57.0463 49.2737 57.4433 49.2392C58.1164 49.2047 58.7896 49.1356 59.4456 49.0493C59.5664 49.0493 59.6872 49.0148 59.7908 48.9975C60.136 48.9458 60.4812 48.8767 60.8265 48.8077C71.5457 46.6673 79.6067 37.2253 79.6067 25.8846V25.9019Z"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.0068 49.0493C53.68 49.1356 54.3532 49.2047 55.0264 49.2392C54.336 49.2047 53.6628 49.1356 53.0068 49.0493Z"
        fill="white"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6445 48.825C51.9898 48.894 52.335 48.963 52.6802 49.0148"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.4434 49.2393C58.1165 49.2048 58.7897 49.1357 59.4457 49.0494"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2364 49.2737C56.2364 49.2737 56.2018 49.2737 56.1846 49.2737C56.1846 49.2737 56.2191 49.2737 56.2364 49.2737Z"
        fill="white"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8267 48.825C60.4815 48.8941 60.1362 48.9631 59.791 49.0149"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.9481 78.6698V85.5744C60.9481 87.5767 59.3082 89.2165 57.3059 89.2165H55.1656C53.1633 89.2165 51.5234 87.5767 51.5234 85.5744V48.8077C51.558 48.825 51.6097 48.825 51.6443 48.825C51.9895 48.894 52.3347 48.9631 52.6799 49.0149C52.7835 49.0321 52.9043 49.0494 53.0079 49.0494C53.6638 49.1357 54.337 49.2047 55.0275 49.2393C55.4072 49.2738 55.8042 49.2738 56.184 49.2738C56.2012 49.2738 56.2357 49.2738 56.2357 49.2738C56.65 49.2738 57.047 49.2565 57.444 49.2393C58.1172 49.2047 58.7904 49.1357 59.4463 49.0494V49.0667C59.5499 49.0494 59.6707 49.0321 59.7916 49.0149C60.1368 48.9631 60.482 48.894 60.8272 48.825C60.8618 48.8077 60.9481 48.8077 60.9481 48.8077V78.6698ZM60.9481 78.6698C60.3957 79.274 58.9803 80.4995 56.4256 80.4995M56.4256 80.4995C53.0424 80.4995 51.7478 78.3419 51.7478 78.3419C51.7478 78.3419 53.0252 80.4995 56.4256 80.4995Z"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.2 45.6835L60.9473 58.8366V65.7929L102.944 52.0874C102.391 49.8952 101.805 47.7548 101.2 45.6662V45.6835Z"
        stroke="black"
        strokeWidth="3.33143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.239 43.9573C118.686 42.2139 116.822 41.23 115.079 41.7824L113.301 42.8181C113.991 44.8894 114.578 47.0299 115.061 49.222L117.064 48.1173C118.807 47.565 119.791 45.7007 119.239 43.9573Z"
        stroke="black"
        strokeWidth="2.72728"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.0229 7.44953C111.144 24.0031 122.26 53.9688 110.643 94.0496C108.278 54.245 97.1794 19.3081 81.0229 7.44953ZM81.0229 4.11809C79.9182 4.11809 78.8307 4.67045 78.2093 5.65435C77.26 7.13882 77.6397 9.10662 79.0551 10.1423C93.848 20.9824 104.947 54.0033 107.329 94.2395C107.433 95.8621 108.675 97.1739 110.298 97.3638C110.419 97.3638 110.54 97.3811 110.678 97.3811C112.145 97.3811 113.474 96.4144 113.888 94.9645C125.695 54.176 114.613 22.0526 82.6627 4.5151C82.1622 4.23892 81.6098 4.10083 81.0574 4.10083L81.0229 4.11809Z"
        fill="black"
      />
      <path
        d="M74.1355 25.643C74.1355 15.4934 65.9019 7.2597 55.7523 7.2597C45.6027 7.2597 37.3691 15.4934 37.3691 25.643C37.3691 35.7927 45.6027 44.0263 55.7523 44.0263C65.9019 44.0263 74.1355 35.7927 74.1355 25.643ZM67.2656 28.4739C66.3507 28.4739 65.5912 27.7316 65.5912 26.7995C65.5912 19.2391 60.551 13.094 54.3714 13.094C53.4566 13.094 52.6971 12.3518 52.6971 11.4197C52.6971 10.4876 53.4393 9.74534 54.3714 9.74534C62.3979 9.74534 68.9399 17.3921 68.9399 26.7823C68.9399 27.6971 68.1977 28.4566 67.2656 28.4566V28.4739Z"
        fill="black"
      />
    </g>
  ),
  {
    viewBox: "0 0 121 98",
    fill: "none",
  }
);
