import PropTypes from "prop-types";
import cn from "classnames";
import { tagular } from "@cohesion/tagular";

export function DestinationPanel({
  item = { submenu: { items: [] } },
  activeDestination,
  level3Data,
  handleLevel3Data,
}) {
  return (
    <>
      <div className="w-[230px] shrink-0 border-r border-black-200">
        {item.submenu?.items.map(({ title: itemTitle }) => (
          <button
            key={itemTitle}
            type="button"
            className={cn(
              "block w-full text-left my-2 py-1 pl-4 @7xl:pl-6 pr-4 text-black first:mt-8 last:mb-8 hover:text-blue hover:bg-black-100",
              activeDestination === itemTitle
                ? "bg-blue-100 text-blue font-semibold"
                : ""
            )}
            onClick={handleLevel3Data}
            data-title={itemTitle}
          >
            {itemTitle}
          </button>
        ))}
      </div>
      <div className="py-8 shrink w-[450px] xxl:w-[600px]">
        <div className="flex @5xl:flex-nowrap gap-x-4 @5xl:gap-x-6 gap-y-4 @5xl:gap-y-6">
          {level3Data &&
            level3Data.descendants?.map(({ countries, regions, cities }) => {
              const sections = [
                {
                  descendants: countries,
                  descendantTitle: "Countries",
                },
                {
                  descendants: regions,
                  descendantTitle:
                    level3Data.title === "North America" ? "States" : "Regions",
                },
                {
                  descendants: cities,
                  descendantTitle: "Cities",
                },
              ];

              return sections.map(({ descendants, descendantTitle }) => {
                if (Array.isArray(descendants) && descendants.length !== 0) {
                  return (
                    <div
                      key={descendantTitle}
                      className={cn(
                        descendants.length === 3 ? "w-1/3" : "w-1/2"
                      )}
                    >
                      <p className="type-sm border-b border-black-200 font-semibold pb-1 mb-2">
                        {descendantTitle}
                      </p>
                      {descendants.map(({ title: dTitle, url }) => (
                        <a
                          key={dTitle}
                          href={url}
                          className="block py-1 hover:text-blue"
                          onClick={() =>
                            tagular("click", {
                              actionOutcome: "INTERNALLINK",
                              outboundUrl: url,
                              webElement: {
                                location: "HEADER",
                                elementType: "LINK",
                                position: "GLOBAL SUB MENU",
                                text: dTitle,
                                name: descendantTitle,
                              },
                            })
                          }
                        >
                          {dTitle}
                        </a>
                      ))}
                    </div>
                  );
                }
                return null;
              });
            })}
        </div>
      </div>
    </>
  );
}

DestinationPanel.propTypes = {
  activeDestination: PropTypes.string,
  handleLevel3Data: PropTypes.func,
  item: PropTypes.shape({
    cta: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    featured: PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    submenu: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          descendants: PropTypes.arrayOf(
            PropTypes.shape({
              cities: PropTypes.arrayOf(
                PropTypes.shape({
                  title: PropTypes.string,
                  url: PropTypes.string,
                })
              ),
              countries: PropTypes.arrayOf(
                PropTypes.shape({
                  title: PropTypes.string,
                  url: PropTypes.string,
                })
              ),
              regions: PropTypes.arrayOf(
                PropTypes.shape({
                  title: PropTypes.string,
                  url: PropTypes.string,
                })
              ),
            })
          ),
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
  }),
  level3Data: PropTypes.shape({
    descendants: PropTypes.arrayOf(
      PropTypes.shape({
        cities: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
        countries: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
        regions: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      })
    ),
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};
