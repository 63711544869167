import React from "react";
import DOMPurify from "isomorphic-dompurify";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./styles.module.css";

const defaultOptions = {};

export function SanitizeHTML({
  className = "",
  withStyles = false,
  options = {},
  elem: Elem = "div",
  html,
  ...attributes
}) {
  const sanitized = DOMPurify.sanitize(html, {
    ...defaultOptions,
    ...options,
  });

  return (
    <Elem
      className={cn(withStyles && styles.dangerHTML, className)}
      dangerouslySetInnerHTML={{ __html: sanitized }}
      {...attributes}
    />
  );
}

SanitizeHTML.propTypes = {
  className: PropTypes.string,
  elem: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "p",
    "span",
    "div",
    "section",
    "article",
  ]),
  html: PropTypes.string.isRequired,
  withStyles: PropTypes.bool,
  options: PropTypes.shape({}),
};
