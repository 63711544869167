/**
 * Component for handling login actions & adding params to post-login / post-creation url
 *
 * @param {string} action - The intended login action (constants/auth/actions.js)
 * @param {string} params - Any params to be added to return url (handled in forward.page.js)
 */

import PropTypes from "prop-types";

import actions from "@constants/auth/actions";

export function Login({
  className = "",
  onClick = () => {},
  params = {},
  disabled = false,
  children,
  action,
}) {
  const base = "/api/auth/login";

  // consume and format optional parameters
  const parsedParams = new URLSearchParams({ ...params, action });

  // assemble url
  const url = `${base}?${parsedParams}`;

  return (
    <a
      href={disabled ? null : url}
      className={className}
      onClick={typeof onClick === "function" ? onClick : () => {}}
    >
      <span className="sr-only">Login</span>
      {children}
    </a>
  );
}

Login.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  action: PropTypes.oneOf(actions.map(({ name }) => name)).isRequired,
  onClick: PropTypes.func,
  params: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};
