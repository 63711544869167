import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { tagular } from "@cohesion/tagular";

import { TriangleDownFill } from "@icons";

const buildKey = s => s.replace("", "-").replace("'", "");

export function FooterDropdown({
  className = "",
  ghosted = false,
  id,
  onSelect,
  options,
  tagular: tagularPayload = {},
}) {
  const ref = useRef(null);
  const [value, setValue] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = i => {
    const val = options[i];

    setValue(val);
    onSelect(val);
    setIsOpen(false);

    if (tagular) {
      tagular("click", {
        actionOutcome: "FILTER",
        webElement: {
          elementType: "DROPDOWN",
        },
        ...tagularPayload,
      });
    }
  };

  const handleOutsideClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => document.removeEventListener("click", handleOutsideClick);
  }, [isOpen]);

  return (
    <div ref={ref} id={id} className={cn("relative h-auto", className)}>
      <div className="relative">
        <button
          className={cn(
            "relative flex justify-between items-center w-full rounded-lg px-4 py-2 md:px-5 md:py-4 text-left font-semibold focus:outline-none border z-10",
            ghosted && !isOpen ? "border-white" : "border-transparent",
            isOpen ? "border-transparent" : "border-black-200",
            {
              "shadow-lg bg-white rounded-tr-none rounded-tl-none border-transparent":
                isOpen,
            }
          )}
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          <p
            className={cn("leading-none", { "text-white": ghosted && !isOpen })}
          >
            {value.label}
          </p>
          <TriangleDownFill
            className={cn(
              "text-xs ml-4",
              ghosted && !isOpen ? "text-white" : "text-blue",
              { "text-blue": ghosted && isOpen },
              { "text-blue": !ghosted }
            )}
          />
        </button>
        <ul
          className={cn(
            "absolute bottom-full w-full bg-white border border-b-0 border-black-100 rounded-tr-lg rounded-tl-lg py-2 shadow-lg-inverted",
            { hidden: !isOpen }
          )}
        >
          {options.map((option, i) => {
            const { label } = option;

            if (options[i].label === value.label) return null;

            return (
              <li className="mx-1 md:mx-3" key={buildKey(label)}>
                <button
                  className="w-full p-3 my-2 text-left rounded hover:bg-black-100 hover:font-semibold"
                  onClick={() => handleSelect(i)}
                  type="button"
                >
                  {label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

FooterDropdown.propTypes = {
  className: PropTypes.string,
  ghosted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  tagular: PropTypes.shape({}),
};
