import { withIcon } from "@icons/_withIcon";

export const LinkFill = withIcon(
  () => (
    <>
      <path
        d="M9.00003 4.31664L11.3417 1.97496C11.6484 1.66601 12.0132 1.4208 12.4151 1.25347C12.817 1.08615 13.2481 1 13.6834 1C14.1187 1 14.5498 1.08615 14.9517 1.25347C15.3536 1.4208 15.7184 1.66601 16.0251 1.97496C16.334 2.28166 16.5792 2.64647 16.7466 3.04837C16.9139 3.45027 17 3.8813 17 4.31664C17 4.75198 16.9139 5.18301 16.7466 5.5849C16.5792 5.9868 16.334 6.35161 16.0251 6.65832L12.2731 10.4103C11.9664 10.7193 11.6015 10.9645 11.1997 11.1318C10.7978 11.2991 10.3667 11.3853 9.93138 11.3853C9.49604 11.3853 9.06501 11.2991 8.66311 11.1318C8.26122 10.9645 7.89641 10.7193 7.5897 10.4103C7.43147 10.2562 7.28886 10.0869 7.16394 9.90474"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9 13.6834L6.65832 16.0251C6.35161 16.334 5.9868 16.5792 5.5849 16.7466C5.18301 16.9139 4.75198 17 4.31664 17C3.8813 17 3.45027 16.9139 3.04837 16.7466C2.64647 16.5792 2.28166 16.334 1.97496 16.0251C1.66601 15.7184 1.4208 15.3536 1.25347 14.9517C1.08615 14.5498 1 14.1187 1 13.6834C1 13.2481 1.08615 12.817 1.25347 12.4151C1.4208 12.0132 1.66601 11.6484 1.97496 11.3417L5.72697 7.5897C6.03368 7.28075 6.39849 7.03555 6.80038 6.86822C7.20228 6.70089 7.63331 6.61475 8.06865 6.61475C8.50399 6.61475 8.93502 6.70089 9.33692 6.86822C9.73881 7.03555 10.1036 7.28075 10.4103 7.5897C10.5687 7.74368 10.7113 7.91304 10.8361 8.09529"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </>
  ),
  {
    viewBox: "0 0 18 18",
    fill: "none",
  }
);
