import { withIcon } from "@icons/_withIcon";

// Changelog: previous name: `TravelEdiquette` (requires prop mono)
export const EtiquetteFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M6.91067 30.3066L4.08224 33.135L9.749 38.8017L12.5774 35.9733L6.91067 30.3066Z"
        fill={accent}
      />
      <path
        d="M37.3045 20.4741C36.6752 19.8466 35.8227 19.4941 34.934 19.4941C34.0452 19.4941 33.1928 19.8466 32.5635 20.4741L27.8845 25.1421C27.7275 24.3656 27.3069 23.6672 26.6941 23.1651C26.0812 22.6631 25.3137 22.3882 24.5215 22.3871H14.7565C14.3315 22.3858 13.9105 22.4689 13.5178 22.6316C13.1252 22.7944 12.7689 23.0335 12.4695 23.3351L3.66048 32.1431C3.47301 32.3307 3.3677 32.585 3.3677 32.8501C3.3677 33.1153 3.47301 33.3696 3.66048 33.5571L9.32748 39.2241C9.51501 39.4116 9.76932 39.5169 10.0345 39.5169C10.2996 39.5169 10.554 39.4116 10.7415 39.2241L14.4085 35.5571C14.9984 34.97 15.7962 34.6394 16.6285 34.6371H26.3375C26.8274 34.6388 27.3128 34.5431 27.7655 34.3556C28.2182 34.1682 28.6291 33.8927 28.9745 33.5451L37.3045 25.2161C37.9321 24.5867 38.2846 23.734 38.2846 22.8451C38.2846 21.9562 37.9321 21.1036 37.3045 20.4741ZM35.8945 23.8021L27.5605 32.1311C27.4002 32.2921 27.2096 32.4197 26.9996 32.5066C26.7897 32.5934 26.5647 32.6378 26.3375 32.6371H16.6285C15.2661 32.641 13.9602 33.1822 12.9945 34.1431L10.0345 37.1031L5.78248 32.8511L13.8825 24.7511C13.9966 24.6362 14.1325 24.545 14.2822 24.483C14.4319 24.4211 14.5925 24.3895 14.7545 24.3901H24.5195C24.8926 24.4 25.2471 24.5551 25.5075 24.8225C25.7679 25.0899 25.9136 25.4484 25.9136 25.8216C25.9136 26.1949 25.7679 26.5534 25.5075 26.8207C25.2471 27.0881 24.8926 27.2433 24.5195 27.2531H17.3305C17.0653 27.2531 16.8109 27.3585 16.6234 27.546C16.4358 27.7336 16.3305 27.9879 16.3305 28.2531C16.3305 28.5183 16.4358 28.7727 16.6234 28.9602C16.8109 29.1478 17.0653 29.2531 17.3305 29.2531H25.1075C25.581 29.2543 26.0501 29.1617 26.4878 28.9808C26.9254 28.7999 27.3229 28.5343 27.6575 28.1991L33.9765 21.8901C34.23 21.6358 34.5742 21.4926 34.9334 21.492C35.2925 21.4915 35.6371 21.6336 35.8915 21.8871C36.1458 22.1407 36.289 22.4849 36.2896 22.844C36.2902 23.2031 36.148 23.5478 35.8945 23.8021Z"
        fill={primary}
      />
      <path
        d="M20.5945 2.57614C18.9739 2.57614 17.3896 3.05671 16.0421 3.95708C14.6946 4.85745 13.6444 6.13718 13.0242 7.63443C12.404 9.13169 12.2418 10.7792 12.5579 12.3687C12.8741 13.9582 13.6545 15.4182 14.8004 16.5642C15.9464 17.7101 17.4064 18.4905 18.9959 18.8067C20.5854 19.1229 22.2329 18.9606 23.7302 18.3404C25.2274 17.7202 26.5072 16.67 27.4075 15.3225C28.3079 13.975 28.7885 12.3908 28.7885 10.7701C28.7885 8.59696 27.9252 6.51278 26.3885 4.97611C24.8518 3.43944 22.7677 2.57614 20.5945 2.57614ZM24.8265 9.06414L20.0015 13.8891C19.814 14.0766 19.5596 14.1819 19.2945 14.1819C19.0293 14.1819 18.775 14.0766 18.5875 13.8891L16.3625 11.6641C16.1848 11.4747 16.0878 11.2236 16.0921 10.9639C16.0963 10.7042 16.2014 10.4563 16.3851 10.2728C16.5688 10.0892 16.8167 9.98426 17.0764 9.98023C17.3361 9.97619 17.5872 10.0734 17.7765 10.2511L19.2945 11.7681L23.4125 7.65114C23.6011 7.46898 23.8537 7.36819 24.1159 7.37047C24.3781 7.37275 24.6289 7.47791 24.8143 7.66332C24.9997 7.84873 25.1049 8.09954 25.1072 8.36174C25.1094 8.62394 25.0086 8.87654 24.8265 9.06514V9.06414Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
