import { Fragment, forwardRef, useRef, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { Dialog, Popover, Transition } from "@headlessui/react";

import { LogoWithGlobe } from "@icons/logos/logoWithGlobe";
import { LogoGlobe } from "@icons/logos/logoGlobe";
import {
  ArrowRight,
  ChevronLeft,
  HamburgerFill,
  CloseX,
  Bookmark,
  Search,
} from "@icons";

import { ImgixNextImage } from "@components/imgixNextImage";

import { keyify } from "@utils/keyify";

import { tagular } from "@cohesion/tagular";
import { LP_URL } from "@constants/lpUrl";
import { ArticlePanel } from "./articlePanel";
import { DestinationPanel } from "./destinationPanel";
import { ProfilePanel } from "./profilePanel";

function handleMenuTagularEvents({ text, url, position, tagularPayload }) {
  const eventType = tagularPayload?.event || "click";

  switch (eventType) {
    case "product_click":
      tagular(eventType, {
        actionOutcome: "INTERNALLINK",
        outboundUrl: url,
        product: {
          location: tagularPayload?.location || "GLOBAL SUB MENU_SHOP",
          text,
          position,
          brand: tagularPayload?.brand || "shop",
        },
      });
      break;

    case "product_view":
      tagular(eventType, {
        product: {
          location: `GLOBAL SUB MENU_${text}`,
          text: `${tagularPayload?.brand} SUB NAV`,
          position: 0,
          brand: tagularPayload?.brand,
        },
      });
      break;

    default:
      tagular(eventType, {
        actionOutcome: tagularPayload?.actionOutcome || "INTERNALLINK",
        outboundUrl: url,
        webElement: {
          location: "HEADER",
          elementType: "LINK",
          position: "GLOBAL SUB MENU",
          text,
        },
      });
      break;
  }
}

const DynamicGlobalSearch = dynamic(
  () => import("./typesenseSearch").then(m => m.GlobalSearch),
  { ssr: false }
);

const NavButton = forwardRef(({ onClick, title, ...props }, ref) => {
  function handleOnClick(e) {
    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: "null",
      webElement: {
        location: "HEADER",
        elementType: "LINK",
        position: "GLOBAL NAV BAR",
        text: title,
      },
    });
    onClick(e);
  }
  return <button type="button" ref={ref} onClick={handleOnClick} {...props} />;
});
NavButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export function GlobalNav({ nav, className = "" }) {
  const scollToRef = useRef();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileLevel1Open, setMobileLevel1Open] = useState(false);
  const [mobileLevel2Open, setMobileLevel2Open] = useState(false);
  const [level2Data, setLevel2Data] = useState(
    nav.find(({ title }) => title === "Destinations")
  );
  const [activeDestination, setActiveDestination] = useState("Africa");
  const [mobileLevel3Open, setMobileLevel3Open] = useState(false);
  const [level3Data, setLevel3Data] = useState(
    level2Data.submenu.items.find(({ title }) => title === "Africa")
  );

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
    setMobileLevel1Open(true);
    setMobileLevel2Open(false);
    setMobileLevel3Open(false);
    tagular("click", {
      actionOutcome: "EXPAND",
      outboundUrl: "null",
      webElement: {
        location: "HEADER",
        elementType: "BUTTON",
        position: "GLOBAL NAV BAR",
        text: "HAMBURGER MENU",
      },
    });
  };

  const handleLevel2Data = e => {
    const text = e.currentTarget.getAttribute("data-title");
    const data = nav.find(({ title }) => title === text);
    setLevel2Data(data);

    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: "null",
      webElement: {
        location: "HEADER",
        elementType: "LINK",
        position: "GLOBAL SUB MENU",
        text,
      },
    });

    if (data.tagular) {
      handleMenuTagularEvents({
        text: data.title,
        tagularPayload: { event: "product_view", ...data.tagular },
      });
    }
  };

  const handleMobileLevel2Open = e => {
    handleLevel2Data(e);
    setMobileLevel1Open(false);
    setMobileLevel2Open(true);
    setMobileLevel3Open(false);
    // scroll to top of panel when opening
    scollToRef.current.scrollIntoView(0);
  };

  const handleMobileLevel2Close = () => {
    setMobileMenuOpen(true);
    setMobileLevel1Open(true);
    setMobileLevel2Open(false);
    setMobileLevel3Open(false);
    tagular("click", {
      actionOutcome: "CLOSE",
      outboundUrl: "null",
      webElement: {
        location: "HEADER",
        elementType: "BUTTON",
        position: "GLOBAL NAV BAR",
        text: "Back",
      },
    });
  };

  const handleLevel3Data = e => {
    const text = e.currentTarget.getAttribute("data-title");
    const data = level2Data.submenu.items.find(({ title }) => title === text);
    setLevel3Data(data);
    setActiveDestination(text);

    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: "null",
      webElement: {
        location: "HEADER",
        elementType: "LINK",
        position: "GLOBAL SUB MENU",
        text,
      },
    });
  };

  const handleMobileLevel3Open = e => {
    handleLevel3Data(e);
    setMobileLevel1Open(false);
    setMobileLevel2Open(false);
    setMobileLevel3Open(true);
    // scroll to top of panel when opening
    scollToRef.current.scrollIntoView(0);
  };

  const handleMobileLevel3Close = () => {
    setMobileLevel1Open(false);
    setMobileLevel2Open(true);
    setMobileLevel3Open(false);
    tagular("click", {
      actionOutcome: "CLOSE",
      outboundUrl: "null",
      webElement: {
        location: "HEADER",
        elementType: "BUTTON",
        position: "GLOBAL NAV BAR",
        text: "Back",
      },
    });
  };

  const handleSubmenuTagularEvent = event => {
    const text = event.currentTarget.getAttribute("data-title");
    const url = event.currentTarget.getAttribute("data-url");

    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: url,
      webElement: {
        location: "HEADER",
        elementType: "LINK",
        position: "GLOBAL NAV BAR",
        text,
      },
    });
  };

  const slideTransitionProps = {
    enter: "transition ease-out duration-300",
    enterFrom: "opacity-0 translate-y-1",
    enterTo: "opacity-100 translate-y-0",
    leave: "transition ease-in duration-0",
    leaveFrom: "opacity-100 translate-y-0",
    leaveTo: "opacity-0 translate-y-1",
  };

  return (
    <header
      className={cn(
        "bg-white z-50",
        {
          relative: !className.includes("fixed"),
        },
        className
      )}
    >
      <nav
        className="md:@container container flex items-center justify-between gap-x-6 lg:gap-x-12"
        aria-label="Global Nav"
      >
        <div className="flex gap-x-6 lg:gap-x-12">
          <a
            href="/"
            className="-m-1.5 p-1.5"
            aria-label="Lonely Planet homepage"
            onClick={() =>
              tagular("click", {
                actionOutcome: "INTERNALLINK",
                outboundUrl: `${LP_URL}/`,
                webElement: {
                  location: "HEADER",
                  elementType: "LINK",
                  position: "GLOBAL NAV BAR",
                  text: "LONELY PLANET",
                },
              })
            }
          >
            <span className="sr-only">Lonely Planet</span>
            <LogoWithGlobe className="text-5xl text-blue" />
          </a>
        </div>

        {/* Desktop */}
        <Popover.Group className="hidden lg:flex lg:self-stretch lg:gap-x-12">
          {nav &&
            nav.map(
              ({ title, displayTitle, tagular: tagularPayload, ...item }) => (
                <Popover className="relative" key={title}>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        as={NavButton}
                        className={cn(
                          "relative flex items-center gap-x-1 h-full font-semibold text-black hover:text-blue focus:outline-none focus:text-blue",
                          open ? "text-blue" : ""
                        )}
                        title={title}
                        onClick={() => {
                          if (!open && tagularPayload) {
                            handleMenuTagularEvents({
                              text: title,
                              tagularPayload: {
                                event: "product_view",
                                ...tagularPayload,
                              },
                            });
                          }
                        }}
                      >
                        {displayTitle || title}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                          className={cn(
                            "absolute left-0 right-0 -bottom-1 mx-auto translate-y-full text-white",
                            { hidden: !open }
                          )}
                        >
                          <path
                            d="M8.49348 0L17.9794 15.5138H0.0615234L8.49348 0Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          className={cn(
                            "absolute top-full mt-4 rounded-3xl overflow-hidden bg-white rounded drop-shadow-sm",
                            title === "Destinations"
                              ? "max-w-5xl @7xl:max-w-none left-[21%] -translate-x-[21%] xl:-left-8 xl:translate-x-0"
                              : "max-w-4xl @5xl:max-w-4xl @7xl:max-w-none left-1/2 -translate-x-1/2"
                          )}
                        >
                          <div
                            className={cn(
                              "flex gap-x-6 w-content",
                              title === "Destinations"
                                ? "px-0 py-0 pr-6"
                                : "px-6 py-10"
                            )}
                          >
                            {(() => {
                              switch (title) {
                                case "Destinations":
                                  return (
                                    <DestinationPanel
                                      item={item}
                                      activeDestination={activeDestination}
                                      level3Data={level3Data}
                                      handleLevel3Data={handleLevel3Data}
                                    />
                                  );
                                case "Articles":
                                  return <ArticlePanel item={item} />;
                                default:
                                  return (
                                    <div className="w-80">
                                      {item.submenu?.items.map(
                                        (
                                          {
                                            title: itemTitle,
                                            subtitle: itemSubtitle,
                                            url: suburl,
                                            icon: Subicon,
                                            image: subImage,
                                            tagular: itemTagular,
                                          },
                                          subIndex
                                        ) => (
                                          <Fragment key={suburl}>
                                            <a
                                              key={itemTitle}
                                              href={suburl}
                                              onClick={() => {
                                                handleMenuTagularEvents({
                                                  text: itemTitle,
                                                  url: suburl,
                                                  position: subIndex + 1,
                                                  tagularPayload: itemTagular,
                                                });
                                              }}
                                              className="relative flex py-0 text-black group"
                                            >
                                              {Subicon && (
                                                <div className="px-6 py-4 mr-3 rounded-md shrink-0 bg-black-100">
                                                  <Subicon
                                                    className="w-9 h-9"
                                                    aria-hidden="true"
                                                    width="36"
                                                    height="36"
                                                  />
                                                </div>
                                              )}
                                              {subImage && (
                                                <ImgixNextImage
                                                  src={subImage}
                                                  alt={`picture of ${itemTitle}`}
                                                  className="shrink-0 mr-6 rounded-md aspect-[84/76] grow-0"
                                                  width={82}
                                                  height={76}
                                                  imgixParams={{
                                                    auto: "format",
                                                    fit: "crop",
                                                  }}
                                                />
                                              )}
                                              <div className="flex flex-col">
                                                <div>
                                                  <p className="flex font-semibold group-hover:text-blue">
                                                    <span className="mr-2">
                                                      {itemTitle}
                                                    </span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p>{itemSubtitle}</p>
                                                </div>
                                              </div>
                                            </a>
                                            {subIndex <
                                              item.submenu.items.length - 1 && (
                                              <hr className="max-w-full my-5 border-black-200" />
                                            )}
                                          </Fragment>
                                        )
                                      )}
                                    </div>
                                  );
                              }
                            })()}

                            <div
                              className={cn("shrink-0 ml-auto mr-0 w-60", {
                                "py-8": title === "Destinations",
                              })}
                            >
                              {item.featured && (
                                <article className="relative">
                                  <ImgixNextImage
                                    src={item.featured.image}
                                    alt={`picture of ${item.featured.title}`}
                                    className="rounded-md aspect-[244/175]"
                                    width={244}
                                    height={175}
                                    imgixParams={{
                                      auto: "format",
                                      fit: "crop",
                                    }}
                                  />
                                  <a
                                    href={item.featured.url}
                                    className="card-link mt-3 mb-2 flex gap-x-2.5 items-center hover:text-blue font-semibold"
                                    onClick={() =>
                                      handleMenuTagularEvents({
                                        text: item.featured.title,
                                        url: item.featured.url,
                                        position: 5,
                                        tagularPayload: item.featured.tagular,
                                      })
                                    }
                                  >
                                    {item.featured.title}
                                  </a>
                                  <p>{item.featured.description}</p>
                                </article>
                              )}
                            </div>
                          </div>
                          {title !== "Destinations" && item.cta && (
                            <a
                              href={item.cta.url}
                              className="flex bg-black-100 gap-x-2.5 items-center py-3 px-6 font-semibold text-black hover:bg-blue hover:text-white"
                              onClick={() =>
                                handleMenuTagularEvents({
                                  text: item.cta.title,
                                  url: item.cta.url,
                                  position: 6,
                                  tagularPayload: item.cta.tagular,
                                })
                              }
                            >
                              {item.cta.title}
                              <ArrowRight
                                width="16"
                                height="16"
                                aria-hidden="true"
                              />
                            </a>
                          )}
                          {title === "Destinations" && (
                            <div className="flex bg-black-100">
                              <a
                                href={item.cta.url}
                                className="flex w-[230px] shrink-0 border-r border-black-200 bg-black-100 items-center justify-between py-3 px-6 font-semibold text-black hover:bg-blue hover:text-white"
                                onClick={() =>
                                  tagular("click", {
                                    actionOutcome: "INTERNALLINK",
                                    outboundUrl: item.cta.url,
                                    webElement: {
                                      location: "HEADER",
                                      elementType: "LINK",
                                      position: "GLOBAL SUB MENU",
                                      text: item.cta.title,
                                    },
                                  })
                                }
                              >
                                {item.cta.title}
                              </a>
                              <a
                                href={level3Data.url}
                                className="flex w-full bg-black-100 gap-x-2.5 items-center justify-end py-3 px-6 font-semibold text-black hover:bg-blue hover:text-white"
                                onClick={() =>
                                  tagular("click", {
                                    actionOutcome: "INTERNALLINK",
                                    outboundUrl: level3Data.url,
                                    webElement: {
                                      location: "HEADER",
                                      elementType: "LINK",
                                      position: "GLOBAL SUB MENU",
                                      text: `More in ${level3Data.title === "The Caribbean" ? "the Caribbean" : level3Data.title}`,
                                    },
                                  })
                                }
                              >
                                More in{" "}
                                {level3Data.title === "The Caribbean"
                                  ? "the Caribbean"
                                  : level3Data.title}
                                <ArrowRight
                                  width="16"
                                  height="16"
                                  aria-hidden="true"
                                />
                              </a>
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              )
            )}
        </Popover.Group>
        <div className="flex justify-end flex-1 md:self-stretch">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={cn(
                    "relative mr-4 lg:mr-8 flex items-center gap-x-1 h-full font-semibold text-black hover:text-blue focus:outline-none focus:text-blue",
                    open ? "text-blue" : ""
                  )}
                  onClick={() => {
                    document.documentElement.classList.toggle(
                      "no-scroll-mobile",
                      !open
                    );

                    if (open) {
                      // tracking event
                      const openSearchEvent = new Event(
                        "GlobalHeader/openSearch"
                      );
                      document.dispatchEvent(openSearchEvent);
                    }

                    tagular("click", {
                      actionOutcome: open ? "CLOSE" : "EXPAND",
                      outboundUrl: "null",
                      webElement: {
                        location: "HEADER",
                        elementType: "LINK",
                        position: "GLOBAL NAV BAR",
                        text: open ? "SEARCH-X" : "SEARCH-NAV",
                      },
                    });
                  }}
                >
                  <Search className="inline-flex" width="20" height="20" />
                  <span className="hidden md:inline ml-2">Search</span>
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="w-screen md:w-128 lg:w-144 h-screen md:h-auto md:max-h-screen-90 overflow-scroll fixed top-0 left-0 bg-white lg:rounded drop-shadow-sm md:absolute md:left-full md:-translate-x-full z-10">
                    {open && <DynamicGlobalSearch open={open} />}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <a
            href="/profile/saves"
            className="flex items-center mr-4 font-semibold text-black transition-colors hover:bg-white hover:text-blue focus:outline-none focus:text-blue"
            data-title="saves"
            data-url="/profile/saves"
            onClick={handleSubmenuTagularEvent}
          >
            <Bookmark width="20" height="20" className="inline-flex" />{" "}
            <span className="hidden ml-2 md:inline">Saves</span>
          </a>
          <ProfilePanel />
          <button
            type="button"
            className="lg:hidden items-center justify-between py-2.5 pl-2.5 text-black"
            onClick={handleMobileMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <HamburgerFill width="20" height="20" className="inline-flex" />
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-20 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Dialog.Panel className="flex flex-col w-full pb-12 overflow-y-auto bg-white">
                <div
                  ref={scollToRef}
                  className="sticky top-0 z-10 flex items-center justify-between p-2 bg-white border-b border-black-200"
                >
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-black"
                    onClick={() => {
                      if (mobileLevel1Open) {
                        setMobileMenuOpen(false);
                        tagular("click", {
                          actionOutcome: "CLOSE",
                          outboundUrl: "null",
                          webElement: {
                            location: "HEADER",
                            elementType: "BUTTON",
                            position: "GLOBAL NAV BAR",
                            text: "X",
                          },
                        });
                      }
                      if (mobileLevel2Open) {
                        handleMobileLevel2Close();
                      }
                      if (mobileLevel3Open) {
                        handleMobileLevel3Close();
                      }
                    }}
                  >
                    <span className="sr-only">
                      {mobileLevel1Open ? "Close menu" : "Back"}
                    </span>
                    {mobileLevel1Open ? (
                      <LogoGlobe
                        width="25"
                        height="25"
                        className="text-blue"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        <ChevronLeft
                          width="14"
                          height="14"
                          aria-hidden="true"
                        />
                        {mobileLevel2Open && level2Data.title && (
                          <span className="ml-2 font-semibold">
                            {level2Data.displayTitle || level2Data.title}
                          </span>
                        )}
                        {mobileLevel3Open && level3Data.title && (
                          <span className="ml-2 font-semibold">
                            {level3Data.title}
                          </span>
                        )}
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-black"
                    onClick={() => {
                      setMobileMenuOpen(false);
                      tagular("click", {
                        actionOutcome: "CLOSE",
                        outboundUrl: "null",
                        webElement: {
                          location: "HEADER",
                          elementType: "BUTTON",
                          position: "GLOBAL NAV BAR",
                          text: "X",
                        },
                      });
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <div className="p-2 rounded-full bg-black-100">
                      <CloseX width="16" height="16" aria-hidden="true" />
                    </div>
                  </button>
                </div>

                <Transition show={mobileLevel1Open} {...slideTransitionProps}>
                  {mobileLevel1Open &&
                    nav &&
                    nav.map(({ title, displayTitle }) => (
                      <button
                        key={keyify(title)}
                        type="button"
                        className="flex items-center justify-between w-full px-6 py-3 font-semibold text-left text-black border-b border-black-200"
                        onClick={handleMobileLevel2Open}
                        data-title={title}
                      >
                        {displayTitle || title}
                        <ChevronLeft
                          className="rotate-180"
                          width="14"
                          height="14"
                          aria-hidden="true"
                        />
                      </button>
                    ))}
                </Transition>

                <Transition show={mobileLevel2Open} {...slideTransitionProps}>
                  {level2Data.featured && (
                    <article className="relative flex items-center p-4 border-b gap-x-6 border-black-200">
                      <ImgixNextImage
                        src={level2Data.featured.image}
                        alt={`picture of ${level2Data.featured.title}`}
                        className="rounded-md aspect-[82/78] w-20 shrink-0"
                        width={82}
                        height={78}
                        imgixParams={{
                          auto: "format",
                          fit: "crop",
                        }}
                      />
                      <div className="w-3/4">
                        <a
                          href={level2Data.featured.url}
                          className="card-link mt-3 mb-2 flex gap-x-2.5 items-center hover:text-blue font-semibold"
                          onClick={() =>
                            handleMenuTagularEvents({
                              text: level2Data.featured.title,
                              url: level2Data.featured.url,
                              position: "1",
                              tagularPayload: level2Data.featured.tagular,
                            })
                          }
                        >
                          {level2Data.featured.title}{" "}
                        </a>
                        <p>{level2Data.featured.description}</p>
                      </div>
                    </article>
                  )}

                  {level2Data.title === "Destinations" ? (
                    level2Data.submenu?.items.map(({ title }) => (
                      <button
                        key={title}
                        type="button"
                        className="flex items-center justify-between w-full p-4 text-left text-black border-b border-black-200"
                        onClick={handleMobileLevel3Open}
                        data-title={title}
                      >
                        {title}
                        <ChevronLeft
                          className="rotate-180"
                          width="14"
                          height="14"
                          aria-hidden="true"
                        />
                      </button>
                    ))
                  ) : (
                    <>
                      {level2Data.title === "Articles" && (
                        <p className="p-4 border-b label-sm border-black-200">
                          Articles by interest
                        </p>
                      )}

                      {level2Data.submenu?.items.map(
                        (
                          {
                            title,
                            subtitle,
                            url,
                            icon: Icon,
                            image,
                            tagular: tagularPayload,
                          },
                          subIndex
                        ) => (
                          <article
                            key={title}
                            className="relative flex items-center p-4 border-b gap-x-6 border-black-200"
                          >
                            {Icon && (
                              <div
                                className={cn(
                                  level2Data.title === "Articles"
                                    ? ""
                                    : "shrink-0 bg-black-100 py-4 px-6 rounded-md"
                                )}
                              >
                                <Icon
                                  className={cn(
                                    level2Data.title === "Articles"
                                      ? ""
                                      : "w-9 h-9"
                                  )}
                                  aria-hidden="true"
                                  width={
                                    level2Data.title === "Articles"
                                      ? "20"
                                      : "36"
                                  }
                                  height={
                                    level2Data.title === "Articles"
                                      ? "20"
                                      : "36"
                                  }
                                />
                              </div>
                            )}
                            {image && (
                              <ImgixNextImage
                                src={image}
                                alt={`picture of ${title}`}
                                className="shrink-0 rounded-md aspect-[84/76] grow-0"
                                width={82}
                                height={76}
                                imgixParams={{
                                  auto: "format",
                                  fit: "crop",
                                }}
                              />
                            )}
                            <div className="w-3/4">
                              <a
                                href={url}
                                className={cn(
                                  "card-link flex items-center hover:text-blue",
                                  level2Data.title === "Articles"
                                    ? "font-light"
                                    : "font-semibold"
                                )}
                                onClick={() =>
                                  handleMenuTagularEvents({
                                    text: title,
                                    url,
                                    position: subIndex + 2,
                                    tagularPayload,
                                  })
                                }
                              >
                                {title}
                              </a>
                              <p>{subtitle}</p>
                            </div>
                          </article>
                        )
                      )}
                    </>
                  )}
                  {level2Data.cta && (
                    <a
                      href={level2Data.cta.url}
                      className="flex bg-black-100 gap-x-2.5 items-center justify-between p-4 font-semibold text-black hover:bg-blue hover:text-white"
                      onClick={() =>
                        handleMenuTagularEvents({
                          text: level2Data.cta.title,
                          url: level2Data.cta.url,
                          position: 6,
                          tagularPayload: level2Data.cta.tagular,
                        })
                      }
                    >
                      {level2Data.cta.title}
                      <ArrowRight width="16" height="16" aria-hidden="true" />
                    </a>
                  )}
                </Transition>

                <Transition show={mobileLevel3Open} {...slideTransitionProps}>
                  {mobileLevel3Open && (
                    <div>
                      {level3Data &&
                        level3Data.descendants?.map(
                          ({ countries, regions, cities }) => {
                            const sections = [
                              {
                                descendants: countries,
                                descendantTitle: "Countries",
                              },
                              {
                                descendants: regions,
                                descendantTitle:
                                  level3Data.title === "North America"
                                    ? "States"
                                    : "Regions",
                              },
                              {
                                descendants: cities,
                                descendantTitle: "Cities",
                              },
                            ];

                            return sections.map(
                              ({ descendants, descendantTitle }) => {
                                if (
                                  Array.isArray(descendants) &&
                                  descendants.length !== 0
                                ) {
                                  return (
                                    <div key={descendantTitle}>
                                      <p className="p-4 font-semibold border-b bg-black-100 border-black-200">
                                        {descendantTitle}
                                      </p>
                                      {descendants.map(
                                        ({ title: dTitle, url }) => (
                                          <a
                                            key={dTitle}
                                            href={url}
                                            className="flex items-center justify-between w-full p-4 pl-8 text-left border-b hover:text-blue border-black-200"
                                            onClick={() =>
                                              tagular("click", {
                                                actionOutcome: "INTERNALLINK",
                                                outboundUrl: url,
                                                webElement: {
                                                  location: "HEADER",
                                                  elementType: "LINK",
                                                  position: "GLOBAL SUB MENU",
                                                  text: dTitle,
                                                  name: descendantTitle,
                                                },
                                              })
                                            }
                                          >
                                            {dTitle}
                                          </a>
                                        )
                                      )}
                                    </div>
                                  );
                                }
                                return null;
                              }
                            );
                          }
                        )}

                      {level3Data && level3Data.url && (
                        <a
                          href={level3Data.url}
                          className="flex bg-black-100 gap-x-2.5 items-center justify-between p-4 font-semibold text-black hover:bg-blue hover:text-white"
                          onClick={() =>
                            tagular("click", {
                              actionOutcome: "INTERNALLINK",
                              outboundUrl: level3Data.url,
                              webElement: {
                                location: "HEADER",
                                elementType: "LINK",
                                position: "GLOBAL SUB MENU",
                                text: `More in ${level3Data.title === "The Caribbean" ? "the Caribbean" : level3Data.title}`,
                              },
                            })
                          }
                        >
                          More in{" "}
                          {level3Data.title === "The Caribbean"
                            ? "the Caribbean"
                            : level3Data.title}
                          <ArrowRight
                            width="16"
                            height="16"
                            aria-hidden="true"
                          />
                        </a>
                      )}
                    </div>
                  )}
                </Transition>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </header>
  );
}

GlobalNav.propTypes = {
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
      featured: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }).isRequired,
      submenu: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            descendants: PropTypes.arrayOf(
              PropTypes.shape({
                countries: PropTypes.arrayOf(
                  PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                  })
                ),
                regions: PropTypes.arrayOf(
                  PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                  })
                ),
                cities: PropTypes.arrayOf(
                  PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                  })
                ),
              }).isRequired
            ),
          }).isRequired
        ).isRequired,
      }).isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};
