import { withIcon } from "@icons/_withIcon";

export const LodgingFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M24.712 19.3444H22.152C21.8759 19.3444 21.652 19.5682 21.652 19.8444V24.7704C21.652 25.0465 21.8759 25.2704 22.152 25.2704H24.712C24.9881 25.2704 25.212 25.0465 25.212 24.7704V19.8444C25.212 19.5682 24.9881 19.3444 24.712 19.3444Z"
        fill={accent}
      />
      <path
        d="M17.903 19.3444H15.343C15.0669 19.3444 14.843 19.5682 14.843 19.8444V24.7704C14.843 25.0465 15.0669 25.2704 15.343 25.2704H17.903C18.1791 25.2704 18.403 25.0465 18.403 24.7704V19.8444C18.403 19.5682 18.1791 19.3444 17.903 19.3444Z"
        fill={accent}
      />
      <path
        d="M24.712 9.94838H22.152C21.8759 9.94838 21.652 10.1722 21.652 10.4484V15.3744C21.652 15.6505 21.8759 15.8744 22.152 15.8744H24.712C24.9881 15.8744 25.212 15.6505 25.212 15.3744V10.4484C25.212 10.1722 24.9881 9.94838 24.712 9.94838Z"
        fill={accent}
      />
      <path
        d="M17.903 9.94838H15.343C15.0669 9.94838 14.843 10.1722 14.843 10.4484V15.3744C14.843 15.6505 15.0669 15.8744 15.343 15.8744H17.903C18.1791 15.8744 18.403 15.6505 18.403 15.3744V10.4484C18.403 10.1722 18.1791 9.94838 17.903 9.94838Z"
        fill={accent}
      />
      <path
        d="M30.7 2.98237C30.5905 2.88966 30.4626 2.82115 30.3247 2.78134C30.1868 2.74152 30.0421 2.73131 29.9 2.75137L9.80701 5.76137C9.57007 5.79683 9.35374 5.91617 9.19738 6.09768C9.04101 6.27919 8.955 6.5108 8.95501 6.75037V19.7784L3.75501 22.2194C3.58313 22.3001 3.43779 22.428 3.33596 22.5883C3.23414 22.7486 3.18004 22.9345 3.18001 23.1244V37.0884C3.18001 37.3536 3.28536 37.6079 3.4729 37.7955C3.66044 37.983 3.91479 38.0884 4.18001 38.0884H35.82C36.0852 38.0884 36.3396 37.983 36.5271 37.7955C36.7147 37.6079 36.82 37.3536 36.82 37.0884V23.1244C36.82 22.9345 36.7659 22.7486 36.6641 22.5883C36.5622 22.428 36.4169 22.3001 36.245 22.2194L31.045 19.7784V3.74037C31.0453 3.59666 31.0147 3.45455 30.9552 3.32375C30.8956 3.19294 30.8086 3.07651 30.7 2.98237ZM5.18001 23.7604L8.95501 21.9884V36.0884H5.18001V23.7604ZM18.274 36.0884V30.8764H22.19V36.0884H18.274ZM23.19 28.8764H17.274C17.0088 28.8764 16.7544 28.9817 16.5669 29.1693C16.3794 29.3568 16.274 29.6112 16.274 29.8764V36.0884H10.955V7.61137L29.045 4.90137V36.0884H24.19V29.8764C24.19 29.6112 24.0847 29.3568 23.8971 29.1693C23.7096 28.9817 23.4552 28.8764 23.19 28.8764ZM34.82 23.7604V36.0884H31.045V21.9884L34.82 23.7604Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
