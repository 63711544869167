import { withIcon } from "@icons/_withIcon";

export const LGBTQFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M13.1335 26.184C13.0037 26.1844 12.8753 26.2102 12.7555 26.26L14.6795 28.184H26.5995L28.5275 26.255C28.4141 26.2098 28.2935 26.1857 28.1715 26.184H13.1335Z"
        fill={accent}
      />
      <path
        d="M5.95548 19.458L7.47448 20.976H33.8035L35.3485 19.432C35.4945 19.286 35.6265 19.132 35.7605 18.976H5.52048C5.66048 19.14 5.80048 19.303 5.95548 19.458Z"
        fill={accent}
      />
      <path
        d="M3.72248 11.772C3.57833 12.4285 3.51387 13.1 3.53048 13.772H37.7745C37.7745 13.612 37.7885 13.453 37.7835 13.292C37.7666 12.7806 37.703 12.2718 37.5935 11.772H3.72248Z"
        fill={accent}
      />
      <path
        d="M18.5185 34.848C19.0811 35.4104 19.844 35.7264 20.6395 35.7264C21.435 35.7264 22.1979 35.4104 22.7605 34.848L26.4255 31.184L36.7625 20.846C37.7567 19.8483 38.5372 18.6586 39.0564 17.3494C39.5756 16.0401 39.8227 14.6389 39.7825 13.231C39.7446 11.8194 39.4152 10.4309 38.815 9.15281C38.2147 7.87468 37.3566 6.73452 36.2945 5.80399C34.2562 4.06643 31.6324 3.17096 28.9571 3.2999C26.2818 3.42883 23.7562 4.57247 21.8945 6.49799L20.5945 7.71899L19.1485 6.26599C17.2116 4.32898 14.5846 3.24073 11.8453 3.24063C9.10609 3.24054 6.47899 4.32861 4.54198 6.26549C2.60497 8.20237 1.51672 10.8294 1.51663 13.5686C1.51653 16.3079 2.60461 18.935 4.54148 20.872L18.5185 34.848ZM3.72248 11.772C4.04556 10.3156 4.75398 8.97278 5.77369 7.88392C6.79339 6.79506 8.08692 6.00016 9.51899 5.58235C10.9511 5.16454 12.4691 5.13917 13.9143 5.50888C15.3596 5.8786 16.679 6.62982 17.7345 7.68399L19.1865 9.13699C19.5774 9.51136 20.0977 9.72034 20.639 9.72034C21.1802 9.72034 21.7006 9.51136 22.0915 9.13699L23.3135 7.91599C24.8149 6.35092 26.8561 5.41652 29.0219 5.30283C31.1877 5.18914 33.3155 5.90471 34.9725 7.30399C36.3023 8.47421 37.2214 10.0404 37.5945 11.772C37.704 12.2718 37.7676 12.7806 37.7845 13.292C37.7895 13.453 37.7795 13.612 37.7755 13.772C37.7308 15.6871 37.0184 17.5264 35.7615 18.972C35.6275 19.126 35.4955 19.282 35.3495 19.428L33.8045 20.972L28.5285 26.248L26.5995 28.184L21.3465 33.434C21.159 33.6215 20.9046 33.7268 20.6395 33.7268C20.3743 33.7268 20.12 33.6215 19.9325 33.434L14.6795 28.184L12.7555 26.26L7.47448 20.976L5.95548 19.458C5.80048 19.303 5.66048 19.14 5.52048 18.976C4.68542 17.996 4.08841 16.8361 3.77614 15.5871C3.46388 14.338 3.44483 13.0336 3.72048 11.776L3.72248 11.772Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);

export const LGBTQFill = withIcon(
  () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0011 6.43808L16.7311 5.70008C18.2442 4.14435 20.2894 3.21816 22.4568 3.10725C24.6241 2.99635 26.7532 3.70892 28.4171 5.10208C29.2549 5.85389 29.9307 6.76855 30.4032 7.79022C30.7992 8.64662 31.0455 9.56315 31.1327 10.5H0.878906C1.06235 8.58807 1.90569 6.78978 3.27713 5.42208C4.83395 3.8695 6.94377 2.99896 9.14245 3.00196C11.3411 3.00496 13.4486 3.88126 15.0011 5.43808L16.0011 6.43808ZM1.14041 13.5C1.51788 14.866 2.2428 16.125 3.26113 17.1461L4.61505 18.5H27.3674L28.7411 17.1261C29.5372 16.3302 30.1627 15.3805 30.5795 14.3349C30.6886 14.0612 30.7828 13.7825 30.8619 13.5H1.14041ZM24.3679 21.5H7.61505L15.2851 29.1701C15.4727 29.3576 15.727 29.4629 15.9921 29.4629C16.2573 29.4629 16.5116 29.3576 16.6991 29.1701L24.3679 21.5Z"
    />
  ),
  { viewBox: "0 0 32 32" }
);
