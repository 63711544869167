import { withIcon } from "@icons/_withIcon";

// Changelog: name was ChevronDown
export const ChevronDownFill = withIcon(
  () => (
    <path d="M1 0.436401L5 4.37175L9 0.436401L10 1.42024L5 6.4624L8.68036e-07 1.42024L1 0.436401Z" />
  ),
  {
    viewBox: "0 0 10 7",
  }
);
