import { withIcon } from "@icons/_withIcon";

export const VideoFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M20.5945 25.8388C22.1811 25.8388 23.7321 25.3683 25.0513 24.4869C26.3705 23.6054 27.3987 22.3525 28.0058 20.8867C28.613 19.4209 28.7719 17.8079 28.4623 16.2518C28.1528 14.6957 27.3888 13.2663 26.2669 12.1444C25.145 11.0225 23.7156 10.2585 22.1595 9.94894C20.6034 9.63941 18.9904 9.79828 17.5246 10.4054C16.0588 11.0126 14.8059 12.0408 13.9244 13.36C13.043 14.6792 12.5725 16.2302 12.5725 17.8168C12.5727 19.9443 13.418 21.9846 14.9224 23.4889C16.4267 24.9933 18.467 25.8385 20.5945 25.8388ZM18.3095 14.4908C18.3097 14.3898 18.337 14.2907 18.3885 14.2037C18.4399 14.1168 18.5137 14.0452 18.6022 13.9965C18.6907 13.9477 18.7906 13.9235 18.8916 13.9264C18.9926 13.9292 19.0909 13.9591 19.1765 14.0128L24.4405 17.3398C24.521 17.3906 24.5874 17.461 24.6334 17.5444C24.6794 17.6278 24.7035 17.7216 24.7035 17.8168C24.7035 17.9121 24.6794 18.0058 24.6334 18.0892C24.5874 18.1726 24.521 18.243 24.4405 18.2938L19.1765 21.6168C19.0909 21.6705 18.9926 21.7004 18.8916 21.7032C18.7906 21.7061 18.6907 21.6819 18.6022 21.6331C18.5137 21.5844 18.4399 21.5128 18.3885 21.4259C18.337 21.3389 18.3097 21.2398 18.3095 21.1388V14.4908Z"
        fill={accent}
      />
      <path
        d="M34.5945 3.5538H6.59448C5.26889 3.55539 3.99804 4.08268 3.0607 5.02002C2.12336 5.95736 1.59607 7.22821 1.59448 8.5538V27.0798C1.59607 28.4054 2.12336 29.6762 3.0607 30.6136C3.99804 31.5509 5.26889 32.0782 6.59448 32.0798H15.8475L15.6155 33.2908C15.4789 34.0011 15.0993 34.6418 14.5418 35.1027C13.9844 35.5636 13.2838 35.8161 12.5605 35.8168C12.2953 35.8168 12.0409 35.9222 11.8534 36.1097C11.6658 36.2972 11.5605 36.5516 11.5605 36.8168C11.5605 37.082 11.6658 37.3364 11.8534 37.5239C12.0409 37.7114 12.2953 37.8168 12.5605 37.8168H28.6285C28.8937 37.8168 29.1481 37.7114 29.3356 37.5239C29.5231 37.3364 29.6285 37.082 29.6285 36.8168C29.6285 36.5516 29.5231 36.2972 29.3356 36.1097C29.1481 35.9222 28.8937 35.8168 28.6285 35.8168C27.9051 35.8161 27.2046 35.5636 26.6471 35.1027C26.0897 34.6418 25.7101 34.0011 25.5735 33.2908L25.3415 32.0798H34.5945C35.9201 32.0782 37.1909 31.5509 38.1283 30.6136C39.0656 29.6762 39.5929 28.4054 39.5945 27.0798V8.5538C39.5929 7.22821 39.0656 5.95736 38.1283 5.02002C37.1909 4.08268 35.9201 3.55539 34.5945 3.5538ZM23.6085 33.6668C23.7582 34.4491 24.089 35.1854 24.5745 35.8168H16.6145C17.0999 35.1854 17.4308 34.4491 17.5805 33.6668L17.8805 32.0798H23.3005L23.6085 33.6668ZM37.5945 27.0798C37.5945 27.8755 37.2784 28.6385 36.7158 29.2011C36.1532 29.7637 35.3901 30.0798 34.5945 30.0798H6.59448C5.79883 30.0798 5.03577 29.7637 4.47316 29.2011C3.91055 28.6385 3.59448 27.8755 3.59448 27.0798V8.5538C3.59448 7.75815 3.91055 6.99509 4.47316 6.43248C5.03577 5.86987 5.79883 5.5538 6.59448 5.5538H34.5945C35.3901 5.5538 36.1532 5.86987 36.7158 6.43248C37.2784 6.99509 37.5945 7.75815 37.5945 8.5538V27.0798Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
