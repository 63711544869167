import { withIcon } from "@icons/_withIcon";

export const MissingImg = withIcon(
  () => (
    <>
      <path d="M37.5 18.75C37.1685 18.75 36.8505 18.8817 36.6161 19.1161C36.3817 19.3505 36.25 19.6685 36.25 20V26.905L27.75 18.405C27.0468 17.702 26.0931 17.3071 25.0988 17.3071C24.1044 17.3071 23.1507 17.702 22.4475 18.405L13.6425 27.1925L10.1675 23.7175C9.46427 23.0145 8.51062 22.6196 7.51625 22.6196C6.52188 22.6196 5.56823 23.0145 4.865 23.7175L3.75 24.835V10C3.75 9.00544 4.14509 8.05161 4.84835 7.34835C5.55161 6.64509 6.50544 6.25 7.5 6.25H22.5C22.8315 6.25 23.1495 6.1183 23.3839 5.88388C23.6183 5.64946 23.75 5.33152 23.75 5C23.75 4.66848 23.6183 4.35054 23.3839 4.11612C23.1495 3.8817 22.8315 3.75 22.5 3.75H7.5C6.67924 3.75 5.86651 3.91166 5.10823 4.22575C4.34994 4.53984 3.66095 5.00022 3.08058 5.58058C1.90848 6.75269 1.25 8.3424 1.25 10V30C1.25 31.6576 1.90848 33.2473 3.08058 34.4194C3.66095 34.9998 4.34994 35.4602 5.10823 35.7742C5.86651 36.0883 6.67924 36.25 7.5 36.25H32.5C34.1576 36.25 35.7473 35.5915 36.9194 34.4194C38.0915 33.2473 38.75 31.6576 38.75 30V20C38.75 19.6685 38.6183 19.3505 38.3839 19.1161C38.1495 18.8817 37.8315 18.75 37.5 18.75ZM32.5 33.75H7.5C6.50544 33.75 5.55161 33.3549 4.84835 32.6517C4.14509 31.9484 3.75 30.9946 3.75 30V28.37L6.6325 25.485C6.86691 25.2507 7.18479 25.119 7.51625 25.119C7.84771 25.119 8.16559 25.2507 8.4 25.485L12.75 29.845C12.9844 30.0793 13.3023 30.211 13.6337 30.211C13.9652 30.211 14.2831 30.0793 14.5175 29.845L24.2075 20.1625C24.4419 19.9282 24.7598 19.7965 25.0912 19.7965C25.4227 19.7965 25.7406 19.9282 25.975 20.1625L36.21 30.4125C36.1088 31.3271 35.6748 32.1725 34.9907 32.788C34.3067 33.4034 33.4202 33.7458 32.5 33.75Z" />
      <path d="M8.59497 15.1345C8.59497 16.1339 8.89136 17.1109 9.44664 17.9418C10.0019 18.7727 10.7912 19.4203 11.7145 19.8027C12.6379 20.185 13.6539 20.2849 14.6341 20.0898C15.6142 19.8946 16.5145 19.4132 17.221 18.7063C17.9275 17.9994 18.4085 17.0989 18.6032 16.1187C18.7979 15.1384 18.6974 14.1225 18.3147 13.1993C17.9319 12.2761 17.2839 11.4872 16.4527 10.9323C15.6215 10.3774 14.6444 10.0815 13.645 10.082C12.3056 10.0834 11.0215 10.6162 10.0747 11.5635C9.12782 12.5108 8.59563 13.7952 8.59497 15.1345ZM16.1975 15.1345C16.1975 15.6394 16.0478 16.1329 15.7673 16.5526C15.4868 16.9724 15.0882 17.2995 14.6218 17.4927C14.1554 17.6859 13.6421 17.7365 13.147 17.638C12.6519 17.5395 12.1971 17.2964 11.8401 16.9394C11.4831 16.5824 11.24 16.1276 11.1415 15.6325C11.043 15.1374 11.0936 14.6241 11.2868 14.1577C11.48 13.6913 11.8071 13.2927 12.2269 13.0122C12.6466 12.7317 13.1401 12.582 13.645 12.582C14.3217 12.5827 14.9706 12.8518 15.4491 13.3304C15.9277 13.8089 16.1968 14.4578 16.1975 15.1345Z" />
      <path d="M34.325 9.94213L38.385 5.88463C38.6198 5.64991 38.7516 5.33157 38.7516 4.99963C38.7516 4.66769 38.6198 4.34934 38.385 4.11463C38.1503 3.87991 37.832 3.74805 37.5 3.74805C37.1681 3.74805 36.8498 3.87991 36.615 4.11463L32.5575 8.17463L28.5 4.11463C28.3838 3.99857 28.2459 3.90654 28.0941 3.8438C27.9423 3.78105 27.7797 3.74881 27.6154 3.74893C27.4512 3.74905 27.2886 3.78151 27.1369 3.84447C26.9852 3.90743 26.8474 3.99966 26.7313 4.11588C26.4969 4.35059 26.3654 4.6688 26.3656 5.00051C26.3658 5.33221 26.4978 5.65024 26.7325 5.88463L30.79 9.94213L26.7325 13.9996C26.5048 14.2354 26.3789 14.5511 26.3817 14.8789C26.3846 15.2066 26.516 15.5201 26.7478 15.7519C26.9795 15.9837 27.2931 16.1151 27.6208 16.118C27.9485 16.1208 28.2643 15.9948 28.5 15.7671L32.5575 11.7096L36.615 15.7671C36.8494 16.0018 37.1675 16.1338 37.4992 16.1341C37.8309 16.1343 38.1491 16.0028 38.3838 15.7684C38.6185 15.534 38.7505 15.216 38.7507 14.8843C38.751 14.5526 38.6194 14.2343 38.385 13.9996L34.325 9.94213Z" />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
