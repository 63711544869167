import { withIcon } from "@icons/_withIcon";

export const TimeToVisitFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M29.043 22.5235C27.4224 22.5235 25.8382 23.0041 24.4907 23.9045C23.1432 24.8048 22.0929 26.0846 21.4727 27.5818C20.8525 29.0791 20.6903 30.7266 21.0064 32.3161C21.3226 33.9056 22.103 35.3656 23.249 36.5116C24.3949 37.6575 25.855 38.4379 27.4444 38.7541C29.0339 39.0703 30.6815 38.908 32.1787 38.2878C33.676 37.6676 34.9557 36.6174 35.8561 35.2699C36.7564 33.9224 37.237 32.3381 37.237 30.7175C37.2367 28.5444 36.3734 26.4604 34.8367 24.9238C33.3001 23.3872 31.2161 22.5238 29.043 22.5235ZM33.274 29.0175L28.45 33.8365C28.2625 34.024 28.0082 34.1293 27.743 34.1293C27.4778 34.1293 27.2235 34.024 27.036 33.8365L24.812 31.6175C24.7191 31.5247 24.6454 31.4144 24.5951 31.2931C24.5448 31.1718 24.5188 31.0417 24.5188 30.9104C24.5188 30.779 24.5446 30.649 24.5948 30.5276C24.645 30.4062 24.7187 30.2959 24.8115 30.203C24.9043 30.1101 25.0146 30.0364 25.1359 29.9861C25.2572 29.9358 25.3873 29.9099 25.5186 29.9098C25.65 29.9098 25.7801 29.9356 25.9014 29.9858C26.0228 30.036 26.1331 30.1097 26.226 30.2025L27.743 31.7175L31.86 27.6005C32.0479 27.413 32.3026 27.3078 32.5681 27.3081C32.8335 27.3084 33.088 27.4141 33.2755 27.602C33.463 27.7899 33.5682 28.0446 33.5679 28.3101C33.5676 28.5755 33.4619 28.83 33.274 29.0175Z"
        fill={accent}
      />
      <path
        d="M13.072 2.28354C12.8068 2.28354 12.5524 2.3889 12.3649 2.57643C12.1774 2.76397 12.072 3.01832 12.072 3.28354V4.52954H8.334C7.0084 4.53113 5.73756 5.05842 4.80022 5.99576C3.86288 6.9331 3.33559 8.20394 3.334 9.52954V33.1515C3.33559 34.4771 3.86288 35.748 4.80022 36.6853C5.73756 37.6227 7.0084 38.1499 8.334 38.1515H19.85C20.1152 38.1515 20.3696 38.0462 20.5571 37.8586C20.7446 37.6711 20.85 37.4168 20.85 37.1515C20.85 36.8863 20.7446 36.632 20.5571 36.4444C20.3696 36.2569 20.1152 36.1515 19.85 36.1515H8.334C7.53835 36.1515 6.77529 35.8355 6.21268 35.2729C5.65007 34.7102 5.334 33.9472 5.334 33.1515V14.8965H34.782V21.5235C34.782 21.7888 34.8874 22.0431 35.0749 22.2306C35.2624 22.4182 35.5168 22.5235 35.782 22.5235C36.0472 22.5235 36.3016 22.4182 36.4891 22.2306C36.6766 22.0431 36.782 21.7888 36.782 21.5235V9.52954C36.7804 8.20394 36.2531 6.9331 35.3158 5.99576C34.3784 5.05842 33.1076 4.53113 31.782 4.52954H28.043V3.28354C28.043 3.01832 27.9376 2.76397 27.7501 2.57643C27.5626 2.3889 27.3082 2.28354 27.043 2.28354C26.7778 2.28354 26.5234 2.3889 26.3359 2.57643C26.1484 2.76397 26.043 3.01832 26.043 3.28354V4.52954H14.072V3.28354C14.072 3.01832 13.9666 2.76397 13.7791 2.57643C13.5916 2.3889 13.3372 2.28354 13.072 2.28354ZM26.043 6.52954V7.77654C26.043 8.04176 26.1484 8.29611 26.3359 8.48365C26.5234 8.67118 26.7778 8.77654 27.043 8.77654C27.3082 8.77654 27.5626 8.67118 27.7501 8.48365C27.9376 8.29611 28.043 8.04176 28.043 7.77654V6.52954H31.782C32.5777 6.52954 33.3407 6.84561 33.9033 7.40822C34.4659 7.97083 34.782 8.73389 34.782 9.52954V12.8965H5.334V9.52954C5.334 8.73389 5.65007 7.97083 6.21268 7.40822C6.77529 6.84561 7.53835 6.52954 8.334 6.52954H12.072V7.77654C12.072 8.04176 12.1774 8.29611 12.3649 8.48365C12.5524 8.67118 12.8068 8.77654 13.072 8.77654C13.3372 8.77654 13.5916 8.67118 13.7791 8.48365C13.9666 8.29611 14.072 8.04176 14.072 7.77654V6.52954H26.043Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
