import { withIcon } from "@icons/_withIcon";

// Change log: previous name: "Sparkles"
export const SparklesFill = withIcon(
  () => (
    <>
      <path d="M5.51876 12.8731L2.95002 15.4648L5.51868 18.0565L8.08741 15.4648L5.51876 12.8731Z" />
      <path d="M18.8708 6.28654H17.5374C17.5354 7.92603 16.889 9.49778 15.7399 10.6571C14.5909 11.8164 13.0331 12.4686 11.4081 12.4706V13.8159C13.0331 13.818 14.5909 14.4702 15.7399 15.6295C16.8889 16.7888 17.5353 18.3605 17.5374 20H18.8708C18.8728 18.3605 19.5192 16.7888 20.6682 15.6295C21.8172 14.4702 23.3751 13.818 25 13.8159V12.4706C23.3751 12.4685 21.8173 11.8163 20.6682 10.6571C19.5192 9.49776 18.8728 7.92602 18.8708 6.28654Z" />
      <path d="M4.82057 0H3.48717C3.48628 0.932853 3.11859 1.82724 2.46481 2.48687C1.81104 3.14649 0.92458 3.51747 0 3.51837V4.8637C0.924578 4.8646 1.81103 5.23557 2.46481 5.8952C3.11859 6.55482 3.48627 7.44921 3.48717 8.38206H4.82057C4.82146 7.44921 5.18915 6.55482 5.84292 5.8952C6.4967 5.23557 7.38315 4.8646 8.30773 4.8637V3.51837C7.38315 3.51747 6.49669 3.14649 5.84292 2.48687C5.18914 1.82724 4.82146 0.932851 4.82057 0Z" />
    </>
  ),
  {
    viewBox: "0 0 25 20",
  }
);
