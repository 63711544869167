import { withIcon } from "@icons/_withIcon";

export const BookCheckmark = withIcon(
  () => (
    <>
      <path
        d="M9.50133 14.6001C7.69122 14.2641 5.83418 14.2702 4.02633 14.6181C3.84327 14.6533 3.67988 14.7554 3.56802 14.9046C3.45616 15.0537 3.40383 15.2391 3.42125 15.4247C3.43867 15.6103 3.52458 15.7828 3.66223 15.9085C3.79988 16.0342 3.97942 16.1041 4.16583 16.1046C4.21607 16.1047 4.26623 16.1007 4.31583 16.0926C5.93978 15.7792 7.60812 15.7731 9.23433 16.0746C9.42763 16.1056 9.62537 16.0597 9.78533 15.9469C9.94529 15.834 10.0548 15.6631 10.0904 15.4706C10.1261 15.2781 10.085 15.0793 9.97608 14.9167C9.86713 14.754 9.6989 14.6404 9.50733 14.6001H9.50133Z"
        fill="currentColor"
      />
      <path
        d="M9.50133 8.87126C7.69136 8.53371 5.83403 8.53982 4.02633 8.88926C3.84327 8.92447 3.67988 9.02658 3.56802 9.1757C3.45616 9.32482 3.40383 9.51025 3.42125 9.69584C3.43867 9.88144 3.52458 10.0539 3.66223 10.1796C3.79988 10.3053 3.97942 10.3752 4.16583 10.3758C4.21614 10.3761 4.26638 10.3716 4.31583 10.3623C5.93985 10.0496 7.60805 10.0434 9.23433 10.3443C9.42499 10.3697 9.61814 10.3209 9.77386 10.208C9.92958 10.0951 10.036 9.92667 10.071 9.73753C10.1061 9.5484 10.0671 9.35303 9.9622 9.19181C9.85729 9.03058 9.69445 8.91583 9.50733 8.87126H9.50133Z"
        fill="currentColor"
      />
      <path
        d="M1.5 5L3.45335 4.4419C5.60801 3.82628 7.89199 3.82628 10.0467 4.4419L12 5V21.5L10.0467 20.9419C7.89199 20.3263 5.60801 20.3263 3.45335 20.9419L1.5 21.5V5Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 6.49988V4.99993L22.195 4.91278C18.963 3.98936 15.537 3.98936 12.305 4.91278L12 4.99993V21.4999L12.305 21.4128C15.537 20.4894 18.963 20.4894 22.195 21.4128L22.5 21.4999V13.9999"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13.4429L16.9853 15.5L22.5 9.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  {
    viewBox: "0 0 24 25",
    fill: "none",
  }
);
