import { withIcon } from "@icons/_withIcon";

export const Phone = withIcon(
  () => (
    <>
      <path
        d="M1.02789 6.91439C0.535122 6.07122 0.673044 5.00178 1.36361 4.31121L3.13026 2.54456C3.52079 2.15403 4.15394 2.15403 4.54447 2.54455L6.13745 4.13748C6.58367 4.5837 6.51012 5.32661 5.98505 5.67665L5.01492 6.3234C4.48986 6.67344 4.4163 7.41633 4.8625 7.86255L6.49996 9.50006L8.13747 11.1375C8.58369 11.5837 9.32658 11.5102 9.67661 10.9851L10.3234 10.015C10.6734 9.4899 11.4163 9.41634 11.8625 9.86257L13.3386 11.3387C13.7291 11.7292 13.7291 12.3624 13.3386 12.7529L11.5419 14.5496C10.8682 15.2233 9.82612 15.3607 9.00085 14.8846V14.8846C5.72365 12.9943 2.99599 10.282 1.08704 7.01561L1.02789 6.91439Z"
        stroke="currentColor"
      />
      <path
        d="M14.4456 8.13335C14.9702 6.33829 14.5247 4.31985 13.1091 2.90418C11.6934 1.48851 9.67494 1.04299 7.87988 1.56763"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M12.0903 7.82743C12.3879 6.8094 12.1352 5.66467 11.3323 4.86179C10.5295 4.05892 9.38474 3.80625 8.3667 4.10379"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </>
  ),
  {
    viewBox: "0 0 16 16",
    fill: "transparent",
  }
);
