import { withIcon } from "@icons/_withIcon";

export const Facebook = withIcon(
  ({ primary, white }) => {
    return (
      <path
        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
        fill={white ? "#fff" : primary}
      />
    );
  },
  {
    viewBox: "0 0 24 24",
    primary: "#1877F2",
  }
);

export const FacebookCircle = withIcon(
  ({ primary, accent, white }) => {
    return (
      <>
        <path
          d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
          fill={white ? "#fff" : primary}
        />
        <path
          d="M24 16C24 11.6 20.4 8 16 8C11.6 8 8 11.6 8 16C8 20 10.9 23.3 14.7 23.9V18.3H12.7V16H14.7V14.2C14.7 12.2 15.9 11.1 17.7 11.1C18.6 11.1 19.5 11.3 19.5 11.3V13.3H18.5C17.5 13.3 17.2 13.9 17.2 14.5V16H19.4L19 18.3H17.1V24C21.1 23.4 24 20 24 16Z"
          fill={accent}
        />
      </>
    );
  },
  {
    viewBox: "0 0 32 32",
    primary: "#1877F2",
    accent: "white",
  }
);

export const FacebookFlat = withIcon(
  () => (
    <path d="M9.39739 0H6.20098C4.30421 0 2.19435 0.797856 2.19435 3.5473C2.20359 4.50537 2.19435 5.42293 2.19435 6.45546H0V9.94735H2.26246V19.9999H6.4196V9.88097H9.1633L9.41159 6.44564H6.34769C6.34769 6.44564 6.3545 4.91746 6.34769 4.47374C6.34769 3.3872 7.47892 3.44941 7.54633 3.44941C8.08436 3.44941 9.1304 3.45091 9.39889 3.44941V0H9.39739Z" />
  ),
  {
    viewBox: "0 0 10 20",
  }
);
