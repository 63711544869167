import {
  TracingInstrumentation,
  FaroSessionSpanProcessor,
  FaroTraceExporter,
} from "@grafana/faro-web-tracing";
import {
  ConsoleInstrumentation,
  ErrorsInstrumentation,
  FetchTransport,
  LogLevel,
  WebVitalsInstrumentation,
  initializeFaro as coreInit,
  getWebInstrumentations,
  defaultMetas,
  pageMeta,
} from "@grafana/faro-web-sdk";
import {
  WebTracerProvider,
  BatchSpanProcessor,
} from "@opentelemetry/sdk-trace-web";
import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from "@opentelemetry/core";
import { Resource } from "@opentelemetry/resources";
import { SEMRESATTRS_SERVICE_NAME } from "@opentelemetry/semantic-conventions";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { LongTaskInstrumentation } from "@opentelemetry/instrumentation-long-task";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import {
  diag,
  DiagConsoleLogger,
  DiagLogLevel,
  trace,
  context,
} from "@opentelemetry/api";

const debug = false;
if (process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "development" && debug) {
  diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.ERROR);
}
const appName = "dotcom-frontend-2";
export const browserInstrumentation = ({ getReactPageId }) => {
  const faro = coreInit({
    app: {
      name: appName,
      environment:
        process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "production"
          ? "production"
          : "development",
    },
    instrumentations: [
      ...getWebInstrumentations(),
      new WebVitalsInstrumentation(),
      new ErrorsInstrumentation(),
      new ConsoleInstrumentation({
        disabledLevels: [LogLevel.TRACE, LogLevel.ERROR, LogLevel.DEBUG], // console.log will be captured
      }),
      new TracingInstrumentation(),
    ],
    transports: [
      new FetchTransport({
        url: "/api/logs",
      }),
    ],
    metas: [
      ...defaultMetas,
      {
        page: {
          ...pageMeta()?.page,
          id: getReactPageId(),
        },
      },
    ],
  });

  // set up otel
  const resource = Resource.default().merge(
    new Resource({
      [SEMRESATTRS_SERVICE_NAME]: appName,
      "runtime.name": "browser",
    })
  );
  const provider = new WebTracerProvider({
    resource,
  });
  // The processor sorts through data as it comes in, before it is sent to the exporter
  provider.addSpanProcessor(
    new FaroSessionSpanProcessor(
      new BatchSpanProcessor(new FaroTraceExporter({ ...faro }))
    )
  );

  // A context manager allows OTel to keep the context of function calls across async functions
  // ensuring you don't have disconnected traces
  const contextManager = new ZoneContextManager();
  provider.register({
    contextManager,
    propagator: new CompositePropagator({
      propagators: [
        new W3CBaggagePropagator(),
        new W3CTraceContextPropagator(),
      ],
    }),
  });

  const ignorePatterns = /^(?!\/api\/logs$|\/v1\/traces$).*/;
  const ignoreUrls = [ignorePatterns];
  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        ignoreUrls,
        "@opentelemetry/instrumentation-xml-http-request": {
          propagateTraceHeaderCorsUrls: ["https://*.lonelyplanet.com"],
          ignoreUrls,
        },
        "@opentelemetry/instrumentation-fetch": {
          propagateTraceHeaderCorsUrls: ["https://*.lonelyplanet.com"],
          clearTimingResources: true,
          applyCustomAttributesOnSpan(span) {
            span.setAttribute("app.synthetic_request", "false");
          },
          ignoreUrls,
        },
      }),
      new DocumentLoadInstrumentation({ ignoreUrls }),
      new LongTaskInstrumentation({
        observerCallback: span => {
          span.setAttribute("location.pathname", window.location.pathname);
        },
        ignoreUrls,
      }),
    ],
  });

  try {
    faro?.api.pushLog(["Faro was initialized"]);
    faro.api.initOTEL(trace, context);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error initializing Faro", error);
  }
};
