import { withIcon } from "@icons/_withIcon";

// Changelog: name was Wallet
export const WalletFill = withIcon(
  () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4.5L15 14.5C15 14.6326 14.9473 14.7598 14.8536 14.8536C14.7598 14.9473 14.6326 15 14.5 15L3.5 15C2.83696 15 2.20107 14.7366 1.73223 14.2678C1.26339 13.7989 1 13.163 1 12.5V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H12.5C12.6326 1 12.7598 1.05268 12.8536 1.14645C12.9473 1.24022 13 1.36739 13 1.5V2.733L3.5 2.733C3.33634 2.7395 3.18155 2.80909 3.06806 2.92719C2.95456 3.04528 2.89118 3.20271 2.89118 3.3665C2.89118 3.53029 2.95456 3.68772 3.06806 3.80581C3.18155 3.92391 3.33634 3.9935 3.5 4L14.5 4C14.6326 4 14.7598 4.05268 14.8536 4.14645C14.9473 4.24022 15 4.36739 15 4.5ZM8.5 7.25C7.94771 7.25 7.5 7.69772 7.5 8.25V10.75C7.5 11.3023 7.94771 11.75 8.5 11.75H13.5V7.25H8.5Z"
    />
  ),
  {
    viewBox: "0 0 16 16",
  }
);
export const WalletFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M20.5945 19.2672V28.2672C20.5945 28.7976 20.8052 29.3063 21.1803 29.6814C21.5553 30.0564 22.064 30.2672 22.5945 30.2672H36.5945V17.2672H22.5945C22.064 17.2672 21.5553 17.4779 21.1803 17.8529C20.8052 18.228 20.5945 18.7367 20.5945 19.2672ZM26.5945 21.7672C26.99 21.7672 27.3767 21.8844 27.7056 22.1042C28.0345 22.324 28.2909 22.6363 28.4422 23.0018C28.5936 23.3672 28.6332 23.7694 28.5561 24.1573C28.4789 24.5453 28.2884 24.9017 28.0087 25.1814C27.729 25.4611 27.3726 25.6516 26.9847 25.7287C26.5967 25.8059 26.1946 25.7663 25.8291 25.6149C25.4637 25.4635 25.1513 25.2072 24.9315 24.8783C24.7118 24.5494 24.5945 24.1627 24.5945 23.7672C24.5945 23.2367 24.8052 22.728 25.1803 22.3529C25.5553 21.9779 26.064 21.7672 26.5945 21.7672Z"
        fill={accent}
      />
      <path
        d="M7.59448 38.2672H33.5945C34.9201 38.2656 36.1909 37.7383 37.1283 36.8009C38.0656 35.8636 38.5929 34.5927 38.5945 33.2672V10.2672C38.5945 10.0019 38.4891 9.74758 38.3016 9.56004C38.114 9.37251 37.8597 9.26715 37.5945 9.26715H33.5945V3.26715C33.5945 3.00193 33.4891 2.74758 33.3016 2.56004C33.114 2.37251 32.8597 2.26715 32.5945 2.26715H7.09448C5.90141 2.26847 4.75759 2.74301 3.91396 3.58663C3.07034 4.43026 2.59581 5.57408 2.59448 6.76715V33.2672C2.59607 34.5927 3.12336 35.8636 4.0607 36.8009C4.99804 37.7383 6.26889 38.2656 7.59448 38.2672ZM36.5945 11.2672V33.2672C36.5945 34.0628 36.2784 34.8259 35.7158 35.3885C35.1532 35.9511 34.3901 36.2672 33.5945 36.2672H7.59448C6.79883 36.2672 6.03577 35.9511 5.47316 35.3885C4.91055 34.8259 4.59448 34.0628 4.59448 33.2672V10.5052C5.3334 11.0023 6.20388 11.2676 7.09448 11.2672H36.5945ZM7.09448 4.26715H31.5945V9.26715H7.09448C6.43144 9.26715 5.79556 9.00376 5.32672 8.53492C4.85787 8.06608 4.59448 7.43019 4.59448 6.76715C4.59448 6.10411 4.85787 5.46823 5.32672 4.99938C5.79556 4.53054 6.43144 4.26715 7.09448 4.26715Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  }
);
