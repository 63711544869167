import PropTypes from "prop-types";
import cn from "classnames";
import { tagular } from "@cohesion/tagular";

export function ArticlePanel({ item = { submenu: { items: [] } } }) {
  return (
    <div className={cn("w-contents max-w-full")}>
      <p className="label-sm mb-5 border-b border-black-200 pb-2">
        Articles by interest
      </p>
      <div className="columns-2 gap-x-6">
        {item.submenu?.items.map(
          ({
            title: itemTitle,
            subtitle: itemSubtitle,
            url: suburl,
            icon: Subicon,
          }) => (
            <a
              key={itemTitle}
              href={suburl}
              className="group relative flex items-start py-0 whitespace-nowrap text-black mb-4 last:mb-0"
              onClick={() =>
                tagular("click", {
                  actionOutcome: "INTERNALLINK",
                  outboundUrl: suburl,
                  webElement: {
                    location: "HEADER",
                    elementType: "LINK",
                    position: "GLOBAL SUB MENU",
                    text: itemTitle,
                  },
                })
              }
            >
              {Subicon && (
                <Subicon aria-hidden="true" className="w-6 h-6 mr-2 shrink-0" />
              )}
              <div className="flex-1">
                <p className={cn("flex group-hover:text-blue")}>{itemTitle}</p>
                <p>{itemSubtitle}</p>
              </div>
            </a>
          )
        )}
      </div>
    </div>
  );
}

ArticlePanel.propTypes = {
  item: PropTypes.shape({
    cta: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    featured: PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    submenu: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.elementType,
          subtitle: PropTypes.string,
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
  }),
};
