import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import cn from "classnames";

import { CaretDown } from "@icons";
import { Logo } from "@icons/logos";

import { INTERNATIONAL_SITES } from "@constants/internationalSites";

import { SocialRow } from "@components/socialRow";
import { NewsletterSignup } from "@components/newsletterSignup";
import { LP_URL } from "@constants/lpUrl";
import { tagular } from "@cohesion/tagular";

import { FooterDropdown } from "./footerDropdown";

export function Footer({ sections = [], socials = [] }) {
  /**
   * TODO: There is an issue here where the component is rendering before the
   * data makes it here for hydration, so trying to dynamically set the initial
   * state fails as sections is `undefined.` The solution here is hacky, so
   * consider refactoring to make this less brittle.
   */
  const [openTabs, setOpenTabs] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const toggleTabOpen = i => {
    setOpenTabs({
      ...openTabs,
      [i]: !openTabs[i],
    });
  };

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      tagular("product_view", {
        product: {
          location: "FOOTER_SHOP",
          text: "SHOP FOOTER",
          position: 0,
          brand: "shop",
        },
      });
    }
  }, [inView]);

  return (
    <footer>
      <section>
        <div className="container pt-12 border-t border-black-200 md:pb-4 lg:py-12">
          <div className="justify-between md:flex">
            <div className="text-center md:w-1/4 flex-0 md:text-left">
              <a href="/" className="no-underline">
                <Logo
                  className="mx-auto text-3xl cursor-pointer text-blue w-36 md:text-4xl md:w-56 md:mx-0"
                  label="Lonely Planet Home"
                />
              </a>
              <p className="mt-1 mb-10 md:mt-2">For Explorers Everywhere</p>

              <h3 className="mb-4 text-sm font-semibold uppercase">
                Follow us
              </h3>
              <SocialRow
                className="flex justify-center mx-auto lg:flex-none lg:justify-normal"
                socials={socials}
              />

              <h3 className="mt-10 mb-4 text-sm font-semibold">SUBSCRIBE</h3>
              <NewsletterSignup
                btnText="Subscribe now"
                title="Get 20% off your first order."
                source="Footer-signup"
              />
            </div>
            <div className="mt-10 md:w-3/5 flex-0 md:mt-0">
              <div className="md:grid md:gap-4 grid-cols-3 grid-layout grid-rows-[150px_auto]">
                {sections &&
                  sections.map((section, i) => {
                    const { heading, sublinks } = section;
                    return (
                      <nav
                        key={heading}
                        className={cn(
                          `fn-${i}`,
                          "border-t md:border-t-0 border-black-200"
                        )}
                      >
                        <button
                          ref={heading === "Shop" ? ref : null}
                          className={cn(
                            "w-full flex justify-between items-center pt-5 md:pt-0 md:cursor-default md:pointer-events-none",
                            openTabs[i] === true ? "pb-0" : "pb-5 md:pb-0"
                          )}
                          onClick={() => toggleTabOpen(i)}
                          name={`Open ${heading} menu`}
                          type="button"
                        >
                          <h3 className="text-sm font-semibold leading-none uppercase md:text-left">
                            {heading}
                          </h3>
                          <CaretDown
                            className={cn(
                              "md:hidden text-2xl text-black-300 transform transition-transform",
                              { "rotate-180": openTabs[i] === true }
                            )}
                          />
                        </button>
                        <ul
                          className={cn(
                            "overflow-hidden md:max-h-full transition-all duration-300",
                            {
                              "max-h-0": openTabs[i] === false,
                            },
                            openTabs[i] === false ? "max-h-0 pb-0" : "lg:pb-0",
                            {
                              "pb-12 lg:pb-0": i === 3 && openTabs[3] === true,
                            }
                          )}
                        >
                          {sublinks.map((sublink, subIndex) => {
                            const { href, label } = sublink;

                            return (
                              <li key={`${heading}-${label}`}>
                                <a
                                  href={href.replace(LP_URL, "")}
                                  className="block mt-6 text-sm text-black-400 md:mt-2"
                                  onClick={() => {
                                    if (heading === "Shop") {
                                      tagular("product_click", {
                                        actionOutcome: "EXTERNALLINK",
                                        outboundUrl: href,
                                        product: {
                                          location: "FOOTER_SHOP",
                                          text: label.toUpperCase(),
                                          position: subIndex + 1,
                                          brand: "shop",
                                        },
                                      });
                                    } else {
                                      tagular("click", {
                                        actionOutcome: "INTERNALLINK",
                                        outboundUrl: href,
                                        webElement: {
                                          location: "FOOTER",
                                          elementType: "LINK",
                                          position: heading.toUpperCase(),
                                          text: label.toUpperCase(),
                                        },
                                      });
                                    }
                                  }}
                                >
                                  {label}
                                </a>
                              </li>
                            );
                          })}
                          {sections.length === i + 1 && (
                            <>
                              <li>
                                <button
                                  type="button"
                                  className="hidden mt-6 font-sans text-sm font-light text-left show-cookie-settings text-black-400 md:mt-2"
                                  // eslint-disable-next-line no-undef
                                  onClick={() => Optanon.ToggleInfoDisplay()}
                                >
                                  Cookie Settings
                                </button>
                              </li>
                              <li>
                                <a
                                  href="/do-not-sell-or-share-my-personal-information"
                                  className="block mt-6 text-sm text-black-400 md:mt-2"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Do Not Sell or Share My Personal Information
                                </a>
                              </li>
                            </>
                          )}
                        </ul>
                      </nav>
                    );
                  })}
                {/**
                 * TODO: Convert this to a module once that work is rolled in.
                 */}

                <style jsx>{`
                  :global(.lp_consent) .show-cookie-settings {
                    display: block;
                  }
                  .grid-layout {
                    grid-template-areas:
                      "l c tr"
                      "l c br";
                  }
                  .fn-0 {
                    grid-area: l;
                  }
                  .fn-1 {
                    grid-area: c;
                  }
                  .fn-2 {
                    grid-area: tr;
                  }
                  .fn-3 {
                    grid-area: br;
                  }
                `}</style>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 bg-black">
        <div className="container flex flex-col-reverse items-center justify-between md:flex-row">
          <p className="text-sm text-white">
            &copy; {new Date().getFullYear()} Lonely Planet, a Red Ventures
            company. All rights reserved. No part of this site may be reproduced
            without our written permission.
          </p>
          <div className="inline-flex items-center w-full mb-6 md:w-96 md:mb-0">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="language-dropdown"
              className="mr-6 font-semibold text-white uppercase"
            >
              Language
            </label>
            <FooterDropdown
              id="language-dropdown"
              className="flex-auto"
              options={INTERNATIONAL_SITES}
              onSelect={val => {
                window.location = `https://${
                  INTERNATIONAL_SITES.find(site => site.label === val.label).url
                }`;
              }}
              ghosted
            />
          </div>
        </div>
      </section>
    </footer>
  );
}

Footer.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  socials: PropTypes.arrayOf(PropTypes.shape({})),
};
