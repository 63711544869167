import { withIcon } from "@icons/_withIcon";

export const WaterSportFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M58.508 82.6721H68.3573C69.7603 82.6721 70.8957 81.5367 70.8957 80.1336V79.4413H85.1665C88.6004 79.4413 91.3973 76.6444 91.3973 73.2106C91.3973 69.7767 88.6004 66.9798 85.1665 66.9798H70.8957V66.2875C70.8957 64.8844 69.7603 63.749 68.3573 63.749H53.4311H43.5818C42.1787 63.749 41.0433 64.8844 41.0433 66.2875V66.9798H27.6956C24.2618 66.9798 21.4648 69.7767 21.4648 73.2106C21.4648 76.6444 24.2618 79.4413 27.6956 79.4413H41.0433V80.1336C41.0433 81.5367 42.1787 82.6721 43.5818 82.6721H58.508ZM85.1665 74.3644H70.8957V72.0567H85.1665C85.8034 72.0567 86.3204 72.5736 86.3204 73.2106C86.3204 73.8475 85.8034 74.3644 85.1665 74.3644ZM65.8188 68.8259V77.5952H46.1203V68.8259H65.8188ZM41.0433 72.0567V74.3644H27.6956C27.0587 74.3644 26.5418 73.8475 26.5418 73.2106C26.5418 72.5736 27.0587 72.0567 27.6956 72.0567H41.0433Z"
        fill={accent}
      />
      <path
        d="M58.508 106.11H68.3573C69.7603 106.11 70.8957 104.974 70.8957 103.571V102.879H85.1665C88.6004 102.879 91.3973 100.082 91.3973 96.6481C91.3973 93.2142 88.6004 90.4173 85.1665 90.4173H70.8957V89.725C70.8957 88.3219 69.7603 87.1865 68.3573 87.1865H53.4311H43.5818C42.1787 87.1865 41.0433 88.3219 41.0433 89.725V90.4173H27.6956C24.2618 90.4173 21.4648 93.2142 21.4648 96.6481C21.4648 100.082 24.2618 102.879 27.6956 102.879H41.0433V103.571C41.0433 104.974 42.1787 106.11 43.5818 106.11H58.508ZM85.1665 97.8019H70.8957V95.4942H85.1665C85.8034 95.4942 86.3204 96.0111 86.3204 96.6481C86.3204 97.285 85.8034 97.8019 85.1665 97.8019ZM65.8188 92.2634V101.033H46.1203V92.2634H65.8188ZM41.0433 95.4942V97.8019H27.6956C27.0587 97.8019 26.5418 97.285 26.5418 96.6481C26.5418 96.0111 27.0587 95.4942 27.6956 95.4942H41.0433Z"
        fill={accent}
      />
      <path
        d="M110.669 56.8354L92.6971 35.3738V3.03846C92.6971 1.63538 91.5617 0.5 90.1586 0.5H74.3648C73.3217 0.5 72.3709 1.15538 71.9924 2.13385L68.0601 12.4077L66.6478 12.9985C59.817 15.8508 51.9709 15.8508 45.1401 12.9985L43.7185 12.4077L39.7862 2.13385C39.4078 1.15538 38.457 0.5 37.4139 0.5H21.62C20.217 0.5 19.0816 1.63538 19.0816 3.03846V35.3738L1.09077 56.8354C0.712308 57.2877 0.5 57.8692 0.5 58.4692V117.962C0.5 119.365 1.63539 120.5 3.03847 120.5H108.722C110.125 120.5 111.26 119.365 111.26 117.962V58.4692C111.26 57.8692 111.048 57.2969 110.669 56.8446V56.8354ZM65.614 18.7492L55.8755 44.1615L46.137 18.7492C52.4786 20.7062 59.2632 20.7062 65.6047 18.7492H65.614ZM5.57693 68.2262H11.0416V76.9954H5.57693V68.2262ZM5.57693 91.5339H11.0416V100.303H5.57693V91.5339ZM106.183 100.303H100.719V91.5339H106.183V100.303ZM106.183 76.9954H100.719V68.2262H106.183V76.9954ZM106.183 63.1492H98.1802C96.7771 63.1492 95.6417 64.2846 95.6417 65.6877V79.5339C95.6417 80.9369 96.7771 82.0723 98.1802 82.0723H106.183V86.4569H98.1802C96.7771 86.4569 95.6417 87.5923 95.6417 88.9954V102.842C95.6417 104.245 96.7771 105.38 98.1802 105.38H106.183V115.423H58.5063V105.103H53.337V115.423H5.57693V105.38H13.58C14.9831 105.38 16.1185 104.245 16.1185 102.842V88.9954C16.1185 87.5923 14.9831 86.4569 13.58 86.4569H5.57693V82.0723H13.58C14.9831 82.0723 16.1185 80.9369 16.1185 79.5339V65.6877C16.1185 64.2846 14.9831 63.1492 13.58 63.1492H5.57693V59.3831L23.5585 37.9215C23.937 37.4692 24.1493 36.8877 24.1493 36.2877V5.57692H35.6601L53.3463 51.7308V63.7492H58.5155V51.5L76.1002 5.57692H87.6109V36.2969C87.6109 36.8877 87.8232 37.4692 88.2017 37.9215L106.174 59.3831V63.1492H106.183Z"
        fill={primary}
      />
      <path
        d="M58.5149 82.6816H53.3457V87.3247H58.5149V82.6816Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 121 121",
    fill: "none",
  }
);
