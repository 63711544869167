import { withIcon } from "@icons/_withIcon";

export const Expand = withIcon(
  () => (
    <path
      d="M10.3333 1H15M15 1V5.66667M15 1L9.55556 6.44444M5.66667 15H1M1 15V10.3333M1 15L6.44444 9.55556"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  {
    viewBox: "0 0 16 16",
  }
);
