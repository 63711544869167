import { useState, useEffect } from "react";
import useSWR from "swr";
import PropTypes from "prop-types";

import { swrFetch } from "@queries/swrFetch";

import { GlobalNav } from "@components/globalNav";
import { Footer } from "@components/footer";
import { AlertBanner } from "@components/alertBanner";
import { UserProvider } from "@auth0/nextjs-auth0/client";

import { CookieContextProvider } from "@context/cookies";
import { UserContextProvider } from "@context/user";
import { AdContextProvider } from "@components/ad/context";

import { navbar, footer } from "./_data";

export function Layout({
  adTargeting = null,
  disableAlert = false,
  disableNav = false,
  navbarClassName = "",
  GPTReady,
  children,
}) {
  /**
   * Reminder: This is client-side.
   */
  const { data } = useSWR(`/api/layout`, swrFetch);

  const [showAlert, setShowAlert] = useState(false);

  const week = 7 * 86400;

  const handleAlertClose = () => {
    setShowAlert(false);
    document.cookie = `alertDismissed=true;max-age=${week};path=/`;
  };

  useEffect(() => {
    if (!data?.alertDismissed) {
      setShowAlert(true);
    }
  }, [data]);

  return (
    <UserProvider>
      <UserContextProvider>
        <AdContextProvider GPTReady={GPTReady} adTargeting={adTargeting}>
          <CookieContextProvider cookies={data?.cookies}>
            {showAlert && !disableAlert && (
              <AlertBanner alert={data?.alert} onClose={handleAlertClose} />
            )}
            <div className="relative">
              {!disableNav && (
                <GlobalNav nav={navbar} className={navbarClassName} />
              )}

              <main>{children}</main>
            </div>
            <Footer {...footer} />
          </CookieContextProvider>
        </AdContextProvider>
      </UserContextProvider>
    </UserProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  disableAlert: PropTypes.bool,
  disableNav: PropTypes.bool,
  navbarClassName: PropTypes.string,
  GPTReady: PropTypes.bool.isRequired,
  adTargeting: PropTypes.shape({}),
};
