import PropTypes from "prop-types";
import Image from "next/image";
import {
  AttractionsFeature,
  Bed,
  Cutlery,
  DrinkMenu,
  MapPinCircleFeature,
  ShopFeature,
  Ticket,
} from "@icons";

import { LogoGlobe } from "@icons/logos";
import cn from "classnames";

import { imgixLoader } from "@components/imgixNextImage";

export const placeholders = {
  destination: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-destination.jpg",
    Icon: MapPinCircleFeature,
  },
  attractions: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-attractions.jpg",
    Icon: AttractionsFeature,
  },
  restaurants: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-restaurants.jpg",
    Icon: Cutlery,
  },
  entertainment: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-entertainment.jpg",
    Icon: Ticket,
  },
  nightlife: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-nightlife.jpg",
    Icon: DrinkMenu,
  },
  shopping: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-shopping.jpg",
    Icon: ShopFeature,
  },
  hotels: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-hotels.jpg",
    Icon: Bed,
  },
  general: {
    src: "https://lonelyplanetstatic.imgix.net/marketing/placeholders/placeholder-general.jpg",
    Icon: LogoGlobe,
  },
};

export function ImagePlaceholder({
  type = "general",
  className = null,
  srcParams = "",
  ...imgProps
}) {
  const { src, Icon } = placeholders[type] || placeholders.general;
  const imgixSrc = `${src}${srcParams}`;
  return (
    <div
      className={cn(
        "relative flex max-w-full items-center justify-center overflow-hidden",
        className
      )}
    >
      <Image
        loader={imgixLoader}
        src={imgixSrc}
        alt=""
        className={cn("max-w-full w-full h-full object-cover", className)}
        {...imgProps}
      />
      <Icon
        mono
        className="absolute top-[50%] left-0 right-0 w-24 max-w-[30%] h-auto mx-auto text-white aspect-square transform translate-y-[-50%]"
      />
    </div>
  );
}

ImagePlaceholder.propTypes = {
  type: PropTypes.oneOf([...Object.keys(placeholders)]),
  className: PropTypes.string,
  srcParams: PropTypes.string,
};
