import { withIcon } from "@icons/_withIcon";

export const TranquilityFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M60.4957 120.5C27.4174 120.5 0.499023 93.5816 0.499023 60.5033C0.499023 27.425 27.4174 0.5 60.4957 0.5C93.574 0.5 120.499 27.4184 120.499 60.5033C120.499 93.5883 93.5807 120.5 60.4957 120.5ZM60.4957 5.79889C30.3384 5.79889 5.79791 30.3394 5.79791 60.5033C5.79791 90.6672 30.3384 115.201 60.4957 115.201C90.653 115.201 115.2 90.6606 115.2 60.5033C115.2 30.346 90.6596 5.79889 60.4957 5.79889Z"
        fill={primary}
      />
      <path
        d="M61.7207 96.039C34.0009 96.039 26.5228 76.7576 26.2182 75.9231C25.7081 74.552 26.4102 73.0285 27.7813 72.5185C29.1458 72.0151 30.6692 72.704 31.1859 74.0684C31.4641 74.7904 38.0678 91.27 62.913 90.7202C87.6257 90.1969 92.0105 74.9693 92.1827 74.3268C92.5603 72.9226 94.0108 72.0747 95.415 72.4457C96.8259 72.8166 97.6737 74.2473 97.3094 75.6581C97.1041 76.4662 91.9244 95.4097 63.019 96.0191C62.5818 96.0257 62.1513 96.0323 61.7207 96.0323V96.039Z"
        fill={accent}
      />
      <path
        d="M39.0354 61.9804C32.9748 61.9804 28.0469 57.0524 28.0469 50.9918C28.0469 49.528 29.2325 48.3423 30.6963 48.3423C32.1601 48.3423 33.3458 49.528 33.3458 50.9918C33.3458 54.1314 35.9025 56.6815 39.0354 56.6815C42.1684 56.6815 44.7251 54.1248 44.7251 50.9918C44.7251 49.528 45.9108 48.3423 47.3746 48.3423C48.8384 48.3423 50.024 49.528 50.024 50.9918C50.024 57.0524 45.0961 61.9804 39.0354 61.9804Z"
        fill={accent}
      />
      <path
        d="M81.8567 61.9804C75.7961 61.9804 70.8682 57.0524 70.8682 50.9918C70.8682 49.528 72.0538 48.3423 73.5176 48.3423C74.9814 48.3423 76.1671 49.528 76.1671 50.9918C76.1671 54.1314 78.7238 56.6815 81.8567 56.6815C84.9897 56.6815 87.5464 54.1248 87.5464 50.9918C87.5464 49.528 88.732 48.3423 90.1959 48.3423C91.6597 48.3423 92.8453 49.528 92.8453 50.9918C92.8453 57.0524 87.9173 61.9804 81.8567 61.9804Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 121 121",
    fill: "none",
  }
);
