import { withIcon } from "@icons/_withIcon";

export const Feedback = withIcon(
  () => (
    <>
      <path d="M5.37917 8.76248L3.96417 10.1765L3.61941 9.83178C3.52533 9.73958 3.39867 9.68824 3.26696 9.68891C3.13524 9.68957 3.0091 9.74219 2.91596 9.83533C2.82282 9.92847 2.7702 10.0546 2.76954 10.1863C2.76887 10.318 2.82022 10.4447 2.91241 10.5388L3.61065 11.237C3.65706 11.2835 3.71217 11.3203 3.77283 11.3454C3.83348 11.3706 3.89849 11.3835 3.96415 11.3835C4.02981 11.3835 4.09482 11.3706 4.15547 11.3454C4.21613 11.3203 4.27124 11.2835 4.31765 11.237L6.0862 9.46951C6.17839 9.37543 6.22974 9.24877 6.22907 9.11705C6.22841 8.98534 6.17579 8.8592 6.08265 8.76606C5.98951 8.67292 5.86337 8.6203 5.73165 8.61964C5.59994 8.61897 5.47328 8.67031 5.3792 8.76251L5.37917 8.76248Z" />
      <path d="M12.8512 5.64744C12.8047 5.60101 12.7496 5.56417 12.689 5.53904C12.6283 5.51391 12.5633 5.50098 12.4977 5.50098C12.432 5.50098 12.367 5.51391 12.3063 5.53904C12.2457 5.56417 12.1906 5.60101 12.1442 5.64744L11.4986 6.29298L10.8531 5.64747C10.759 5.55528 10.6324 5.50394 10.5007 5.5046C10.3689 5.50527 10.2428 5.55789 10.1497 5.65103C10.0565 5.74417 10.0039 5.8703 10.0032 6.00202C10.0026 6.13374 10.0539 6.2604 10.1461 6.35447L10.7916 6.99998L10.1461 7.64549C10.0989 7.69175 10.0614 7.74689 10.0356 7.80775C10.0099 7.8686 9.99643 7.93396 9.9961 8.00004C9.99577 8.06612 10.0085 8.13161 10.0337 8.19272C10.0588 8.25383 10.0958 8.30936 10.1425 8.35608C10.1893 8.40281 10.2448 8.43981 10.3059 8.46494C10.367 8.49007 10.4325 8.50284 10.4986 8.50251C10.5646 8.50217 10.63 8.48874 10.6909 8.463C10.7517 8.43725 10.8069 8.39969 10.8531 8.35249L11.4986 7.70698L12.1441 8.35249C12.2382 8.44469 12.3649 8.49603 12.4966 8.49537C12.6283 8.4947 12.7544 8.44208 12.8476 8.34894C12.9407 8.2558 12.9933 8.12967 12.994 7.99795C12.9947 7.86623 12.9433 7.73957 12.8511 7.64549L12.2056 6.99998L12.8511 6.35447C12.8976 6.30806 12.9344 6.25295 12.9596 6.19229C12.9847 6.13164 12.9976 6.06662 12.9976 6.00097C12.9976 5.93531 12.9847 5.87029 12.9596 5.80963C12.9344 5.74897 12.8976 5.69386 12.8512 5.64744Z" />
      <path d="M14 3.00024H10.1182L8.22363 2.05297C8.1474 2.01476 8.06267 1.9967 7.97749 2.0005C7.89231 2.00429 7.80952 2.02982 7.73699 2.07465C7.66446 2.11947 7.60461 2.18212 7.56313 2.25661C7.52165 2.3311 7.49991 2.41497 7.5 2.50024V6.09233C7.33988 6.03307 7.17073 6.00191 7 6.00023H2C1.60231 6.00067 1.22103 6.15884 0.939819 6.44005C0.658609 6.72126 0.500434 7.10254 0.5 7.50023V12.5002C0.500434 12.8979 0.658609 13.2792 0.939819 13.5604C1.22103 13.8416 1.60231 13.9998 2 14.0002H5.88184L7.77637 14.9475C7.8526 14.9857 7.93733 15.0038 8.02251 15C8.10769 14.9962 8.19048 14.9707 8.26301 14.9258C8.33554 14.881 8.39539 14.8184 8.43687 14.7439C8.47835 14.6694 8.50009 14.5855 8.5 14.5002V10.9081C8.66012 10.9674 8.82927 10.9986 9 11.0002H14C14.3977 10.9998 14.779 10.8416 15.0602 10.5604C15.3414 10.2792 15.4996 9.89793 15.5 9.50023V4.50024C15.4996 4.10254 15.3414 3.72126 15.0602 3.44005C14.779 3.15884 14.3977 3.00067 14 3.00024ZM7.5 13.6916L6.22363 13.053C6.15411 13.0185 6.0776 13.0005 6 13.0002H2C1.86744 13.0001 1.74037 12.9473 1.64664 12.8536C1.55291 12.7599 1.50017 12.6328 1.5 12.5002V7.50023C1.50017 7.36768 1.55291 7.2406 1.64664 7.14687C1.74037 7.05314 1.86744 7.00041 2 7.00023H7C7.13256 7.00041 7.25963 7.05314 7.35336 7.14687C7.44709 7.2406 7.49983 7.36768 7.5 7.50023V13.6916ZM14.5 9.50023C14.4998 9.63279 14.4471 9.75987 14.3534 9.8536C14.2596 9.94733 14.1326 10.0001 14 10.0002H9C8.86744 10.0001 8.74037 9.94733 8.64664 9.8536C8.55291 9.75987 8.50017 9.63279 8.5 9.50023V3.30883L9.77637 3.9475C9.84589 3.98197 9.9224 4.00001 10 4.00024H14C14.1326 4.00041 14.2596 4.05314 14.3534 4.14687C14.4471 4.2406 14.4998 4.36768 14.5 4.50024V9.50023Z" />
    </>
  ),
  {
    viewBox: "0 0 16 17",
  }
);
