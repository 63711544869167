import { withIcon } from "@icons/_withIcon";

export const Gear = withIcon(
  ({ primary }) => (
    <>
      <path
        d="M8.99951 1H6.99951L6.75816 2.12632C6.61324 2.80258 6.09172 3.32524 5.49353 3.67237C4.8955 4.01942 4.19318 4.2012 3.53523 3.98862L2.4375 3.63395L1.4375 5.366L2.29216 6.13811C2.80556 6.60191 2.99951 7.30813 2.99951 8C2.99951 8.69192 2.80555 9.39819 2.29212 9.86202L1.43766 10.634L2.43766 12.366L3.53523 12.0114C4.19319 11.7988 4.8955 11.9806 5.49354 12.3276C6.09172 12.6748 6.61324 13.1974 6.75816 13.8737L6.99951 15H8.99951L9.24085 13.8737C9.38577 13.1974 9.90733 12.6748 10.5055 12.3276C11.1036 11.9805 11.806 11.7987 12.464 12.0113L13.5619 12.366L14.5619 10.634L13.7067 9.8614C13.1934 9.39772 12.9995 8.6917 12.9995 8C12.9995 7.30835 13.1934 6.60238 13.7067 6.13872L14.562 5.366L13.562 3.63395L12.464 3.9887C11.806 4.2013 11.1036 4.0195 10.5055 3.67241C9.90733 3.32524 9.38577 2.80256 9.24085 2.12626L8.99951 1Z"
        stroke={primary}
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.9995 8C10.9995 9.65685 9.65637 11 7.99951 11C6.34266 11 4.99951 9.65685 4.99951 8C4.99951 6.34315 6.34266 5 7.99951 5C9.65637 5 10.9995 6.34315 10.9995 8Z"
        stroke={primary}
        strokeLinejoin="round"
        fill="none"
      />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);
