import { withIcon } from "@icons/_withIcon";

export const OpenBook = withIcon(
  () => (
    <>
      <path d="M15.1629 2.18002C12.8692 1.25464 10.3102 1.23142 7.99995 2.11502C5.68973 1.23142 3.13075 1.25464 0.836949 2.18002C0.744262 2.21717 0.66482 2.28121 0.608856 2.36391C0.552893 2.44661 0.522973 2.54417 0.522949 2.64402V14.031C0.522903 14.1129 0.542952 14.1935 0.581337 14.2658C0.619721 14.3381 0.675268 14.3998 0.743099 14.4457C0.81093 14.4915 0.888971 14.5199 0.97037 14.5285C1.05177 14.5371 1.13404 14.5256 1.20995 14.495C3.32704 13.6409 5.69285 13.6409 7.80995 14.495C7.83964 14.5039 7.87011 14.5099 7.90095 14.513C7.92232 14.5185 7.94404 14.5225 7.96595 14.525C7.97695 14.525 7.98695 14.531 7.99695 14.531C8.00695 14.531 8.03695 14.515 8.05795 14.513C8.10055 14.5125 8.14291 14.5065 8.18395 14.495C10.301 13.6409 12.6669 13.6409 14.7839 14.495C14.8433 14.5192 14.9069 14.5315 14.9709 14.531C15.1036 14.531 15.2307 14.4783 15.3245 14.3846C15.4183 14.2908 15.4709 14.1636 15.4709 14.031V2.64402C15.4714 2.54486 15.4424 2.44779 15.3875 2.36517C15.3327 2.28255 15.2545 2.2181 15.1629 2.18002ZM1.52295 13.32V2.98802C3.45427 2.2907 5.56863 2.2907 7.49995 2.98802V13.32C5.55606 12.6987 3.46684 12.6987 1.52295 13.32ZM14.4769 13.32C12.5331 12.6987 10.4438 12.6987 8.49995 13.32V2.98802C10.4313 2.2907 12.5456 2.2907 14.4769 2.98802V13.32Z" />
      <path d="M12.3648 10.271C11.4627 10.1943 10.5542 10.2414 9.66484 10.411C9.54272 10.4345 9.43373 10.5027 9.35916 10.6022C9.28458 10.7017 9.24977 10.8255 9.26151 10.9493C9.27326 11.0731 9.33071 11.188 9.42267 11.2718C9.51463 11.3555 9.63448 11.4019 9.75884 11.402C9.79233 11.402 9.82577 11.3994 9.85884 11.394C10.6586 11.2413 11.4756 11.1989 12.2868 11.268C12.3523 11.2737 12.4182 11.2663 12.4809 11.2464C12.5435 11.2266 12.6016 11.1945 12.6519 11.1522C12.7021 11.1098 12.7435 11.058 12.7737 10.9996C12.8039 10.9413 12.8223 10.8775 12.8278 10.812C12.8334 10.746 12.8259 10.6796 12.8055 10.6165C12.7852 10.5534 12.7526 10.495 12.7095 10.4447C12.6664 10.3944 12.6138 10.3531 12.5546 10.3233C12.4954 10.2935 12.4309 10.2757 12.3648 10.271Z" />
      <path d="M13.3119 7.58109C12.105 7.35605 10.8664 7.36012 9.66093 7.59309C9.53881 7.61658 9.42983 7.68474 9.35525 7.78425C9.28068 7.88377 9.24586 8.00751 9.25761 8.13131C9.26935 8.25511 9.32681 8.37009 9.41876 8.45381C9.51072 8.53754 9.63057 8.58398 9.75493 8.58409C9.78847 8.58423 9.82195 8.58122 9.85493 8.57509C10.9376 8.36612 12.0498 8.36205 13.1339 8.56309C13.261 8.58006 13.3898 8.54755 13.4936 8.47226C13.5974 8.39698 13.6684 8.28469 13.6917 8.15861C13.7151 8.03252 13.6891 7.90227 13.6192 7.79479C13.5492 7.6873 13.4407 7.6108 13.3159 7.58109H13.3119Z" />
      <path d="M9.66106 4.77412C9.53774 4.79641 9.42729 4.86419 9.35158 4.96405C9.27587 5.06391 9.24041 5.18855 9.25223 5.31331C9.26405 5.43807 9.32229 5.55383 9.41541 5.63769C9.50853 5.72155 9.62975 5.76738 9.75506 5.76612C9.7886 5.76625 9.82208 5.76324 9.85506 5.75712C10.9377 5.54815 12.0499 5.54407 13.1341 5.74512C13.2641 5.76773 13.3978 5.73853 13.5066 5.66376C13.6154 5.589 13.6906 5.47462 13.7161 5.34512C13.74 5.21492 13.7114 5.08053 13.6364 4.97142C13.5614 4.86232 13.4462 4.78741 13.3161 4.76312C12.1078 4.53724 10.8679 4.54097 9.66106 4.77412Z" />
      <path d="M6.33487 10.3999C5.12813 10.1759 3.8901 10.18 2.68487 10.4119C2.56283 10.4354 2.45391 10.5035 2.37933 10.6029C2.30476 10.7023 2.26987 10.8259 2.28149 10.9496C2.2931 11.0734 2.35037 11.1883 2.44214 11.2721C2.53391 11.3559 2.65359 11.4026 2.77787 11.4029C2.81136 11.403 2.8448 11.4003 2.87787 11.3949C3.9605 11.1859 5.07273 11.1819 6.15687 11.3829C6.28574 11.4036 6.41757 11.373 6.52421 11.2978C6.63085 11.2225 6.70384 11.1086 6.7276 10.9802C6.75136 10.8519 6.724 10.7194 6.65137 10.6109C6.57873 10.5025 6.46658 10.4268 6.33887 10.3999H6.33487Z" />
      <path d="M6.33487 7.58109C5.12822 7.35605 3.89001 7.36012 2.68487 7.59309C2.56283 7.61656 2.45391 7.68463 2.37933 7.78404C2.30476 7.88346 2.26987 8.00708 2.28149 8.13081C2.2931 8.25454 2.35037 8.36951 2.44214 8.45331C2.53391 8.53711 2.65359 8.58373 2.77787 8.58409C2.81141 8.58432 2.8449 8.58131 2.87787 8.57509C3.96055 8.36661 5.07269 8.36254 6.15687 8.56309C6.28398 8.58006 6.41274 8.54754 6.51656 8.47226C6.62037 8.39698 6.69129 8.28469 6.71466 8.1586C6.73803 8.03251 6.71206 7.90227 6.64212 7.79478C6.57218 7.6873 6.46361 7.6108 6.33887 7.58109H6.33487Z" />
      <path d="M6.33493 4.7629C5.12831 4.53796 3.89017 4.54169 2.68493 4.7739C2.56171 4.79619 2.45134 4.8639 2.37564 4.96364C2.29994 5.06339 2.26442 5.18791 2.27611 5.31258C2.2878 5.43726 2.34584 5.55301 2.43876 5.63695C2.53168 5.72088 2.65272 5.7669 2.77793 5.7659C2.81148 5.76613 2.84497 5.76312 2.87793 5.7569C3.96062 5.54842 5.07275 5.54435 6.15693 5.7449C6.28713 5.76886 6.42152 5.7402 6.53062 5.66522C6.63973 5.59023 6.71464 5.47504 6.73893 5.3449C6.75075 5.28014 6.74967 5.21369 6.73575 5.14936C6.72182 5.08502 6.69533 5.02407 6.6578 4.97C6.62026 4.91592 6.57242 4.86979 6.51701 4.83426C6.46161 4.79872 6.39973 4.77447 6.33493 4.7629Z" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  }
);
