import { withIcon } from "@icons/_withIcon";

export const Partner = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4.25013C4.25736 4.25013 3.25 5.25749 3.25 6.50013C3.25 7.74277 4.25736 8.75013 5.5 8.75013C6.74264 8.75013 7.75 7.74277 7.75 6.50013C7.75 5.25749 6.74264 4.25013 5.5 4.25013ZM1.75 6.50013C1.75 4.42907 3.42893 2.75013 5.5 2.75013C7.57107 2.75013 9.25 4.42907 9.25 6.50013C9.25 8.5712 7.57107 10.2501 5.5 10.2501C3.42893 10.2501 1.75 8.5712 1.75 6.50013Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 13.2501C3.42893 13.2501 1.75 14.9291 1.75 17.0001H0.25C0.25 14.1006 2.6005 11.7501 5.5 11.7501C8.3995 11.7501 10.75 14.1006 10.75 17.0001H9.25C9.25 14.9291 7.57107 13.2501 5.5 13.2501Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8.75013C13.2574 8.75013 12.25 9.75749 12.25 11.0001C12.25 12.2428 13.2574 13.2501 14.5 13.2501C15.7426 13.2501 16.75 12.2428 16.75 11.0001C16.75 9.75749 15.7426 8.75013 14.5 8.75013ZM10.75 11.0001C10.75 8.92907 12.4289 7.25013 14.5 7.25013C16.5711 7.25013 18.25 8.92907 18.25 11.0001C18.25 13.0712 16.5711 14.7501 14.5 14.7501C12.4289 14.7501 10.75 13.0712 10.75 11.0001Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 17.7501C12.4289 17.7501 10.75 19.4291 10.75 21.5001H9.25C9.25 18.6006 11.6005 16.2501 14.5 16.2501C17.3995 16.2501 19.75 18.6006 19.75 21.5001H18.25C18.25 19.4291 16.5711 17.7501 14.5 17.7501Z"
      />
      <path d="M12.25 1.25012L13 2.00013L13.75 1.25013C14.1642 0.835921 14.8358 0.83592 15.25 1.25013C15.6642 1.66435 15.6642 2.33592 15.25 2.75013L13 5.00013L10.75 2.7502C10.3357 2.33596 10.3357 1.66432 10.75 1.25009C11.1642 0.835894 11.8358 0.83591 12.25 1.25012Z" />
    </>
  ),
  {
    viewBox: "0 0 20 22",
  }
);
