import { withIcon } from "@icons/_withIcon";

export const Family = withIcon(
  () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C10.7574 2.25 9.75 3.25736 9.75 4.5C9.75 5.74264 10.7574 6.75 12 6.75C13.2426 6.75 14.25 5.74264 14.25 4.5C14.25 3.25736 13.2426 2.25 12 2.25ZM8.25 4.5C8.25 2.42893 9.92893 0.75 12 0.75C14.0711 0.75 15.75 2.42893 15.75 4.5C15.75 6.57107 14.0711 8.25 12 8.25C9.92893 8.25 8.25 6.57107 8.25 4.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12622 2.55078C6.26553 2.0533 5.14597 2.17397 4.41055 2.90939C3.53187 3.78807 3.53187 5.21269 4.41055 6.09137C5.14597 6.82678 6.26553 6.94746 7.12622 6.44998L7.87686 7.74865C6.44297 8.57744 4.57757 8.3797 3.34989 7.15203C1.88542 5.68756 1.88542 3.31319 3.34989 1.84873C4.57757 0.621052 6.44297 0.423318 7.87686 1.25211L7.12622 2.55078Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5912 2.90901C18.8558 2.17365 17.7364 2.05294 16.8757 2.55028L16.1252 1.25153C17.5591 0.422973 19.4243 0.620774 20.6518 1.84835C22.1163 3.31282 22.1163 5.68718 20.6518 7.15165C19.4243 8.37923 17.5591 8.57703 16.1252 7.74847L16.8757 6.44972C17.7364 6.94706 18.8558 6.82635 19.5912 6.09099C20.4699 5.21231 20.4699 3.78769 19.5912 2.90901Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11.25C9.92893 11.25 8.25 12.9289 8.25 15V18H6.75V15C6.75 12.1005 9.1005 9.75 12 9.75C14.8995 9.75 17.25 12.1005 17.25 15V18H15.75V15C15.75 12.9289 14.0711 11.25 12 11.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 15C21.75 12.9289 20.0711 11.25 18 11.25V9.75C20.8995 9.75 23.25 12.1005 23.25 15V18H21.75V15Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.25C3.92893 11.25 2.25 12.9289 2.25 15V18H0.75V15C0.75 12.1005 3.1005 9.75 6 9.75V11.25Z"
      />
    </>
  ),
  {
    viewBox: "0 0 24 18",
  }
);
